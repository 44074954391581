import { Empty } from 'antd';

import ListPageTableWrapper from '@common/components/ListPageTableWrapper';
import Table from '@common/components/Table.legacy';

const DocumentsTable = ({ tableConfig }) => (
  <ListPageTableWrapper>
    <Table
      data-cy="documentsTable"
      {...tableConfig}
      locale={{
        emptyText: tableConfig.loading ? (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="Loading, please wait..."
          />
        ) : (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="No documents to show"
          />
        ),
      }}
    />
  </ListPageTableWrapper>
);

export default DocumentsTable;
