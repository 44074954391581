import { gql } from '@apollo/client';

import { AttributeType } from '@modules/compliance-profile/constants';

import { namespace } from '../constants';
//! this value should be an action param

export const LIST_CONNECTED_REQUIREMENTS = `${namespace}/LIST_CONNECTED_REQUIREMENTS`;

const LIST_CONNECTED_REQUIREMENTS_QUERY = gql`
  query requirements($input: RequirementInput, $documentsOptions: ListOptions) {
    requirements(input: $input) {
      nodes {
        _id
        documents(options: $documentsOptions) {
          _id
          friendlyName
          connectedLicense {
            status
            name
            number
            type
            initialData {
              firstName
              lastName
              licenseNumber
              licenseState
              producerNumber
            }
            customData {
              licenses {
                stateCode
                licenseStatus
                licenseExpirationDate
                licenseClassCode
                loa {
                  loaName
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const fetchConnectedRequirements = ({
  filter,
  options,
  documentsOptions,
}) => ({
  type: LIST_CONNECTED_REQUIREMENTS,
  payload: {
    key: 'requirements',
    graphql: {
      fetchPolicy: 'no-cache',
      query: LIST_CONNECTED_REQUIREMENTS_QUERY,
      variables: {
        input: {
          filter: {
            ...filter,
            attributeType: AttributeType.Connected,
          },
          ...options,
        },
        documentsOptions,
      },
    },
  },
});
