import { useFeatures as useReactFeaturesToggle } from '@paralleldrive/react-feature-toggles';
import * as R from 'ramda';

const useFeatures = () => {
  const features = useReactFeaturesToggle();

  const hasFeature = (feature) => {
    const regex = RegExp(feature, 'i');
    return R.any((x) => regex.test(x), features);
  };

  return { features, hasFeature };
};

export default useFeatures;
