export const USER_PLACEHOLDER_VALUES = {
  userName: 'userName',
  userEmail: 'userEmail',
  acceptInviteUrl: 'acceptInviteUrl',
} as const;

export const userPlaceholders = {
  title: 'User',
  placeholders: [
    {
      name: 'User name',
      region: `{{ ${USER_PLACEHOLDER_VALUES.userName} }}`,
    },
    {
      name: 'User email',
      region: `{{ ${USER_PLACEHOLDER_VALUES.userEmail} }}`,
    },
    {
      name: 'Accept invite URL',
      region: `{{ ${USER_PLACEHOLDER_VALUES.acceptInviteUrl} }}`,
    },
  ],
};
