import qs from 'query-string';
import * as R from 'ramda';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { setPreferredSearchParams } from '@modules/system-settings/actions';

const DEFAULT_SIZE = 12;
const DEFAULT_PAGE = 1;

const useListPagination = ({
  isSetPreferredSearchParamsEnabled = true,
} = {}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const searchParams = qs.parse(search);

  const { page, size } = searchParams;

  const onChangePagination = (page, size) => {
    const pageObj = page !== 1 ? { page } : {};
    const searchParamsWithoutPage = R.omit(['page'], searchParams);

    const newSearchParams = R.mergeAll([
      searchParamsWithoutPage,
      pageObj,
      { size },
    ]);

    if (isSetPreferredSearchParamsEnabled) {
      dispatch(
        setPreferredSearchParams({
          size,
        }),
      );
    }

    navigate(`${pathname}?${qs.stringify(newSearchParams)}`, { replace: true });
  };

  return {
    onChangePagination,
    currentPage: Number(page || DEFAULT_PAGE),
    currentSize: Number(size || DEFAULT_SIZE),
  };
};

export default useListPagination;
