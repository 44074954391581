import { InfoCircleFilled } from '@ant-design/icons';
import { Input, Modal } from 'antd';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

const CustomComplianceModal = ({
  initialValue,
  description,
  onSend,
  open,
  ...props
}) => {
  const [value, setValue] = useState('');

  useEffect(() => {
    if (initialValue) {
      setValue(initialValue);
    }
  }, [initialValue]);

  useEffect(() => {
    if (!open) {
      setValue('');
    }
  }, [open]);

  return (
    <Modal
      {...props}
      open={open}
      okButtonProps={{
        'data-cy': 'addCustomButton',
        disabled: !Boolean(value),
      }}
      onOk={() => {
        onSend(value);
        setValue('');
      }}
    >
      <CustomComplianceModal.Description>
        {description}
      </CustomComplianceModal.Description>
      <Input
        data-cy="customNameInput"
        autoFocus
        value={value}
        onChange={(e) => setValue(e.target.value)}
        placeholder="Name..."
      />
      <CustomComplianceModal.Warning>
        <CustomComplianceModal.InfoCircleFilled theme="filled" /> This name will
        be visible to your parties
      </CustomComplianceModal.Warning>
    </Modal>
  );
};

CustomComplianceModal.Description = styled.div`
  margin-bottom: 10px;
`;

CustomComplianceModal.Warning = styled.div`
  margin-top: 22px;
  margin-bottom: 3px;
`;

CustomComplianceModal.InfoCircleFilled = styled(InfoCircleFilled)`
  && {
    margin-right: 6px;
    color: #3f9aff;
  }
`;

export default CustomComplianceModal;
