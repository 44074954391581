import {
  CheckCircleOutlined,
  EditOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import {
  Alert,
  Button,
  type ButtonProps,
  Drawer,
  Modal,
  Tag,
  message,
} from 'antd';
import * as R from 'ramda';
import { useEffect, useReducer } from 'react';
import styled from 'styled-components';

import Spinner from '@common/components/Spinner';
import { getCurrentComplianceProfile } from '@modules/compliance-profile/selectors';
import {
  fetchDocumentForm,
  updateDocumentForm,
} from '@modules/document/actions';
import FillDocumentForm from '@modules/document/components/FillDocumentForm';
import FillDocumentFormLegacy from '@modules/document/components/FillDocumentForm.legacy';
import { useSaveProgressOnFillSignFeatureFlag } from '@modules/feature-flags/hooks';
import { getRequestsByParty } from '@modules/party/selectors';
import { getProjectById } from '@modules/project/selectors';
import RequirementCard from '@modules/requirement/components/RequirementCard';
import { RequirementStatus } from '@modules/requirement/constants';
import { getGraphqlPayload, getNewGraphqlPayload } from '@store/helpers';
import { useAppDispatch, useAppSelector } from '@store/hooks';

import {
  type FetchFillableFormRequirementsSucceessAction,
  type FillableFormRequirement,
  fetchFillableFormRequirements,
} from '../actions';
import SignAgreement from '../components/SignAgreement';
import { FIELD_ASSOCIATION_TYPES, FILLABLE_FORM_STATUS } from '../constants';

type DocumentFormToSubmit = {
  _id: string;
  fillableForm: Record<string, any>;
  saveType: 'ALL' | 'PARTIAL';
};

type ReducerState = {
  isLoading: boolean;
  isSubmitting: boolean;
  isSavingDraft: boolean;
  isSaveDraftModalOpened: boolean;
  fillableFormRequirements: FillableFormRequirement[];
  openedDocumentForm?: any;
  documentFormToSubmit?: DocumentFormToSubmit;
  isSubmitAgreementModalOpened: boolean;
  submissionFailedMessage: boolean | string;
  isSubmitCompletedModalOpened: boolean;
  isDocumentFormDrawerOpened: boolean;
  isFormInSignAgreementModalVisible: boolean;
  signer?: { name: string; email: string };
};

type Reducer = (
  state: ReducerState,
  newState: Partial<ReducerState>,
) => ReducerState;

const reducer: Reducer = (state, newState = {}) => ({
  ...state,
  ...newState,
});

type FillableFormRequirementsListContainerProps = {
  requestId: string;
  token: string;
  filler: string;
  partyId: string;
  selectedProjectId?: string | null;
  onFillableFormRequirementsLoaded: (
    fillableFormRequirements: FillableFormRequirement[],
  ) => any;
  onDocumentFormUpdated?: () => any;
  documentFormSigner?: { name: string; email: string };
  organizationId: string;
  isReadOnly: boolean;
  canSign: boolean;
};

const FillableFormRequirementsListContainer = ({
  requestId,
  token,
  filler,
  partyId,
  selectedProjectId,
  onFillableFormRequirementsLoaded,
  onDocumentFormUpdated,
  documentFormSigner,
  organizationId,
  isReadOnly,
  canSign,
}: FillableFormRequirementsListContainerProps) => {
  const { isSaveProgressOnFillSignFeatureFlagEnabled } =
    useSaveProgressOnFillSignFeatureFlag();
  const dispatch = useAppDispatch();

  const [
    {
      isLoading,
      isSubmitting,
      isSavingDraft,
      isSaveDraftModalOpened,
      fillableFormRequirements,
      openedDocumentForm,
      documentFormToSubmit,
      isSubmitAgreementModalOpened,
      submissionFailedMessage,
      isSubmitCompletedModalOpened,
      isDocumentFormDrawerOpened,
      isFormInSignAgreementModalVisible,
      signer,
    },
    setState,
  ] = useReducer<Reducer>(reducer, {
    isLoading: false,
    isSubmitting: false,
    isSavingDraft: false,
    isSaveDraftModalOpened: false,
    fillableFormRequirements: [],
    openedDocumentForm: undefined,
    documentFormToSubmit: undefined,
    isSubmitAgreementModalOpened: false,
    submissionFailedMessage: false,
    isSubmitCompletedModalOpened: false,
    isDocumentFormDrawerOpened: false,
    isFormInSignAgreementModalVisible: false,
    signer: documentFormSigner,
  });

  const project = useAppSelector<any>((state) =>
    getProjectById(state, selectedProjectId),
  );
  const complianceProfile = useAppSelector<any>(getCurrentComplianceProfile);

  const partyRequests = useAppSelector((state) =>
    getRequestsByParty(state, partyId),
  );
  const latestRequest = R.last(partyRequests);

  const getFillableFormRequirements = async () => {
    setState({
      isLoading: true,
    });

    const res = await dispatch(
      fetchFillableFormRequirements({
        partyId,
        projectId: selectedProjectId,
        filler,
      }),
    );

    const payload =
      getGraphqlPayload<FetchFillableFormRequirementsSucceessAction>(res);

    if (payload) {
      setState({ fillableFormRequirements: payload });
      onFillableFormRequirementsLoaded &&
        onFillableFormRequirementsLoaded(payload);
    }

    setState({
      isLoading: false,
    });
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: Legacy
  useEffect(() => {
    getFillableFormRequirements();
  }, [R.prop('_id', complianceProfile), selectedProjectId]);

  useEffect(() => {
    !isSubmitAgreementModalOpened && setState({ signer: documentFormSigner });
  }, [documentFormSigner, isSubmitAgreementModalOpened]);

  const getDocumentForm = async (formId: string) => {
    setState({ isDocumentFormDrawerOpened: true });

    const res = await dispatch(
      fetchDocumentForm({
        requestId,
        token,
        partyId,
        projectId: project?._id,
        fillableFormId: formId,
        organization: organizationId,
      }),
    );
    const payload = getGraphqlPayload<any>(res);
    setState({ openedDocumentForm: payload });
  };

  const handleOnDocumentFormSaveDraft = (data: DocumentFormToSubmit) => {
    setState({
      documentFormToSubmit: data,
      isSaveDraftModalOpened: true,
    });
  };

  const handleOnDocumentFormSubmit = (data: DocumentFormToSubmit) => {
    setState({
      documentFormToSubmit: data,
      isSubmitAgreementModalOpened: true,
    });
  };

  const handleOnUpdateDocumentForm = async (
    documentForm: DocumentFormToSubmit,
  ) => {
    // update document
    // if last signer, documentFormToSubmit contanis the flattened documentFile
    const res = await dispatch(
      updateDocumentForm({
        requestId,
        token,
        projectId: project?._id,
        filler,
        signerName: signer?.name,
        ...documentForm,
      }),
    );

    const { data: updatedDocumentForm, error } = getNewGraphqlPayload<any>(res);

    if (error?.code) {
      throw new Error(`${error.code}: ${error.message}`);
    }

    if (!updatedDocumentForm) {
      throw new Error('Error when updating the document');
    }

    if (
      updatedDocumentForm.fillableForm?.status ===
      FILLABLE_FORM_STATUS.COMPLETED
    ) {
      throw new Error('Error when signing the document');
    }
  };

  const handleOnSubmitModalAgreement = async () => {
    try {
      setState({ isSubmitting: true });

      await handleOnUpdateDocumentForm(
        documentFormToSubmit as DocumentFormToSubmit,
      );

      setState({
        isSubmitAgreementModalOpened: false,
        isSubmitting: false,
        isSubmitCompletedModalOpened: true,
      });
    } catch (err) {
      setState({
        isSubmitAgreementModalOpened: false,
        isSubmitting: false,
        submissionFailedMessage: (err as Error).message,
      });
    }
  };

  const handleOnConfirmSaveDraft = async () => {
    try {
      setState({ isSavingDraft: true });

      await handleOnUpdateDocumentForm(
        documentFormToSubmit as DocumentFormToSubmit,
      );

      setState({
        isSaveDraftModalOpened: false,
        isSavingDraft: false,
      });

      handleOnCloseDrawer();
    } catch (err) {
      setState({
        isSaveDraftModalOpened: false,
        isSavingDraft: false,
        submissionFailedMessage: (err as Error).message,
      });

      getFillableFormRequirements();
    }
  };

  const handleOnCloseDrawer = () => {
    return new Promise((res) => {
      setState({
        isDocumentFormDrawerOpened: false,
        openedDocumentForm: undefined,
        isSubmitCompletedModalOpened: false,
      });

      // wait drawer animation
      setTimeout(res, 300);
    });
  };

  const handleOnCloseDrawerAfterSubmit = () => {
    handleOnCloseDrawer().then(() => {
      message.success(
        'Thank you for completing. This document is being digitally signed.',
      );
      getFillableFormRequirements();
      onDocumentFormUpdated && onDocumentFormUpdated();
    });
  };

  const handleOnFillClick = (...props: [string]) => {
    if (isReadOnly) {
      Modal.error({
        title: 'Access restricted',
        content: (
          <div>
            <p>
              To protect your privacy, this document can be viewed and completed
              only by accessing from the authorized link in the email we sent
              you.
            </p>
            <p>
              Please click the link in the email to enable your ability to view
              and complete this document.
            </p>
          </div>
        ),
      });
      return;
    }

    if (!canSign) {
      Modal.error({
        title: 'Not allowed',
        content: (
          <div>
            <p>
              You are not able to sign a forwarded request. Please contact the
              requester if you need to sign.
            </p>
          </div>
        ),
      });
      return;
    }

    getDocumentForm(...props);
  };

  const handleOnSignerChange = (signer: ReducerState['signer']) =>
    setState({ signer });

  const getAction = ({
    requirementComplianceStatusValue,
    fillableForm,
    documents,
  }: Pick<
    FillableFormRequirement,
    'requirementComplianceStatusValue' | 'fillableForm' | 'documents'
  >) => {
    if (requirementComplianceStatusValue === RequirementStatus.NonCompliant) {
      const isSigning = documents?.some(
        (doc) => doc?.fillableForm?.status === FILLABLE_FORM_STATUS.SIGNING,
      );

      if (isSigning) {
        return (
          <Tag icon={<SyncOutlined />} color="processing">
            Compiling Document
          </Tag>
        );
      }

      return (
        <Button
          type="primary"
          data-cy="fillableFormRequirementCardFillButton"
          onClick={() => handleOnFillClick(fillableForm?._id)}
          disabled={Boolean(requestId && latestRequest?._id !== requestId)}
        >
          <EditOutlined />
          Fill & Sign
        </Button>
      );
    }

    return (
      <Tag
        icon={<CheckCircleOutlined />}
        color="success"
        data-cy="fillableFormDocumentFormCompletedBadge"
      >
        Completed
      </Tag>
    );
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div>
      {fillableFormRequirements.map(
        ({
          _id,
          fillableForm,
          requirementComplianceStatusValue,
          documents,
        }) => (
          <RequirementCard
            key={_id}
            icon={EditOutlined}
            title={fillableForm?.name}
            description={fillableForm?.additionalInfo}
            data-cy="fillableFormRequirementCard"
            action={getAction({
              requirementComplianceStatusValue,
              fillableForm,
              documents,
            })}
          />
        ),
      )}
      <FillableFormRequirementsListContainer.Drawer
        closable={false}
        open={isDocumentFormDrawerOpened}
        destroyOnClose={true}
        bodyStyle={{ padding: 0 }}
        contentWrapperStyle={{
          width: '950px',
        }}
      >
        {Boolean(openedDocumentForm) ? (
          isSaveProgressOnFillSignFeatureFlagEnabled ? (
            <FillDocumentForm
              documentForm={openedDocumentForm}
              project={project}
              filler={filler}
              onBackButtonClick={handleOnCloseDrawer}
              onSubmit={handleOnDocumentFormSubmit}
              onSaveDraft={handleOnDocumentFormSaveDraft}
            />
          ) : (
            <FillDocumentFormLegacy
              documentForm={openedDocumentForm}
              project={project}
              filler={filler}
              onBackButtonClick={handleOnCloseDrawer}
              onSubmit={handleOnDocumentFormSubmit}
            />
          )
        ) : (
          <Spinner />
        )}
      </FillableFormRequirementsListContainer.Drawer>
      <Modal
        open={isSaveDraftModalOpened}
        onOk={handleOnConfirmSaveDraft}
        onCancel={() => setState({ isSaveDraftModalOpened: false })}
        confirmLoading={isSavingDraft}
        title="Fill & Sign is not complete"
        okText="Save"
        cancelText="Cancel"
        closable={!isSavingDraft}
        maskClosable={!isSavingDraft}
        cancelButtonProps={{ disabled: isSavingDraft }}
        destroyOnClose
      >
        Do you want to save your progress and complete at a later time?
      </Modal>
      <Modal
        open={isSubmitAgreementModalOpened}
        onOk={handleOnSubmitModalAgreement}
        onCancel={() => setState({ isSubmitAgreementModalOpened: false })}
        confirmLoading={isSubmitting}
        title="Almost done"
        okText="I agree"
        okButtonProps={
          {
            'data-cy': 'documentFormSubmitAgreeButton',
            disabled:
              isFormInSignAgreementModalVisible ||
              (filler === FIELD_ASSOCIATION_TYPES.PARTY && !signer?.name),
          } as ButtonProps
        }
        zIndex={1005}
        closable={!isSubmitting}
        maskClosable={!isSubmitting}
        cancelButtonProps={{ disabled: isSubmitting }}
        destroyOnClose
      >
        {isSubmitting ? (
          <Spinner />
        ) : (
          <SignAgreement
            signer={signer}
            verifySigner={filler === FIELD_ASSOCIATION_TYPES.PARTY}
            onSignerChange={handleOnSignerChange}
            onFormVisibilityChange={(isVisible: boolean) =>
              setState({
                isFormInSignAgreementModalVisible: isVisible,
              })
            }
          />
        )}
      </Modal>
      <Modal
        open={isSubmitCompletedModalOpened}
        title="Form successfully completed"
        okText="Close"
        // @ts-ignore
        okType="secondary"
        cancelButtonProps={{ style: { display: 'none' } }}
        onOk={handleOnCloseDrawerAfterSubmit}
        onCancel={handleOnCloseDrawerAfterSubmit}
        zIndex={1005}
      >
        <Alert
          message={openedDocumentForm?.document?.friendlyName}
          description="You’ve completed all of your fields in this document."
          type="success"
          showIcon
        />
      </Modal>
      <Modal
        open={Boolean(submissionFailedMessage)}
        title="Form submission failed"
        okText="Close"
        // @ts-ignore
        okType="secondary"
        cancelButtonProps={{ style: { display: 'none' } }}
        onOk={() => setState({ submissionFailedMessage: false })}
        onCancel={() => setState({ submissionFailedMessage: false })}
        zIndex={1005}
      >
        <Alert
          message={openedDocumentForm?.document?.friendlyName}
          description={
            submissionFailedMessage ||
            'The submission failed with an error. Please try again.'
          }
          type="error"
          showIcon
        />
      </Modal>
    </div>
  );
};

FillableFormRequirementsListContainer.Drawer = styled(Drawer)`
  z-index: 1002;

  .ant-drawer-content-wrapper {
    max-width: 90%;

    @media (max-width: 450px) {
      max-width: 100%;
    }
  }
`;

export default FillableFormRequirementsListContainer;
