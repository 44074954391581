const WarningStatus = () => (
  <svg
    viewBox="0 0 12 12"
    fill="none"
    display="block"
    xmlns="http://www.w3.org/2000/svg"
    data-cy="warningStatusCircleIcon"
  >
    <rect width="12" height="12" rx="6" fill="#FADB14" />
    <path
      d="M6.625 2.25a.624.624 0 1 0-1.25 0v5a.624.624 0 1 0 1.25 0v-5ZM6 10.375a.781.781 0 1 0 0-1.562.781.781 0 0 0 0 1.562Z"
      fill="#614700"
    />
  </svg>
);
export default WarningStatus;
