const MenuBarIcon = ({ isActive, width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.6957 1.52588e-05H7.91304C7.74565 1.52588e-05 7.6087 0.136972 7.6087 0.304363V6.08697C7.6087 6.25436 7.74565 6.39132 7.91304 6.39132H13.6957C13.863 6.39132 14 6.25436 14 6.08697V0.304363C14 0.136972 13.863 1.52588e-05 13.6957 1.52588e-05ZM13.6957 7.60871H7.91304C7.74565 7.60871 7.6087 7.74567 7.6087 7.91306V13.6957C7.6087 13.8631 7.74565 14 7.91304 14H13.6957C13.863 14 14 13.8631 14 13.6957V7.91306C14 7.74567 13.863 7.60871 13.6957 7.60871ZM6.08696 1.52588e-05H0.304348C0.136957 1.52588e-05 0 0.136972 0 0.304363V6.08697C0 6.25436 0.136957 6.39132 0.304348 6.39132H6.08696C6.25435 6.39132 6.3913 6.25436 6.3913 6.08697V0.304363C6.3913 0.136972 6.25435 1.52588e-05 6.08696 1.52588e-05ZM6.08696 7.60871H0.304348C0.136957 7.60871 0 7.74567 0 7.91306V13.6957C0 13.8631 0.136957 14 0.304348 14H6.08696C6.25435 14 6.3913 13.8631 6.3913 13.6957V7.91306C6.3913 7.74567 6.25435 7.60871 6.08696 7.60871Z"
      fill={isActive ? '#fff' : '#dedede'}
    />
  </svg>
);

MenuBarIcon.defaultProps = {
  width: 17,
  height: 17,
};

export default MenuBarIcon;
