import { useQuery } from '@graphql/hooks';
import { graphql } from '@graphql/types';

const EMAIL_TEMPLATES_QUERY = graphql(`
  query SendRequestDrawer_EmailTemplates {
    listEmailTemplates {
      _id
      name
      type
    }
  }
`);

export const useEmailTemplatesQuery = ({
  skip,
}: {
  skip?: boolean;
}) => {
  const { loading, data } = useQuery(EMAIL_TEMPLATES_QUERY, {
    includeTypename: true,
    skip,
  });

  return {
    emailTemplates: data?.listEmailTemplates,
    isEmailTemplatesLoading: loading,
  };
};
