import { Col, Pagination, Row, Spin } from 'antd';
import * as R from 'ramda';
import styled from 'styled-components';

import ProjectCard from './ProjectCard';
import ProjectsEmptyState from './ProjectsEmptyState';

const columnConfig = {
  sm: { span: 24 },
  md: { span: 12 },
  xl: { span: 8 },
};

const ProjectsCardsView = ({
  loading,
  projects,
  currentProjectId,
  setProjectAsCurrent,
  selectedProjects,
  setSelectedProjects,
  paginationOptions,
  openEditProjectModal,
  areAllProjectsSelected,
  setSelectedProjectsCount,
  setAreAllProjectsSelected,
}) => (
  <ProjectsCardsView.Wrapper>
    <ProjectsCardsView.ViewWrapper>
      <Spin spinning={loading}>
        <Row type="flex" gutter={[16, 16]} style={{ alignItems: 'stretch' }}>
          {R.isEmpty(projects) ? (
            <ProjectsCardsView.EmptyWrapper>
              <ProjectsEmptyState />
            </ProjectsCardsView.EmptyWrapper>
          ) : (
            projects.map((project) => (
              <Col key={`key_${project._id}`} {...columnConfig}>
                <ProjectCard
                  project={project}
                  currentProjectId={currentProjectId}
                  setAreAllProjectsSelected={setAreAllProjectsSelected}
                  setProjectAsCurrent={setProjectAsCurrent}
                  selectedProjects={
                    areAllProjectsSelected
                      ? projects.map((project) => project._id)
                      : selectedProjects
                  }
                  setSelectedProjectsCount={setSelectedProjectsCount}
                  setSelectedProjects={setSelectedProjects}
                  openEditProjectModal={openEditProjectModal}
                />
              </Col>
            ))
          )}
        </Row>
      </Spin>
    </ProjectsCardsView.ViewWrapper>
    <ProjectsCardsView.Pagination {...paginationOptions} />
  </ProjectsCardsView.Wrapper>
);

ProjectsCardsView.Wrapper = styled.div`
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

ProjectsCardsView.ViewWrapper = styled.div`
  width: 100%;
  padding: 22px 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray};
`;

ProjectsCardsView.EmptyWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

ProjectsCardsView.Pagination = styled(Pagination)`
  margin-top: 22px !important;
  display: flex;
  align-items: center;
`;

export default ProjectsCardsView;
