import { SyncOutlined } from '@ant-design/icons';
import { Alert, Popover, Tag } from 'antd';
import styled from 'styled-components';

const PendingBadge = () => (
  <Popover
    content={
      <StyledAlert
        message="The license information is being retrieved and should be available within the next 24 hours."
        type="info"
        showIcon
      />
    }
    title="Awaiting verification"
    trigger="hover"
  >
    <Tag icon={<SyncOutlined />} color="processing">
      Awaiting verification
    </Tag>
  </Popover>
);

const StyledAlert = styled(Alert)`
  max-width: 360px;
  align-items: baseline;
`;

export default PendingBadge;
