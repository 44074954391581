import { InfoCircleFilled, InfoCircleOutlined } from '@ant-design/icons';
import { Checkbox, Modal, Popover, Space } from 'antd';
import { useState } from 'react';
import styled from 'styled-components';

import FullWidthSpace from '@common/components/FullWidthSpace';

const RemoveWaiverModal = ({
  visible,
  onClose,
  onSubmit,
  loading,
  isOverride,
}: {
  visible: boolean;
  loading: boolean;
  onClose: () => void;
  onSubmit: (props: { applyToAllProjects: boolean }) => void | Promise<void>;
  isOverride?: boolean;
}) => {
  const [applyToAllProjects, setToApplyAllProjects] = useState(false);
  const onCancelHandle = () => {
    setToApplyAllProjects(false);
    onClose();
  };
  const onOkHandle = () => {
    const res = onSubmit({ applyToAllProjects });

    if (res instanceof Promise) {
      res.finally(() => {
        setToApplyAllProjects(false);
      });
    } else {
      setToApplyAllProjects(false);
    }
  };

  return (
    <Modal
      title={
        <Space size={12} direction="horizontal">
          {
            // custom spacing to align with Modal.confirm. See docs: https://ant.design/components/modal
          }
          <StyledInfoCircle />
          <StyledModalTitle>
            Are you sure you want to remove the{' '}
            {isOverride ? 'override' : 'waiver'} from this requirement?
          </StyledModalTitle>
        </Space>
      }
      open={visible}
      onCancel={onCancelHandle}
      okButtonProps={{
        // @ts-expect-error - Cypress selector
        'data-cy': 'confirmRestoreRequirementButton',
      }}
      confirmLoading={loading}
      onOk={onOkHandle}
      centered={true}
      okText={`Remove ${isOverride ? 'override' : 'waiver'}`}
    >
      {
        <FullWidthSpace size="small" align="center">
          <Checkbox
            onChange={() => setToApplyAllProjects((value) => !value)}
            checked={applyToAllProjects}
            id="remove-waiver-across-all-projects"
          />
          <label htmlFor="remove-waiver-across-all-projects">
            Remove {isOverride ? 'override' : 'waive'} across all projects
          </label>
          <Popover
            content={
              <>
                This will be applied to all <strong>existing</strong> and{' '}
                <strong>future</strong> projects
              </>
            }
          >
            <InfoCircleOutlined />
          </Popover>
        </FullWidthSpace>
      }
    </Modal>
  );
};

// --ant-color-warning
// based on the modal title font-size
const StyledInfoCircle = styled(InfoCircleFilled)`
  color: #faad14;
  font-size: 22px;
`;

// based on the modal title font-size
const StyledModalTitle = styled.span`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
`;

export default RemoveWaiverModal;
