import {
  CloudDownloadOutlined,
  ContainerOutlined,
  FileSearchOutlined,
} from '@ant-design/icons';
import { Button, Dropdown, Menu, Space } from 'antd';

import SelectionDropdown from '@common/components/SelectionDropdown';
import {
  useIsComplianceRequirementsAddOnEnabled,
  useIsContextsAddOnEnabled,
} from '@modules/add-on';

const DocumentsFilterButtons = ({
  updateDocumentsArchiveStatus,
  isActiveDocuments,
  deleteDocuments,
  setIsVisibleAssignModal,
  setIsVisibleAssociateToProjectModal,
  setIsVisibleAddDocumentTypesModal,
  setIsVisibleRemoveDocumentTypesModal,
  setIsVisibleBulkMatchRequirementsModal,
  setIsVisibleAddRequirementValueModal,
  markSelectedAsReviewed,
  markSelectedAsToReview,
  detachDocumentsFromParty,
  detachDocumentsFromProject,
  downloadDocuments,
  reviewDocuments,
  currentProjectId,
  disableDownloadButton,
  disableReviewButton,
  disableBulkMatchRequirementsButton,
  disableAddDocumentTypesButton,
  disableRemoveDocumentTypesButton,
  areAllSelected,
  selectedEntitiesCount,
  onSelectAll,
  onSelectAllVisible,
  onSelectNone,
  totalCount,
  totalVisible,
}) => {
  const { isContextsAddOnEnabled } = useIsContextsAddOnEnabled();
  const { isComplianceRequirementsAddOnEnabled } =
    useIsComplianceRequirementsAddOnEnabled();

  return (
    <Space>
      <SelectionDropdown
        areAllSelected={areAllSelected}
        selectedEntitiesCount={selectedEntitiesCount}
        onSelectAll={onSelectAll}
        onSelectAllVisible={onSelectAllVisible}
        onSelectNone={onSelectNone}
        totalCount={totalCount}
        totalVisible={totalVisible}
      />
      <Button
        data-cy="downloadDocumentsButton"
        icon={<CloudDownloadOutlined />}
        onClick={downloadDocuments}
        disabled={disableDownloadButton}
      >
        Download
      </Button>
      <Button
        data-cy="reviewDocumentsButton"
        icon={<FileSearchOutlined />}
        disabled={disableReviewButton}
        onClick={reviewDocuments}
      >
        Review
      </Button>
      <Button
        data-cy={
          isActiveDocuments
            ? 'archiveDocumentsButton'
            : 'unarchiveDocumentsButton'
        }
        icon={<ContainerOutlined />}
        onClick={updateDocumentsArchiveStatus}
      >
        {isActiveDocuments ? 'Archive' : 'Unarchive'}
      </Button>
      <Dropdown
        trigger={['click']}
        dropdownRender={() => (
          <Menu>
            <Menu.Item onClick={() => setIsVisibleAssignModal(true)}>
              Assign to party
            </Menu.Item>
            <Menu.Item onClick={detachDocumentsFromParty}>
              Detach from current party
            </Menu.Item>
            {isContextsAddOnEnabled && (
              <Menu.Item
                onClick={() => setIsVisibleAssociateToProjectModal(true)}
              >
                Associate to project
              </Menu.Item>
            )}
            {isContextsAddOnEnabled && (
              <Menu.Item
                onClick={detachDocumentsFromProject}
                disabled={!currentProjectId}
              >
                Detach from current project
              </Menu.Item>
            )}
            <Menu.Item
              onClick={() => setIsVisibleAddDocumentTypesModal(true)}
              disabled={disableAddDocumentTypesButton}
            >
              Add document types
            </Menu.Item>
            <Menu.Item
              onClick={() => setIsVisibleRemoveDocumentTypesModal(true)}
              disabled={disableRemoveDocumentTypesButton}
            >
              Remove document types
            </Menu.Item>
            {isComplianceRequirementsAddOnEnabled && (
              <Menu.Item
                disabled={disableBulkMatchRequirementsButton}
                data-cy="bulkMatchRequirementsButton"
                onClick={() => setIsVisibleBulkMatchRequirementsModal(true)}
              >
                Bulk match requirements
              </Menu.Item>
            )}
            <Menu.Item
              data-cy="markAsReviewed"
              onClick={markSelectedAsReviewed}
            >
              Mark as reviewed
            </Menu.Item>
            <Menu.Item
              data-cy="markAsToReview"
              onClick={markSelectedAsToReview}
            >
              Mark as to review
            </Menu.Item>
            {isComplianceRequirementsAddOnEnabled && (
              <Menu.Item
                data-cy="addRequirementValueButton"
                onClick={() => setIsVisibleAddRequirementValueModal(true)}
              >
                Add requirement value
              </Menu.Item>
            )}
            <Menu.Item onClick={deleteDocuments}>Delete</Menu.Item>
          </Menu>
        )}
      >
        <Button data-cy="documentsMoreActionsButton">More...</Button>
      </Dropdown>
    </Space>
  );
};
export default DocumentsFilterButtons;
