import { gql } from '@apollo/client';

import { namespace } from '../constants';

export const CREATE_PARTY_CONVERSATION = `${namespace}/CREATE_PARTY_CONVERSATION`;

const CREATE_PARTY_CONVERSATION_MUTATION = gql`
  mutation createPartyConversation($payload: CreatePartyConversationInput!) {
    createPartyConversation(data: $payload)
  }
`;

export const createPartyConversation = (payload) => ({
  type: CREATE_PARTY_CONVERSATION,
  payload: {
    key: 'createPartyConversation',
    graphql: {
      mutation: CREATE_PARTY_CONVERSATION_MUTATION,
      variables: {
        payload,
      },
    },
  },
});
