import Flex from '@common/components/Flex';
import TagWithPopover from '@common/components/TagWithPopover';
import { CustomFieldType } from '@modules/custom-field/constants';
import { getSelectedOptionValue } from '@modules/custom-field/utils/custom-field-helpers';
import { Spin } from 'antd';
import styled from 'styled-components';
import { useProjectCustomFields } from './hooks';

export function ProjectCustomFieldChips({
  projectId,
}: Readonly<{ projectId: string }>) {
  const { customFields, loading } = useProjectCustomFields(projectId);

  if (loading) {
    return (
      <Flex justify="center">
        <Spin />
      </Flex>
    );
  }

  return (
    <StyledCustomFieldWrapper>
      {customFields.map(({ customField, value }) => (
        <TagWithPopover
          key={customField._id}
          color={customField.color}
          name={customField.name}
          value={
            customField.type === CustomFieldType.Dropdown && customField.options
              ? getSelectedOptionValue({
                  options: customField.options,
                  selectedOptionId: value,
                })
              : value
          }
        />
      ))}
    </StyledCustomFieldWrapper>
  );
}

const StyledCustomFieldWrapper = styled.div`
  margin: 7px 100px 0 70px;

  .ant-tag {
    margin-bottom: 7px;
  }
`;
