import Icon, {
  MailOutlined,
  MailTwoTone,
  PhoneOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Avatar } from 'antd';
import * as R from 'ramda';
import styled from 'styled-components';

import PrimaryStarIcon from '@common/icons/PrimaryStarIcon';

import FaxIcon from './icons/FaxIcon';

const PartyContactCard = ({ partyContact }) => (
  <PartyContactCard.Wrapper>
    <PartyContactCard.ContentWrapper>
      <PartyContactCard.IconWrapper>
        {Boolean(R.prop('avatar', partyContact)) ? (
          <Avatar size={34} src={R.prop('avatar', partyContact)} />
        ) : (
          <Avatar size={34} icon={<UserOutlined />} />
        )}
        {R.prop('isPrimary', partyContact) && (
          <PartyContactCard.PrimaryIconWrapper>
            <PrimaryStarIcon />
          </PartyContactCard.PrimaryIconWrapper>
        )}
      </PartyContactCard.IconWrapper>
      <PartyContactCard.DescriptionWrapper>
        {R.prop('contactPersonName', partyContact) ? (
          <PartyContactCard.Name>
            {R.prop('contactPersonName', partyContact)}
          </PartyContactCard.Name>
        ) : (
          <PartyContactCard.Unnamed>Unnamed Contact</PartyContactCard.Unnamed>
        )}
        {R.prop('companyName', partyContact) && (
          <PartyContactCard.CompanyName>
            {R.prop('companyName', partyContact)}
          </PartyContactCard.CompanyName>
        )}
        {R.prop('title', partyContact) && (
          <PartyContactCard.Title>
            {R.prop('title', partyContact)}
          </PartyContactCard.Title>
        )}
        {R.prop('email', partyContact) && (
          <PartyContactCard.Email>
            {R.prop('isDefaultRequestRecipient', partyContact) ? (
              <MailTwoTone />
            ) : (
              <MailOutlined />
            )}
            {R.prop('email', partyContact)}
          </PartyContactCard.Email>
        )}
        {R.prop('phone', partyContact) && (
          <PartyContactCard.Phone>
            <PhoneOutlined />
            {R.prop('phone', partyContact)}
          </PartyContactCard.Phone>
        )}
        {R.prop('fax', partyContact) && (
          <PartyContactCard.Fax>
            <Icon component={FaxIcon} />
            {R.prop('fax', partyContact)}
          </PartyContactCard.Fax>
        )}
      </PartyContactCard.DescriptionWrapper>
    </PartyContactCard.ContentWrapper>
  </PartyContactCard.Wrapper>
);

PartyContactCard.Wrapper = styled.div`
  margin-bottom: 10px;
  padding: 5px;

  .ant-avatar.ant-avatar-icon {
    background-color: rgb(105, 123, 141);
  }
`;

PartyContactCard.ContentWrapper = styled.div`
  display: flex;
`;

PartyContactCard.IconWrapper = styled.div`
  position: relative;
`;

PartyContactCard.PrimaryIconWrapper = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;
`;

PartyContactCard.DescriptionWrapper = styled.div`
  margin-left: 13px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;

  .anticon {
    margin-right: 10px;
  }
`;

PartyContactCard.Name = styled.div`
  font-size: 16px;
  line-height: 19px;
  color: rgba(0, 0, 0, 0.85);
`;

PartyContactCard.Unnamed = styled.div`
  font-size: 16px;
  color: rgba(0, 0, 0, 0.35);
`;

PartyContactCard.Title = styled.div`
  margin-top: 7px;
`;

PartyContactCard.CompanyName = styled.div`
  margin-top: 7px;
  font-size: 14px;
`;

PartyContactCard.Email = styled.div`
  margin-top: 7px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

PartyContactCard.Phone = styled.div`
  margin-top: 7px;
`;

PartyContactCard.Fax = styled.div`
  margin-top: 7px;
`;

PartyContactCard.ActionWrapper = styled.div`
  height: 20%;
  margin-left: auto;
  cursor: pointer;
`;

PartyContactCard.MenuIconWrapper = styled.div`
  width: 15px;
  display: flex;
  justify-content: center;
  font-size: 15px;
`;

export default PartyContactCard;
