import type { CustomFieldOption } from '@graphql/types/graphql';

import type { CustomField } from '../../constants';

export const mapCustomFieldsToFormValues = (customFields?: CustomField[]) =>
  customFields?.reduce(
    (obj, customField) => ({
      ...obj,
      [customField._id]: customField.value,
    }),
    {} as Record<string, CustomField['value']>,
  );

export const isCustomFieldValueValid = (
  value: string | number | null | undefined,
): boolean => {
  if (typeof value === 'number' && !Number.isNaN(value)) return true;
  if (typeof value === 'string' && Boolean(value)) return true;
  return false;
};

type ParsedCustomFieldValue =
  | {
      optionId: string;
      value: string | number;
    }
  | { value: string | number };

export const parseCustomFieldValue = (
  value: string | number,
): ParsedCustomFieldValue => {
  try {
    const parsedValue: CustomFieldOption = JSON.parse(value as string);

    if (typeof parsedValue === 'object' && typeof parsedValue !== 'number') {
      return {
        optionId: parsedValue._id,
        value: parsedValue.value,
      };
    } else {
      return { value };
    }
  } catch (e) {
    return { value };
  }
};
