import { gql } from '@apollo/client';

import { namespace } from '../constants';

export const IS_CONTACT_EMAIL_TAKEN = `${namespace}/IS_CONTACT_EMAIL_TAKEN`;

// get is contact email taken
const IS_CONTACT_EMAIL_TAKEN_QUERY = gql`
  query isContactEmailTaken($payload: isEmailTakenInput!) {
    isContactEmailTaken(data: $payload)
  }
`;

export const isContactEmailTaken = (payload) => ({
  type: IS_CONTACT_EMAIL_TAKEN,
  payload: {
    key: 'isContactEmailTaken',
    graphql: {
      fetchPolicy: 'no-cache',
      query: IS_CONTACT_EMAIL_TAKEN_QUERY,
      variables: { payload },
    },
  },
});
