import {
  useIsComplianceRequirementsAddOnEnabled,
  useIsContextsAddOnEnabled,
} from '@modules/add-on';

import { useMemo } from 'react';
import { getPlaceholders } from '../constants';

export const usePlaceholders = () => {
  const { isComplianceRequirementsAddOnEnabled } =
    useIsComplianceRequirementsAddOnEnabled();
  const { isContextsAddOnEnabled } = useIsContextsAddOnEnabled();

  const placeholders = useMemo(
    () =>
      getPlaceholders({
        isComplianceRequirementsAddOnEnabled,
        isContextsAddOnEnabled,
      }),
    [isComplianceRequirementsAddOnEnabled, isContextsAddOnEnabled],
  );

  return { placeholders };
};
