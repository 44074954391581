import { InfoCircleOutlined } from '@ant-design/icons';
import { Checkbox, Popover, Skeleton } from 'antd';
import type { ReactNode } from 'react';
import styled from 'styled-components';

type DocumentRemindersCheckboxProps = {
  isChecked: boolean;
  isVisible: boolean;
  isLoading?: boolean;
  isDisabled?: boolean;
  onChange: (arg: boolean) => void;
  popoverContent: () => ReactNode;
};

const CHECKBOX_LABEL = 'Enable Document reminders';
const DISABLED_CHECKBOX_POPOVER_TEXT =
  'Please add an action under Settings > Automations in order to enable this reminder';

export const DocumentRemindersAutomationCheckbox = ({
  isChecked = true,
  isDisabled,
  isLoading,
  isVisible,
  onChange,
  popoverContent,
}: DocumentRemindersCheckboxProps) => {
  if (isLoading) {
    return (
      <div data-cy="checkboxSkeleton">
        <StyledCheckboxSkeleton active size="small" />
      </div>
    );
  }

  if (isDisabled) {
    return (
      <Checkbox checked={false} disabled>
        <Popover content={DISABLED_CHECKBOX_POPOVER_TEXT}>
          <span>{CHECKBOX_LABEL}</span>
          <StyledInfoIcon data-cy="disabledDocumentRemindersCheckboxInfoIcon" />
        </Popover>
      </Checkbox>
    );
  }

  return (
    <>
      {isVisible && (
        <Checkbox
          checked={isChecked}
          onChange={({ target }) => {
            onChange(target.checked);
          }}
        >
          <span>{CHECKBOX_LABEL}</span>
          <Popover title="Reminders" content={popoverContent}>
            <StyledInfoIcon data-cy="documentRemindersCheckboxInfoIcon" />
          </Popover>
        </Checkbox>
      )}
    </>
  );
};

const StyledInfoIcon = styled(InfoCircleOutlined)`
  margin-left: 8px;
`;

const StyledCheckboxSkeleton = styled(Skeleton.Input)`
  width: 250px;
`;
