import { WarningOutlined } from '@ant-design/icons';
import { Alert, Popover, Tag } from 'antd';
import styled from 'styled-components';

const ErrorBadge = () => (
  <Popover
    content={
      <StyledAlert
        message="The information provided from the party was unable to be matched to a National Producer Number."
        type="error"
        showIcon
      />
    }
    title="Unable to retrieve license information"
    trigger="hover"
  >
    <Tag icon={<WarningOutlined />} color="error">
      Unable to retrieve
    </Tag>
  </Popover>
);

const StyledAlert = styled(Alert)`
  max-width: 360px;
  align-items: baseline;
`;

export default ErrorBadge;
