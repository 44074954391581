import { gql } from '@apollo/client';

import { namespace } from '../constants';

export const FETCH_DOCUMENTS_BY_REQUIREMENT = `${namespace}/FETCH_DOCUMENTS_BY_REQUIREMENT`;

const FETCH_DOCUMENTS_BY_REQUIREMENT_QUERY = gql`
  query listDocumentsByRequirement($payload: ListDocumentsByRequirementInput!) {
    listDocumentsByRequirement(data: $payload) {
      _id
      friendlyName
    }
  }
`;

export const fetchDocumentsByRequirement = (payload) => ({
  type: FETCH_DOCUMENTS_BY_REQUIREMENT,
  payload: {
    key: 'listDocumentsByRequirement',
    graphql: {
      mutation: FETCH_DOCUMENTS_BY_REQUIREMENT_QUERY,
      variables: {
        payload,
      },
    },
  },
});
