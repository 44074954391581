import moment from 'moment';

import {
  DateSuffix,
  KeyType,
  MIN_DATE,
  type SelectedFilter,
  type WithinFilter,
} from '@common/components/DateFilterSelector';

const generateDateFilterRangeUtcStartOfDay = (filter: WithinFilter) => {
  const now = moment();
  const nowUtc = moment.utc(now.format('YYYY-MM-DD')).valueOf();

  switch (filter.key) {
    case KeyType.NEXT:
      const endOfNext = moment
        .utc(
          now
            .add(filter.withinDateValue, filter.withinDateType)
            .format('YYYY-MM-DD'),
        )
        .valueOf();

      return `${nowUtc}~${endOfNext}`;
    case KeyType.LAST:
      const startOfLast = moment
        .utc(
          now
            .subtract(filter.withinDateValue, filter.withinDateType)
            .format('YYYY-MM-DD'),
        )
        .valueOf();

      return `${startOfLast}~${nowUtc}`;
    case KeyType.OLDER:
      return `${MIN_DATE}~${now
        .subtract(filter.withinDateValue, filter.withinDateType)
        .valueOf()}`;
  }
};

const generateDateFilterRange = (filter: WithinFilter) => {
  const now = moment();

  switch (filter.key) {
    case KeyType.NEXT:
      const startOfNext = now.startOf('D').valueOf();
      const endOfNext = now
        .add(filter.withinDateValue, filter.withinDateType)
        .endOf('D')
        .valueOf();

      return `${startOfNext}~${endOfNext}`;
    case KeyType.LAST:
      const startOfLast = now
        .subtract(filter.withinDateValue, filter.withinDateType)
        .startOf('D')
        .valueOf();
      const endOfLast = now.endOf('D').valueOf();

      return `${startOfLast}~${endOfLast}`;
    case KeyType.OLDER:
      const endOfOlder = now
        .subtract(filter.withinDateValue, filter.withinDateType)
        .endOf('D')
        .valueOf();

      return `${MIN_DATE}~${endOfOlder}`;
  }
};

// Expirations and other date values are saved as Date 00:00 UTC
// The adapter ensures that the date is always represented as UTC+0 no matter the TZ
export const adaptFilterToStartOfDayUtc = (
  filterName: string,
  selectedFilter?: SelectedFilter,
) => {
  switch (selectedFilter?.key) {
    case KeyType.ON:
      const on = moment
        .utc(selectedFilter.value.format('YYYY-MM-DD'))
        .valueOf();

      return {
        [filterName]: on,
        [`${filterName}${DateSuffix.FilterType}`]: selectedFilter.key,
        [`${filterName}${DateSuffix.PickerValue}`]: on,
      };
    case KeyType.BETWEEN:
      const startOfBetween = moment
        .utc(selectedFilter.from.format('YYYY-MM-DD'))
        .valueOf();

      const endOfBetween = moment
        .utc(selectedFilter.to.format('YYYY-MM-DD'))
        .startOf('D')
        .valueOf();

      return {
        [filterName]: `${startOfBetween}~${endOfBetween}`,
        [`${filterName}${DateSuffix.FilterType}`]: selectedFilter.key,
        [`${filterName}${DateSuffix.From}`]: startOfBetween,
        [`${filterName}${DateSuffix.To}`]: endOfBetween,
      };

    case KeyType.LAST:
    case KeyType.NEXT:
    case KeyType.OLDER:
      return {
        [filterName]: generateDateFilterRangeUtcStartOfDay(selectedFilter),
        [`${filterName}${DateSuffix.FilterType}`]: selectedFilter.key,
        [`${filterName}${DateSuffix.WithinDateType}`]:
          selectedFilter.withinDateType,
        [`${filterName}${DateSuffix.WithinDateCount}`]:
          selectedFilter.withinDateValue,
      };
    default:
      return adaptFilter(filterName, selectedFilter);
  }
};

// adapt date filters based on user TZ
export const adaptFilter = (
  filterName: string,
  selectedFilter?: SelectedFilter,
) => {
  switch (selectedFilter?.key) {
    case KeyType.ON:
      const on = selectedFilter.value.endOf('D').valueOf();

      return {
        // buildFetchParams checks for the existance of `filterName` before requesting listParties,
        // but doesn't use it when making a request to listParties.
        // `filterName` should be removed in favor of `${filterName}${DateSuffix.FilterType}`
        [filterName]: on,
        [`${filterName}${DateSuffix.FilterType}`]: selectedFilter.key,
        [`${filterName}${DateSuffix.PickerValue}`]: on,
      };
    case KeyType.BETWEEN:
      const startOfBetween = selectedFilter.from.startOf('D').valueOf();
      const endOfBetween = selectedFilter.to.endOf('D').valueOf();

      return {
        [filterName]: `${startOfBetween}~${endOfBetween}`,
        [`${filterName}${DateSuffix.FilterType}`]: selectedFilter.key,
        [`${filterName}${DateSuffix.From}`]: startOfBetween,
        [`${filterName}${DateSuffix.To}`]: endOfBetween,
      };
    case KeyType.LAST:
    case KeyType.NEXT:
    case KeyType.OLDER:
      return {
        [filterName]: generateDateFilterRange(selectedFilter),
        [`${filterName}${DateSuffix.FilterType}`]: selectedFilter.key,
        [`${filterName}${DateSuffix.WithinDateType}`]:
          selectedFilter.withinDateType,
        [`${filterName}${DateSuffix.WithinDateCount}`]:
          selectedFilter.withinDateValue,
      };
    default:
      return {
        [filterName]: null,
        [`${filterName}${DateSuffix.FilterType}`]: null,
        [`${filterName}${DateSuffix.WithinDateType}`]: null,
        [`${filterName}${DateSuffix.WithinDateCount}`]: null,
        [`${filterName}${DateSuffix.From}`]: null,
        [`${filterName}${DateSuffix.To}`]: null,
      };
  }
};
