import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { markMessagesAsRead } from '@modules/party-conversation/actions';
import { getPartyConversationsByParty } from '@modules/party-conversation/selectors';

import PartyConversationList from '../components/PartyConversationList';

const PartyConversationListContainer = ({
  searchValue,
  selectedPartyConversationId,
  setSelectedPartyConversationId,
}) => {
  const { partyId } = useParams();

  const dispatch = useDispatch();

  const partyConversations = useSelector((state) =>
    getPartyConversationsByParty(state, partyId),
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(
        markMessagesAsRead({
          partyId,
          conversationId: selectedPartyConversationId,
        }),
      );
    }, 3000);
    return () => clearTimeout(timer);
  }, [dispatch, partyId, selectedPartyConversationId]);

  return (
    <PartyConversationList
      selectedPartyConversationId={selectedPartyConversationId}
      partyConversations={partyConversations.filter(
        (conversation) =>
          conversation.subject.indexOf(searchValue) + 1 ||
          conversation.messages?.nodes?.some(
            (messageData) => messageData.message.indexOf(searchValue) + 1,
          ),
      )}
      setSelectedPartyConversationId={setSelectedPartyConversationId}
    />
  );
};

export default PartyConversationListContainer;
