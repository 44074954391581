import styled from 'styled-components';

export const Notes = styled.div`
  text-decoration: none;
  width: 100%;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.85);
  padding: 0 10px;
  border-left: 2px solid #ccc;
  margin: 5px 0;

  &:empty {
    display: none;
  }
`;
