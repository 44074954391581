import { SUBJECT_STATUS } from '@modules/party/constants';
import { Overridden, Waived } from '@modules/requirement/components/Badges';

type SubjectStatusBadgeProps = {
  statusValue: (typeof SUBJECT_STATUS)[keyof typeof SUBJECT_STATUS];
  resetRequirementOn: string;
};

export const SubjectStatusBadge = ({
  statusValue,
  resetRequirementOn,
}: SubjectStatusBadgeProps) => {
  switch (true) {
    case statusValue === SUBJECT_STATUS.WAIVED:
      return <Waived until={resetRequirementOn} />;

    case statusValue === SUBJECT_STATUS.OVERRIDDEN:
      return <Overridden until={resetRequirementOn} />;

    default:
      return null;
  }
};
