import { AutoComplete, Modal, Spin } from 'antd';
import debounce from 'lodash/debounce';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { fetchFillableForm } from '../../actions';
import useFillableFormSuggestions from '../../hooks/useFillableFormSuggestions';
import { getFillableFormById } from '../../selectors';

const { Option } = AutoComplete;

const QUERY_LIMIT = 10;

const SelectFillableFormModal = ({
  visible,
  onCancel,
  onSelect,
  disabledFillableFormIds,
}) => {
  const [fillableFormId, setFillableFormId] = useState();
  const [isLoadingForm, setIsLoadingForm] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const dispatch = useDispatch();
  const fillableForm = useSelector((state) =>
    getFillableFormById(state, fillableFormId),
  );

  const {
    isLoadingSuggestions,
    isLoadingMoreSuggestions,
    fillableFormSuggestions: suggestionsList,
    suggestionsTotalCount,
    loadFillableFormSuggestions,
    loadMoreFillableFormSuggestions,
  } = useFillableFormSuggestions();

  const invalidFormsMap = suggestionsList.reduce((acc, formSuggestion) => {
    const isFormValid =
      formSuggestion.formFields?.length &&
      !disabledFillableFormIds.includes(formSuggestion._id);

    if (!isFormValid) {
      return {
        ...acc,
        [formSuggestion._id]: true,
      };
    }
    return acc;
  }, {});

  const fetchMoreSuggestions = () => {
    loadMoreFillableFormSuggestions({
      skip: suggestionsList.length,
      limit: QUERY_LIMIT,
      filters: { name: inputValue.trim() },
    });
  };

  const fetchSuggestionsByText = (text) => {
    loadFillableFormSuggestions({
      limit: QUERY_LIMIT,
      filters: { name: text.trim() },
    });
  };

  const debouncedFetchForms = useCallback(
    debounce((inputText) => fetchSuggestionsByText(inputText), 200),
    [],
  );

  const handleSelectFocus = () => {
    fetchSuggestionsByText(inputValue);
  };

  useEffect(() => {
    if (fillableFormId) {
      const loadFormData = async () => {
        setIsLoadingForm(true);
        await dispatch(fetchFillableForm(fillableFormId));
        setIsLoadingForm(false);
      };

      loadFormData();
    }
  }, [dispatch, fillableFormId]);

  const handleInputChange = (value) => {
    setInputValue(value);
    setFillableFormId();
    debouncedFetchForms(value);
  };

  const handleSelectScroll = ({ target }) => {
    const { scrollTop, scrollHeight, clientHeight } = target;
    const hasUserScrolledToBottom = scrollTop + clientHeight === scrollHeight;

    if (hasUserScrolledToBottom) {
      if (suggestionsList.length < suggestionsTotalCount) {
        target.scrollTo(0, target.scrollHeight);
        fetchMoreSuggestions();
      }
    }
  };

  const handleSuggestionSelect = (suggestionValue) => {
    const suggestion = suggestionsList.find(
      ({ _id }) => _id === suggestionValue,
    );

    setFillableFormId(suggestionValue);
    setInputValue(suggestion?.name || '');
  };

  const resetState = () => {
    setFillableFormId();
    setInputValue('');
  };

  return (
    <Modal
      open={visible}
      destroyOnClose
      title="Add Fillable Form"
      okText="Add Fillable Form"
      confirmLoading={isLoadingForm}
      onOk={() => {
        onSelect(fillableForm);
        resetState();
      }}
      okButtonProps={{
        'data-cy': 'addFillableFormButton',
        disabled: !fillableFormId,
      }}
      onCancel={() => {
        resetState();
        onCancel();
      }}
      zIndex={1003}
    >
      <p>Select a form you want to add to this compliance profile</p>
      <StyledAutoComplete
        loading={isLoadingSuggestions}
        value={inputValue}
        placeholder="Search a form by name..."
        listHeight={175}
        onChange={handleInputChange}
        onFocus={handleSelectFocus}
        onSelect={handleSuggestionSelect}
        onPopupScroll={handleSelectScroll}
        notFoundContent={
          isLoadingSuggestions ? (
            <StyledSpinWrapper>
              <Spin />
            </StyledSpinWrapper>
          ) : null
        }
      >
        {suggestionsList.map((item) => (
          <Option
            key={item._id}
            value={item._id}
            disabled={Boolean(invalidFormsMap[item._id])}
          >
            {item.name}
          </Option>
        ))}

        {isLoadingMoreSuggestions && (
          <Option key="spinner">
            <StyledSpinWrapper>
              <Spin spinning={isLoadingMoreSuggestions} />
            </StyledSpinWrapper>
          </Option>
        )}
      </StyledAutoComplete>
    </Modal>
  );
};

const StyledAutoComplete = styled(AutoComplete)`
  &&& {
    width: 100%;
  }
`;

const StyledSpinWrapper = styled.div`
  padding: 20px 10px;
  display: flex;
  justify-content: center;
`;

export default SelectFillableFormModal;
