import type {
  ApolloError,
  DocumentNode,
  OperationVariables,
  QueryHookOptions,
  QueryResult,
  TypedDocumentNode,
} from '@apollo/client';
import isNil from 'lodash/isNil';

import type { RootState } from '@common/types';
import { getDataWithoutTypename } from '@graphql/utils';
import { ACTION_ERROR_SUFFIX, ACTION_SUCCESS_SUFFIX } from '@store/constants';
import { useAppDispatch, useAppSelector } from '@store/hooks';

import useQuery, { type UseQueryCustomOptions } from './useQuery';

type UseQueryWithReduxCustomOptions = {
  reduxActionType: string;
  reduxActionKey: string;
};

type TReduxData = {
  [x: string]: any | undefined;
};

const fakeSelector = () => null;

/**
 * Use this hook to start fetching data using Apollo hooks incrementally without being worried about breaking the current behavior.
 * This hook will dispatch SUCCESS or ERROR action for the reduxAction type you pass.
 * It also returns the data saved in Redux as long as a reduxSelector is passed.
 * This allows a progressive migration of all the components which are using and/or modifying the data in redux store.
 * Example:
 *    Comp A and B are using the data in redux, and Comp B might modify that data that is now reflected in Comp A.
 *    By using this hook you can refactor Comp A without breaking it because it will keep reading from redux.
 *    Once Comp B is migrated as well, you can replace this hook in favor of useQuery
 */

function useQueryWithRedux<
  TData = any,
  TVariables extends OperationVariables = OperationVariables,
>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options: QueryHookOptions<TData, TVariables> &
    UseQueryCustomOptions &
    UseQueryWithReduxCustomOptions,
): QueryResult<TData, TVariables>;

function useQueryWithRedux<
  TData = any,
  TVariables extends OperationVariables = OperationVariables,
>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options: QueryHookOptions<TData, TVariables> &
    UseQueryCustomOptions &
    UseQueryWithReduxCustomOptions & {
      reduxSelector: (state: RootState) => any;
    },
): QueryResult<TReduxData, TVariables>;

function useQueryWithRedux<
  TData = any,
  TVariables extends OperationVariables = OperationVariables,
>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options: QueryHookOptions<TData, TVariables> &
    UseQueryCustomOptions &
    UseQueryWithReduxCustomOptions & {
      reduxSelector?: (state: RootState) => any;
    },
) {
  const dispatch = useAppDispatch();
  const { reduxActionType, reduxActionKey, reduxSelector, ...queryOptions } =
    options;

  const reduxData = useAppSelector(reduxSelector || fakeSelector);

  const meta = {
    previousAction: {
      payload: {
        key: reduxActionKey,
      },
    },
  };

  const queryWithReduxOptions = {
    ...queryOptions,
    onCompleted: (data: TData) => {
      dispatch({
        type: `${reduxActionType}${ACTION_SUCCESS_SUFFIX}`,
        payload: { data: getDataWithoutTypename(data) },
        meta,
      });
      queryOptions.onCompleted?.(data);
    },
    onError: (error: ApolloError) => {
      dispatch({
        type: `${reduxActionType}${ACTION_ERROR_SUFFIX}`,
        payload: error,
        meta,
      });
      queryOptions.onError?.(error);
    },
  };

  const queryRes = useQuery(query, queryWithReduxOptions);

  /**
   * if reduxSelector is passed, we will use the data in redux as the data of the query
   */

  if (reduxSelector) {
    const queryResFromRedux = {
      ...(queryRes || {}),
      data: {
        [reduxActionKey]: !isNil(
          (queryRes?.data as Record<string, TData>)?.[reduxActionKey],
        )
          ? reduxData
          : undefined,
      },
    };

    return queryResFromRedux;
  }

  return queryRes;
}

export default useQueryWithRedux;
