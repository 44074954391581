import { CloseCircleFilled } from '@ant-design/icons';
import { Modal } from 'antd';
import styled from 'styled-components';

const modal = ({ entityName, entityLabel, onOk, isCustom }) => {
  const custom = `${isCustom ? 'custom' : ''}`;

  Modal.confirm({
    title: `Delete ${custom} ${entityName}`,
    content: (
      <div>
        Are you sure you want to delete {custom} {entityName}
        {`${entityLabel ? ` ${entityLabel}` : ''}`}?
      </div>
    ),
    okText: `Delete ${entityName}`,
    okType: 'danger',
    cancelText: 'Cancel',
    onOk,
    icon: <StyledErrorIcon />,
    zIndex: 1100,
  });
};

const StyledErrorIcon = styled(CloseCircleFilled)`
  color: red !important;
`;

export default modal;
