import { PLACEHOLDER_VALUES } from '@modules/email-template/constants';

import { TextRegionType } from '../constants';

type Region = {
  name: string;
  type: (typeof TextRegionType)[keyof typeof TextRegionType];
};

export const removeDuplicatedRegions = (rawRegions: Region[]) => {
  const { regions } = rawRegions.reduce<{
    regions: Region[];
    regionNames: Record<string, boolean>;
  }>(
    (acc, region) => {
      if (acc.regionNames[region.name]) {
        return acc;
      }

      return {
        regions: [...acc.regions, region],
        regionNames: { ...acc.regionNames, [region.name]: true },
      };
    },
    {
      regions: [],
      regionNames: {},
    },
  );

  return regions;
};

export const removeSystemPlaceholders = (regionsList: Region[]) => {
  return regionsList.filter(
    (region) => !(PLACEHOLDER_VALUES as { [x: string]: string })[region.name],
  );
};

export const getTextRegions = (mjml: string) => {
  if (typeof mjml === 'string') {
    const allBracesRegExp = /{{{? {0,}?([A-z+]{1,}) {0,}}?}}/gi;
    const tripleBracesRegExp = /{{{ {0,}?([A-z+]{1,}) {0,}}}}/i;

    const mappedRegions = [...mjml.trim().matchAll(allBracesRegExp)].map(
      (m) => ({
        type: m[0].match(tripleBracesRegExp)
          ? TextRegionType.Rich
          : TextRegionType.Simple,
        name: m[1],
      }),
    );

    const regionsList = removeDuplicatedRegions(mappedRegions);

    return removeSystemPlaceholders(regionsList);
  }

  return [];
};
