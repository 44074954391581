import { CaretDownFilled } from '@ant-design/icons';
import { Button, Dropdown, Menu, Typography } from 'antd';
import styled from 'styled-components';

import CheckIcon from '@common/icons/CheckIcon';

import ChecklistAttributeForm from '../forms/ChecklistAttributeForm';

const ChecklistAttribute = ({
  checklist,
  isShowForm,
  setIsShowForm,
  value,
  setValue,
  updateChecklistAttribute,
  deleteChecklistAttribute,
}) => (
  <div>
    {isShowForm ? (
      <ChecklistAttributeForm
        value={value}
        submitButtonLabel="Update"
        onChangeHandle={(e) => setValue(e.target.value)}
        submitButtonHandle={updateChecklistAttribute}
        closeButtonHandle={() => {
          setIsShowForm(false);
          setValue('');
        }}
      />
    ) : (
      <ChecklistAttribute.Attribute>
        <ChecklistAttribute.IconWrapper>
          <CheckIcon muted />
        </ChecklistAttribute.IconWrapper>
        <Typography.Paragraph
          ellipsis={{
            rows: 2,
            expandable: checklist.attributeLabel.length > 20,
          }}
        >
          {checklist.attributeLabel}
        </Typography.Paragraph>
        <ChecklistAttribute.DropdownContainer data-cy="checklistItemDropdown">
          <Dropdown
            dropdownRender={() => (
              <Menu>
                <Menu.Item
                  data-cy="editChecklistAttributeItem"
                  onClick={() => setValue(checklist.attributeLabel)}
                >
                  Edit Item
                </Menu.Item>
                <Menu.Item
                  data-cy="deleteChecklistAttributeItem"
                  onClick={() => deleteChecklistAttribute()}
                >
                  Delete Item
                </Menu.Item>
              </Menu>
            )}
            trigger={['click']}
          >
            <Button shape="circle" type="link">
              <CaretDownFilled />
            </Button>
          </Dropdown>
        </ChecklistAttribute.DropdownContainer>
      </ChecklistAttribute.Attribute>
    )}
  </div>
);

ChecklistAttribute.Attribute = styled.div`
  position: relative;
  margin: 5px 0;
  display: flex;
  align-items: baseline;

  .ant-typography {
    margin-bottom: 0 !important;
  }
`;

ChecklistAttribute.IconWrapper = styled.div`
  margin-right: 14px;
  height: 100%;
`;

ChecklistAttribute.DropdownContainer = styled.div`
  margin-left: 20px;
  opacity: 0.6;

  &:hover {
    opacity: 1;
  }

  button {
    color: rgba(0, 0, 0, 0.65) !important;
  }
`;

export default ChecklistAttribute;
