import { graphql } from '@graphql/types';

export const CREATE_PARTY_TAG_MUTATION = graphql(`
  mutation CreatePartyTag($data: CreatePartyTagInput!) {
    createPartyTag(data: $data) {
      _id
      name
      color
      organization
      createdAt
      updatedAt
    }
  }
`);

export const DELETE_PARTY_TAG_MUTATION = graphql(`
  mutation DeletePartyTag($id: String!) {
    deletePartyTag(id: $id) {
      _id
      name
      color
      organization
      createdAt
      updatedAt
    }
  }
`);

export const UPDATE_PARTY_TAG_MUTATION = graphql(`
  mutation UpdatePartyTag($data: UpdatePartyTagInput!) {
    updatePartyTag(data: $data) {
      _id
      name
      color
      organization
      createdAt
      updatedAt
    }
  }
`);

export const ADD_TAGGINGS_TO_PARTIES_MUTATION = graphql(`
  mutation AddTaggingsToParties($data: AddTaggingsToPartiesInput!) {
    addTaggingsToParties(data: $data) {
      _id
      taggings {
        _id
        partyTag {
          _id
          name
          color
        }
        addedAt
        addedBy
        expiresAt
      }
    }
  }
`);

export const DELETE_TAGGINGS_FROM_PARTIES_MUTATION = graphql(`
  mutation DeleteTaggingsFromParties($data: DeleteTaggingsFromPartiesInput!) {
    deleteTaggingsFromParties(data: $data) {
      _id
      taggings {
        _id
        partyTag {
          _id
          name
          color
        }
        addedAt
        addedBy
        expiresAt
      }
    }
  }
`);

export const BULK_ADD_TAGS_TO_PARTIES_MUTATION = graphql(`
  mutation BulkAddTagsParties($data: BulkAddTagsPartiesInput) {
    bulkAddTagsParties(data: $data) {
      scheduled
    }
  }
`);

export const CREATE_PARTY_TAGS_MUTATION = graphql(`
  mutation CreatePartyTags($data: CreatePartyTagsInput!) {
    createPartyTags(data: $data) {
      _id
      name
      color
      organization
      createdAt
      updatedAt
    }
  }
`);

export const BULK_DELETE_TAGGINGS_FROM_PARTIES_MUTATION = graphql(`
  mutation BulkDeleteTagsParties($data: BulkDeleteTagsPartiesInput) {
    bulkDeleteTagsParties(data: $data) {
      scheduled
    }
  }
`);

export const UPDATE_TAGGING_MUTATION = graphql(`
  mutation updateTagging($data: UpdateTaggingInput!) {
    updateTagging(data: $data) {
      _id
      taggings {
        _id
        partyTag {
          _id
          name
          color
        }
        addedAt
        addedBy
        expiresAt
      }
    }
  }
`);
