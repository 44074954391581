import { Button, Modal, Radio, type RadioChangeEvent } from 'antd';
import { type ReactNode, useState } from 'react';
import styled from 'styled-components';

type ModalFooterProps = {
  onCancel: () => void;
  onDelete: () => void;
};

const ModalFooter = ({ onCancel, onDelete }: ModalFooterProps) => (
  <>
    <Button type="default" onClick={onCancel}>
      Cancel
    </Button>
    <Button type="default" danger onClick={onDelete}>
      Remove contact
    </Button>
  </>
);

type RemoveContactButtonProps = {
  modalTitle: ReactNode;
  options: {
    key: string;
    title: string;
    description: string;
    disabled?: boolean;
  }[];
  onDelete: (selectedOption: string | undefined) => void;
};

const RemoveContactButton = ({
  modalTitle,
  options,
  onDelete,
}: RemoveContactButtonProps) => {
  const [visible, setVisible] = useState(false);
  const [selectedRadio, setSelectedRadio] = useState(
    options.find((el) => el.disabled !== true)?.key,
  );

  const showModal = () => {
    setVisible(true);
  };

  const hideModal = () => {
    setVisible(false);
  };

  const handleDelete = () => {
    onDelete(selectedRadio);
    setVisible(false);
  };

  const handleRadioChange = (evt: RadioChangeEvent) => {
    setSelectedRadio(evt.target.value);
  };

  return (
    <>
      <Button type="default" danger onClick={showModal}>
        Remove contact
      </Button>
      <StyledModal
        title={modalTitle}
        open={visible}
        closable={false}
        centered={true}
        footer={<ModalFooter onCancel={hideModal} onDelete={handleDelete} />}
      >
        <Radio.Group onChange={handleRadioChange} value={selectedRadio}>
          {options.map((option) => (
            <Radio
              key={option.key}
              value={option.key}
              disabled={option.disabled}
            >
              <StyledRadioDescription>
                <span className="title">{option.title}</span>
                <span className="description">{option.description}</span>
              </StyledRadioDescription>
            </Radio>
          ))}
        </Radio.Group>
      </StyledModal>
    </>
  );
};

const StyledRadioDescription = styled.p`
  .title {
    font-size: 14px;
    display: block;
  }

  .description {
    font-size: 12px;
    display: block;
    color: ${({ theme }) => theme.colors.darkGray};
  }
`;

const StyledModal = styled(Modal)`
  .ant-modal-content {
    padding: 32px 32px 24px;
    box-shadow: initial;
  }

  .ant-modal-header,
  .ant-modal-footer {
    border: none;
    margin: 0;
    padding: 0;
  }

  .ant-modal-body {
    padding: 15px 0;
  }
`;

export default RemoveContactButton;
