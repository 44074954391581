const MenuLinesIcon = ({ isActive, width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      y="1.52588e-05"
      width="16"
      height="2"
      fill={isActive ? '#fff' : '#dedede'}
    />
    <rect
      y="5.00002"
      width="16"
      height="2"
      fill={isActive ? '#fff' : '#dedede'}
    />
    <rect y="10" width="16" height="2" fill={isActive ? '#fff' : '#dedede'} />
  </svg>
);

MenuLinesIcon.defaultProps = {
  width: 20,
  height: 16,
};

export default MenuLinesIcon;
