import { gql } from '@apollo/client';

import type { GQLPagination } from '@common/types';

import { namespace } from '../constants';
import type { PartyConversation } from '../fragments';

import type { IssueOptions } from './types';

export const FETCH_PARTY_CONVERSATION_ISSUES = `${namespace}/FETCH_PARTY_CONVERSATION_ISSUES`;

const FETCH_PARTY_CONVERSATION_ISSUES_QUERY = gql`
  query getPartyConversationIssue(
    $conversationId: String
    $options: PartyMessageIssueInput!
    $messagesInput: PartyMessagesInput
  ) {
    partyConversation(conversationId: $conversationId) {
      messages(input: $messagesInput) {
        nodes {
          _id
          createdAt
        }
      }
      issues(options: $options) {
        totalCount
        nodes {
          _id
          email
          messageId
          resendable
          ignorable
          resolved
        }
      }
    }
  }
`;

export const getPartyConversationIssues = (payload: {
  conversationId?: PartyConversation['_id'];
  options: IssueOptions;
  messagesInput?: GQLPagination;
}) => ({
  type: FETCH_PARTY_CONVERSATION_ISSUES,
  payload: {
    key: 'partyConversation',
    graphql: {
      query: FETCH_PARTY_CONVERSATION_ISSUES_QUERY,
      variables: payload,
      fetchPolicy: 'no-cache',
    },
  },
});
