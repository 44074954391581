import { FileOutlined } from '@ant-design/icons';
import { Button, Popover, Typography } from 'antd';
import { htmlToText } from 'html-to-text';
import qs from 'query-string';
import * as R from 'ramda';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import BadgePopover from '@common/components/Badges/components/BadgePopover';
import FileNameWithBadges from '@common/components/FileName/FileNameWithBadges';
import { getIsCustomRequirement } from '@common/utils/compliance-attributes-helpers';
import RuleDisplayValue from '@modules/compliance-profile/components/RuleDisplayValue';
import { AttributeType } from '@modules/compliance-profile/constants';
import { getFlagAndVerificationByAffectedSubject } from '@modules/document/utils/document-helpers';

import { RequirementStatus } from '../constants';
import MatchedAttributesContainer from '../containers/MatchedAttributesContainer';
import { dispatchNoteModalEvent } from '../hooks';
import {
  parseAttributeValue,
  sortRequirementsByAttributeType,
} from '../utils/requirement-helpers';

import { PublicNote } from './PublicNote';
import { RequirementMenu } from './RequirementMenu';
import {
  RequirementIcon,
  getRequirementValue,
  getSubjectLabel,
} from './SubjectContent.utils';

const SubjectContent = ({
  subject,
  party,
  organization,
  documents,
  setStatusRequirementData,
  onRestoreRequirement,
  setIsShowWaiveModal,
  setIsShowOverrideModal,
  requirementsBySubject,
  redirectToReviewPage,
  getDocumentsBySubjectId,
  activeDocumentsToShow = 3,
  projectId,
  organizationNamespace,
  hideRequirementActions,
  onRemoveWaiveAutomaticPlacementOnPartyRequirement,
}) => {
  const isSubjectWaivedOrOverridden = Boolean(
    subject?.requirementComplianceStatusValue,
  );
  const selectedDocuments = getDocumentsBySubjectId(subject.subjectId);
  const displayedActiveDocuments = R.compose(
    R.slice(0, activeDocumentsToShow),
    R.sortWith([R.descend(R.prop('createdAt'))]),
  )(selectedDocuments);
  const activeDocumentsIds = selectedDocuments.map((el) => el._id);
  const documentsCount = R.pathOr(
    0,
    ['partyComplianceProfile', 'metadata', subject.subjectId, 'documentsCount'],
    party,
  );
  const otherDocumentsCount =
    documentsCount - activeDocumentsToShow > 0
      ? documentsCount - activeDocumentsToShow
      : 0;
  const subjectLabel = getSubjectLabel(
    subject?.requirementComplianceStatusValue,
  );
  const reviewSubjectDocumentsUrl = () =>
    `${organizationNamespace}/parties/${
      party?._id
    }/documents-review?${qs.stringify({ selected: activeDocumentsIds })}${
      projectId ? `&project=${projectId}` : ''
    }`;

  const { flag, documentByFlag, verification, documentByVerification } =
    getFlagAndVerificationByAffectedSubject(documents, subject.subjectId);
  const documentsWithAdditionalNotes = R.filter(
    (document) =>
      R.path(['metadata', subject.subjectId, 'additionalDetails'], document) &&
      !document.archivedAt,
    documents,
  );

  const sortedRequirementsByAttributeType = sortRequirementsByAttributeType(
    [AttributeType.AmBestFinancialStrength, AttributeType.AmBestRating],
    requirementsBySubject(subject.subjectId),
  );

  return (
    <div>
      {Boolean(documents?.length) && (
        <SubjectContent.ModuleSubjectDocuments>
          {selectedDocuments.length > 0 && <label>Active documents</label>}
          {displayedActiveDocuments.map((document) => (
            <SubjectContent.FileNameWrapper key={`key_${document._id}`}>
              <FileNameWithBadges
                filename={document.friendlyName}
                fileData={document}
                party={party}
                projectId={projectId}
                organizationNamespace={organizationNamespace}
              />
            </SubjectContent.FileNameWrapper>
          ))}
          {otherDocumentsCount > 0 && (
            <SubjectContent.Link to={reviewSubjectDocumentsUrl}>
              + {otherDocumentsCount} more...
            </SubjectContent.Link>
          )}
        </SubjectContent.ModuleSubjectDocuments>
      )}
      {verification && (
        <BadgePopover
          documentData={{
            _id: R.propOr('', '_id', documentByVerification),
            issuedBy: R.propOr({}, 'issuedBy', documentByVerification),
            documentName: R.propOr('', 'friendlyName', documentByVerification),
          }}
          verificationData={verification}
          isStatic
        />
      )}
      {flag && (
        <BadgePopover
          documentData={{
            _id: R.propOr('', '_id', documentByFlag),
            issuedBy: R.propOr({}, 'issuedBy', documentByFlag),
            documentName: R.propOr('', 'friendlyName', documentByFlag),
          }}
          flagData={flag}
          isShowFlagInfo
          isStatic
        />
      )}
      {documentsWithAdditionalNotes.length > 0 &&
        documentsWithAdditionalNotes.map((document) => (
          <SubjectContent.AdditionalNotes key={`key_${document._id}`}>
            <Popover
              placement="bottom"
              content={
                <PopoverWrapper>
                  <div>Data found in the following document: </div>
                  <DocumentsNames>
                    <ins
                      onClick={() => {
                        redirectToReviewPage(document._id);
                      }}
                    >
                      {document.friendlyName}
                    </ins>
                  </DocumentsNames>
                </PopoverWrapper>
              }
            >
              <SubjectContent.FileOutlined />
            </Popover>
            {R.path(
              ['metadata', subject.subjectId, 'additionalDetails'],
              document,
            )}
          </SubjectContent.AdditionalNotes>
        ))}
      <SubjectContent.ReqRowTitle>
        <SubjectContent.Compliance>
          <p>Requirements</p>
        </SubjectContent.Compliance>
      </SubjectContent.ReqRowTitle>
      {sortedRequirementsByAttributeType.map((requirement) => {
        const statusValue = R.prop(
          'requirementComplianceStatusValue',
          requirement,
        );
        const detectedValue = parseAttributeValue(
          R.prop('automaticallyDetected', requirement),
          R.prop('attributeType', requirement),
        );
        const isWaived = statusValue === RequirementStatus.Waived;
        const isOverridden = statusValue === RequirementStatus.Overridden;

        const handleWaiveOrOverride = (data) => {
          setStatusRequirementData({ ...requirement, ...data });

          if (data.overrideType === RequirementStatus.Waived) {
            setIsShowWaiveModal(true);
          }

          if (data.overrideType === RequirementStatus.Overridden) {
            setIsShowOverrideModal(true);
          }
        };

        const handleCancelWaiveOrOverride = (
          requirement,
          removeWaiveOverrideAcrossProject,
        ) => {
          onRestoreRequirement(requirement, removeWaiveOverrideAcrossProject);
        };

        return (
          <SubjectContent.ReqRow
            data-cy="requirementRow"
            key={`key_${requirement._id}`}
          >
            <div
              className={statusValue}
              data-cy={`${statusValue}RequirementRow`}
            >
              <SubjectContent.IconContainer>
                <RequirementIcon
                  status={requirement?.requirementComplianceStatusValue}
                />
              </SubjectContent.IconContainer>
              <SubjectContent.RequirementsValue
                status={requirement.requirementComplianceStatusValue}
              >
                <div className="requirement-text">
                  <RuleDisplayValue
                    partyName={party?.name}
                    orgName={organization?.name}
                    rule={requirement}
                    isCustom={getIsCustomRequirement(requirement)}
                  />
                </div>
                {(isOverridden || isWaived) && (
                  <SubjectContent.RequirementNotes data-cy="waivedRequirementNotes">
                    {R.propOr('', 'notes', requirement)}
                  </SubjectContent.RequirementNotes>
                )}
              </SubjectContent.RequirementsValue>
              <SubjectContent.DetectedValue data-cy="subjectContentDetectedValue">
                {isSubjectWaivedOrOverridden ? (
                  <Popover
                    content={
                      <StyledSubjectOverrideContent>
                        {subjectLabel} must first be removed on the subject
                        level before editing {subjectLabel.toLowerCase()} on the
                        requirement.
                      </StyledSubjectOverrideContent>
                    }
                  >
                    <div>
                      <div style={{ pointerEvents: 'none' }}>
                        {getRequirementValue(
                          detectedValue,
                          statusValue,
                          requirement,
                        )}
                      </div>
                    </div>
                  </Popover>
                ) : (
                  getRequirementValue(detectedValue, statusValue, requirement)
                )}
                {!hideRequirementActions && !isOverridden && !isWaived && (
                  <MatchedAttributesContainer
                    partyId={party?._id}
                    matchingDocumentsCount={R.prop(
                      'matchingDocumentsCount',
                      requirement,
                    )}
                    requirementId={R.prop('attributeId', requirement)}
                    redirectToReviewPage={redirectToReviewPage}
                  />
                )}
              </SubjectContent.DetectedValue>
              {!hideRequirementActions && (
                <SubjectContent.Action>
                  <RequirementMenu
                    disabled={isSubjectWaivedOrOverridden}
                    requirement={requirement}
                    onCancelWaiveOrOverride={handleCancelWaiveOrOverride}
                    onWaiveOrOverride={handleWaiveOrOverride}
                    isEditingNote={requirement?.publicNotes}
                    onOpenNoteModal={() => {
                      dispatchNoteModalEvent({
                        _id: requirement._id,
                        publicNotes: requirement.publicNotes,
                        selectedProject: projectId,
                      });
                    }}
                    onRemoveWaiveAutomaticPlacementOnPartyRequirement={
                      onRemoveWaiveAutomaticPlacementOnPartyRequirement
                    }
                  />
                </SubjectContent.Action>
              )}
            </div>
            {Boolean(requirement.attributeDescription) && (
              <SubjectContent.Description>
                <Typography.Paragraph
                  ellipsis={{
                    rows: 2,
                    expandable: requirement.attributeDescription.length > 20,
                  }}
                >
                  {htmlToText(requirement.attributeDescription)}
                </Typography.Paragraph>
              </SubjectContent.Description>
            )}
            {requirement?.publicNotes && (
              <AlertWrapper>
                <PublicNote
                  requirementId={requirement._id}
                  selectedProject={projectId}
                  publicNotes={requirement.publicNotes}
                  deletable
                />
              </AlertWrapper>
            )}
          </SubjectContent.ReqRow>
        );
      })}
    </div>
  );
};

const AlertWrapper = styled.div`
  padding-left: 32px;
`;

const StyledSubjectOverrideContent = styled.p`
  max-width: 200px;
  margin: 0;
`;

SubjectContent.ReqRow = styled.div`
  padding: 3px 0;
  margin: 0;
  font-size: 13px;
  color: ${(props) => props.theme.colors.black};
  transition: all 0.3s;
  min-height: 32px;

  > div {
    display: flex;
    flex: 1;
  }

  &:hover {
    background-color: ${(props) => props.theme.colors.lightBlue};
    border-radius: 4px;
  }

  .overridden,
  .waived {
    .requirement-text {
      opacity: 0.6;
    }
  }
`;

SubjectContent.Action = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-left: 10px;
`;

SubjectContent.PopoverWrapper = styled.div`
  padding: 5px;
  font-size: 12px;
  color: ${(props) => props.theme.colors.lightBlack};

  ins {
    cursor: pointer;
  }

  a {
    color: inherit;
  }
`;

SubjectContent.RequirementsValue = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 20px;
  flex: 15;

  .operator {
    color: rgba(0, 0, 0, 0.65);
  }
`;

SubjectContent.Description = styled.div`
  margin: 4px 10px 4px 36px;
  width: 70%;

  .ant-typography {
    font-size: 12px !important;
    margin-bottom: 0;
    color: rgba(0, 0, 0, 0.65);
  }
`;

SubjectContent.RequirementNotes = styled.div`
  text-decoration: none;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.85);
  padding: 0 10px;
  border-left: 2px solid #ccc;
  margin: 5px 0;

  &:empty {
    display: none;
  }
`;

SubjectContent.DetectedValue = styled.div`
  display: flex;
  align-items: baseline;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  overflow-x: auto;
  margin-left: auto;
  justify-content: flex-end;
  margin-right: 10px;

  > .ant-tag {
    margin-top: 4px;
  }

  > span {
    display: inline-block;
    line-height: 26px;
  }
`;

export const PopoverWrapper = styled.div`
  padding: 5px;
  font-size: 12px;
  color: ${(props) => props.theme.colors.lightBlack};

  ins {
    cursor: pointer;
  }

  a {
    color: inherit;
  }
`;

SubjectContent.RestoreButton = styled.div`
  margin-right: 20px;
  font-size: 13px;
  text-decoration: underline;
  color: ${(props) => props.theme.colors.lightBlack};
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
`;

SubjectContent.Compliance = styled.div`
  display: flex;
  align-items: baseline;
`;

SubjectContent.AdditionalNotes = styled.div`
  margin: 10px 7px;
  display: flex;
  color: ${(props) => props.theme.colors.grayText};
  font-size: 12px;
`;

SubjectContent.FileOutlined = styled(FileOutlined)`
  margin-right: 5px;
  padding-top: 2px;
  color: ${(props) => props.theme.colors.blue} !important;
`;

SubjectContent.ReqRowTitle = styled.div`
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  padding: 0 7px;
  margin: 0;
  color: ${(props) => props.theme.colors.black};

  p {
    margin: 0;
    color: ${(props) => props.theme.colors.grayText};
    font-size: 12px;
  }
`;

SubjectContent.IconContainer = styled.div`
  display: flex;
  width: 35px;
  justify-content: center;
  align-items: center;
`;

SubjectContent.ModuleSubjectDocuments = styled.div`
  position: absolute;
  right: -50%;
  width: 46%;
  display: flex;
  flex-direction: column;
  z-index: 1;

  label {
    margin-bottom: 10px;
    font-size: 12px;
    color: ${(props) => props.theme.colors.grayText};
    opacity: 0.7;
  }
`;

SubjectContent.FileNameWrapper = styled.div`
  margin-bottom: 5px;
`;

export const DocumentsNames = styled.div`
  display: flex;
  flex-direction: column;

  ins:hover {
    color: ${(props) => props.theme.colors.blue};
  }
`;

SubjectContent.Link = styled(Link)`
  font-size: 12px;
`;

SubjectContent.ActionButton = styled(Button)`
  && {
    font-size: 16px;
    height: 26px;
    padding-top: 2px;
    color: ${(props) => props.theme.colors.paleGray};
  }
`;

export default SubjectContent;
