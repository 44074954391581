import { gql } from '@apollo/client';
import { FILTER_OPERATORS, FILTER_TYPES } from '@common/constants/filters';
import { useMutation } from '@graphql/hooks';

const ARCHIVE_PRIMARY_RECORDS_MUTATION = gql`
  mutation ArchivePrimaryRecords($data: ArchivePrimaryRecordsInput!) {
    archivePrimaryRecords(data: $data) {
      operation {
        code
        name
        status
        progress
      }
      errors {
        code
        message
      }
    }
  }
`;

type ArchivePrimaryRecordsArgs = {
  variables: { ids: string[] };
};

export const useArchivePrimaryRecordsMutation = () => {
  const [
    archivePrimaryRecordsMutation,
    { loading: isArchivingPrimaryRecords },
  ] = useMutation(ARCHIVE_PRIMARY_RECORDS_MUTATION);

  const archivePrimaryRecords = ({ variables }: ArchivePrimaryRecordsArgs) => {
    return archivePrimaryRecordsMutation({
      variables: {
        data: {
          filter: {
            and: [
              {
                name: FILTER_TYPES.id,
                operator: FILTER_OPERATORS.in,
                value: variables.ids,
              },
            ],
            or: [],
          },
        },
      },
    });
  };

  return {
    archivePrimaryRecords,
    isArchivingPrimaryRecords,
  };
};
