import { Button, Modal, Select, message } from 'antd';
import debounce from 'lodash/debounce';
import pluralize from 'pluralize';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import {
  addPartiesToProject,
  bulkAddPartiesToProject,
  getViewedProjects,
  openProjectUpdatesWSSubscription,
} from '@modules/project/actions';
import { getGraphqlResponse } from '@store/helpers';

const AddToProjectModal = ({
  visible,
  partiesIds,
  onClose,
  onSuccess,
  partiesFilters,
  selectedPartiesCount,
  areAllPartiesSelected,
  openCreateProjectModal,
}) => {
  const [projects, setProjects] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedProjectId, setSelectedProjectId] = useState(null);

  const dispatch = useDispatch();

  // biome-ignore lint/correctness/useExhaustiveDependencies: Legacy
  const fetchViewedProjects = useCallback(
    (value) => {
      dispatch(
        getViewedProjects({
          first: 100,
          filter: {
            name: value,
            isActive: true,
            //? This check is needed for prevent unwanted filtering when bulk assigning to a project
            ...(partiesIds.length === 1 && { partyId: partiesIds[0] }),
          },
        }),
      ).then((payload) => {
        const viewedProjects = getGraphqlResponse(payload)?.nodes || [];

        setProjects(viewedProjects);
      });
    },
    [dispatch, partiesIds.length],
  );

  useEffect(() => {
    if (visible) {
      fetchViewedProjects();
    }
  }, [fetchViewedProjects, visible]);

  useEffect(() => {
    if (!visible) {
      setSelectedProjectId([]);
      setSelectedProjectId(null);
    }
  }, [visible]);

  const handleProjectSelectOnChange = (projectId) => {
    setSelectedProjectId(projectId);
    /**
     * temporary implementation:
     * subscribe to projectUpdated socket in order to give a feedback when compliance sync process is completed.
     *
     * More explanation here: https://github.com/trustlayer/trustlayer-web/pull/1174
     *
     * @todo remove it as soon as the story https://app.shortcut.com/trustlayer/story/9536/visible-job-queue is done.
     */
    dispatch(openProjectUpdatesWSSubscription(projectId));
  };

  const onOk = async () => {
    setIsLoading(true);

    if (areAllPartiesSelected) {
      await dispatch(
        bulkAddPartiesToProject({
          projectId: selectedProjectId,
          filterQuery: partiesFilters,
        }),
      );

      message.success(
        `${pluralize(
          'party',
          selectedPartiesCount,
          true,
        )}  scheduled for assignment of project`,
      );
    } else {
      await dispatch(
        addPartiesToProject({
          projectId: selectedProjectId,
          partyIds: partiesIds,
        }),
      );

      message.success(
        `${
          partiesIds.length === 1 ? 'Party has' : 'Parties have'
        } been successfully added to project. Compliance synchronization in progress`,
      );
    }

    setIsLoading(false);

    if (onSuccess) {
      onSuccess();
    }
  };

  const onChangeHandle = useCallback(
    (value) => fetchViewedProjects(value),
    [fetchViewedProjects],
  );
  const debOnChangeHandle = debounce(onChangeHandle, 200);

  return (
    <Modal
      destroyOnClose
      title="Add to project"
      open={visible}
      onOk={onOk}
      okButtonProps={{
        disabled: !selectedProjectId,
        loading: isLoading,
        'data-cy': 'addToProjectModalOkButton',
      }}
      okText="Add to project"
      onCancel={onClose}
    >
      <AddToProjectModal.Label>
        Select an existing project or{' '}
        <Button
          data-cy="addToProjectModalCreateNewProject"
          type="link"
          onClick={openCreateProjectModal}
        >
          create new
        </Button>
      </AddToProjectModal.Label>
      <AddToProjectModal.Select
        data-cy="addToProjectSelectProjectInput"
        showSearch
        allowClear
        value={selectedProjectId || undefined}
        onChange={handleProjectSelectOnChange}
        placeholder="Search project..."
        optionFilterProp="children"
        onSearch={debOnChangeHandle}
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {projects.map((project) => (
          <Select.Option key={`key_${project._id}`} value={project._id}>
            {project.name}
          </Select.Option>
        ))}
      </AddToProjectModal.Select>
    </Modal>
  );
};

AddToProjectModal.Label = styled.div`
  display: flex;
  align-items: center;

  button {
    padding-left: 5px;
  }
`;

AddToProjectModal.Select = styled(Select)`
  width: 100%;
  margin-top: 5px;
`;

export default AddToProjectModal;
