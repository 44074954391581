import { Modal, Typography } from 'antd';
import type { EditorState } from 'draft-js';
import { useState } from 'react';

import FullWidthSpace from '@common/components/FullWidthSpace';
import RichTextEditor from '@common/components/RichTextEditor';
import { getEditorHTMLContent } from '@common/components/RichTextEditor/helpers';
import { trackEvent } from '@common/utils/track-helpers';
import { useMutation } from '@graphql/hooks';
import { UPDATE_REQUIREMENT_PUBLIC_NOTES } from '@modules/requirement/mutations';

const AddNoteModal = ({
  isEditingNote,
  isVisible,
  onClose,
  notes,
  onNotesChange,
  onSubmit,
  isSubmitting,
}: {
  isEditingNote?: boolean;
  isVisible: boolean;
  onClose: () => void;
  notes: string;
  onNotesChange: (notes: string) => void;
  onSubmit: () => void;
  isSubmitting?: boolean;
}) => {
  return (
    <Modal
      title={`${isEditingNote ? 'Edit' : 'Add'} Note`}
      centered
      okText={isEditingNote ? 'Edit' : 'Add'}
      cancelText="Cancel"
      open={isVisible}
      onCancel={onClose}
      onOk={onSubmit}
      okButtonProps={{ loading: isSubmitting }}
    >
      <FullWidthSpace size="small" direction="vertical">
        <Typography.Text>Note:</Typography.Text>
        <RichTextEditor
          minHeight={32}
          editorState={undefined}
          disableInlineStyleButtons={false}
          focusDependence={false}
          isOneLineEditor={false}
          defaultContent={notes}
          isValid
          placeholder="Add note description..."
          onChange={(e: EditorState) => onNotesChange(getEditorHTMLContent(e))}
        />
        <Typography.Text type="secondary">
          This note will be visible on the party&apos;s request page
        </Typography.Text>
      </FullWidthSpace>
    </Modal>
  );
};

const AddNoteModalContainer = ({
  isVisible,
  onClose,
  requirement,
  projectId,
  onCompleted,
}: {
  isVisible: boolean;
  onClose: () => void;
  onCompleted?: () => void | (() => Promise<void>);
  requirement?: {
    _id: string;
    publicNotes?: string | null;
  };
  projectId?: string;
}) => {
  const [notes, setNotes] = useState<string>(requirement?.publicNotes ?? '');
  const [mutate, { loading: isSubmitting }] = useMutation(
    UPDATE_REQUIREMENT_PUBLIC_NOTES,
  );

  if (!requirement?._id) {
    return null;
  }

  return (
    <AddNoteModal
      isEditingNote={!!requirement?.publicNotes}
      isVisible={isVisible}
      onClose={onClose}
      notes={notes}
      onNotesChange={setNotes}
      isSubmitting={isSubmitting}
      onSubmit={() => {
        mutate({
          variables: {
            data: {
              id: requirement._id,
              publicNotes: notes,
              projectId,
            },
          },
          onCompleted: () => {
            trackEvent('User added a note to a requirement');

            const completedResult = onCompleted?.();

            if (completedResult instanceof Promise) {
              return completedResult.then(onClose);
            }

            return onClose();
          },
        });
      }}
    />
  );
};

export default AddNoteModalContainer;
