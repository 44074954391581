import * as R from 'ramda';

export const partyHasDocuments = (party) => {
  const partyApplicableDocumentsCount = R.prop(
    'totalApplicableDocumentsCount',
    party,
  );

  const documentsCount = R.isNil(partyApplicableDocumentsCount)
    ? R.prop('totalDocumentsCount', party)
    : partyApplicableDocumentsCount;

  return documentsCount > 0;
};
