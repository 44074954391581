import * as R from 'ramda';

import { STATE_KEY } from '../constants';

export const getEventLogs = R.compose(R.values, R.path([STATE_KEY, 'data']));

export const getEventLogsByParty = (state, partyId) =>
  R.compose(
    R.filter(
      (log) =>
        log.contextEntityId === partyId && log.contextEntityType === 'party',
    ),
    R.values,
    R.path([STATE_KEY, 'data']),
  )(state);

export const getEventLogsByRequest = (state, requestId) =>
  R.compose(
    R.filter(
      (log) =>
        log.contextEntityId === requestId &&
        log.contextEntityType === 'request',
    ),
    R.values,
    R.path([STATE_KEY, 'data']),
  )(state);

export const getEventLogsTotalCount = (state) =>
  R.pathOr(null, [STATE_KEY, 'totalCount'], state);
