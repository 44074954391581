const RatingInformationNote = () => (
  <div>
    <p>
      “Best&apos;s Credit Ratings™ are under continuous review and subject to
      change and/or affirmation. For the latest Best&apos;s Credit Ratings™ and
      Best&apos;s Credit Reports (which include Best&apos;s Credit Ratings™),
      visit the A.M. Best website at http://www.ambest.com. See Guide to
      Best&apos;s Credit Ratings™ for explanation of use and charges.”
    </p>
    <p>
      “Best&apos;s Credit Ratings™ reproduced herein appear under license from
      A.M. Best and do not constitute, either expressly or impliedly, an
      endorsement of TrustLayer, Inc or its recommendations, formulas, criteria
      or comparisons to any other ratings, rating scales or rating organizations
      which are published or referenced herein. A.M. Best is not responsible for
      transcription errors made in presenting Best&apos;s Credit Ratings™.
      Best&apos;s Credit Ratings™ are proprietary and may not be reproduced or
      distributed without the express written permission of A.M. Best Company.”
    </p>
    <p>
      A Best&apos;s Financial Strength Rating opinion addresses the relative
      ability of an insurer to meet its ongoing insurance obligations. It is not
      a warranty of a company&apos;s financial strength and ability to meet its
      obligations to policyholders. View our Important Notice: Best&apos;s
      Credit Ratings for a disclaimer notice and complete details at
      http://www.ambest.com/ratings/index.html.
    </p>
  </div>
);

export default RatingInformationNote;
