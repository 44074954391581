import * as R from 'ramda';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { getPartyContactsByIds } from '@modules/party/selectors';

import PartyConversation from '../components/PartyConversation';
import {
  MessageTitleByType,
  MessageType,
  PartyMessageStatus,
} from '../constants';

const PartyConversationContainer = ({ partyConversation, ...props }) => {
  const { partyId } = useParams();

  const contactsParticipantsData = useSelector((state) =>
    getPartyContactsByIds(
      state,
      partyId,
      R.propOr([], 'participants', partyConversation),
    ),
  );

  const getUserName = (user) =>
    R.path(['profile', 'name'], user) || R.prop('email', user);

  const getContactName = (contact) =>
    R.prop('contactPersonName', contact) || R.prop('email', contact);

  const usersParticipantsData = R.propOr(
    [],
    'usersParticipants',
    partyConversation,
  );
  const participants = [
    ...usersParticipantsData.map((user) => getUserName(user)),
    ...contactsParticipantsData.map((contact) => getContactName(contact)),
  ].join(', ');
  const lastMessage =
    partyConversation?.messages?.nodes[
      partyConversation?.messages?.nodes.length - 1
    ];

  const getMessageValue = (messageData) => {
    const type = R.propOr('', 'type', messageData);
    const messageValue =
      type === MessageType.Message
        ? R.prop('message', messageData)
        : MessageTitleByType[type];
    const senderContactId = R.prop('senderContactId', messageData);
    const senderContact = R.find(
      R.propEq('_id', senderContactId),
      contactsParticipantsData,
    );
    const authorName = Boolean(senderContactId)
      ? getContactName(senderContact)
      : getUserName(R.prop('senderUser', messageData));

    return `${authorName || R.prop('senderType', messageData)}: ${
      Boolean(messageValue) ? messageValue : 'This message has no content'
    }`;
  };

  const hasAtLeastOneError = partyConversation.issues.totalCount;

  const messageStatus = hasAtLeastOneError
    ? PartyMessageStatus.Warning
    : lastMessage?.status;

  return (
    <PartyConversation
      {...props}
      partyConversation={partyConversation}
      participants={participants}
      lastMessageText={getMessageValue(lastMessage)}
      isReadLastMessage={Boolean(lastMessage?.readAt)}
      lastMessageStatus={messageStatus}
      lastMessageDate={lastMessage?.createdAt}
    />
  );
};

export default PartyConversationContainer;
