import { CloudUploadOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import styled from 'styled-components';

const DocumentsHeaderButtons = ({ setIsShowUploadModal }) => (
  <DocumentsHeaderButtons.Button
    type="primary"
    icon={<CloudUploadOutlined />}
    onClick={() => {
      setIsShowUploadModal(true);
    }}
  >
    <DocumentsHeaderButtons.ButtonLabel>
      Upload documents
    </DocumentsHeaderButtons.ButtonLabel>
  </DocumentsHeaderButtons.Button>
);

DocumentsHeaderButtons.Button = styled(Button)`
  .anticon {
    font-size: 18px;
  }
`;

DocumentsHeaderButtons.ButtonLabel = styled.span`
  margin-left: 8px;
`;

export default DocumentsHeaderButtons;
