import { SyncOutlined } from '@ant-design/icons';
import { Popover } from 'antd';

import { FIELD_ASSOCIATION_TYPES } from '@modules/fillable-form/constants';
import { SUBJECT_STATUS } from '@modules/party/constants';
import {
  Overridden,
  WaitingCompletion,
  Waived,
} from '@modules/requirement/components/Badges';

import { RequirementIconByStatus, RequirementStatus } from '../constants';

export const getRequirementValue = (
  value: any,
  statusValue: string,
  requirement: {
    targetValue: string;
    resetRequirementOn: string;
    isAutomaticallyWaivedAcrossProjects?: boolean;
  },
) => {
  switch (true) {
    case statusValue === RequirementStatus.Waived:
      return (
        <Waived
          until={requirement.resetRequirementOn}
          isAutomaticallyWaivedAcrossProjects={
            requirement.isAutomaticallyWaivedAcrossProjects
          }
        />
      );

    case statusValue === RequirementStatus.Overridden:
      return (
        <Overridden
          until={requirement.resetRequirementOn}
          isAutomaticallyWaivedAcrossProjects={
            requirement.isAutomaticallyWaivedAcrossProjects
          }
        />
      );

    case statusValue === RequirementStatus.NonCompliant &&
      requirement.targetValue === FIELD_ASSOCIATION_TYPES.PARTY:
      return <WaitingCompletion />;

    case requirement.isAutomaticallyWaivedAcrossProjects:
      return (
        <Popover content="The waiver applies to future projects">
          <SyncOutlined />
        </Popover>
      );

    case !!value:
      return value;

    default:
      return '-';
  }
};

export const RequirementIcon = ({
  status,
}: {
  status: (typeof SUBJECT_STATUS)[keyof typeof SUBJECT_STATUS];
}) => RequirementIconByStatus[status];

export const getSubjectLabel = (
  status: (typeof SUBJECT_STATUS)[keyof typeof SUBJECT_STATUS],
) => {
  if (status === SUBJECT_STATUS.WAIVED) {
    return 'Waiver';
  }
  if (status === SUBJECT_STATUS.OVERRIDDEN) {
    return 'Override';
  }
};
