import { Collapse } from 'antd';
import * as R from 'ramda';
import React, { useEffect, useState } from 'react';

import {
  getSubjectsByModule,
  getUniqModules,
} from '@common/utils/compliance-attributes-helpers';

import CollapsePanel from '../components/CollapsePanel';
import ModuleLabel from '../components/ModuleLabel';
import { filterRequirementsBySubject } from '../utils/requirement-helpers';

import CollapsePanelSubjectContainer from './CollapsePanelSubjectsContainer';

const CollapsePanelContainer = ({
  party = {},
  documents = [],
  requirements = [],
  selectedDocuments,
  projectId,
  organizationNamespace,
  hideRequirementActions = false,
  setConversationModalData,
  waivedSubjects = [],
}) => {
  const modules = getUniqModules(requirements);
  const [activeKeys, setActiveKeys] = useState([]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: Legacy
  useEffect(() => {
    setActiveKeys(modules.map((x) => x.moduleId));
  }, [requirements]);

  const requirementsBySubject = (subjectId) =>
    filterRequirementsBySubject(subjectId, requirements);

  const getIsInsuranceModule = React.useCallback((moduleData) => {
    const search = /insurance/g;
    return (
      R.propOr('', 'moduleId', moduleData).toLowerCase().match(search) ||
      R.propOr('', 'moduleLabel', moduleData).toLowerCase().match(search)
    );
  }, []);

  return (
    <CollapsePanel
      activeKeys={activeKeys}
      onChange={(keys) => setActiveKeys(keys)}
    >
      {modules.map((moduleData) => {
        /**
         * @description - Here we merge the extracted subjects from the requirements with the waived subjects in the party object
         */
        const subjects = getSubjectsByModule(
          moduleData?.moduleId,
          requirements,
        )?.map((subject) => {
          const waivedSubject = waivedSubjects?.find(
            (waivedSubject) => waivedSubject.subjectId === subject.subjectId,
          );
          return waivedSubject || subject;
        });

        return (
          <Collapse.Panel
            key={moduleData?.moduleId}
            header={<ModuleLabel>{moduleData?.moduleLabel}</ModuleLabel>}
            showArrow={false}
          >
            <CollapsePanelSubjectContainer
              documents={documents}
              hideRequirementActions={hideRequirementActions}
              isInsuranceModule={getIsInsuranceModule(moduleData)}
              isLicenseModule={getIsInsuranceModule(moduleData)}
              organizationNamespace={organizationNamespace}
              party={party}
              projectId={projectId}
              requirementsBySubject={requirementsBySubject}
              selectedDocuments={selectedDocuments}
              subjects={subjects}
              setConversationModalData={setConversationModalData}
            />
          </Collapse.Panel>
        );
      })}
    </CollapsePanel>
  );
};
export default CollapsePanelContainer;
