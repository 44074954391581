import { gql } from '@apollo/client';

import { namespace } from '../constants';

export const UPLOAD_USER_AVATAR = `${namespace}/UPLOAD_USER_AVATAR`;

const UPLOAD_USER_AVATAR_MUTATION = gql`
  mutation uploadAvatar($avatar: UploadAvatarInput!) {
    uploadAvatar(data: $avatar) {
      url
      friendlyName
      s3Key
    }
  }
`;

export const uploadUserAvatar = (avatar) => ({
  type: UPLOAD_USER_AVATAR,
  payload: {
    key: 'uploadAvatar',
    graphql: {
      mutation: UPLOAD_USER_AVATAR_MUTATION,
      variables: {
        avatar,
      },
      context: { hasUpload: true },
    },
  },
});
