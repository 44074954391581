import { gql } from '@apollo/client';

import { namespace } from '../constants';

export const UPDATE_DOCUMENT_FORM = `${namespace}/UPDATE_DOCUMENT_FORM`;

const UPDATE_DOCUMENT_FORM_QUERY = gql`
  mutation updateDocumentForm($data: UpdateDocumentFormInput!) {
    updateDocumentForm(data: $data) {
      _id
      url
      fillableForm {
        _id
        status
      }
    }
  }
`;

export const updateDocumentForm = (data) => ({
  type: UPDATE_DOCUMENT_FORM,
  payload: {
    key: 'updateDocumentForm',
    graphql: {
      mutation: UPDATE_DOCUMENT_FORM_QUERY,
      variables: { data },
      context: { hasUpload: true },
    },
  },
});
