import { TeamOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import styled from 'styled-components';

import { useNewBackgroundTableColorsFeatureFlag } from '@modules/feature-flags/hooks';
import ReportsDropdown from '@modules/report/containers/ReportsDropdown';

const ListPageHeader = ({
  report,
  controlButtons,
  fallbackEntitiesName,
  statusTitleLabel,
  onToggleStatusClick,
  total,
  selectedType,
  reportsType,
  currentContext,
  toggleStatusButtonLabel,
}) => {
  const { isNewBackgroundTableColorsFeatureFlagEnabled } =
    useNewBackgroundTableColorsFeatureFlag();
  return (
    <StyledWrapper
      $hasNewBgColor={isNewBackgroundTableColorsFeatureFlagEnabled}
      data-cy="listPageHeader"
    >
      <StyledInfoDivision>
        {report ? (
          <>
            <Tooltip
              placement="topLeft"
              title={
                report.isPrivate
                  ? 'Report visible only to you'
                  : 'Report visible to your team'
              }
            >
              {report.isPrivate ? <UserOutlined /> : <TeamOutlined />}
            </Tooltip>
            {report.name}
          </>
        ) : (
          `${statusTitleLabel} ${selectedType || fallbackEntitiesName}`
        )}
        <StyledCurrentContext>{currentContext}</StyledCurrentContext>
        {Boolean(total) && (
          <StyledTotal data-cy="listPageHeaderTotal">{total}</StyledTotal>
        )}
        <ReportsDropdown reportsType={reportsType} />

        <Button type="link" onClick={onToggleStatusClick}>
          <StyledButtonText>{toggleStatusButtonLabel}</StyledButtonText>
        </Button>
      </StyledInfoDivision>

      <div>{controlButtons}</div>
    </StyledWrapper>
  );
};

const textColor = 'rgba(0, 0, 0, 0.85)';

const StyledWrapper = styled.div`
  padding: 12px 0 0;
  margin: 10px 0;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  background-color: ${({ theme, $hasNewBgColor }) =>
    $hasNewBgColor ? theme.colors.mainBackground : 'initial'};
`;

const StyledInfoDivision = styled.div`
  display: flex;
  align-items: baseline;
  font-size: 18px;
  color: ${textColor};

  > .anticon:first-child {
    margin-right: 10px;
  }
`;

const StyledCurrentContext = styled.p`
  font-size: 16px;
  color: ${textColor};
  margin: 0 0 0 5px;
`;

const StyledTotal = styled.span`
  padding: 2px 6px;
  margin: 0 5px;
  background-color: #fff;
  border: 1px solid ${({ theme }) => theme.colors.paleBlue};
  border-radius: 99em;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.04);
  font-size: 13px;
  font-weight: 500;
  color: ${textColor};
  position: relative;
  top: -1px;
`;

const StyledButtonText = styled.span`
  padding: 4px;
  text-decoration: underline;
  color: ${textColor};

  &:hover {
    text-decoration: none;
    color: ${textColor};
  }
`;

export default ListPageHeader;
