import {
  BarChartOutlined,
  EditOutlined,
  MoreOutlined,
} from '@ant-design/icons';
import { Button, Dropdown, Modal, Popover, message } from 'antd';
import * as R from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import Box from '@common/components/Box';
import BoxTitle from '@common/components/BoxTitle';
import FullWidthSpace from '@common/components/FullWidthSpace';
import Spinner from '@common/components/Spinner';
import { useMutation } from '@graphql/hooks';
import * as ComplianceProfileActions from '@modules/compliance-profile/actions';
import BaseComplianceProfileIcon from '@modules/compliance-profile/components/BaseComplianceProfileIcon';
import ComplianceProfileContainer from '@modules/compliance-profile/containers/ComplianceProfileContainer';
import { HideForViewerRole } from '@modules/organization-member/containers/HideForRoles';
import { setIsReloadPage } from '@modules/organization/actions';
import { getCurrentProject } from '@modules/project/selectors';

import { MATCH_COMPLIANCE_MUTATION } from '../mutations';

import { trackEvent } from '@common/utils/track-helpers';
import AssignComplianceProfileModal from '@modules/party-compliance-profile/components/AssignComplianceProfileModal';
import { useState } from 'react';
import ComplianceModules from './ComplianceModules';
import PartyComplianceIndicator from './PartyComplianceIndicator/PartyComplianceIndicator';

export const BoxType = {
  Overview: 'overview',
  Compliance: 'compliance',
};

const ComplianceSummary = ({
  organizationNamespace = '',
  party,
  partyComplianceProfile,
  requirements,
  type,
  setSelectedComplianceProfile,
  setCurrentRequirements,
  isShowProfileDrawer,
  setIsShowProfileDrawer,
  isPartyComplianceProfileLoading,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [
    isVisibleAssignComplianceProfileModal,
    setIsVisibleAssignComplianceProfileModal,
  ] = useState();

  const openProfileDrawer = () => {
    setIsShowProfileDrawer(true);
  };

  const openProfileDrawerWithEdit = () => {
    setSelectedComplianceProfile(partyComplianceProfile || { name: '' });

    setCurrentRequirements(
      partyComplianceProfile
        ? R.propOr([], 'rules', partyComplianceProfile)
        : requirements,
    );
    dispatch(
      ComplianceProfileActions.setCurrentDocumentChecklists(
        R.propOr([], 'documentChecklists', partyComplianceProfile),
      ),
    );
    setIsShowProfileDrawer(true);
  };

  const currentProject = useSelector(getCurrentProject);

  const isIncludedInCurrentProject =
    R.isEmpty(currentProject) ||
    R.compose(
      R.includes(party._id),
      R.map((x) => x._id),
      R.propOr([], 'parties'),
    )(currentProject);

  const getComplianceProfileName = () =>
    R.propOr('Custom Compliance Profile', 'name', partyComplianceProfile);

  const items = [
    {
      label: 'Make Compliant',
      key: 'make_compliant',
    },
  ];

  const [matchComplianceMutation] = useMutation(MATCH_COMPLIANCE_MUTATION, {
    variables: { data: { id: party._id } },
    onCompleted: () => {
      // TODO: Refactor this to refetch the data with Apollo
      dispatch(setIsReloadPage(true));
      message.success(`The party has been made compliant.`);

      trackEvent('User performed a "Make compliant" action');
    },
  });

  const menuProps = {
    items,
    onClick: ({ key }) => {
      if (key === 'make_compliant') {
        Modal.confirm({
          title: 'Are you sure you want to make this party compliant?',
          content:
            'Making this party compliant will generate a document that will hold compliance.',
          okText: 'Make Compliant',
          cancelText: 'Cancel',
          onOk: matchComplianceMutation,
        });
      }
    },
  };

  return (
    <StyledComplianceSummary data-cy="complianceSummaryContainer">
      <AssignComplianceProfileModal
        showApplyToAllProjectsCheckbox={false}
        isShow={isVisibleAssignComplianceProfileModal}
        onClose={() => {
          setIsVisibleAssignComplianceProfileModal(false);
        }}
        party={party._id}
        onSuccess={() => {
          setIsVisibleAssignComplianceProfileModal(false);
        }}
      />
      <ComplianceProfileContainer
        isShow={isShowProfileDrawer}
        setIsShow={setIsShowProfileDrawer}
      />
      <SummaryDetail direction="vertical" size="small" wrap={false}>
        <BoxTitle
          icon={<BarChartOutlined />}
          label={
            R.isEmpty(currentProject) || !isIncludedInCurrentProject ? (
              'Compliance summary'
            ) : (
              <span>
                <i>{R.prop('name', currentProject)}</i> / Compliance summary
              </span>
            )
          }
        >
          <Dropdown trigger={['click']} menu={menuProps}>
            <StyledButton size="small" icon={<MoreOutlined />} type="text" />
          </Dropdown>
        </BoxTitle>
        <StyledContent>
          <StyledProfileWrapper>
            {isPartyComplianceProfileLoading ? (
              <Spinner />
            ) : (
              <>
                {partyComplianceProfile && (
                  <>
                    <StyledComplianceProfileWrapper data-cy="complianceSummaryProfileName">
                      <StyledComplianceProfileNameWrapper>
                        <StyledComplianceProfileName>
                          <i>Profile:</i>{' '}
                          {partyComplianceProfile.baseComplianceProfile && (
                            <BaseComplianceProfileIcon />
                          )}{' '}
                          {getComplianceProfileName()}
                        </StyledComplianceProfileName>
                        {R.isEmpty(currentProject) ? (
                          <HideForViewerRole>
                            <StyledEditIconWrapper
                              shape="circle"
                              type="link"
                              data-cy="complianceSummaryEditPartyProfileButton"
                              onClick={() => openProfileDrawerWithEdit()}
                            >
                              <EditOutlined />
                            </StyledEditIconWrapper>
                          </HideForViewerRole>
                        ) : (
                          <Popover
                            title="Editing compliance profiles from within a project"
                            content={
                              <div style={{ width: 300 }}>
                                <p>
                                  You can edit the compliance profile for this
                                  party from the &quot;Project settings&quot;
                                  modal.
                                </p>
                                <p>
                                  Custom compliance profiles are not supported
                                  within projects.
                                </p>
                              </div>
                            }
                          >
                            <Button
                              type="dashed"
                              shape="circle"
                              disabled
                              ghost
                              icon={<EditOutlined />}
                            />
                          </Popover>
                        )}
                      </StyledComplianceProfileNameWrapper>
                    </StyledComplianceProfileWrapper>
                  </>
                )}
                {partyComplianceProfile ? (
                  <>
                    {type === BoxType.Overview && (
                      <ComplianceModules
                        requirements={requirements}
                        redirectToCompliance={(subjectId) =>
                          navigate(
                            `${organizationNamespace}/parties/${party._id}/compliance`,
                            { state: { subjectId } },
                          )
                        }
                      />
                    )}
                  </>
                ) : (
                  <StyledEmtyStateWrapper>
                    <StyledEmptyDescription>
                      No requirements associated to this party. Assign a
                      compliance profile to track requirements and expirations.
                    </StyledEmptyDescription>
                    {R.isEmpty(currentProject) ? (
                      <StyledAssignButton
                        data-cy="assignComplianceProfileButton"
                        onClick={() =>
                          setIsVisibleAssignComplianceProfileModal(true)
                        }
                      >
                        Assign compliance profile
                      </StyledAssignButton>
                    ) : (
                      <Popover
                        title="Assigning compliance profiles from within a project"
                        content={
                          <div style={{ width: 300 }}>
                            <p>
                              You can assign a compliance profile to this party
                              from the &quot;Project settings&quot; modal.
                            </p>
                          </div>
                        }
                      >
                        <StyledAssignButton
                          disabled
                          ghost
                          data-cy="assignComplianceProfileButton"
                          onClick={openProfileDrawer}
                        >
                          Assign compliance profile
                        </StyledAssignButton>
                      </Popover>
                    )}
                  </StyledEmtyStateWrapper>
                )}
              </>
            )}
          </StyledProfileWrapper>
          {partyComplianceProfile && type === BoxType.Compliance && (
            <StyledProgress>
              <StyledProgressWrapper>
                <PartyComplianceIndicator
                  party={party}
                  size={80}
                  strokeWidth={5}
                  fontSize={15}
                />
              </StyledProgressWrapper>
            </StyledProgress>
          )}
        </StyledContent>
      </SummaryDetail>
    </StyledComplianceSummary>
  );
};

const StyledButton = styled(Button)`
  box-shadow: none !important;
`;

const StyledComplianceSummary = styled(Box)`
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
`;

const StyledContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

const StyledProfileWrapper = styled.div`
  width: 100%;
`;

const StyledProgress = styled.div`
  display: flex;
  justify-content: right;
  padding: 0 10px 0 15px;
`;

const StyledEmtyStateWrapper = styled.div`
  padding-left: 29px;
  padding-right: 11px;
`;

const StyledEmptyDescription = styled.div`
  font-size: 12px;
  color: ${(props) => props.theme.colors.lightBlack};
`;

const StyledAssignButton = styled(Button)`
  margin-top: 10px !important;
`;

const SummaryDetail = styled(FullWidthSpace)`
  width: 100%;

  label {
    font-size: 13px;
    color: ${(props) => props.theme.colors.grayText};
  }

  i {
    font-style: normal;
  }
`;

const StyledProgressWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -10px;

  .ant-progress {
    margin-right: 0 !important;
  }
`;

const StyledComplianceProfileWrapper = styled.div`
  margin: 10px 10px 10px 18px;
  padding: 5px 10px;
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(195, 209, 226, 0.8);
  border-radius: 4px;
  background-color: ${(props) => props.theme.colors.blueGray};
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.04);
`;

const StyledComplianceProfileNameWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  i {
    font-size: 11px;
    text-transform: uppercase;
    font-style: normal;
    color: rgba(0, 0, 0, 0.65);
  }
`;

const StyledComplianceProfileName = styled.span`
  font-size: 15px;
  line-height: 32px;
  color: rgba(0, 0, 0, 0.85);
`;

const StyledEditIconWrapper = styled(Button)`
  font-size: 18px;
  color: #5c6c87;
  padding: 0 5px;
`;

export default ComplianceSummary;
