export const COMPLIANCE_PROFILE_PLACEHOLDER_VALUES = {
  compliantSubjects: 'compliantSubjects',
  nonCompliantSubjects: 'nonCompliantSubjects',
  expiringSubjects: 'expiringSubjects',
} as const;

export const complianceProfilePlaceholders = {
  title: 'Compliance subjects',
  placeholders: [
    {
      name: 'Compliance subjects: compliant subjects list',
      region: `{{ ${COMPLIANCE_PROFILE_PLACEHOLDER_VALUES.compliantSubjects} }}`,
    },
    {
      name: 'Compliance subjects: non-compliant subjects list',
      region: `{{ ${COMPLIANCE_PROFILE_PLACEHOLDER_VALUES.nonCompliantSubjects} }}`,
    },
    {
      name: 'Compliance subjects: expiring subjects list',
      region: `{{ ${COMPLIANCE_PROFILE_PLACEHOLDER_VALUES.expiringSubjects} }}`,
    },
  ],
};
