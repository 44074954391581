import * as React from 'react';

const useMenu = (menuElementRef) => {
  const [isMenuOpen, setMenu] = React.useState(false);
  const [eventPosition, setEventPosition] = React.useState(null);
  const [menuTimer, setMenuTimer] = React.useState(null);
  const openMenu = () => setMenu(true);
  const closeMenu = () => setMenu(false);
  const onMenuLeave = () => setMenuTimer(setTimeout(closeMenu, 500));
  const onMenuHover = () => {
    if (menuTimer) clearTimeout(menuTimer);
  };

  const onTargetClick = (e) => {
    const { clientX, clientY } = e;
    setEventPosition({ clientX, clientY });
    openMenu();
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: More Deps Than Needed
  const listener = React.useCallback(
    (event) => {
      const target = event.target;
      if (target instanceof HTMLElement || target instanceof Element) {
        if (
          !menuElementRef ||
          !menuElementRef.current ||
          menuElementRef.current?.contains(target)
        ) {
          return;
        }
        closeMenu();
      }
    },
    [menuElementRef],
  );

  React.useEffect(() => {
    window.addEventListener('mousedown', listener);
    return () => window.removeEventListener('mousedown', listener);
  });

  return [
    isMenuOpen,
    onMenuHover,
    onMenuLeave,
    onTargetClick,
    closeMenu,
    eventPosition,
  ];
};

export default useMenu;
