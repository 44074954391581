import { Form, Input, InputNumber } from 'antd';
import styled from 'styled-components';

import { isInputNumber } from '../constants';

const CustomRequirement = ({
  selectedAttribute,
  setSelectedAttribute,
  validateCustomRequirement,
  selectedOperator,
  targetValue,
  setTargetValue,
}) => {
  const [form] = Form.useForm();

  return (
    <CustomRequirement.Form
      form={form}
      initialValues={{ attributeLabel: selectedAttribute.attributeLabel }}
    >
      <CustomRequirement.TitleInputWrapper>
        <Form.Item
          name="attributeLabel"
          rules={[
            {
              validator: (_, value) =>
                validateCustomRequirement(value, selectedAttribute.attributeId),
              message: 'This requirement is already present',
            },
          ]}
        >
          <CustomRequirement.TextArea
            data-cy="customRequirementInput"
            autoFocus
            autoSize={{ minRows: 1, maxRows: 4 }}
            placeholder="Enter your custom requirement..."
            onChange={(e) =>
              setSelectedAttribute({
                ...selectedAttribute,
                attributeLabel: e.target.value,
              })
            }
          />
        </Form.Item>
      </CustomRequirement.TitleInputWrapper>
      {isInputNumber(selectedOperator) && (
        <CustomRequirement.NumberInputWrapper>
          <Form.Item>
            <CustomRequirement.InputNumber
              data-cy="inputNumber"
              value={targetValue}
              formatter={(value) =>
                `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
              parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
              step={1000000}
              onChange={(value) => setTargetValue(value || 0)}
              min={0}
            />
          </Form.Item>
        </CustomRequirement.NumberInputWrapper>
      )}
    </CustomRequirement.Form>
  );
};

CustomRequirement.Form = styled(Form)`
  width: 100%;
  display: flex;

  .ant-form-item {
    width: 100%;
  }
`;

CustomRequirement.TitleInputWrapper = styled.div`
  flex: 6;
  margin: 0 9px 0 0;

  > .ant-form-item {
    margin-bottom: 0;
  }
`;

CustomRequirement.NumberInputWrapper = styled(
  CustomRequirement.TitleInputWrapper,
)`
  flex: 2;
`;

CustomRequirement.TextArea = styled(Input.TextArea)`
  && {
    margin: 0;
  }
`;

CustomRequirement.InputNumber = styled(InputNumber)`
  && {
    width: 100%;
  }
`;

export default CustomRequirement;
