import styled from 'styled-components';

import DocumentsPage from '@modules/document/pages/DocumentsPage';

const TabDocumentsContent = () => (
  <TabDocumentsContent.Wrapper>
    <DocumentsPage showCurrentContext={false} />
  </TabDocumentsContent.Wrapper>
);

TabDocumentsContent.Wrapper = styled.div`
  margin: -30px -24px 0;
`;

export default TabDocumentsContent;
