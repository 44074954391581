import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  getIsCustomModule,
  getIsCustomSubject,
  getIsFillableFormModule,
  getIsSurveyModule,
  getRequirementsByModuleSubject,
} from '@common/utils/compliance-attributes-helpers';
import * as ComplianceProfileActions from '@modules/compliance-profile/actions';
import { getTemplates } from '@modules/requirement/selectors';

import ComplianceModuleSubjects from '../components/ComplianceModuleSubjects';
import {
  getCurrentComplianceProfile,
  getCurrentRequirements,
} from '../selectors';

const DrawerSubjectsContainer = ({
  moduleId,
  moduleLabel,
  subject,
  index,
  draggable,
  setIsActiveSubject,
  setEditSubjectId,
  setEditSubjectValue,
}) => {
  const templates = useSelector(getTemplates);
  const requirements = useSelector(getCurrentRequirements);
  const complianceProfile = useSelector(getCurrentComplianceProfile);
  const [activeKey, setActiveKey] = useState(
    getRequirementsByModuleSubject(
      moduleId,
      subject.subjectId,
      requirements,
    ).filter((requirement) => requirement.attributeId !== '').length > 0
      ? subject.subjectId
      : '',
  );

  const isCustomSubject = getIsCustomSubject(
    subject.subjectId,
    moduleId,
    templates,
  );
  const isCustomModule = getIsCustomModule(moduleId, templates);
  const isFillableFormModule = getIsFillableFormModule({ moduleId });
  const isSurveyModule = getIsSurveyModule({ moduleId });
  const activeRequirementsCount = requirements.filter(
    (x) => x.subjectId === subject.subjectId && Boolean(x.attributeId),
  ).length;

  const dispatch = useDispatch();

  const isRequirements = (moduleId, subjectId) =>
    getRequirementsByModuleSubject(moduleId, subjectId, requirements).length >
    0;

  const onExpand = (isActive) => {
    if (!isRequirements(moduleId, subject.subjectId)) {
      setIsActiveSubject(moduleId, subject.subjectId, true);
    }
    setActiveKey(isActive ? subject.subjectId : '');
  };

  const removeSubjectRequirementsKeepingSampleSubject = (
    requirements,
    subjectId,
  ) => {
    const [sample] = requirements.filter((x) => x.subjectId === subjectId);
    setCurrentRequirements([
      ...requirements.filter((x) => x.subjectId !== subjectId),
      {
        ...sample,
        attributeId: '',
        attributeLabel: '',
      },
    ]);
  };

  const removeSubjectRequirements = (requirements, subjectId) => {
    setCurrentRequirements(
      requirements.filter((x) => x.subjectId !== subjectId),
    );
  };

  const onDisableSubject = (requirements, subjectId) => {
    if (isFillableFormModule || isSurveyModule) {
      removeSubjectRequirements(requirements, subjectId);
    } else {
      removeSubjectRequirementsKeepingSampleSubject(requirements, subjectId);
    }
  };

  const setCurrentRequirements = (data) =>
    dispatch(ComplianceProfileActions.setCurrentRequirements(data));

  return (
    <ComplianceModuleSubjects
      activeKey={activeKey}
      setActiveKey={setActiveKey}
      isCustomSubject={isCustomSubject}
      isCustomModule={isCustomModule}
      activeRequirementsCount={activeRequirementsCount}
      onExpand={onExpand}
      onDisableSubject={onDisableSubject}
      moduleId={moduleId}
      moduleLabel={moduleLabel}
      isRequirements={isRequirements}
      subject={subject}
      index={index}
      draggable={draggable}
      requirements={requirements}
      complianceProfile={complianceProfile}
      setCurrentRequirements={setCurrentRequirements}
      setEditSubjectId={setEditSubjectId}
      setEditSubjectValue={setEditSubjectValue}
    />
  );
};

export default DrawerSubjectsContainer;
