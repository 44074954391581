import { partyDetailsTabs as LEGACY_PARTY_DETAILS_PAGE_TABS } from '@modules/party';
import { PRIMARY_RECORDS_TABS } from '@modules/primary-records';

export const APPLICATION_PATH = {
  root: '/',
} as const;

export const LOCATION_STATE_KEY = 'location' as const;

export const legacyPartyDetailsTabToPrimaryRecordDetailsTabMap = {
  [LEGACY_PARTY_DETAILS_PAGE_TABS.Compliance.key]:
    PRIMARY_RECORDS_TABS.Requests.key,
  [LEGACY_PARTY_DETAILS_PAGE_TABS.Overview.key]:
    PRIMARY_RECORDS_TABS.Requests.key,
  [LEGACY_PARTY_DETAILS_PAGE_TABS.Projects.key]:
    PRIMARY_RECORDS_TABS.Requests.key,
  [LEGACY_PARTY_DETAILS_PAGE_TABS.CustomFields.key]:
    PRIMARY_RECORDS_TABS.Attributes.key,
  [LEGACY_PARTY_DETAILS_PAGE_TABS.Documents.key]:
    PRIMARY_RECORDS_TABS.Documents.key,
  [LEGACY_PARTY_DETAILS_PAGE_TABS.Communications.key]:
    PRIMARY_RECORDS_TABS.Messages.key,
};
