export const DOCUMENTS_PLACEHOLDER_VALUES = {
  documentsCount: 'documentsCount',
  uploadDocumentsUrl: 'uploadDocumentsUrl',
  reviewDocumentsUrl: 'reviewDocumentsUrl',
} as const;

export const documentsPlaceholders = {
  title: 'Documents',
  placeholders: [
    {
      name: 'Documents count',
      region: `{{ ${DOCUMENTS_PLACEHOLDER_VALUES.documentsCount} }}`,
    },
    {
      name: 'Upload documents URL',
      region: `{{ ${DOCUMENTS_PLACEHOLDER_VALUES.uploadDocumentsUrl} }}`,
    },
    {
      name: 'Review document URL',
      region: `{{ ${DOCUMENTS_PLACEHOLDER_VALUES.reviewDocumentsUrl} }}`,
    },
  ],
};
