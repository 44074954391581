import { EditOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import styled from 'styled-components';

import useSearchParams from '@common/hooks/useSearchParams';
import { HideForViewerRole } from '@modules/organization-member/containers/HideForRoles';
import AddParticipantsPopover from '@modules/party-conversation/components/AddParticipantsPopover.legacy';
import { MessagesIssuesQuickViewLegacy } from '@modules/party-conversation/components/MessagesIssuesQuickView';
import { PartyConversationType } from '@modules/party-conversation/constants';

const ChatHeader = ({ selectedPartyConversation, participantsData }) => {
  const { pushParams } = useSearchParams();

  const handleIssueClick = (messageId) => {
    pushParams({ messageId });
  };

  return (
    <StyledWrapper>
      {selectedPartyConversation && (
        <>
          <div>
            <StyledSubjectTitle data-cy="chatHeader">
              {selectedPartyConversation.subject}
            </StyledSubjectTitle>

            <StyledUserSection data-cy="chatParticipants">
              <>
                {selectedPartyConversation.usersParticipants?.map((user) => (
                  <span key={user?._id}>
                    <Tooltip title={user?.email}>
                      {user?.profile?.name || user?.email}
                    </Tooltip>
                  </span>
                ))}

                {participantsData.map((partyContact) => (
                  <span key={partyContact._id}>
                    <Tooltip title={partyContact.email}>
                      {partyContact.contactPersonName || partyContact.email}
                    </Tooltip>
                  </span>
                ))}
              </>

              <AddParticipantsPopover
                conversationId={selectedPartyConversation._id}
                currentParticipants={participantsData.map(({ _id }) => _id)}
              >
                <Button
                  data-cy="editParticipantsButton"
                  type="link"
                  icon={<EditOutlined />}
                >
                  edit participants
                </Button>
              </AddParticipantsPopover>
            </StyledUserSection>
          </div>

          <StyledActionsContainer>
            <MessagesIssuesQuickViewLegacy
              conversationId={selectedPartyConversation._id}
              onIssueClick={handleIssueClick}
            />
            <HideForViewerRole>
              {selectedPartyConversation.type ===
                PartyConversationType.DocumentRequest && (
                <StyledButton
                  target="_blank"
                  data-cy="documentsRequestPageBtn"
                  rel="noopener noreferrer"
                  href={`/request-landing/${selectedPartyConversation.request}`}
                >
                  Document request page
                </StyledButton>
              )}
            </HideForViewerRole>
          </StyledActionsContainer>
        </>
      )}
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 70px;
  width: 100%;
`;

const StyledButton = styled(Button)`
  margin-left: 8px;
`;

const StyledSubjectTitle = styled.h2`
  margin: 0;
  padding: 0;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.85);
`;

const StyledUserSection = styled.section`
  color: rgba(0, 0, 0, 0.65);
  font-size: 12px;

  > span::before {
    content: ', ';
  }

  > span:first-child::before {
    content: '';
  }

  button {
    margin-left: 5px;
    font-size: 12px;
  }
`;

const StyledActionsContainer = styled.section`
  display: flex;
  align-items: center;
`;

export default ChatHeader;
