import { Button, Dropdown, Menu, Modal } from 'antd';
import styled from 'styled-components';

import DotsIcon from '@common/icons/Dots';
import { getIsCustomRequirementFromValuedProjectCustomField } from '@common/utils/compliance-attributes-helpers';
import { HideForViewerRole } from '@modules/organization-member/containers/HideForRoles';
import { RequirementStatus } from '@modules/requirement/constants';

type RequirementStatusValues =
  (typeof RequirementStatus)[keyof typeof RequirementStatus];

type RequirementMenuProps = {
  onCancelWaiveOrOverride: (requirement: any, isOverride?: boolean) => void;
  onWaiveOrOverride: (data: {
    updateExisting: boolean;
    overrideType: RequirementStatusValues;
  }) => void;
  onUpdateMatchAcrossProjects: (data: {
    attributeId: string;
    match: boolean;
  }) => void;
  isAutomaticallyMatchedAcrossProjects: boolean;
  disabled: boolean;
  onOpenNoteModal: () => void;
  isEditingNote?: boolean;
  requirement: {
    requirementComplianceStatusValue: RequirementStatusValues;
    attributeId: string;
    isAutomaticallyWaivedAcrossProjects: boolean;
    party: string;
  };
  onRemoveWaiveAutomaticPlacementOnPartyRequirement: (requirement: {
    party: string;
    attributeId: string;
  }) => void;
};

export const RequirementMenu = ({
  onCancelWaiveOrOverride,
  onWaiveOrOverride,
  onOpenNoteModal,
  onUpdateMatchAcrossProjects,
  isAutomaticallyMatchedAcrossProjects,
  disabled,
  isEditingNote,
  requirement,
  onRemoveWaiveAutomaticPlacementOnPartyRequirement,
}: RequirementMenuProps) => {
  const isWaived =
    requirement?.requirementComplianceStatusValue === RequirementStatus.Waived;
  const isOverridden =
    requirement?.requirementComplianceStatusValue ===
    RequirementStatus.Overridden;

  const isCustomRequirementFromValuedProjectCustomField =
    getIsCustomRequirementFromValuedProjectCustomField(requirement);

  const canBeMatchedAcrossProjects =
    isCustomRequirementFromValuedProjectCustomField &&
    !isWaived &&
    !isOverridden;

  return (
    <HideForViewerRole>
      <Dropdown
        dropdownRender={() => (
          <Menu>
            {!isOverridden && (
              <Menu.Item
                disabled={disabled}
                data-cy="waiveRequirementButton"
                onClick={() => {
                  onWaiveOrOverride({
                    updateExisting: isWaived,
                    overrideType: RequirementStatus.Waived,
                  });
                }}
              >
                {isWaived ? 'Edit waiver' : 'Waive requirement'}
              </Menu.Item>
            )}
            {isWaived && (
              <Menu.Item
                disabled={disabled}
                data-cy="restoreRequirementButton"
                onClick={() => {
                  onCancelWaiveOrOverride(requirement, false);
                }}
              >
                Remove waiver
              </Menu.Item>
            )}
            {!isWaived && (
              <Menu.Item
                disabled={disabled}
                data-cy="overrideRequirementButton"
                onClick={() => {
                  onWaiveOrOverride({
                    updateExisting: isOverridden,
                    overrideType: RequirementStatus.Overridden,
                  });
                }}
              >
                {isOverridden ? 'Edit override' : 'Override requirement'}
              </Menu.Item>
            )}
            {isOverridden && (
              <Menu.Item
                disabled={disabled}
                data-cy="restoreRequirementButton"
                onClick={() => {
                  onCancelWaiveOrOverride(requirement, true);
                }}
              >
                Remove override
              </Menu.Item>
            )}
            {requirement?.isAutomaticallyWaivedAcrossProjects && (
              <Menu.Item
                disabled={disabled}
                onClick={() => {
                  Modal.confirm({
                    title: `Are you sure you want to remove the automatic ${
                      isOverridden ? 'override' : 'waive'
                    } from this requirement?`,
                    content: `New projects will not be automatically ${
                      isOverridden ? 'overriden' : 'waived'
                    }.`,
                    okText: `Remove ${isOverridden ? 'override' : 'waive'}`,
                    okButtonProps: {
                      // @ts-expect-error - Cypress selector
                      'data-cy': 'confirmRemoveAutomaticWaiverButton',
                    },
                    onOk: () =>
                      onRemoveWaiveAutomaticPlacementOnPartyRequirement(
                        requirement,
                      ),
                  });
                }}
              >
                Remove automatic waiver placement
              </Menu.Item>
            )}
            {canBeMatchedAcrossProjects && onUpdateMatchAcrossProjects && (
              <Menu.Item
                key="match-across-projects"
                onClick={() =>
                  onUpdateMatchAcrossProjects({
                    attributeId: requirement.attributeId,
                    match: !isAutomaticallyMatchedAcrossProjects,
                  })
                }
              >
                {isAutomaticallyMatchedAcrossProjects
                  ? 'Stop matching across projects'
                  : 'Match across projects'}
              </Menu.Item>
            )}
            {
              <Menu.Item onClick={onOpenNoteModal}>
                {isEditingNote ? 'Edit' : 'Add'} note
              </Menu.Item>
            }
          </Menu>
        )}
        trigger={['click']}
      >
        <StyledActions data-cy="dropdownMenu" shape="circle" type="link">
          <DotsIcon />
        </StyledActions>
      </Dropdown>
    </HideForViewerRole>
  );
};

const StyledActions = styled(Button)`
  && {
    font-size: 16px;
    height: 26px;
    padding-top: 2px;
    color: ${(props) => props.theme.colors.paleGray};
  }
`;
