/**
 * @description Pulse statuses grouped by component viz
 */
export const POLICY_STATUSES = {
  pending: 'Pending',

  inforce: 'Inforce',

  renewed: 'Renewed',

  canceled: 'Canceled',
  expired: 'Expired',

  pendingCancellation: 'PendingCancellation',
  nonRenewed: 'NonRenewed',

  notFound: 'NotFound',
  error: 'Error',
} as const;
