import type { ReactNode } from 'react';
import styled from 'styled-components';

const Icon = styled.span`
  margin-right: 10px;
  margin-top: -2px;
  font-size: 18px;
  color: ${(props) => props.theme.colors.grayText};
  opacity: 0.65;
`;

const Label = styled.span`
  font-size: 16px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 10px;
  justify-content: space-between;
`;

type BoxTitleProps = {
  label?: string;
  icon?: ReactNode;
  children?: ReactNode;
};

const BoxTitle = ({ label, icon, children }: BoxTitleProps) => (
  <Wrapper>
    <div>
      {icon && <Icon>{icon}</Icon>}
      {label && <Label>{label}</Label>}
    </div>
    {children}
  </Wrapper>
);

export default BoxTitle;
