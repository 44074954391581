import { SearchOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import styled from 'styled-components';

const MessagesSearch = ({ searchValue, setSearchValue }) => (
  <MessagesSearch.Wrapper>
    <Input
      value={searchValue}
      data-cy="messageSearchField"
      onChange={(e) => setSearchValue(e.target.value)}
      prefix={<SearchOutlined />}
      placeholder="Search..."
    />
  </MessagesSearch.Wrapper>
);

MessagesSearch.Wrapper = styled.div`
  padding: 15px;
  border-bottom: 1px solid #e6eaef;
  background-color: ${(props) => props.theme.colors.white};
  position: sticky;
  top: 70px;
  z-index: 2;
`;

export default MessagesSearch;
