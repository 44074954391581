import {
  setBulkOperationCompleted,
  setIsReloadPage,
} from '@modules/organization/actions';
import {
  getBulkOperationCompleted,
  getIsReloadPage,
} from '@modules/organization/selectors';
import * as R from 'ramda';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const useFetchListPageData = (
  searchParams,
  buildFetchParams,
  fetchData,
  entityType,
) => {
  const previousFetchData = useRef();
  const isReloadPage = useSelector(getIsReloadPage);
  const bulkOperationCompleted = useSelector(getBulkOperationCompleted);

  const dispatch = useDispatch();

  const shouldRefreshData =
    bulkOperationCompleted.status &&
    bulkOperationCompleted.entity === entityType;

  // biome-ignore lint/correctness/useExhaustiveDependencies: buildFetchParams is not memoized
  useEffect(() => {
    const fetchParams = buildFetchParams();
    const areParamsEqual = R.equals(fetchParams, previousFetchData.current);

    if (areParamsEqual && !isReloadPage) {
      return;
    }

    fetchData(fetchParams);
    previousFetchData.current = fetchParams;

    // the two dispatch below are executed also at the first render,
    // in case the redux store contains an old true value - f.i. because the user switched page when setBulkOperationCompleted was true
    if (isReloadPage) {
      dispatch(setIsReloadPage(false));
    }

    dispatch(setBulkOperationCompleted({ status: false }));
  }, [searchParams, isReloadPage]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: buildFetchParams cannot be added as dependency since its passed as aprop not memoized
  useEffect(() => {
    const fetchParams = buildFetchParams();
    const areParamsEqual = R.equals(fetchParams, previousFetchData.current);

    if (!areParamsEqual) {
      previousFetchData.current = fetchParams;

      dispatch(setBulkOperationCompleted({ status: false }));
    }
  }, [searchParams]);

  const refreshData = () => {
    const fetchParams = buildFetchParams();
    dispatch(setBulkOperationCompleted({ status: false }));
    fetchData(fetchParams);
  };

  return {
    shouldRefreshData,
    refreshData,
  };
};

export default useFetchListPageData;
