import * as R from 'ramda';

import { STATE_KEY } from '../constants';

export const getPartyConversations = R.compose(
  R.values,
  R.path([STATE_KEY, 'data']),
);

export const getPartyConversationsByParty = (state, partyId) =>
  R.compose(
    R.filter((partyConversation) =>
      R.propEq('party', partyId, partyConversation),
    ),
    getPartyConversations,
  )(state);

export const getPartyConversationTotalIssuesCount = (state, conversationId) =>
  R.pathOr(
    0,
    [STATE_KEY, 'data', conversationId, 'issues', 'totalCount'],
    state,
  );

export const getPartyConversationsByRequest = (state, requestId) =>
  R.compose(
    R.find((partyConversation) =>
      R.propEq('request', requestId, partyConversation),
    ),
    getPartyConversations,
  )(state);

export const getPartyConversationById = (state, id) =>
  R.compose(
    R.find((conversation) => conversation._id === id),
    getPartyConversations,
  )(state);

export const getNewMessagesCount = R.path([STATE_KEY, 'newMessagesCount']);

export * from './getPartyConversationMessagesCount';
