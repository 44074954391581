import { useMemo } from 'react';
import type { EmailTemplateType } from '../constants';
import { getPlaceholdersByEmailTemplateType } from '../utils';
import { usePlaceholders } from './usePlaceholders';

export const usePlaceholdersByEmailTemplateType = (
  emailTemplateType?: EmailTemplateType,
) => {
  const { placeholders: items } = usePlaceholders();

  const emailPlaceholders = useMemo(
    () => getPlaceholdersByEmailTemplateType(items, emailTemplateType),
    [items, emailTemplateType],
  );

  return { emailPlaceholders };
};
