import { Modal, message } from 'antd';
import * as R from 'ramda';
import React, { Fragment, useEffect, useReducer, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { Notes } from '@common/components/Notes';
import DotsIcon from '@common/icons/Dots';
import { useMutationWithRedux } from '@graphql/hooks';
import { getOperationName } from '@graphql/utils';
import OverrideRequirementModal from '@modules/document/components/OverrideRequirementModal';
import RemoveWaiverModal from '@modules/document/components/RemoveWaiverModal';
import WaiveRequirementModal from '@modules/document/components/WaiveRequirementModal';
import { PulsePolicyInfo } from '@modules/document/containers/CarrierPolicyInfo/PulsePolicyInfo';
import { getTemplateInviteBrokerMessage } from '@modules/document/containers/CarrierPolicyInfo/utility';
import { checkDocumentForSubject } from '@modules/document/utils/document-helpers';
import { usePulseIntegrationFeatureFlag } from '@modules/feature-flags/hooks';
import { getOrganizationMemberByUserId } from '@modules/organization-member/selectors';
import { getActiveOrganizationData } from '@modules/organization/selectors';
import {
  UPDATE_SUBJECT_STATUS,
  selectDocumentForComplianceCard,
} from '@modules/party/actions';
import ChangeSubjectStatusModal from '@modules/party/components/ChangeSubjectStatusModal';
import { SUBJECT_STATUS } from '@modules/party/constants';
import CarriersDetails from '@modules/requirement/components/CarriersDetails';
import { ExpirationDates } from '@modules/requirement/components/ExpirationDates';
import { SubjectStatusBadge } from '@modules/requirement/components/SubjectStatusBadge';
import {
  REMOVE_AUTOMATIC_WAIVER_PLACEMENT_ON_PARTY_REQUIREMENT,
  REMOVE_AUTOMATIC_WAIVER_PLACEMENT_ON_PARTY_SUBJECT,
  REMOVE_REQUIREMENT_WAIVER_MUTATION,
  WAIVE_REQUIREMENT_MUTATION,
  WAIVE_SUBJECT_MUTATION,
} from '@modules/requirement/mutations';
import { getMyId } from '@modules/user/selectors';

import {
  UPDATE_REQUIREMENT_STATUS,
  UPDATE_REQUIREMENT_STATUS_KEY,
} from '../../actions';
import CarriersDetailsLegacy from '../../components/CarriersDetails.legacy';
import CollapseSubject from '../../components/CollapseSubject';
import EmptyCarrierInfo from '../../components/EmptyCarrierInfo';
import SubjectContent from '../../components/SubjectContent';
import ConnectedRequirementsDetails from '../../components/connected-requirements/ConnectedRequirementsDetails';
import {
  ConnectedRequirementsAttributeIds,
  RequirementStatus,
} from '../../constants';

import { trackEvent } from '@common/utils/track-helpers';
import {
  ChangeSubjectModalActionsTypes,
  changeSubjectModalInitialState,
  changeSubjectModalReducer,
} from './CollapsePabelSubjectsContainer.utils';
import { CollapsePanelHeadingLayout } from './components/CollapsePanelHeadingLayout';
import {
  MENU_OPTIONS,
  SubjectActionMenu,
} from './components/SubjectActionMenu';
import { useUpdateSubjectMutation } from './hooks';

const REMOVE_SUBJECT_STATUS_MODAL_LABELS = {
  [SUBJECT_STATUS.WAIVED]: {
    title: `Are you sure you want to remove the waiver from this subject?`,
    okText: 'Remove waiver',
  },
  [SUBJECT_STATUS.OVERRIDDEN]: {
    title: `Are you sure you want to remove the override from this subject?`,
    okText: 'Remove override',
  },
};

export const CollapsePanelSubjectContainer = ({
  documents = [],
  hideRequirementActions,
  isInsuranceModule,
  organizationNamespace,
  party,
  projectId,
  requirementsBySubject,
  selectedDocuments,
  subjects = [],
  setConversationModalData,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const organization = useSelector(getActiveOrganizationData);
  const currentUserId = useSelector(getMyId);
  const currentMember = useSelector((state) =>
    getOrganizationMemberByUserId(state, currentUserId),
  );

  const { isPulseIntegrationFeatureFlagEnabled } =
    usePulseIntegrationFeatureFlag();

  const [activeKeys, setActiveKeys] = useState([]);
  const [changeSubjectModalState, setChangeSubjectModalState] = useReducer(
    changeSubjectModalReducer,
    changeSubjectModalInitialState,
  );
  const [isShowWaiveModal, setIsShowWaiveModal] = useState(false);
  const [isShowOverrideModal, setIsShowOverrideModal] = useState(false);
  const [isShowRemoveWaiverModal, setIsShowRemoveWaiverModal] = useState(false);
  const [isShowRemoveOverrideWaiverModal, setIsRemoveShowOverrideModal] =
    useState(false);
  const [statusRequirementData, setStatusRequirementData] = useState({});
  const documentMap = documents.reduce((acc, document) => {
    acc[document?._id] = document;
    return acc;
  }, {});
  const [waiveRequirement, { loading: isLoadingWaive }] = useMutationWithRedux(
    WAIVE_REQUIREMENT_MUTATION,
    {
      reduxActionKey: UPDATE_REQUIREMENT_STATUS_KEY,
      reduxActionType: UPDATE_REQUIREMENT_STATUS,
    },
  );
  const [removeRequirementWaiver, { loading: isRemovingRequirementWaiver }] =
    useMutationWithRedux(REMOVE_REQUIREMENT_WAIVER_MUTATION, {
      reduxActionKey: UPDATE_REQUIREMENT_STATUS_KEY,
      reduxActionType: UPDATE_REQUIREMENT_STATUS,
    });
  const [removeWaiveAutomaticPlacementOnPartyRequirement] =
    useMutationWithRedux(
      REMOVE_AUTOMATIC_WAIVER_PLACEMENT_ON_PARTY_REQUIREMENT,
      {
        reduxActionKey: UPDATE_REQUIREMENT_STATUS_KEY,
        reduxActionType: UPDATE_REQUIREMENT_STATUS,
      },
    );
  const [removeWaiveAutomaticPlacementOnPartySubject] = useMutationWithRedux(
    REMOVE_AUTOMATIC_WAIVER_PLACEMENT_ON_PARTY_SUBJECT,
    {
      reduxActionKey: UPDATE_REQUIREMENT_STATUS_KEY,
      reduxActionType: UPDATE_REQUIREMENT_STATUS,
    },
  );

  const [waiveSubject, { loading: isLoadingSubjectWaive }] =
    useMutationWithRedux(WAIVE_SUBJECT_MUTATION, {
      reduxActionKey: getOperationName(WAIVE_SUBJECT_MUTATION),
      reduxActionType: UPDATE_SUBJECT_STATUS,
    });

  const { removeSubjectStatus } = useUpdateSubjectMutation();

  const partyComplianceProfile = party?.partyComplianceProfile;

  const onCloseRemoveWaiverModal = () => {
    setIsShowRemoveWaiverModal(false);
    setIsRemoveShowOverrideModal(false);
  };
  const onSetSelectedDocument = (subjectId, documentId) => {
    dispatch(
      selectDocumentForComplianceCard({
        partyId: party?._id,
        subjectId: subjectId,
        documentId,
      }),
    );
  };

  const insurers = React.useMemo(() => {
    return documents.reduce((acc, document) => {
      R.pathOr([], ['insurers'], document).reduce(
        (insurerAcc, currentInsurer) => {
          insurerAcc[currentInsurer?._id] = currentInsurer;
          return insurerAcc;
        },
        acc,
      );
      return acc;
    }, {});
  }, [documents]);

  useEffect(() => {
    setActiveKeys(subjects.map((x) => x?.subjectId));
  }, [subjects]);

  const getDocumentsBySubjectId = (subjectId) =>
    documents.filter(
      (document) =>
        checkDocumentForSubject(document, [subjectId], true) &&
        !Boolean(document?.archivedAt),
    );

  const onRemoveWaiveAutomaticPlacementOnPartyRequirement = (requirement) => {
    return removeWaiveAutomaticPlacementOnPartyRequirement({
      variables: {
        payload: {
          attributeId: requirement.attributeId,
          partyId: requirement.party,
        },
      },
      onCompleted: () => {
        message.success('Automatic placement removed');
      },
    });
  };
  const onRestoreRequirement = (
    requirement,
    removeWaiveOverrideAcrossProject,
  ) => {
    return removeRequirementWaiver({
      variables: {
        payload: {
          attributeId: requirement.attributeId,
          partyId: requirement.party,
          projectId,
          applyToAllProjects: Boolean(removeWaiveOverrideAcrossProject),
        },
      },
      onCompleted: () => {
        message.success('Requirement status restored');
        onCloseRemoveWaiverModal();
      },
    });
  };

  const onAddClick = (selectedDocument) => {
    if (selectedDocument) {
      redirectToReviewPage(selectedDocument);
    }
  };

  const redirectToReviewPage = (documentId) =>
    navigate(
      `${organizationNamespace}/parties/${party?._id}/documents-review${
        documentId ? `/${documentId}` : documentId
      }${projectId ? `?project=${projectId}` : ''}`,
    );

  const handleCloseWaiveSubjectModal = () => {
    setChangeSubjectModalState({ type: 'CLOSE' });
  };

  const handleMenuDropdownActions = (subject) => (type) => {
    switch (type) {
      case MENU_OPTIONS.WAIVE:
        setChangeSubjectModalState({
          type: ChangeSubjectModalActionsTypes.OPEN_WAIVE,
          payload: subject,
        });
        break;
      case MENU_OPTIONS.OVERRIDE:
        setChangeSubjectModalState({
          type: ChangeSubjectModalActionsTypes.OPEN_OVERRIDE,
          payload: subject,
        });
        break;
      case MENU_OPTIONS.EDIT:
        setChangeSubjectModalState({
          type: ChangeSubjectModalActionsTypes.EDIT,
          payload: subject,
        });
        break;
      case MENU_OPTIONS.REMOVE:
        const labels =
          REMOVE_SUBJECT_STATUS_MODAL_LABELS[
            subject?.requirementComplianceStatusValue
          ];

        Modal.confirm({
          title: labels?.title,
          okText: labels?.okText,
          onOk: () => {
            removeSubjectStatus({
              partyId: party?._id,
              subjectId: subject.subjectId,
              projectId,
            });
          },
          okButtonProps: {
            'data-cy': 'confirmRestoreSubjectButton',
          },
        });
        break;
      case MENU_OPTIONS.REMOVE_AUTOMATIC_WAIVER:
        Modal.confirm({
          title: `Are you sure you want to remove the automatic waive from this subject?`,
          content: `New projects will not be automatically waived.`,
          okText: `Remove waive`,
          onOk: () =>
            removeWaiveAutomaticPlacementOnPartySubject({
              variables: {
                payload: { partyId: party?._id, subjectId: subject.subjectId },
              },
            }),
        });
        break;
      case MENU_OPTIONS.REMOVE_AUTOMATIC_OVERRIDE:
        Modal.confirm({
          title: `Are you sure you want to remove the automatic override from this subject?`,
          content: `New projects will not be automatically overriden.`,
          okText: `Remove override`,
          onOk: () =>
            removeWaiveAutomaticPlacementOnPartySubject({
              variables: {
                payload: { partyId: party?._id, subjectId: subject.subjectId },
              },
            }),
        });
        break;

      default:
        break;
    }
  };

  return (
    <>
      <ChangeSubjectStatusModal
        selectedSubject={changeSubjectModalState.selectedSubject}
        visible={changeSubjectModalState.visible}
        type={changeSubjectModalState.type}
        loading={isLoadingSubjectWaive}
        onClose={handleCloseWaiveSubjectModal}
        onSubmit={({
          resetRequirementOn,
          notes,
          selectedSubject,
          applyToAllProjects,
        }) => {
          return waiveSubject({
            variables: {
              payload: {
                projectId,
                partyId: party?._id,
                subjectId: selectedSubject.subjectId,
                status: changeSubjectModalState.type,
                notes,
                resetRequirementOn,
                applyToAllProjects,
              },
            },
            onCompleted: () => {
              trackEvent('User waived a subject');
              handleCloseWaiveSubjectModal();
            },
          });
        }}
      />
      <RemoveWaiverModal
        visible={isShowRemoveWaiverModal}
        isOverride={isShowRemoveOverrideWaiverModal}
        onClose={onCloseRemoveWaiverModal}
        onSubmit={({ applyToAllProjects }) => {
          onRestoreRequirement(statusRequirementData, applyToAllProjects);
        }}
        loading={isRemovingRequirementWaiver}
      />
      {
        <>
          <>
            <WaiveRequirementModal
              visible={isShowWaiveModal}
              onClose={() => setIsShowWaiveModal(false)}
              requirement={statusRequirementData}
              loading={isLoadingWaive}
              onSubmit={({ applyToAllProjects, notes, resetRequirementOn }) => {
                return waiveRequirement({
                  variables: {
                    payload: {
                      attributeId: statusRequirementData.attributeId,
                      partyId: statusRequirementData.party,
                      notes,
                      override: false,
                      resetRequirementOn,
                      applyToAllProjects,
                      projectId,
                    },
                  },
                  onCompleted: () => {
                    message.success('Requirement waived');
                    trackEvent('User waived a requirement');
                    setIsShowWaiveModal(false);
                  },
                });
              }}
            />
            <OverrideRequirementModal
              visible={isShowOverrideModal}
              onClose={() => setIsShowOverrideModal(false)}
              requirement={statusRequirementData}
              loading={isLoadingWaive}
              onSubmit={({ applyToAllProjects, notes, resetRequirementOn }) => {
                return waiveRequirement({
                  variables: {
                    payload: {
                      attributeId: statusRequirementData.attributeId,
                      partyId: statusRequirementData.party,
                      notes,
                      override: true,
                      resetRequirementOn,
                      applyToAllProjects,
                      projectId,
                    },
                  },
                  onCompleted: () => {
                    message.success('Requirement overridden');
                    trackEvent('User overrode a requirement');
                    setIsShowOverrideModal(false);
                  },
                });
              }}
            />
          </>
        </>
      }
      {subjects.map((subject) => {
        const { subjectId } = subject;

        const metadata = R.path(
          ['partyComplianceProfile', 'metadata', subjectId],
          party,
        );

        const selectedDocument = R.propOr('', subjectId, selectedDocuments);
        const insurerId = R.path(
          [selectedDocument, 'metadata', subjectId, 'insurerId'],
          documentMap,
        );
        const carrier = R.propOr({}, insurerId, insurers);
        const policyNumber = R.path(
          ['metadata', subjectId, 'policyNumber'],
          documentMap[selectedDocument],
        );

        const currentInsurerId = R.path(
          ['metadata', subjectId, 'insurerId'],
          documentMap[selectedDocument],
        );

        const currentInsurer = documentMap[selectedDocument]?.insurers?.find(
          ({ _id }) => _id === currentInsurerId,
        );

        const currentDocument = documents.find((document) => {
          return document._id === selectedDocument;
        });

        const hasInsurerContact = party?.partyContacts?.some(
          (contact) => contact._id === currentDocument?.issuedBy?.partyContact,
        );

        const subjectHasDocuments = R.path(
          ['metadata', subjectId, 'documentsCount'],
          partyComplianceProfile,
        );
        const filteredDocuments = documents.filter((document) => {
          return (
            R.path(['metadata', subjectId, 'insurerId'], document) ||
            R.path(['metadata', subjectId, 'policyNumber'], document)
          );
        });

        const shouldShowCarrierPanel = isInsuranceModule && subjectHasDocuments;

        const requirments = requirementsBySubject(subject.subjectId);

        const hasAMbestRequirmentsMet = requirments?.some(
          ({ requirementComplianceStatusValue }) => {
            return (
              requirementComplianceStatusValue === RequirementStatus.Invalidated
            );
          },
        );

        const connectedRequirements = requirments.filter((req) =>
          Object.values(ConnectedRequirementsAttributeIds).includes(
            req.attributeId,
          ),
        );

        const hasConnectedRequirements = Boolean(connectedRequirements.length);

        const defaultMessageInviteBroker = getTemplateInviteBrokerMessage(
          party.name,
          currentMember?.user?.profile?.name || currentMember?.user?.email,
        );

        const isAutomaticOverriddenAcrossProjects =
          party?.automaticallyWaivedSubjects?.some(
            (waivedOverriddenSubject) =>
              waivedOverriddenSubject.subjectId === subjectId &&
              waivedOverriddenSubject.status === 'overridden',
          );

        const isAutomaticWaivedAcrossProjects =
          party?.automaticallyWaivedSubjects?.some(
            (waivedOverriddenSubject) =>
              waivedOverriddenSubject.subjectId === subjectId &&
              waivedOverriddenSubject.status === 'waived',
          );

        return (
          <Fragment key={subjectId}>
            <CollapseSubject
              activeKeys={activeKeys}
              onChange={(keys) => setActiveKeys(keys)}
              subject={subject}
              subjects={subjects}
              header={
                <CollapsePanelHeadingLayout>
                  <CollapsePanelHeadingLayout.LeftContent>
                    <StyledTitle>{subject.subjectLabel}</StyledTitle>
                    <SubjectStatusBadge
                      statusValue={subject?.requirementComplianceStatusValue}
                      resetRequirementOn={subject?.resetRequirementOn}
                    />
                    <Notes>{subject?.notes}</Notes>
                  </CollapsePanelHeadingLayout.LeftContent>
                  <CollapsePanelHeadingLayout.RightContent>
                    <ExpirationDates
                      effectiveDate={metadata?.effectiveDate}
                      expirationDate={
                        metadata?.expirationDate ||
                        metadata?.latestExpirationDate
                      }
                    />
                    {!hideRequirementActions && (
                      <SubjectActionMenu
                        subjectStatus={
                          subject?.requirementComplianceStatusValue
                        }
                        onMenuItemClicked={handleMenuDropdownActions(subject)}
                        menuIcon={<DotsIcon />}
                        isAutomaticOverriddenAcrossProjects={
                          isAutomaticOverriddenAcrossProjects
                        }
                        isAutomaticWaivedAcrossProjects={
                          isAutomaticWaivedAcrossProjects
                        }
                      />
                    )}
                  </CollapsePanelHeadingLayout.RightContent>
                </CollapsePanelHeadingLayout>
              }
            >
              {Boolean(shouldShowCarrierPanel) &&
                (!R.isEmpty(carrier) || Boolean(policyNumber) ? (
                  <>
                    {isPulseIntegrationFeatureFlagEnabled ? (
                      <CarriersDetails
                        documents={filteredDocuments}
                        carrier={carrier}
                        onSetSelectedDocument={(documentId) => {
                          onSetSelectedDocument(subjectId, documentId);
                        }}
                        policyNumber={policyNumber}
                        onSelectedDocumentClick={redirectToReviewPage}
                        selectedDocumentId={selectedDocument}
                        isAMBestAlertVisible={hasAMbestRequirmentsMet}
                        CarrierPolicyInfo={
                          !!currentInsurer?.naic &&
                          !!policyNumber && (
                            <PulsePolicyInfo
                              insurerNAIC={currentInsurer.naic}
                              policyNumber={policyNumber}
                              showInviteBrokerButton={hasInsurerContact}
                              onInviteBroker={() => {
                                setConversationModalData({
                                  visible: true,
                                  defaultSubject: `I would like to validate your client's policy`,
                                  defaultMessage: defaultMessageInviteBroker,
                                  defaultRecipients: [
                                    currentDocument?.issuedBy?.partyContact,
                                  ],
                                });
                              }}
                            />
                          )
                        }
                      />
                    ) : (
                      <CarriersDetailsLegacy
                        documents={filteredDocuments}
                        carrier={carrier}
                        onSetSelectedDocument={(documentId) => {
                          onSetSelectedDocument(subjectId, documentId);
                        }}
                        policyNumber={policyNumber}
                        onSelectedDocumentClick={redirectToReviewPage}
                        selectedDocumentId={selectedDocument}
                        isAMBestAlertVisible={hasAMbestRequirmentsMet}
                      />
                    )}
                  </>
                ) : (
                  <EmptyCarrierInfo
                    onAddClick={() => onAddClick(selectedDocument)}
                  />
                ))}

              {hasConnectedRequirements && (
                <ConnectedRequirementsDetails
                  partyId={party?._id}
                  projectId={projectId}
                  subjectId={subjectId}
                  onSelectedDocumentClick={redirectToReviewPage}
                />
              )}

              <SubjectContent
                subject={subject}
                party={party}
                organization={organization}
                requirementsBySubject={requirementsBySubject}
                documents={documents}
                getDocumentsBySubjectId={getDocumentsBySubjectId}
                setStatusRequirementData={setStatusRequirementData}
                onRestoreRequirement={(requirement, isOverride) => {
                  setIsShowRemoveWaiverModal(true);
                  setIsRemoveShowOverrideModal(isOverride);
                  setStatusRequirementData(requirement);
                }}
                setIsShowWaiveModal={setIsShowWaiveModal}
                setIsShowOverrideModal={setIsShowOverrideModal}
                redirectToReviewPage={redirectToReviewPage}
                projectId={projectId}
                organizationNamespace={organizationNamespace}
                hideRequirementActions={hideRequirementActions}
                onRemoveWaiveAutomaticPlacementOnPartyRequirement={
                  onRemoveWaiveAutomaticPlacementOnPartyRequirement
                }
              />
            </CollapseSubject>
          </Fragment>
        );
      })}
    </>
  );
};

const StyledTitle = styled.div`
  font-size: 14px;
  display: flex;
  align-items: center;
  font-weight: 400;
  color: ${(props) => props.theme.colors.black};
`;
