import { gql } from '@apollo/client';

import { namespace } from '../constants';

export const UPDATE_MESSAGE = `${namespace}/UPDATE_MESSAGE`;

const UPDATE_MESSAGE_MUTATION = gql`
  mutation updateMessage($payload: UpdateMessageInput!) {
    updateMessage(data: $payload)
  }
`;

export const updateMessage = (payload) => ({
  type: UPDATE_MESSAGE,
  payload: {
    key: 'updateMessage',
    graphql: {
      mutation: UPDATE_MESSAGE_MUTATION,
      variables: {
        payload,
      },
    },
  },
});
