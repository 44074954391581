const FilePaperIcon = () => (
  <svg
    width="10"
    height="14"
    viewBox="0 0 10 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.68398 3.94707C9.76602 4.0291 9.8125 4.13984 9.8125 4.25605V12.6875C9.8125 12.9295 9.61699 13.125 9.375 13.125H0.625C0.383008 13.125 0.1875 12.9295 0.1875 12.6875V1.3125C0.1875 1.07051 0.383008 0.875 0.625 0.875H6.43145C6.54766 0.875 6.65977 0.921484 6.7418 1.00352L9.68398 3.94707ZM8.80352 4.45703L6.23047 1.88398V4.45703H8.80352Z"
      fill="#1890FF"
    />
  </svg>
);

export default FilePaperIcon;
