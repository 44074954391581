import * as R from 'ramda';

import { STATE_KEY } from '../constants';

export const getEmailTemplates = R.compose(
  R.values,
  R.path([STATE_KEY, 'data']),
);

export const getEmailTemplate = (state, id) =>
  R.compose(R.find(R.propEq('_id', id)), getEmailTemplates)(state);
