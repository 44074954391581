import { Tag, Tooltip } from 'antd';
import styled from 'styled-components';

import { HideForViewerRole } from '@modules/organization-member/containers/HideForRoles';

type Filter = {
  label: string;
  value: string;
  key: string;
  onClose: () => void;
};

type ListPageFilterTagsProps = {
  isSaveReportVisible: boolean;
  filters: Filter[];
  onSaveReport: () => void;
  onResetFilters: () => void;
  onClose: (key: string) => void;
};

const ListPageFilterTags = ({
  isSaveReportVisible = false,
  filters,
  onSaveReport,
  onResetFilters,
  onClose,
}: ListPageFilterTagsProps) => {
  if (!filters.length) {
    return null;
  }

  return (
    <StyledWrapped data-cy="listPageTagsWrapper">
      {filters.map((filter) => (
        <StyledTag
          data-cy="listPageFilterTag"
          closable
          key={filter.key}
          onClose={() => {
            onClose(filter.key);
          }}
        >
          <StyledLabel>{filter.label || ''}</StyledLabel>
          <Tooltip title={filter.value || ''}>
            <StyledValue>{filter.value || ''}</StyledValue>
          </Tooltip>
        </StyledTag>
      ))}
      {isSaveReportVisible && (
        <HideForViewerRole>
          <StyledButton data-cy="tagsSaveReport" onClick={onSaveReport}>
            save report
          </StyledButton>
          <StyledDotSeparator>·</StyledDotSeparator>
        </HideForViewerRole>
      )}

      <StyledButton data-cy="tagsResetFilter" onClick={onResetFilters}>
        reset filters
      </StyledButton>
    </StyledWrapped>
  );
};

const StyledWrapped = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
`;

const StyledTag = styled(Tag)`
  position: relative;
  display: flex;
  margin: 5px 10px 5px 0;
  padding: 2px 20px 1px 12px;
  font-size: 12px;
  border-radius: 15px;
  background-color: ${(props) => props.theme.colors.white} !important;
  color: ${(props) => props.theme.colors.blue};
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.04);
  border-color: #dce7f4;

  .anticon-close {
    position: absolute;
    top: 7px;
    right: 5px;
  }
`;

const StyledLabel = styled.label`
  margin-right: 5px;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 10px;
  color: rgba(0, 0, 0, 0.65);
`;

const StyledValue = styled.div`
  text-overflow: clip;
  overflow: hidden;
  white-space: nowrap;
`;

const StyledButton = styled.ins`
  line-height: 22px;
  margin-left: 5px;
  display: flex;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
`;

const StyledDotSeparator = styled.span`
  margin: 0 5px 0 10px;
`;

export default ListPageFilterTags;
