// ! THESE ARE LEGACY AND DON'T FOLLOW THE NEW NAMING CONVENTIONS.
// ! SOME OF THESE FEATURE FLAGS ARE ACTUALLY PERMANENT ADD-ON
// ! FUNCTIONALITIES THAT SHOULD NOT BE TREATED AS FEATURE FLAGS.
// ! THIS WILL BE RECTIFIED IN A SUBSEQUENT PR.
// TODO: move those to modules/add-on after completing Coffee Cup Epic
export const FEATURE_FLAGS = {
  BETA_FILLABLE_FORMS: 'beta:forms',
  COMMANDBAR: 'feature:commandbar',
  FEATURE_NPN: 'feature:npn',
  BRANDING: 'feature:branding',
} as const;
