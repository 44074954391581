import { graphql } from '@graphql/types';

export const PARTY_COMPLIANCE_PROFILE_QUERY = graphql(`
  query PartyComplianceProfile($id: ObjectId!) {
    getComplianceProfile(id: $id) {
      _id
      name
      context
      contextId
      baseComplianceProfile
      rules {
        _id
        moduleId
        moduleLabel
        subjectId
        subjectLabel
        attributeId
        attributeLabel
        attributeType
        attributeDescription
        matchingCriteria
        automaticallyDetected
        masterDocumentAttributeId
        operator
        targetValue
        isActive
        complianceProfile
        invalidatedBy
        invalidationScope
      }
      documentChecklists {
        _id
        type {
          _id
          name
          pluralName
          isSystemType
        }
        attributeId
        attributeLabel
        complianceProfile
        isActive
        createdAt
        updatedAt
      }
      partiesCount
      projectsCount
      createdAt
      updatedAt
      deletedAt
      deletedBy
    }
  }
`);
