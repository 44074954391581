import qs from 'query-string';
import * as R from 'ramda';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { defaultSearchParams } from '../constants';
import getEntityByPathname from '../utils/getEntityByPathname';

const usePreferredSearchParams = () => {
  const { pathname, search } = useLocation();
  const searchParams = useMemo(() => qs.parse(search), [search]);
  const entity = getEntityByPathname(pathname);

  const defaultParams = R.prop(entity, defaultSearchParams);

  const size = parseInt(
    R.propOr(R.prop('size', defaultParams), 'size', searchParams),
  );
  const view = R.propOr(R.prop('view', defaultParams), 'view', searchParams);
  const sortField = R.propOr(
    R.prop('sortField', defaultParams),
    'sortField',
    searchParams,
  );
  const sortDirection = R.propOr(
    R.prop('sortDirection', defaultParams),
    'sortDirection',
    searchParams,
  );

  return {
    sortField,
    sortDirection,
    view,
    size,
  };
};

export default usePreferredSearchParams;
