import type { QueryHookOptions } from '@apollo/client';

import { useQuery } from '@graphql/hooks';

import {
  PARTY_AUTOMATIONS_QUERY,
  type PartyAutomationsData,
  type PartyAutomationsVariables,
} from '../queries';
import { getPartyAutomationsMap } from '../utils';

type UsePartyAutomationsProps = {
  partyId: string;
  automationCode?: string;
  queryOptions?: QueryHookOptions<
    PartyAutomationsData,
    PartyAutomationsVariables
  >;
};

/**
 * @todo add filter by automation code to query only an automation of party - once BE adds support for it
 */
export const usePartyAutomations = ({
  partyId,
  queryOptions,
}: UsePartyAutomationsProps) => {
  const {
    loading,
    data: partyAutomationData,
    refetch,
  } = useQuery<PartyAutomationsData, PartyAutomationsVariables>(
    PARTY_AUTOMATIONS_QUERY,
    { variables: { partyId }, ...queryOptions },
  ) || {};

  const partyAutomationsMap = getPartyAutomationsMap({
    partyAutomations: partyAutomationData?.getPartyDataV2?.automations,
  });

  return {
    isLoading: loading,
    data: partyAutomationsMap,
    refetch,
  };
};
