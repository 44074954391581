import type { ParsedQuery } from 'query-string';

import { DateSuffix } from '@common/components/DateFilterSelector/constants';

// TODO: filterType should be an enum
export const getDateFilters = ({
  activeFilters,
  filterType,
}: {
  activeFilters: ParsedQuery<string> | undefined;
  filterType: string;
}) => {
  if (!activeFilters) return {};

  const type = `${filterType}FilterType`;
  const withinDateCount = `${filterType}${DateSuffix.WithinDateCount}`;
  const withinDateType = `${filterType}${DateSuffix.WithinDateType}`;
  const datePickerValue = `${filterType}${DateSuffix.PickerValue}`;
  const dateEndingValue = `${filterType}${DateSuffix.To}`;
  const dateStartingValue = `${filterType}${DateSuffix.From}`;

  return {
    [type]: activeFilters[type],
    [withinDateCount]: activeFilters[withinDateCount],
    [withinDateType]: activeFilters[withinDateType],
    [datePickerValue]: activeFilters[datePickerValue],
    [dateEndingValue]: activeFilters[dateEndingValue],
    [dateStartingValue]: activeFilters[dateStartingValue],
  };
};
