import * as R from 'ramda';
import styled from 'styled-components';

import PartyContactCardContainer from '../containers/PartyContactCardContainer';

const PartyContactCardList = ({
  partyContacts,
  setContactToEdit,
  deleteContact,
  setContactAsPrimary,
  sendContactMessage,
}) => (
  <PartyContactCardList.Wrapper data-cy="partyContactCardList">
    {partyContacts.map((partyContact) => (
      <PartyContactCardContainer
        key={R.prop('_id', partyContact)}
        partyContact={partyContact}
        setContactToEdit={setContactToEdit}
        deleteContact={deleteContact}
        setContactAsPrimary={setContactAsPrimary}
        sendContactMessage={sendContactMessage}
      />
    ))}
  </PartyContactCardList.Wrapper>
);

PartyContactCardList.Wrapper = styled.div`
  padding: 20px;
`;

export default PartyContactCardList;
