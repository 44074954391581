import type { RootState } from '@common/types';

import { STATE_KEY } from '../constants';
import type { PartyConversation } from '../fragments';

export const getPartyConversationMessagesCount = (
  state: RootState,
  partyConversationId: PartyConversation['_id'],
) => {
  return state[STATE_KEY]?.data[partyConversationId]?.messages?.totalCount ?? 0;
};
