import qs from 'query-string';
import * as R from 'ramda';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import FilterButtonWithPopover from '@common/components/FilterButtonWithPopover';
import { ColLeft, ColRight, Row } from '@common/components/ListPageFilter';
import SearchInput from '@common/components/SearchInput';
import SortButtonWithPopover from '@common/components/SortButtonWithPopover';
import SortOptionsList from '@common/components/SortOptionsList';
import ViewSwitcher from '@common/components/ViewSwitcher';
import { fetchProjectCustomFields } from '@modules/custom-field/actions';
import { getProjectCustomFields } from '@modules/custom-field/selectors';
import { setPreferredSearchParams } from '@modules/system-settings/actions';
import { ListView } from '@modules/system-settings/constants';
import usePreferredSearchParams from '@modules/system-settings/hooks/usePreferredSearchParams';

import { useIsComplianceRequirementsAddOnEnabled } from '@modules/add-on';
import ProjectFiltersPopoverContent from '../components/ProjectFiltersPopoverContent';
import ProjectSelectButtons from '../components/ProjectSelectButtons';
import { getProjectsSortOptions, getSortLabelData } from '../constants';

const ProjectsFilterContainer = ({
  updateFilters,
  updateNameFilter,
  resetFilters,
  filtersCount,
  totalCount,
  projectsVisibleCount,
  sortField,
  sortDirection,
  selectedProjectsCount,
  setSelectedProjectsCount,
  areAllProjectsSelected,
  setAreAllProjectsSelected,
  selectedProjects,
  setSelectedProjects,
  selectAllProjects,
  selectAllVisibleProjects,
  updateProjectsActiveStatus,
  deleteProjects,
  embeddedInPartyProfile,
  removePartyFromProjects,
  loading,
}) => {
  const { pathname, search } = useLocation();
  const navigate = useNavigate();

  const [searchValue, setSearchValue] = useState('');
  const [isVisible, setIsVisible] = useState(false);

  const { view: projectsView } = usePreferredSearchParams();
  const customFields = useSelector(getProjectCustomFields);
  const { isComplianceRequirementsAddOnEnabled } =
    useIsComplianceRequirementsAddOnEnabled();

  const dispatch = useDispatch();

  const searchParams = qs.parse(search);
  const searchFilter = R.prop('name', searchParams);

  const isActiveProjects = searchParams.status !== 'inactive';

  useEffect(() => {
    setSearchValue(searchFilter);
  }, [searchFilter]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: More Deps Than Needed
  useEffect(() => {
    dispatch(fetchProjectCustomFields({ first: 150 }));
  }, [dispatch, isVisible]);

  const changeView = () => {
    const view =
      projectsView === ListView.Card ? ListView.Table : ListView.Card;
    dispatch(
      setPreferredSearchParams({
        view,
      }),
    );

    navigate(
      `${pathname}?${qs.stringify(R.assoc('view', view, searchParams))}`,
      { replace: true },
    );
  };

  const handleOnSortChange = (option) => updateFilters(option, []);

  return (
    <Row data-cy="selectAllButtonGroup">
      <ColLeft>
        {selectedProjectsCount ? (
          <ProjectSelectButtons
            isActiveProjects={isActiveProjects}
            selectedProjects={selectedProjects}
            updateProjectsActiveStatus={() =>
              updateProjectsActiveStatus(selectedProjects)
            }
            deleteProjects={() => deleteProjects(selectedProjects)}
            embeddedInPartyProfile={embeddedInPartyProfile}
            removePartyFromProjects={removePartyFromProjects}
            disableRemoveFromProjectsButton={areAllProjectsSelected}
            areAllSelected={areAllProjectsSelected}
            selectedEntitiesCount={selectedProjectsCount}
            onSelectAll={selectAllProjects}
            onSelectAllVisible={selectAllVisibleProjects}
            onSelectNone={() => {
              setSelectedProjects([]);
              setAreAllProjectsSelected(false);
              setSelectedProjectsCount(0);
            }}
            totalCount={totalCount}
            totalVisible={projectsVisibleCount}
          />
        ) : (
          <SearchInput
            key={searchValue}
            isLoading={loading}
            defaultValue={searchValue}
            onSearch={updateNameFilter}
          />
        )}
      </ColLeft>
      <ColRight>
        <ViewSwitcher view={projectsView} onChange={changeView} />
        <FilterButtonWithPopover
          filtersCount={filtersCount}
          popoverContent={
            <ProjectFiltersPopoverContent
              customFields={customFields}
              searchParams={searchParams}
              updateFilters={updateFilters}
            />
          }
          onPopoverVisibleChange={(isVisible) => setIsVisible(isVisible)}
          onResetFilters={resetFilters}
        />
        <SortButtonWithPopover
          sortLabelData={getSortLabelData({
            field: sortField,
            direction: sortDirection,
            isComplianceRequirementsAddOnEnabled,
          })}
          popoverContent={
            <SortOptionsList
              options={getProjectsSortOptions({
                isComplianceRequirementsAddOnEnabled,
              })}
              onChange={handleOnSortChange}
            />
          }
        />
      </ColRight>
    </Row>
  );
};

export default ProjectsFilterContainer;
