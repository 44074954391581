import styled from 'styled-components';

const StyledSeparator = styled.div`
  height: 18px;
  margin: 0 15px;
  border-right: 2px solid ${(props) => props.theme.colors.gray};
`;

const VerticalSeparator = (props) => {
  return <StyledSeparator {...props} />;
};

export default VerticalSeparator;
