import * as R from 'ramda';

import { getFilterData } from '@common/utils/filter-helpers';
import { customFieldUrlRegexp } from '@modules/custom-field/constants';
import {
  formatCustomFieldValue,
  getCustomFieldKeys,
} from '@modules/custom-field/utils/custom-field-helpers';

import {
  ProjectComplianceStatusLabelsMap,
  ProjectFilterFields,
  NormalizedProjectFilters as filters,
} from '../constants';

export const normalizedProjectsFilters = ({ customFields, activeFilters }) => {
  const formatValue = (key, value) => {
    const valueArray = Array.isArray(value) ? value : [value];

    switch (key) {
      case ProjectFilterFields.ComplianceStatus: {
        return valueArray.map(
          (statusKey) => ProjectComplianceStatusLabelsMap[statusKey],
        );
      }
      default: {
        return customFieldUrlRegexp.test(key)
          ? formatCustomFieldValue(key, valueArray, customFields)
          : value;
      }
    }
  };

  return R.compose(
    R.filter((el) => el.isActive),
    R.map((key) => {
      const { label, value } = getFilterData({
        key,
        filters,
        formatValue,
        activeFilters,
      });

      return {
        key,
        label,
        value,
        isActive: Array.isArray(value)
          ? value.filter((el) => Boolean(el)).length > 0
          : Boolean(value),
      };
    }),
    R.uniq,
    R.concat(getCustomFieldKeys()),
    R.keys,
  )(filters);
};
