import { Card } from 'antd';
import styled from 'styled-components';

export default styled(Card)`
  && {
    margin-bottom: 50px;
    border-radius: 4px;
    box-shadow: ${({ theme }) => theme.shadows.card};
  }

  .ant-card-head-title {
    margin: 0;
    font-size: 14px;
    line-height: 26px;
    color: ${(props) => props.theme.colors.grayishBlue};
    display: flex;
    justify-content: space-between;
  }

  .ant-card-body {
    > .ant-table-wrapper {
      border-top: 1px solid #f0f0f0;
      margin: -24px;
      max-width: inherit;

      .ant-table-thead > tr > th {
        font-size: 12px;
        padding-top: 12px;
        padding-bottom: 12px;
        font-weight: normal;
        color: rgba(0, 0, 0, 0.65);
      }

      .ant-table-cell {
        font-size: 13px;
      }

      .ant-table-cell:first-child {
        padding-left: 24px;
      }

      .ant-table-cell:last-child {
        padding-right: 24px;
      }
    }

    > .ant-list {
      margin: -24px;

      .ant-list-item {
        padding-left: 24px;
        padding-right: 24px;
        border-top: 1px solid transparent;
      }
    }
  }
`;
