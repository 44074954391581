import { Layout } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import Spinner from '@common/components/Spinner';
import PartyContactCardListContainer from '@modules/party-contact/containers/PartyContactCardListContainer';
import {
  closeConversationsChannel,
  openConversationsChannel,
} from '@modules/party-conversation/actions';
import { EmptyConversationState } from '@modules/party-conversation/components/EmptyConversationState';
import MessagesListContainer from '@modules/party-conversation/containers/MessagesListContainer';
import PartyConversationListContainer from '@modules/party-conversation/containers/PartyConversationListContainer';

import MessagesControlPanelContainer from '../containers/MessagesControlPanelContainer';
import MessagesSearchContainer from '../containers/MessagesSearchContainer';

import ChatHeaderLegacy from './ChatHeader.legacy';
import SendMessageForm from './SendMessageForm';

const { Sider, Header, Content, Footer } = Layout;

const MessagesTab = ({
  isLoading,
  isVisibleContactModal,
  setIsVisibleContactModal,
  setIsVisibleConversationModal,
  selectedPartyConversationId,
  setSelectedPartyConversationId,
  setSelectedRecipientId,
  searchValue,
  setSearchValue,
  isMessages,
  isEmptyConversations,
  isEmptyContacts,
  setIsMessages,
  conversationParticipantsData,
  selectedConversationData,
}) => {
  const [scrollPosition, setScrollPosition] = useState(-1);
  const [isScrolling, setIsScrolling] = useState(false);
  const { partyId } = useParams();

  const dispatch = useDispatch();

  // biome-ignore lint/correctness/useExhaustiveDependencies: More Deps Than Needed
  useEffect(() => {
    setIsScrolling(false);
    setScrollPosition(-1);
  }, [selectedPartyConversationId]);

  useEffect(() => {
    dispatch(openConversationsChannel(partyId));

    return () => {
      dispatch(closeConversationsChannel(partyId));
    };
  }, [dispatch, partyId]);

  return (
    <MessagesTab.Wrapper>
      {isLoading ? (
        <Spinner />
      ) : (
        <Layout hasSider>
          <Sider
            theme="light"
            width="340"
            style={{
              borderRight: '1px solid #e6eaef',
              overflowY: 'auto',
              backgroundColor: isMessages ? '#fff' : '#f4f8fd',
            }}
          >
            <MessagesControlPanelContainer
              isMessages={isMessages}
              isEmptyConversations={isEmptyConversations}
              setIsMessages={setIsMessages}
              setIsVisibleContactModal={setIsVisibleContactModal}
              setIsVisibleConversationModal={setIsVisibleConversationModal}
            />
            <MessagesSearchContainer
              searchValue={searchValue}
              setSearchValue={setSearchValue}
            />
            {isMessages ? (
              <PartyConversationListContainer
                searchValue={searchValue}
                selectedPartyConversationId={selectedPartyConversationId}
                setSelectedPartyConversationId={setSelectedPartyConversationId}
              />
            ) : (
              <PartyContactCardListContainer
                searchValue={searchValue}
                setSelectedRecipientId={setSelectedRecipientId}
                setIsVisibleConversationModal={setIsVisibleConversationModal}
                isVisibleContactModal={isVisibleContactModal}
                setIsVisibleContactModal={setIsVisibleContactModal}
                setIsMessages={setIsMessages}
                setSelectedPartyConversationId={setSelectedPartyConversationId}
              />
            )}
          </Sider>
          <Layout>
            <MessagesTab.Header>
              <ChatHeaderLegacy
                selectedPartyConversation={selectedConversationData}
                participantsData={conversationParticipantsData}
              />
            </MessagesTab.Header>
            <MessagesTab.Content
              onScroll={(e) => {
                if (scrollPosition <= e.currentTarget.scrollTop) {
                  setScrollPosition(e.currentTarget.scrollTop);
                  setIsScrolling(false);
                } else {
                  setIsScrolling(true);
                }
              }}
            >
              {Boolean(selectedPartyConversationId) ? (
                <MessagesListContainer
                  selectedPartyConversationId={selectedPartyConversationId}
                  isScrolling={isScrolling}
                  selectedConversationData={selectedConversationData}
                />
              ) : (
                <EmptyConversationState
                  isEmptyConversations={isEmptyConversations}
                  isEmptyContacts={isEmptyContacts}
                  onClick={() => {
                    setIsVisibleConversationModal(true);
                  }}
                />
              )}
            </MessagesTab.Content>
            {Boolean(selectedPartyConversationId) && (
              <MessagesTab.Footer>
                <SendMessageForm
                  selectedPartyConversationId={selectedPartyConversationId}
                />
              </MessagesTab.Footer>
            )}
          </Layout>
        </Layout>
      )}
    </MessagesTab.Wrapper>
  );
};

MessagesTab.Wrapper = styled.div`
  box-shadow: rgba(71, 88, 114, 0.14) 0 2px 2px;
  border: 1px solid hsla(213deg, 69%, 95%, 1);
  border-radius: 4px;

  > .ant-layout {
    height: calc(100vh - 280px);
  }

  section,
  aside {
    &:focus {
      outline-width: 0;
    }
  }
`;

MessagesTab.Header = styled(Header)`
  &&& {
    height: 70px;
    line-height: inherit;
    background-color: #fff;
    border-bottom: 1px solid #e6eaef;
    padding: 0;
  }
`;

MessagesTab.Content = styled(Content)`
  background-color: #fff;
  overflow-y: auto;
  position: relative;
`;

MessagesTab.Footer = styled(Footer)`
  &&& {
    padding: 0;
    background-color: #f4f8fd;
  }
`;

export default MessagesTab;
