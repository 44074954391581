import {
  DeleteOutlined,
  MinusCircleOutlined,
  PoweroffOutlined,
  ReloadOutlined,
} from '@ant-design/icons';
import { Button, Space } from 'antd';
import pluralize from 'pluralize';

import SelectionDropdown from '@common/components/SelectionDropdown';

const ProjectSelectButtons = ({
  isActiveProjects,
  selectedProjects,
  updateProjectsActiveStatus,
  deleteProjects,
  embeddedInPartyProfile,
  removePartyFromProjects,
  disableRemoveFromProjectsButton,
  areAllSelected,
  selectedEntitiesCount,
  onSelectAll,
  onSelectAllVisible,
  onSelectNone,
  totalCount,
  totalVisible,
}) => (
  <Space>
    <SelectionDropdown
      areAllSelected={areAllSelected}
      selectedEntitiesCount={selectedEntitiesCount}
      onSelectAll={onSelectAll}
      onSelectAllVisible={onSelectAllVisible}
      onSelectNone={onSelectNone}
      totalCount={totalCount}
      totalVisible={totalVisible}
    />
    {embeddedInPartyProfile ? (
      <Button
        data-cy="removePartyFromProjectsButton"
        icon={<MinusCircleOutlined />}
        onClick={() => removePartyFromProjects()}
        disabled={disableRemoveFromProjectsButton}
      >
        Remove from {pluralize('project', selectedProjects.length)}
      </Button>
    ) : (
      <>
        <Button
          data-cy="makeProjectsInactiveButton"
          icon={isActiveProjects ? <PoweroffOutlined /> : <ReloadOutlined />}
          onClick={updateProjectsActiveStatus}
        >
          {isActiveProjects ? 'Make inactive' : 'Make active'}
        </Button>
        <Button
          data-cy="deleteProjectsButton"
          icon={<DeleteOutlined />}
          onClick={deleteProjects}
        >
          Delete
        </Button>
      </>
    )}
  </Space>
);

export default ProjectSelectButtons;
