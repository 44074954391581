import { Modal } from 'antd';

const DeleteNoteModal = ({
  isVisible,
  onClose,
  onSubmit,
  isSubmitting,
}: {
  isVisible: boolean;
  onClose: () => void;
  onSubmit: () => void;
  isSubmitting?: boolean;
}) => {
  return (
    <Modal
      title="Are you sure you want to remove the note from this requirement?"
      centered
      okText="Remove"
      cancelText="Cancel"
      open={isVisible}
      onCancel={onClose}
      onOk={onSubmit}
      okButtonProps={{ loading: isSubmitting, danger: true }}
    />
  );
};

export default DeleteNoteModal;
