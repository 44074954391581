export const CONVERSATION_PLACEHOLDER_VALUES = {
  conversationName: 'conversationName',
  conversationUrl: 'conversationUrl',
} as const;

export const conversationPlaceholders = {
  title: 'Conversations',
  placeholders: [
    {
      name: 'Conversation subject',
      region: `{{ ${CONVERSATION_PLACEHOLDER_VALUES.conversationName} }}`,
    },
    {
      name: 'Conversation URL',
      region: `{{ ${CONVERSATION_PLACEHOLDER_VALUES.conversationUrl} }}`,
    },
  ],
};
