import Icon, { LoadingOutlined } from '@ant-design/icons';
import {
  Button,
  Divider,
  Popover,
  Skeleton,
  Switch,
  Tooltip,
  Typography,
} from 'antd';
import moment from 'moment';
import styled from 'styled-components';

import Box from '@common/components/Box';
import BoxTitle from '@common/components/BoxTitle';
import PaperPlane from '@common/icons/PaperPlane';
import { DocumentRemindersAutomationSchedules } from '@modules/automations';
import { AUTOMATION_CODES } from '@modules/automations/constants';
import { DisableForViewerRole } from '@modules/organization-member/containers/DisableForRoles';
import { HideForViewerRole } from '@modules/organization-member/containers/HideForRoles';

import { NoContactInformationLabel } from '../../constants';
import type { PartyAutomation } from '../../utils';
import {
  type OnPartyAutomationScheduledActionsViewClick,
  PartyAutomationScheduledActions,
} from '../PartyAutomationScheduledActions';

type DocumentRequestsBoxProps = {
  isTrackingCompliance: boolean;
  isPrimaryContactInvalid: boolean;
  latestRequest?: { sentOn: string };
  requestLandingLink: string;
  automations: Record<string, PartyAutomation> | undefined;
  isLoadingAutomations: boolean;
  onSendReminder: () => void;
  onSendRequest: () => void;
  onAutomationChange: (data: { value: boolean; code: string }) => void;
  isAutomationChanging?: boolean;
  onAutomationActionClick: OnPartyAutomationScheduledActionsViewClick;
};

const MISSING_CONTACT_MESSAGE =
  'Please provide an email address for this party in order to enable automatic requests.';
const MISSING_AUTOMATION_ACTIONS_MESSAGE = `Please add an action under Settings > Automations in order to enable this reminder.`;

type GetDocumentRemindersPopoverArgs = {
  automation?: PartyAutomation;
};

export const DocumentRequestsBox = ({
  isTrackingCompliance,
  isPrimaryContactInvalid,
  latestRequest,
  requestLandingLink,
  automations,
  isLoadingAutomations,
  onSendReminder,
  onSendRequest,
  onAutomationChange,
  isAutomationChanging,
  onAutomationActionClick,
}: DocumentRequestsBoxProps) => {
  const sendCTALabel = latestRequest
    ? 'Send reminder'
    : 'Send document request';
  const onSendClick = latestRequest ? onSendReminder : onSendRequest;

  const expiringDocumentRemindersAutomation =
    automations?.[AUTOMATION_CODES.expiringDocumentReminders];

  const documentRemindersAutomation =
    automations?.[AUTOMATION_CODES.documentReminders];

  /**
   * @note Is visible when there is at least one scheduled action
   */
  const isAutomationEnabledPopoverVisible = Boolean(
    documentRemindersAutomation?.scheduledActions?.length,
  );

  const getDocumentRemindersPopoverProps = ({
    automation,
  }: GetDocumentRemindersPopoverArgs) => {
    if (!automation) {
      return {};
    }

    if (!automation.isEnablable) {
      return {
        content: MISSING_AUTOMATION_ACTIONS_MESSAGE,
      };
    }

    if (!automation.isEnabled) {
      return {
        title: 'Reminders',
        content: (
          <DocumentRemindersAutomationSchedules
            branches={automation.branches?.nodes}
          />
        ),
      };
    }

    return {
      trigger: isAutomationEnabledPopoverVisible ? ['hover'] : [],
      title: 'Reminders',
      content: (
        <PartyAutomationScheduledActions
          actions={automation.scheduledActions}
          onViewClick={onAutomationActionClick}
        />
      ),
    };
  };

  const DocumentRemindersLabel = () => {
    /**
     * @note Since Popover dosen't unmount and mount after content changes, the trigger prop is not updated
     * it stays open when content is changed after automation status update.
     * So to avoid glitch and unwanted behaviors it's better to remove the popover during automation status update.
     */
    if (isAutomationChanging) {
      return (
        <>
          <div className="title">{documentRemindersAutomation?.title}</div>
          <div>
            <StyledLoadingStatus data-cy="DocumentRequestAutomationSubtitleLoader" />
          </div>
        </>
      );
    }
    return (
      <Popover
        {...getDocumentRemindersPopoverProps({
          automation: documentRemindersAutomation,
        })}
      >
        <div className="title">{documentRemindersAutomation?.title}</div>
        {Boolean(documentRemindersAutomation?.isEnabled) && (
          <div className="subtitle">
            {documentRemindersAutomation?.subtitle}
          </div>
        )}
      </Popover>
    );
  };

  return (
    <StyledWrapper>
      <BoxTitle label="Document Requests" />
      <StyledSendRequestSection>
        <HideForViewerRole>
          {isPrimaryContactInvalid ? (
            <Tooltip title={NoContactInformationLabel}>
              <Button type="primary" disabled>
                <Icon component={PaperPlane} />
                {sendCTALabel}
              </Button>
            </Tooltip>
          ) : (
            <Button type="primary" onClick={onSendClick}>
              <Icon component={PaperPlane} />
              {sendCTALabel}
            </Button>
          )}
        </HideForViewerRole>
        {latestRequest && (
          <p>last sent on {moment(latestRequest?.sentOn).format('ll')}</p>
        )}
      </StyledSendRequestSection>

      <StyledDivider />
      {Boolean(isTrackingCompliance) && (
        <Skeleton active loading={isLoadingAutomations}>
          <StyledSubtitle>Automations</StyledSubtitle>
          <DisableForViewerRole>
            <Popover
              content={
                isPrimaryContactInvalid
                  ? MISSING_CONTACT_MESSAGE
                  : MISSING_AUTOMATION_ACTIONS_MESSAGE
              }
              trigger={
                isPrimaryContactInvalid ||
                !expiringDocumentRemindersAutomation?.isEnablable
                  ? ['hover']
                  : []
              }
            >
              <StyledSwitchWrapper data-cy="expiringDocumentRemindersPartyAutomationSwitch">
                <StyledeSwitch
                  data-cy={`partyDetailsAutomaticRequestTrackingButton_${String(
                    expiringDocumentRemindersAutomation?.isEnablable,
                  )}`}
                  checked={expiringDocumentRemindersAutomation?.isEnabled}
                  disabled={
                    isPrimaryContactInvalid ||
                    !expiringDocumentRemindersAutomation?.isEnablable
                  }
                  size="small"
                  onChange={(value) =>
                    onAutomationChange({
                      value,
                      code: AUTOMATION_CODES.expiringDocumentReminders,
                    })
                  }
                />
                <StyledSwitchContent>
                  <span className="title">
                    {expiringDocumentRemindersAutomation?.title}
                  </span>
                </StyledSwitchContent>
              </StyledSwitchWrapper>
            </Popover>

            {isPrimaryContactInvalid ? (
              <Popover content={MISSING_CONTACT_MESSAGE}>
                <StyledSwitchWrapper data-cy="documentRemindersPartyAutomationDisabledSwitch">
                  <StyledeSwitch disabled size="small" />
                  <StyledSwitchContent>
                    <div className="title">
                      {documentRemindersAutomation?.title}
                    </div>
                  </StyledSwitchContent>
                </StyledSwitchWrapper>
              </Popover>
            ) : (
              <StyledSwitchWrapper data-cy="documentRemindersPartyAutomationSwitch">
                <StyledeSwitch
                  data-cy={`documentRemindersPartyAutomationSwitchButton`}
                  disabled={!documentRemindersAutomation?.isEnablable}
                  checked={documentRemindersAutomation?.isEnabled}
                  size="small"
                  onChange={(value: boolean) =>
                    onAutomationChange({
                      value,
                      code: AUTOMATION_CODES.documentReminders,
                    })
                  }
                />
                <StyledSwitchContent>
                  <DocumentRemindersLabel />
                </StyledSwitchContent>
              </StyledSwitchWrapper>
            )}
          </DisableForViewerRole>
        </Skeleton>
      )}

      {Boolean(latestRequest) && (
        <HideForViewerRole>
          <StyledDivider />

          <section>
            <StyledSubtitle>Document Request Page</StyledSubtitle>
            <a
              data-cy="documentRequestLink"
              target="_blank"
              rel="noopener noreferrer"
              href={requestLandingLink}
            >
              <StyledLinkText copyable ellipsis={{ rows: 1 }}>
                {requestLandingLink}
              </StyledLinkText>
            </a>
          </section>
        </HideForViewerRole>
      )}
    </StyledWrapper>
  );
};

const StyledWrapper = styled(Box)`
  margin-bottom: 20px;
`;

const StyledSendRequestSection = styled.section`
  display: flex;
  align-items: center;

  p {
    margin: 0;
    margin-left: 5px;
    color: ${({ theme }) => theme.colors.lightTextGray};
    font-size: 12px;
  }
`;

const StyledDivider = styled(Divider)`
  margin: 11px 0;
`;

const StyledSubtitle = styled.h3`
  margin: 0;
  margin-bottom: 10px;
  color: ${({ theme }) => theme.colors.lightTextGray};
  font-weight: normal;
  font-size: 14px;
`;

const StyledLinkText = styled(Typography.Paragraph)`
  color: ${(props) => props.theme.colors.blue} !important;
  margin: 0 !important;
  font-size: 12px;
`;

const StyledSwitchWrapper = styled.div`
  margin-top: 8px;
  display: flex;
`;

const StyledeSwitch = styled(Switch)`
  margin-top: 3px;
`;

const StyledSwitchContent = styled.div`
  margin-left: 10px;

  .title {
    font-size: 14px;
  }

  .subtitle {
    font-size: 12px;
    color: ${(props) => props.theme.colors.lightTextGray};
  }
`;

const StyledLoadingStatus = styled(LoadingOutlined)`
  margin-top: 5px;
  font-size: 18px;
  color: ${(props) => props.theme.colors.blue};
`;
