import { graphql } from '@graphql/types';

export const UPDATE_REQUIREMENT_PUBLIC_NOTES = graphql(`
  mutation UpdateRequirementPublicNotes(
    $data: UpdateRequirementPublicNotesInput!
  ) {
    updateRequirementPublicNotes(data: $data) {
      _id
      name
      externalIds
      hasWaivedRequirements
      hasOverriddenRequirements
      isTrackingCompliance

      subjects {
        subjectId
        subjectLabel
        notes
        requirementComplianceStatusValue
        resetRequirementOn
      }

      requirements {
        _id
        organization
        party
        partyComplianceProfile
        moduleId
        moduleLabel
        subjectId
        subjectLabel
        attributeId
        attributeLabel
        attributeType
        attributeDescription
        matchingCriteria
        automaticallyDetected
        masterDocumentAttributeId
        notes
        publicNotes
        operator
        targetValue
        requirementComplianceStatusValue
        requirementComplianceStatusSource
        matchingDocumentsCount
        resetRequirementOn
        createdAt
        updatedAt
      }
      documentChecklists {
        _id
        type {
          _id
          name
          pluralName
          isSystemType
        }
        attributeId
        attributeLabel
        complianceProfile
        isActive
        createdAt
        updatedAt
      }
      partyComplianceProfile {
        _id
        name
        complianceProfile
        nextExpirationDate
        latestExpirationDate
        metadata
        complianceStats {
          complianceScore
          compliantRequirementsCount
          nonCompliantRequirementsCount
          waivedRequirementsCount
          overriddenRequirementsCount
          totalRequirementsCount
          totalSubjectsCount
          compliantSubjectsCount
        }
      }
    }
  }
`);
