import { gql } from '@apollo/client';

import { namespace } from '../constants';

export const RESEND_MESSAGE_ISSUE = `${namespace}/RESEND_MESSAGE_ISSUE`;

const RESEND_MESSAGE_ISSUE_MUTATION = gql`
  mutation resendPartyMessageIssue($issueId: ObjectId!, $email: String) {
    resendPartyMessageIssue(issueId: $issueId, email: $email) {
      _id
    }
  }
`;

export const resendMessageIssue = (issueId, email) => ({
  type: RESEND_MESSAGE_ISSUE,
  payload: {
    graphql: {
      mutation: RESEND_MESSAGE_ISSUE_MUTATION,
      context: { hasUpload: true },
      variables: {
        issueId,
        email,
      },
    },
  },
});
