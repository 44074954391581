import { gql } from '@apollo/client';

import { namespace } from '../constants';

export const BULK_ASSIGN_PARTIES_COMPLIANCE_PROFILE = `${namespace}/BULK_ASSIGN_PARTIES_COMPLIANCE_PROFILE`;

const BULK_ASSIGN_PARTIES_COMPLIANCE_PROFILE_MUTATION = gql`
  mutation bulkAssignComplianceProfileToParties(
    $payload: bulkAssignComplianceProfilePartiesInput
  ) {
    bulkAssignComplianceProfileToParties(data: $payload) {
      scheduled
    }
  }
`;

export const bulkAssignPartiesComplianceProfile = (payload) => ({
  type: BULK_ASSIGN_PARTIES_COMPLIANCE_PROFILE,
  payload: {
    key: 'bulkAssignComplianceProfileToParties',
    graphql: {
      mutation: BULK_ASSIGN_PARTIES_COMPLIANCE_PROFILE_MUTATION,
      variables: {
        payload,
      },
    },
  },
});
