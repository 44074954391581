import * as R from 'ramda';

import { getFilterData } from '@common/utils/filter-helpers';
import {
  DocumentFilterFields,
  REVIEW_STATUS_LABEL_MAP,
  SeverityLevelLabelMap,
  VerificationStatusMap,
  NormalizedDocumentFilters as filters,
} from '@modules/document/constants';

export const normalizedDocumentsFilters = ({
  documentTypes,
  subjects,
  projects,
  activeFilters,
}) => {
  const formatValue = (key, value) => {
    const valueArray = Array.isArray(value) ? value : [value];

    switch (key) {
      case DocumentFilterFields.Type: {
        const documentTypesMap = R.indexBy(R.prop('_id'), documentTypes);
        return valueArray.map((typeId) =>
          R.path([typeId, 'name'], documentTypesMap),
        );
      }
      case DocumentFilterFields.AssociatedProjects: {
        const projectsMap = R.indexBy(R.prop('_id'), projects);
        return valueArray.map((projectId) =>
          R.path([projectId, 'name'], projectsMap),
        );
      }
      case DocumentFilterFields.Status: {
        return valueArray.map(
          (statusKey) => REVIEW_STATUS_LABEL_MAP[statusKey],
        );
      }
      case DocumentFilterFields.Subject: {
        const subjectsMap = (subjects || []).reduce(
          (acc, subject) => ({
            ...acc,
            [subject._id]: subject.subjectLabel,
          }),
          {},
        );
        return valueArray.map((subjectId) => subjectsMap[subjectId]);
      }
      case DocumentFilterFields.Verification: {
        return valueArray.map(
          (verificationKey) => VerificationStatusMap[verificationKey],
        );
      }
      case DocumentFilterFields.Flag: {
        return valueArray.map(
          (severityLevel) => SeverityLevelLabelMap[severityLevel],
        );
      }
      default: {
        return value;
      }
    }
  };

  return R.compose(
    R.filter((el) => el.isActive),
    R.map((key) => {
      const { label, value } = getFilterData({
        key,
        filters,
        formatValue,
        activeFilters,
      });

      return {
        key,
        label,
        value,
        isActive: Array.isArray(value)
          ? value.filter((el) => Boolean(el)).length > 0
          : Boolean(value),
      };
    }),
    R.uniq,
    R.keys,
  )(filters);
};
