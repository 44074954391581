import { Button, Input } from 'antd';
import styled from 'styled-components';

const ChecklistAttributeForm = ({
  value,
  submitButtonLabel,
  onChangeHandle,
  submitButtonHandle,
  closeButtonHandle,
}) => (
  <ChecklistAttributeForm.Wrapper>
    <Input.TextArea
      data-cy="addChecklistItemInput"
      autoSize={{ minRows: 1 }}
      placeholder="Enter your checklist item..."
      value={value}
      onChange={(e) => onChangeHandle(e)}
    />
    <ChecklistAttributeForm.CancelButton onClick={closeButtonHandle}>
      Cancel
    </ChecklistAttributeForm.CancelButton>
    <Button
      data-cy="addChecklistItemButton"
      disabled={!value}
      type="primary"
      onClick={submitButtonHandle}
    >
      {submitButtonLabel}
    </Button>
  </ChecklistAttributeForm.Wrapper>
);

ChecklistAttributeForm.Wrapper = styled.div`
  position: relative;
  margin: 5px 0;
  display: flex;
`;

ChecklistAttributeForm.CancelButton = styled(Button)`
  margin: 0 10px !important;
`;

export default ChecklistAttributeForm;
