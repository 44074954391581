import { FeatureFlagsMap } from '../constants';

import { useFeatureFlag } from './useFeatureFlag';

export const useSaveProgressOnFillSignFeatureFlag = () => {
  const { SAVE_PROGRESS_ON_FILL_SIGN } = FeatureFlagsMap;
  const isSaveProgressOnFillSignFeatureFlagEnabled = useFeatureFlag(
    SAVE_PROGRESS_ON_FILL_SIGN,
  );
  return { isSaveProgressOnFillSignFeatureFlagEnabled };
};
