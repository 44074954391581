import {
  DeleteOutlined,
  LoadingOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import { Button, Checkbox, Form, Input, Spin, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import * as PartyActions from '@modules/party/actions';
import { getGraphqlPayload } from '@store/helpers';

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};

const INVALID_EMAIL_MESSAGE = 'Please enter a valid email address';
const LAST_DEFAULT_REQUEST_RECIPIENT_CHECKBOX_TOOLTIP_MESSAGE =
  'Each party must have at least one designated recipient for document requests/reminders.';

const EMPTY_EMAIL_DEAFULT_REQUEST_RECIPIENT_CHECKBOX_MESSAGE =
  'A designated recipient for document requests/reminders must have a valid email';

const PartyContactForm = ({
  initialEmail,
  setForm,
  isDefaultRequestRecipient,
  isLoading,
  avatarUrl,
  setAvatarUrl,
  onDrop,
  isDefaultContactCheckboxDisabled,
  onFieldsChange = () => {},
  isOnlyEmailEditable = false,
}) => {
  const { partyId } = useParams();

  const [form] = Form.useForm();
  const [isCheckingEmail, setIsCheckingEmail] = useState(false);
  const [isAvatarFocused, setIsAvatarFocused] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    setForm(form);
  }, [form, setForm]);

  const avatarImage = isLoading ? (
    <Spin spinning={isLoading} indicator={<LoadingOutlined />} />
  ) : (
    <PartyContactForm.LogoContainer>
      <PartyContactForm.ControlSection
        onMouseEnter={() => setIsAvatarFocused(true)}
        onMouseLeave={() => setIsAvatarFocused(false)}
      >
        {isAvatarFocused && <DeleteOutlined onClick={() => setAvatarUrl('')} />}
      </PartyContactForm.ControlSection>
      <PartyContactForm.Logo src={avatarUrl} />
    </PartyContactForm.LogoContainer>
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: ['image/png', 'image/jpeg', 'image/gif'],
    multiple: false,
  });

  /**
   * the DefaultRequestRecipientCheckbox could be checked only when an email is present.
   * It assumes the email value is valid since there is an email validator (getIsValidEmail)
   */
  const validateDefaultRequestRecipientCheckbox = ({ getFieldValue }) => ({
    validator(_, checkboxValue) {
      const emailValue = getFieldValue('email');

      if (checkboxValue && !emailValue) {
        return Promise.reject(
          new Error(EMPTY_EMAIL_DEAFULT_REQUEST_RECIPIENT_CHECKBOX_MESSAGE),
        );
      }
      return Promise.resolve();
    },
  });

  const getIsValidEmail = async (_, email = '') => {
    setIsCheckingEmail(true);
    const res = await dispatch(
      PartyActions.isContactEmailTaken({ email, partyId }),
    );
    setIsCheckingEmail(false);
    if (getGraphqlPayload(res) && email !== initialEmail) {
      throw new Error('This email is already taken');
    }
    return;
  };

  return (
    <Form
      form={form}
      name="partyCreateContactModal"
      onFieldsChange={onFieldsChange}
      {...formItemLayout}
    >
      <Form.Item
        label="Contact name"
        name="contactPersonName"
        data-cy="contactName"
      >
        <Input
          placeholder="Enter a contact name..."
          disabled={isOnlyEmailEditable}
        />
      </Form.Item>
      <Form.Item label="Company name" name="companyName" data-cy="companyName">
        <Input
          placeholder="Enter a company name..."
          disabled={isOnlyEmailEditable}
        />
      </Form.Item>
      <Form.Item label="Title" name="title" data-cy="contactTitle">
        <Input placeholder="Enter a title..." disabled={isOnlyEmailEditable} />
      </Form.Item>
      <StyledCheckboxFormItem
        name="isDefaultRequestRecipient"
        valuePropName="checked"
        dependencies={['email']}
        rules={[validateDefaultRequestRecipientCheckbox]}
      >
        <Checkbox
          defaultChecked={isDefaultRequestRecipient}
          disabled={isDefaultContactCheckboxDisabled || isOnlyEmailEditable}
        >
          <Tooltip
            trigger={isDefaultContactCheckboxDisabled ? ['hover'] : []}
            title={LAST_DEFAULT_REQUEST_RECIPIENT_CHECKBOX_TOOLTIP_MESSAGE}
          >
            Use this contact for document requests/reminders
          </Tooltip>
        </Checkbox>
      </StyledCheckboxFormItem>

      <Form.Item
        label="Email"
        name="email"
        validateTrigger="onBlur"
        rules={[
          { type: 'email', message: INVALID_EMAIL_MESSAGE },
          { validator: getIsValidEmail },
        ]}
      >
        <Input
          placeholder="Enter an email address..."
          data-cy="contactEmail"
          suffix={
            <Spin indicator={<LoadingOutlined />} spinning={isCheckingEmail} />
          }
        />
      </Form.Item>
      <Form.Item label="Phone number" name="phone" data-cy="contactPhone">
        <Input
          placeholder="Enter a phone number..."
          disabled={isOnlyEmailEditable}
        />
      </Form.Item>
      <Form.Item label="Fax" name="fax" data-cy="contactFax">
        <Input
          placeholder="Enter a fax number..."
          disabled={isOnlyEmailEditable}
        />
      </Form.Item>
      <Form.Item label="Avatar" disabled={isOnlyEmailEditable}>
        <PartyContactForm.UploadImageDetail>
          Format: PNG or JPG, min. width: 100px, min. height: 100px
        </PartyContactForm.UploadImageDetail>
        {avatarUrl ? (
          avatarImage
        ) : (
          <div>
            <PartyContactForm.UploadButton
              type="link"
              icon={<UploadOutlined />}
              disabled={isOnlyEmailEditable}
              {...getRootProps()}
            >
              Upload image
              <input {...getInputProps()} />
            </PartyContactForm.UploadButton>
          </div>
        )}
      </Form.Item>
    </Form>
  );
};

PartyContactForm.Label = styled.div`
  margin-bottom: 10px;
  color: ${(props) => props.theme.colors.textGray};
`;

PartyContactForm.UploadImageDetail = styled.div`
  margin-top: 6px;
  font-size: 13px;
  color: ${(props) => props.theme.colors.grayText};
`;

PartyContactForm.LogoContainer = styled.div`
  position: relative;
  height: 130px;

  button {
    padding: 0;
  }
`;

PartyContactForm.ControlSection = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2000;
  height: 130px;
  width: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;

  i {
    font-size: 20px;
    color: ${(props) => props.theme.colors.white};
    opacity: 0.8;

    &:hover {
      opacity: 1;
    }
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.5);
  }
`;

PartyContactForm.Logo = styled.img`
  position: absolute;
  left: 0;
  top: 0;
  margin-bottom: 15px;
  padding: 5px;
  height: 130px;
  width: 130px;
  border-radius: 50%;
  border: 1px solid #d9d9d9;
`;

PartyContactForm.UploadButton = styled(Button)`
  padding: 0;

  &[disabled] {
    /* Used important here because of an antd bug where
     * ant-button style preceedes ant-button-link style
    */
    background-color: initial !important;
    border: none;
  }
`;

const StyledCheckboxFormItem = styled(Form.Item)`
  margin-left: 180px;
  width: 100%;
`;

export default PartyContactForm;
