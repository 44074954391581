import { SUBJECT_STATUS } from '@modules/party/constants';

export const changeSubjectModalInitialState = {
  visible: false,
};

export const ChangeSubjectModalActionsTypes = {
  OPEN_WAIVE: 'OPEN_WAIVE',
  OPEN_OVERRIDE: 'OPEN_OVERRIDE',
  EDIT: 'EDIT',
  CLOSE: 'CLOSE',
};

//@ts-ignore
export const changeSubjectModalReducer = (state, { type, payload }) => {
  switch (type) {
    case ChangeSubjectModalActionsTypes.OPEN_WAIVE:
      return {
        visible: true,
        type: SUBJECT_STATUS.WAIVED,
        selectedSubject: payload,
      };
    case ChangeSubjectModalActionsTypes.OPEN_OVERRIDE:
      return {
        visible: true,
        type: SUBJECT_STATUS.OVERRIDDEN,
        selectedSubject: payload,
      };
    case ChangeSubjectModalActionsTypes.EDIT:
      return {
        visible: true,
        type: payload?.requirementComplianceStatusValue,
        selectedSubject: payload,
      };
    case ChangeSubjectModalActionsTypes.CLOSE:
      return changeSubjectModalInitialState;
    default:
      return state;
  }
};
