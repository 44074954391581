import { getDocumentTypes } from '@modules/document-type/selectors';
import qs from 'query-string';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import DocumentsFilterContent from '../components/DocumentsFilterContent';

const DocumentsFilterContentContainer = ({ updateFilters, loadedProjects }) => {
  const location = useLocation();

  const documentTypes = useSelector(getDocumentTypes);

  const searchParams = qs.parse(location.search);

  return (
    <DocumentsFilterContent
      loadedProjects={loadedProjects}
      searchParams={searchParams}
      documentTypes={documentTypes}
      updateFilters={updateFilters}
    />
  );
};

export default DocumentsFilterContentContainer;
