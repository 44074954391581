import { PartyFilterFields } from '../../constants';

import {
  AutomationsFilterOperator,
  type FilterPartyAutomation,
  type FilterPartyAutomationsPayload,
  type FilterPartyAutomationsPayloadSerialized,
} from './constants';

export const getAutomationFilterOperationName = (
  value: AutomationsFilterOperator,
) => {
  const map = {
    [AutomationsFilterOperator.in]: 'in',
    [AutomationsFilterOperator.notIn]: 'NOT in',
  };
  return map[value];
};

export const deserializeAutomationsFilterFromSearchParam = ({
  activeFilters,
}: {
  activeFilters: FilterPartyAutomationsPayloadSerialized;
}): FilterPartyAutomationsPayload => {
  const rawValue = activeFilters?.[PartyFilterFields.Automations];
  if (!rawValue)
    return [
      {
        operator: AutomationsFilterOperator.in,
        value: [],
      },
      {
        operator: AutomationsFilterOperator.notIn,
        value: [],
      },
    ];

  const valueArr = Array.isArray(rawValue) ? rawValue : [rawValue];

  const parsedValues = valueArr.map((value) => {
    const [operator = AutomationsFilterOperator.in, valueStr = ''] =
      value.split('-');
    return {
      operator: operator as AutomationsFilterOperator,
      value: valueStr.split(',').filter((value) => Boolean(value)),
    };
  });

  const inFilter = parsedValues.find(
    (i) => i.operator === AutomationsFilterOperator.in,
  );
  const notInFilter = parsedValues.find(
    (i) => i.operator === AutomationsFilterOperator.notIn,
  );

  return [
    {
      operator: AutomationsFilterOperator.in,
      value: inFilter?.value || [],
    },
    {
      operator: AutomationsFilterOperator.notIn,
      value: notInFilter?.value || [],
    },
  ];
};

export const serializeAutomationsFilterToSearchParam = (
  filterPayload: FilterPartyAutomationsPayload,
): FilterPartyAutomationsPayloadSerialized => {
  if (!filterPayload) {
    return undefined;
  }

  const serializedFilters = filterPayload
    .filter((i) => i.value.length)
    .map(({ operator, value }) => `${operator}-${value.join(',')}`);
  return { [PartyFilterFields.Automations]: serializedFilters };
};

export const stringifyPartyAutomationsFilter = ({
  filter,
  automations,
}: {
  filter: FilterPartyAutomationsPayload;
  automations: { _id: string; name: string }[];
}) => {
  if (!filter) return;

  const automationMap = automations.reduce(
    (acc, item) => {
      acc[item._id] = item;
      return acc;
    },
    {} as Record<string, { _id: string; name: string }>,
  );

  const itemAsString = (item: FilterPartyAutomation) => {
    const automationNames = item.value
      .map((item) => automationMap?.[item]?.name)
      .filter((item) => Boolean(item))
      .join(', ');

    return `${getAutomationFilterOperationName(item.operator)} ${automationNames}`;
  };

  return filter
    .filter((item) => item.value.length)
    .map(itemAsString)
    .filter((item) => Boolean(item))
    .join(' and ');
};

export const parseAutomationsFilterToReport = (
  activeFilters: FilterPartyAutomationsPayloadSerialized,
) => {
  const data =
    deserializeAutomationsFilterFromSearchParam({ activeFilters }) || [];
  if (!data) return {};

  const filtersWithValue = data.filter(
    (automation) => automation.value.length > 0,
  );
  return { [PartyFilterFields.Automations]: filtersWithValue };
};

export const parseAutomationsFilterFromReportToSearchParams = (activeFilters: {
  [PartyFilterFields.Automations]?: FilterPartyAutomationsPayload;
}) => {
  const rawFilter = activeFilters?.[PartyFilterFields.Automations];
  if (!rawFilter) return {};

  return serializeAutomationsFilterToSearchParam(rawFilter);
};
