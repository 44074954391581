import { LoadingOutlined } from '@ant-design/icons';
import { Modal, Select, message } from 'antd';
import pluralize from 'pluralize';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import InfiniteScrollSelect from '@common/components/InfiniteScrollSelect';
import { useMutation } from '@graphql/hooks';
import useMutationWithRedux from '@graphql/hooks/useMutationWithRedux';
import { getOperationName } from '@graphql/utils';
import { usePartiesForSearchV2 } from '@modules/party/hooks/usePartiesForSearch';

import TagLabel from '@common/components/TagLabel';
import * as DocumentActions from '../actions';
import {
  ASSIGN_DOCUMENTS_TO_PARTY_MUTATION,
  BULK_ASSIGN_DOCUMENTS_TO_PARTY_MUTATION,
} from '../mutations';
import { getDocument } from '../selectors';

const AssignDocumentModal = ({
  visible,
  documentId,
  documents,
  onSuccess,
  onCancel,
  documentsFilter,
  selectedDocumentsCount,
  areAllDocumentsSelected,
}: {
  visible: boolean;
  documentId?: string;
  documents?: string[];
  onSuccess: () => void;
  onCancel: () => void;
  documentsFilter?: any;
  selectedDocumentsCount?: number;
  areAllDocumentsSelected?: boolean;
}) => {
  const {
    parties,
    partiesTotalCount,
    loading,
    setSearch,
    loadMorePartiesForSearch,
  } = usePartiesForSearchV2({
    skip: !visible,
  });
  const [assignOneDocument, { loading: assigningOne }] = useMutationWithRedux(
    ASSIGN_DOCUMENTS_TO_PARTY_MUTATION,
    {
      reduxActionKey: getOperationName(ASSIGN_DOCUMENTS_TO_PARTY_MUTATION),
      reduxActionType: DocumentActions.ASSIGN_DOCUMENTS_TO_PARTY,
    },
  );
  const [bulkAssign, { loading: bulkAssigning }] = useMutation(
    BULK_ASSIGN_DOCUMENTS_TO_PARTY_MUTATION,
    {
      onCompleted: () => {
        message.success(
          `${pluralize(
            'document',
            selectedDocumentsCount,
            true,
          )}  scheduled for assignment to party`,
        );

        onSuccess();
        setSearch('');
      },
    },
  );
  const [partyId, setPartyId] = useState('');
  const documentData = useSelector((state) => getDocument(state, documentId));

  const assignDocumentsToParty = async () => {
    if (documentId) {
      assignOneDocument({
        variables: {
          payload: {
            documentIds: [documentId],
            partyId,
          },
        },
        onCompleted: () => {
          message.success(`Document assigned to party`);
          onSuccess();
          setSearch('');
        },
      });
    } else if (documents) {
      const filterQuery = !areAllDocumentsSelected
        ? {
            ids: documents,
          }
        : documentsFilter;

      bulkAssign({
        variables: {
          payload: {
            filterQuery,
            partyId,
          },
        },
      });
    }
  };

  return (
    <Modal
      title={`Assign ${
        documentId
          ? documentData?.friendlyName
          : pluralize('document', selectedDocumentsCount, true)
      } to party`}
      okText="Assign"
      okButtonProps={{
        disabled: !Boolean(partyId),
        // @ts-expect-error - Antd does not have data-cy prop
        'data-cy': 'assignToPartyModalOkButton',
      }}
      open={visible}
      onOk={assignDocumentsToParty}
      onCancel={onCancel}
      destroyOnClose
      confirmLoading={assigningOne || bulkAssigning}
    >
      <InfiniteScrollSelect
        data-cy="assignDocumentToPartyInput"
        allowClear
        showSearch
        showArrow={false}
        placeholder="Enter party name..."
        isDataLoading={loading}
        onSearch={setSearch}
        loader={<LoadingOutlined />}
        hasMore={partiesTotalCount > parties.length}
        onSelect={setPartyId}
        loadMore={loadMorePartiesForSearch}
      >
        {/* @ts-expect-error - parties type should be fixed in usePartiesForSearchV2 */}
        {parties?.map((party) => (
          <Select.Option key={party._id} value={party._id}>
            {party.name} {!party.isActive && <TagLabel>Inactive</TagLabel>}
          </Select.Option>
        ))}
      </InfiniteScrollSelect>
    </Modal>
  );
};

AssignDocumentModal.Select = styled(Select)`
  width: 100%;
`;

export default AssignDocumentModal;
