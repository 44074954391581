import { Button, Modal } from 'antd';
import { type ReactNode, useState } from 'react';
import { useBlocker } from 'react-router-dom';

/*
 * It prevents the user from navigating outside a page when there are unsaved changes.
 * @param { boolean } when - When the user should be alerted
 * @param { boolean } [shouldCancelLeavesPage=false] - Cancel should make the user stay on the page or navigate out of it
 * @param { string } [title=Close without saving?] - Modal title
 * @param { string } [message=You have unsaved changes. Are you sure you want to leave this page without saving?] - Modal message
 * @param { string } [okText=Close without saving] - Modal ok button (right one)
 * @param { string } [cancelText=Cancel] - Modal cancel button (left one)
 * @param { Function } [onConfirm] - function run when pressing onConfirm
 * @param { Function } [onClose] - function run when closing the modal by pressing "X" or "Cancel" when shouldCancelLeavesPage=false
 * @return void
 */
const RouteLeavingGuardModal = ({
  when,
  shouldCancelLeavesPage = false,
  title = 'Close without saving?',
  message = 'You have unsaved changes. Are you sure you want to leave this page without saving?',
  okText = 'Close without saving',
  cancelText = 'Cancel',
  onConfirm,
  onCancel,
  onClose,
}: {
  when: boolean;
  shouldCancelLeavesPage?: boolean;
  title?: string;
  message?: string | ReactNode;
  okText?: string;
  cancelText?: string;
  onConfirm?: () => Promise<void> | void;
  onCancel?: () => Promise<void> | void;
  onClose?: () => void | Promise<void>;
}) => {
  const blocker = useBlocker(() => when);
  const [loadingConfirm, setLoadingConfirm] = useState(false);
  const [loadingCancel, setLoadingCancel] = useState(false);
  const [loadingClose, setLoadingClose] = useState(false);

  const handleCancelButton = () => {
    if (shouldCancelLeavesPage) {
      const onCancelReturn = onCancel?.();

      if (onCancelReturn instanceof Promise) {
        onCancelReturn.then(() => {
          setLoadingCancel(false);
          blocker?.proceed?.();
        });

        return;
      }
      setLoadingCancel(false);
      blocker?.proceed?.();
      return;
    }

    handleCloseModal();
  };

  const handleOkButton = () => {
    setLoadingConfirm(true);

    const onConfirmReturn = onConfirm?.();

    if (onConfirmReturn instanceof Promise) {
      onConfirmReturn.then(() => {
        setLoadingConfirm(false);
        blocker?.proceed?.();
      });
    } else {
      setLoadingConfirm(false);
      blocker?.proceed?.();
    }
  };

  const handleCloseModal = () => {
    setLoadingClose(true);
    const onCloseReturn = onClose?.();

    if (onCloseReturn instanceof Promise) {
      onCloseReturn.then(() => {
        setLoadingClose(false);
        blocker?.reset?.();
      });
    } else {
      setLoadingClose(false);
      blocker?.reset?.();
    }
  };

  return (
    <>
      <Modal
        title={title}
        open={blocker.state === 'blocked'}
        onCancel={handleCloseModal}
        zIndex={1050}
        footer={[
          <Button
            key="cancelBtn"
            onClick={handleCancelButton}
            disabled={loadingConfirm || loadingClose}
            loading={loadingCancel}
          >
            {cancelText}
          </Button>,
          <Button
            key="okBtn"
            onClick={handleOkButton}
            type="primary"
            loading={loadingConfirm}
            disabled={loadingCancel || loadingClose}
          >
            {okText}
          </Button>,
        ]}
      >
        <p>{message}</p>
      </Modal>
    </>
  );
};

export default RouteLeavingGuardModal;
