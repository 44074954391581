import { Modal, Tag } from 'antd';
import pluralize from 'pluralize';
import * as R from 'ramda';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { removeBulkDocumentTypes } from '../actions';
import { getDocumentsByIds } from '../selectors';

const { CheckableTag } = Tag;

const RemoveDocumentTypesModal = ({
  visible,
  onCancel,
  onSuccess,
  documents,
  selectedDocumentsCount,
}) => {
  const [documentTypesToRemove, setDocumentTypesToRemove] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const documentsData = useSelector((state) =>
    getDocumentsByIds(state, documents),
  );

  const currentDocumentTypes = useMemo(() => {
    return R.compose(
      R.uniq,
      R.reduce((res, cur) => [...cur.types, ...res], []),
    )(documentsData);
  }, [documentsData]);

  useEffect(() => {
    if (!visible) {
      setDocumentTypesToRemove([]);
    }
  }, [visible]);

  const handleOk = async () => {
    try {
      setIsLoading(true);
      await dispatch(
        removeBulkDocumentTypes({
          documentIds: documents,
          documentTypeIds: documentTypesToRemove,
        }),
      );
      onSuccess();
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };

  return (
    <Modal
      destroyOnClose={true}
      title="Remove document types"
      open={visible}
      onOk={handleOk}
      okButtonProps={{
        disabled: !documentTypesToRemove.length || isLoading,
      }}
      okText="Remove"
      onCancel={onCancel}
      width={550}
    >
      {currentDocumentTypes.length > 0 ? (
        <p>
          Select which document types you want to remove from the{' '}
          {pluralize('document', selectedDocumentsCount, true)}:
        </p>
      ) : null}
      {currentDocumentTypes.length > 0 ? (
        <RemoveDocumentTypesModal.Types>
          {currentDocumentTypes.map((documentType) => (
            <CheckableTag
              data-cy="removeDocumentTypes"
              key={`key_${documentType._id}`}
              checked={documentTypesToRemove.includes(documentType._id)}
              onChange={() => {
                if (documentTypesToRemove.includes(documentType._id)) {
                  setDocumentTypesToRemove(
                    R.without(documentType._id, documentTypesToRemove),
                  );
                } else {
                  setDocumentTypesToRemove(
                    R.append(documentType._id, documentTypesToRemove),
                  );
                }
              }}
            >
              {documentType.name}
            </CheckableTag>
          ))}
        </RemoveDocumentTypesModal.Types>
      ) : (
        <p>No document types available on selected documents</p>
      )}
    </Modal>
  );
};

RemoveDocumentTypesModal.Types = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .ant-tag-checkable {
    cursor: pointer;
  }

  .ant-tag-checkable:not(.ant-tag-checkable-checked):hover {
    color: ${(props) => props.theme.colors.red};
  }

  .ant-tag-checkable-checked {
    background-color: ${(props) => props.theme.colors.red};
  }
`;

export default RemoveDocumentTypesModal;
