import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { fetchFillableFormSuggestions } from '../actions';

const useFillableFormSuggestions = () => {
  const [fillableFormSuggestions, setFormSuggestions] = useState([]);
  const [suggestionsTotalCount, setTotalCount] = useState(0);
  const [isLoadingSuggestions, setIsLoadingSuggestions] = useState(false);
  const [isLoadingMoreSuggestions, setIsLoadingMoreSuggestions] =
    useState(false);

  const dispatch = useDispatch();

  const loadFillableFormSuggestions = async (options) => {
    setIsLoadingSuggestions(true);

    const { payload } = await dispatch(fetchFillableFormSuggestions(options));

    if (payload?.data) {
      const { listForms, countForms } = payload.data;
      setFormSuggestions(listForms);
      setTotalCount(countForms);
    }
    setIsLoadingSuggestions(false);
  };

  const loadMoreFillableFormSuggestions = async (options) => {
    setIsLoadingMoreSuggestions(true);

    const { payload } = await dispatch(fetchFillableFormSuggestions(options));

    if (payload?.data) {
      const { listForms } = payload.data;
      setFormSuggestions([...fillableFormSuggestions, ...listForms]);
    }
    setIsLoadingMoreSuggestions(false);
  };

  return {
    suggestionsTotalCount,
    isLoadingSuggestions,
    isLoadingMoreSuggestions,
    loadFillableFormSuggestions,
    loadMoreFillableFormSuggestions,
    fillableFormSuggestions,
  };
};

export default useFillableFormSuggestions;
