import styled from 'styled-components';

import ProjectsPage from '@modules/project/pages/ProjectsPage';

const TabProjectsContent = () => (
  <TabProjectsContent.Wrapper>
    <ProjectsPage embeddedInPartyProfile={true} />
  </TabProjectsContent.Wrapper>
);

TabProjectsContent.Wrapper = styled.div`
  margin: -30px -24px 0;
`;

export default TabProjectsContent;
