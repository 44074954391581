export const DefaultRule = {
  attributeDescription: '',
  matchingCriteria: '',
  attributeId: '',
  attributeLabel: '',
  attributeType: '',
  automaticallyDetected: '',
  isActive: true,
  masterDocumentAttributeId: '',
  moduleId: '',
  moduleLabel: '',
  operator: '',
  subjectId: '',
  subjectLabel: '',
  targetValue: '',
} as const;
