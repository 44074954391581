import { LoadingOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Select, Spin } from 'antd';
import moment from 'moment';
import { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';

import DatePicker from '@common/components/DatePicker';
import FileName from '@common/components/FileName/FileName';
import RichTextEditor from '@common/components/RichTextEditor';
import { getEditorHTMLContent } from '@common/components/RichTextEditor/helpers';
import ToastMessage from '@common/components/ToastMessage';
import { INVALID_FORMAT_ERROR_MESSAGE } from '@common/constants';
import { defaultDateFormats } from '@common/utils/filter-helpers';
import BaseComplianceProfileIcon from '@modules/compliance-profile/components/BaseComplianceProfileIcon';
import { EmailTemplateSelect } from '@modules/email-template';

import { useIsComplianceRequirementsAddOnEnabled } from '@modules/add-on';
import { REQUEST_PLACEHOLDERS } from '../constants';
import { fileValidator } from '../utils/request-helpers';

export const RequestForm = ({
  party,
  complianceProfile,
  isLoadingFile,
  emailTemplates,
  selectedEmailTemplate,
  onSelectedEmailTemplateChange,
  contacts,
  selectedContacts,
  onSelectedContactsChange,
  selectedMessage,
  onSelectedMessageChange,
  selectedDocuments,
  onSelectedDocumentsChange,
  onFilesDrop,
  selectedDueDate,
  onSelectedDueDateChange,
}) => {
  const {
    isTrackingCompliance,
    partyComplianceProfile,
    name: partyName,
  } = party || {};
  const { baseComplianceProfile } = complianceProfile || {};

  const [isUploadErrorVisible, setIsUploadErrorVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { isComplianceRequirementsAddOnEnabled } =
    useIsComplianceRequirementsAddOnEnabled();

  const onDropRejected = (files) => {
    const fileNames = files.map(({ file }) => file.name);
    setErrorMessage(
      <span>
        {INVALID_FORMAT_ERROR_MESSAGE}
        <strong> {fileNames.join(', ')}</strong>
      </span>,
    );
    setIsUploadErrorVisible(true);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDropRejected,
    onDrop: onFilesDrop,
    validator: fileValidator,
  });

  const handleEditorChange = (updatedEditorState) => {
    onSelectedMessageChange(getEditorHTMLContent(updatedEditorState));
  };

  return (
    <section>
      <ToastMessage
        isError
        content={errorMessage}
        isVisible={isUploadErrorVisible}
        messageKey="uploadErrorToast"
        duration={5}
        onToastDestroy={() => setIsUploadErrorVisible(false)}
      />

      <div data-cy="sendRequestContentWrapper">
        {isComplianceRequirementsAddOnEnabled && isTrackingCompliance && (
          <>
            <StyledLabel>
              {partyName} needs to be compliant with the following compliance
              profile:
            </StyledLabel>
            <StyledComplianceNameBox>
              {baseComplianceProfile && <BaseComplianceProfileIcon />}{' '}
              {partyComplianceProfile?.name}
            </StyledComplianceNameBox>
          </>
        )}

        <StyledSection>
          <StyledLabel>Email template:</StyledLabel>
          <EmailTemplateSelect
            templates={emailTemplates}
            selectedTemplateId={selectedEmailTemplate?._id}
            onTemplateChange={onSelectedEmailTemplateChange}
          />
        </StyledSection>

        <StyledSection>
          <StyledLabel>Recipients:</StyledLabel>
          <Select
            mode="multiple"
            data-cy="selectRequestRecipients"
            placeholder="Select recipients..."
            value={selectedContacts}
            onChange={(values) => onSelectedContactsChange(values)}
          >
            {contacts &&
              contacts.map((contact) => (
                <Select.Option key={contact._id} value={contact._id}>
                  {`${contact.contactPersonName} <${contact.email}>`}
                </Select.Option>
              ))}
          </Select>
        </StyledSection>

        <StyledSection>
          <StyledLabel>
            Message to display on the document request page:
          </StyledLabel>
          <RichTextEditor
            isValid
            minHeight={50}
            placeholder="Specify any additional instructions here..."
            defaultContent={selectedMessage}
            onChange={handleEditorChange}
            placeholders={REQUEST_PLACEHOLDERS}
          />
        </StyledSection>

        <StyledSection>
          <StyledLabel>Add attachments (optional):</StyledLabel>
          <StyledAttachmentsSection>
            <Button {...getRootProps()} data-cy="uploadFileBtn">
              <input {...getInputProps()} />
              <UploadOutlined /> Select files...
            </Button>
            {isLoadingFile && (
              <Spin indicator={<LoadingOutlined type="loading" />} />
            )}
          </StyledAttachmentsSection>
          {selectedDocuments && (
            <StyledFilesWrapper>
              {selectedDocuments.map((file) => (
                <StyledFile key={`key_${file.s3Key}`}>
                  <FileName
                    filename={file.friendlyName}
                    onRemove={() =>
                      onSelectedDocumentsChange(
                        selectedDocuments.filter(
                          (selectedFile) => selectedFile.s3Key !== file.s3Key,
                        ),
                      )
                    }
                  />
                </StyledFile>
              ))}
            </StyledFilesWrapper>
          )}
        </StyledSection>

        <StyledSection>
          <StyledLabel>Set due date (optional):</StyledLabel>
          <DatePicker
            placeholder="Not specified"
            value={selectedDueDate ? moment(selectedDueDate) : null}
            format={defaultDateFormats}
            onChange={(val) => onSelectedDueDateChange(val)}
            onOk={onSelectedDueDateChange}
          />
        </StyledSection>
      </div>
    </section>
  );
};

const StyledLabel = styled.label`
  display: block;
  margin-bottom: 12px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
`;

const StyledComplianceNameBox = styled.div`
  font-size: 14px;
  padding: 4px 12px;
  border: 1px solid ${({ theme }) => theme.colors.gray};
  margin: 0 0 20px;
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.blueGray};
  color: rgba(0, 0, 0, 0.847);
`;

const StyledSection = styled.section`
  margin: 20px 0;
  display: flex;
  flex-direction: column;
`;

const StyledAttachmentsSection = styled.section`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
`;

const StyledFilesWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
`;

const StyledFile = styled.div`
  margin: 2px 0 5px;
`;
