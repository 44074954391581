import { graphql } from '@graphql/types';

export const CREATE_EVENT_LOG_MUTATION = graphql(`
  mutation CreateEventLog($payload: EventLogInput!) {
    createEvent(data: $payload) {
      _id
      type
      data
      contextEntityType
      contextEntityId
      actorType
      projectName
      projectId
      partyName
      partyId
      actorId
      createdAt
      updatedAt
      deletedAt
    }
  }
`);
