import styled from 'styled-components';

export default styled.div`
  padding: 3px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 9px;
  border-radius: 10px;
  color: ${(props) => props.theme.colors.white};
  background-color: ${({ bgcolor }) => bgcolor || '#8099bc'};
  text-transform: uppercase;
  white-space: nowrap;
  text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.2);
  font-weight: bold;
  letter-spacing: 0.2px;
`;
