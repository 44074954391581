import type { ReactElement } from 'react';
import styled from 'styled-components';

type CollapsePanelHeadingLayoutProps = {
  LeftContent: (props: never) => ReactElement;
  RightContent: (props: never) => ReactElement;
};

export const CollapsePanelHeadingLayout: CollapsePanelHeadingLayoutProps = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

CollapsePanelHeadingLayout.LeftContent = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
`;

CollapsePanelHeadingLayout.RightContent = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;
