import { Alert, Button, Drawer, Tabs } from 'antd';
import moment from 'moment';
import * as R from 'ramda';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import Spinner from '@common/components/Spinner';
import { DocumentRemindersAutomationSchedules } from '@modules/automations';
import { AUTOMATION_CODES } from '@modules/automations/constants';
import { EMAIL_TEMPLATE_TYPES } from '@modules/email-template/constants';
import EmailPreviewContainer from '@modules/email-template/containers/EmailPreviewContainer';
import { getOrganizationDefaultRequestMessage } from '@modules/organization/selectors';
import {
  DocumentRemindersAutomationCheckbox,
  utils as partyUtils,
} from '@modules/party';
import {
  processImportFile,
  sendRequest as sendRequestAction,
} from '@modules/party/actions';
import {
  usePartyAutomationUpdate,
  usePartyAutomations,
} from '@modules/party/hooks';
import { getGraphqlPayload } from '@store/helpers';

import { getParty } from '@modules/party/selectors';
import { sendRequestReminder } from '../../actions';
import { RequestForm } from '../../components/RequestForm';
import CustomizeEmailTemplateContainer from '../CustomizeEmailTemplateContainer';
import { useComplianceProfileQuery, useEmailTemplatesQuery } from './hooks';

const INFO_ALERT_BASE_MESSAGE =
  'Automatic reminders are on for this party. Sending a reminder manually will not change the next reminder in your cadence.';

const SendRequestDrawerContainer = ({
  isShow,
  isReminderSendRequest,
  onClose,
  partyId,
  onSuccess,
}) => {
  const dispatch = useDispatch();

  const party = useSelector((state) => getParty(state, partyId));

  const complianceProfileId = party?.partyComplianceProfile?.complianceProfile;
  const { complianceProfile, isComplianceProfileLoading } =
    useComplianceProfileQuery({
      complianceProfileId,
      skip: !complianceProfileId,
    });
  const { emailTemplates, isEmailTemplatesLoading } = useEmailTemplatesQuery({
    skip: !isShow,
  });
  const defaultRequestMessage = useSelector(
    getOrganizationDefaultRequestMessage,
  );

  const [selectedContacts, setSelectedContacts] = useState([]);
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [dueDate, setDueDate] = useState();
  const [isLoadingFile, setIsLoadingFile] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [emailTemplate, setEmailTemplate] = useState();
  const [message, setMessage] = useState(defaultRequestMessage);
  const [
    isDocumentRemindersCheckboxChecked,
    setIsDocumentRemindersCheckboxChecked,
  ] = useState(true);

  const { data: partyAutomations, isLoading: isPartyAutomationsLoading } =
    usePartyAutomations({
      partyId,
      queryOptions: {
        skip: !isShow || !partyId,
      },
    });

  const { updatePartyAutomation } = usePartyAutomationUpdate();

  const request = useMemo(() => (party?.requests || []).at(-1), [party]);

  const isReminder = isReminderSendRequest || !!request;
  const defaultTemplateType = isReminder
    ? EMAIL_TEMPLATE_TYPES.PartyComplianceRequestReminder
    : EMAIL_TEMPLATE_TYPES.NewPartyComplianceRequest;

  const relevantEmailTemplates = useMemo(
    () =>
      emailTemplates?.filter((template) =>
        [EMAIL_TEMPLATE_TYPES.Custom, defaultTemplateType].includes(
          template.type,
        ),
      ) || [],
    [emailTemplates, defaultTemplateType],
  );

  const contacts = useMemo(
    () => (party?.partyContacts || []).filter((contact) => contact.email),
    [party],
  );

  const isLoadingData = isEmailTemplatesLoading || isComplianceProfileLoading;

  useEffect(() => {
    if (isLoadingData || !emailTemplates?.length) {
      return;
    }

    const selectedTemplate = emailTemplates.find(
      (template) => template.type === defaultTemplateType,
    );

    setEmailTemplate(selectedTemplate);
  }, [isLoadingData, emailTemplates, defaultTemplateType]);

  useEffect(() => {
    if (request) {
      setDueDate(request.dueDate);
    }
    setSelectedDocuments(request?.attachments || []);

    const contactsForRequest = contacts.filter(
      (contact) => contact.isDefaultRequestRecipient,
    );

    setSelectedContacts(contactsForRequest.map((contact) => contact._id));
  }, [contacts, request]);

  const onDrop = useCallback(
    async (acceptedFiles) => {
      if (acceptedFiles.length) {
        setIsLoadingFile(true);
        const processedAttachments = await Promise.all(
          acceptedFiles.map((file) => dispatch(processImportFile({ file }))),
        );
        setIsLoadingFile(false);
        setSelectedDocuments([
          ...selectedDocuments,
          ...processedAttachments.map((payload) => getGraphqlPayload(payload)),
        ]);
      }
    },
    [selectedDocuments, dispatch],
  );

  const sendRequest = () =>
    dispatch(
      sendRequestAction({
        party: party?._id,
        recipients: selectedContacts,
        message,
        attachments: selectedDocuments,
        dueDate,
        template: emailTemplate,
      }),
    );

  const sendReminder = () =>
    dispatch(
      sendRequestReminder({
        partyId: party?._id,
        recipients: selectedContacts,
        message,
        attachments: selectedDocuments,
        dueDate,
        template: emailTemplate,
      }),
    );

  const { isTrackingCompliance } = party || {};

  const documentRemindersAutomation =
    partyAutomations?.[AUTOMATION_CODES.documentReminders] || {};

  const { scheduledAt: nextScheduledDocumentReminderAutomationDate } =
    partyUtils.getNextAutomatedDocumentReminder(
      documentRemindersAutomation.scheduledActions,
    );

  const isDocumentRemindersAutomationCheckboxVisible =
    isTrackingCompliance &&
    !documentRemindersAutomation.isEnabled &&
    !isPartyAutomationsLoading;

  const handleOnSubmit = async () => {
    setIsSubmitting(true);

    if (
      isDocumentRemindersAutomationCheckboxVisible &&
      isDocumentRemindersCheckboxChecked
    ) {
      updatePartyAutomation({
        isEnabled: isDocumentRemindersCheckboxChecked,
        id: partyId,
        automationCode: AUTOMATION_CODES.documentReminders,
      });
    }

    if (isReminder) {
      await sendReminder();
    } else {
      await sendRequest();
    }

    if (onSuccess) onSuccess();
    onClose();
    setIsSubmitting(false);
  };

  const DocumentRemindersInfoAlert = () => {
    /**
     * @note "isSubmitting" is used to prevent the alert from showing up
     * while the request / reminder is being sent and the mutation to enable the automation is finished.
     */
    if (!documentRemindersAutomation.isEnabled || isSubmitting) {
      return null;
    }

    return nextScheduledDocumentReminderAutomationDate ? (
      <StyledInfoAlert
        description={`${INFO_ALERT_BASE_MESSAGE} (set to be sent ${moment(
          nextScheduledDocumentReminderAutomationDate,
        ).format('ll')}).`}
        type="info"
        showIcon
      />
    ) : (
      <StyledInfoAlert
        description={INFO_ALERT_BASE_MESSAGE}
        type="info"
        showIcon
      />
    );
  };

  return (
    <Drawer
      title={
        isLoadingData
          ? ''
          : `Send ${isReminder ? 'reminder' : 'document request'} to ${
              party?.name
            }`
      }
      open={isShow}
      onClose={onClose}
      width={700}
      maskClosable={false}
      destroyOnClose
      keyboard={false}
    >
      {!party || isLoadingData ? (
        <Spinner />
      ) : (
        <Tabs defaultActiveKey="send">
          <Tabs.TabPane tab="Send" key="send">
            <section>
              <DocumentRemindersInfoAlert />
              <RequestForm
                party={party}
                complianceProfile={complianceProfile}
                isLoadingFile={isLoadingFile}
                emailTemplates={relevantEmailTemplates}
                selectedEmailTemplate={emailTemplate}
                onSelectedEmailTemplateChange={(_id) =>
                  setEmailTemplate({ _id })
                }
                contacts={contacts}
                selectedContacts={selectedContacts}
                onSelectedContactsChange={setSelectedContacts}
                selectedMessage={message}
                onSelectedMessageChange={setMessage}
                selectedDocuments={selectedDocuments}
                onSelectedDocumentsChange={setSelectedDocuments}
                onFilesDrop={onDrop}
                selectedDueDate={dueDate}
                onSelectedDueDateChange={setDueDate}
              />
              <StyledActionsWrapper>
                <section>
                  <DocumentRemindersAutomationCheckbox
                    isChecked={isDocumentRemindersCheckboxChecked}
                    isVisible={isDocumentRemindersAutomationCheckboxVisible}
                    isLoading={isPartyAutomationsLoading}
                    isDisabled={!documentRemindersAutomation.isEnablable}
                    onChange={setIsDocumentRemindersCheckboxChecked}
                    popoverContent={() => (
                      <DocumentRemindersAutomationSchedules
                        branches={
                          documentRemindersAutomation?.branches?.nodes || []
                        }
                      />
                    )}
                  />
                </section>

                <section>
                  <StyledButton onClick={onClose}>Cancel</StyledButton>
                  <StyledButton
                    type="primary"
                    onClick={handleOnSubmit}
                    loading={isSubmitting}
                    data-cy="confirmSendRequestButton"
                    disabled={
                      !selectedContacts.length || isPartyAutomationsLoading
                    }
                  >
                    Confirm and send
                  </StyledButton>
                </section>
              </StyledActionsWrapper>
            </section>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Customize email " key="customize">
            {emailTemplate?._id && (
              <CustomizeEmailTemplateContainer
                onChange={setEmailTemplate}
                templateId={emailTemplate?._id}
              />
            )}
          </Tabs.TabPane>
          <Tabs.TabPane tab="Preview email" key="preview">
            <EmailPreviewContainer
              template={R.pick(
                ['_id', 'subject', 'regions'],
                emailTemplate || {},
              )}
              partyId={partyId}
            />
          </Tabs.TabPane>
        </Tabs>
      )}
    </Drawer>
  );
};

export default SendRequestDrawerContainer;

const StyledActionsWrapper = styled.section`
  margin: 40px 0 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledButton = styled(Button)`
  margin-left: 16px;
`;

const StyledInfoAlert = styled(Alert)`
  margin: 20px 0;
  padding: 9px;

  .ant-alert-icon {
    font-size: 20px;
    margin-right: 10px;
  }
`;
