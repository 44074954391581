import { graphql } from '@graphql/types';

export const PARTY_CUSTOM_FIELDS_QUERY = graphql(`
  query partyCustomFields($partyId: ID!) {
    getPartyDataV2(_id: $partyId) {
      customFields {
        nodes {
          _id
          name
          value
          description
          type
          options {
            _id
            key
            value
          }
        }
        totalCount
      }
      projectStats {
        totalProjectsCount
      }
    }
  }
`);
