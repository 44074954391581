import { Modal, Select, message } from 'antd';
import { useState } from 'react';
import styled from 'styled-components';

import { FILTER_OPERATORS } from '@common/constants/filters';
import { AUTOMATION_CODES, type AutomationCode } from '@modules/automations';
import { useDisableAutomationMutation } from '@modules/primary-records/hooks/useDisableAutomationMutation';
import { useEnableAutomationMutation } from '@modules/primary-records/hooks/useEnableAutomationMutation';

export enum AUTOMATIONS_MODAL_TYPES {
  enable = 'enable',
  disable = 'disable',
}

type AutomationBulkActionModalProps = {
  type: keyof typeof AUTOMATIONS_MODAL_TYPES;
  visible: boolean;
  selectedElementsIds?: string[];
  activeFilters?: Record<string, string | string[]>;
  onSuccess: () => void;
  onClose: () => void;
};

const AUTOMATIONS_OPTIONS = [
  {
    label: 'Document reminders',
    value: AUTOMATION_CODES.documentReminders,
  },
  {
    label: 'Expiring document reminders',
    value: AUTOMATION_CODES.expiringDocumentReminders,
  },
];

export const AutomationsActionModal = ({
  type,
  visible,
  activeFilters,
  selectedElementsIds = [],
  onSuccess,
  onClose,
}: AutomationBulkActionModalProps) => {
  const [selectedAutomationCode, setSelectedAutomationCode] =
    useState<AutomationCode>(AUTOMATION_CODES.documentReminders);

  const { enableAutomation, isEnablingAutomation } =
    useEnableAutomationMutation();
  const { disableAutomation, isDisablingAutomation } =
    useDisableAutomationMutation();

  const BULK_ACTION_LABEL =
    type === AUTOMATIONS_MODAL_TYPES.disable ? 'Disable' : 'Enable';

  const handleDisableAutomation = async () => {
    try {
      // this method return true if the automation is async or false if it's sync
      const {
        data: { disablePrimaryRecordsAutomation: isAsyncOperation },
      } = await disableAutomation({
        variables: {
          automationCode: selectedAutomationCode,
          filter: {
            and: [
              {
                name: 'id',
                operator: FILTER_OPERATORS.in,
                value: selectedElementsIds,
              },
            ],
          },
        },
      });

      if (isAsyncOperation) {
        message.success('Your bulk disable automation is being processed.');
        onSuccess();
        return;
      }

      message.success('Automation disabled successfully');
      onSuccess();
    } catch {
      // we ignore the error because the error message is handled by apollo
    }
  };

  const handleEnableAutomation = async () => {
    try {
      // this method return true if the automation is async or false if it's sync
      const {
        data: { enablePrimaryRecordsAutomation: isAsyncOperation },
      } = await enableAutomation({
        variables: {
          automationCode: selectedAutomationCode,
          filter: {
            and: [
              {
                name: 'id',
                operator: FILTER_OPERATORS.in,
                value: selectedElementsIds,
              },
            ],
          },
        },
      });

      if (isAsyncOperation) {
        message.success('Your bulk enable automation is being processed.');
        onSuccess();
        return;
      }

      message.success('Automation enabled successfully');
      onSuccess();
    } catch {
      // we ignore the error because the error message is handled by apollo
    }
  };

  return (
    <Modal
      title={`${BULK_ACTION_LABEL} automation`}
      confirmLoading={isEnablingAutomation || isDisablingAutomation}
      open={visible}
      onOk={
        type === 'disable' ? handleDisableAutomation : handleEnableAutomation
      }
      onCancel={onClose}
      okText={`${BULK_ACTION_LABEL}`}
    >
      <StyledModalContent>
        <span className="details">
          {`${BULK_ACTION_LABEL}`} the automation for the selected Primary
          Records.
        </span>

        <span>Automation</span>
        <Select
          data-cy="automationSelect"
          value={selectedAutomationCode}
          onChange={setSelectedAutomationCode}
          options={AUTOMATIONS_OPTIONS}
        />
      </StyledModalContent>
    </Modal>
  );
};

const StyledModalContent = styled.section`
  padding: 20px 0;
  font-size: 14px;

  .details {
    display: block;
    margin-bottom: 20px;
  }

  .automationSelect {
    width: 100%;
    margin-top: 8px;
  }
`;
