import qs from 'query-string';
import * as R from 'ramda';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import FilterButtonWithPopover from '@common/components/FilterButtonWithPopover';
import { ColLeft, ColRight, Row } from '@common/components/ListPageFilter';
import SearchInput from '@common/components/SearchInput';
import SortButtonWithPopover from '@common/components/SortButtonWithPopover';
import SortOptionsList from '@common/components/SortOptionsList';
import ViewSwitcher from '@common/components/ViewSwitcher';
import { downloadInit } from '@modules/download/actions';
import { getCurrentProject } from '@modules/project/selectors';
import { setPreferredSearchParams } from '@modules/system-settings/actions';
import { ListView } from '@modules/system-settings/constants';
import usePreferredSearchParams from '@modules/system-settings/hooks/usePreferredSearchParams';

import DocumentsFilterButtons from '../components/DocumentsFilterButtons';
import {
  DocumentsSortOptions,
  EveryPartyId,
  getSortLabelData,
} from '../constants';
import { useDownloadDocument } from '../hooks';
import { getDocumentsByIds } from '../selectors';
import { getUniqueDocumentFileName } from '../utils/document-helpers';

import { trackEvent } from '@common/utils/track-helpers';
import DocumentsFilterContentContainer from './DocumentsFilterContentContainer';

const DocumentsFilterContainer = ({
  loadedProjects,
  filtersCount,
  documentsVisibleCount,
  updateFilters,
  resetFilters,
  sortField,
  documentsTotalCount,
  areAllDocumentsSelected,
  sortDirection,
  updateNameFilter,
  selectedDocuments,
  setSelectedDocuments,
  selectedDocumentsCount,
  setIsVisibleAssignModal,
  setSelectedDocumentsCount,
  setIsVisibleAssociateToProjectModal,
  setIsVisibleAddDocumentTypesModal,
  setIsVisibleRemoveDocumentTypesModal,
  setIsVisibleBulkMatchRequirementsModal,
  setIsVisibleAddRequirementValueModal,
  selectAllDocuments,
  setAreAllDocumentsSelected,
  selectAllVisibleDocuments,
  loading,
  markSelectedAsReviewed,
  markSelectedAsToReview,
  updateDocumentsArchiveStatus,
  deleteDocuments,
  detachDocumentsFromParty,
  detachDocumentsFromProject,
}) => {
  const { partyId, organizationSlugifyName } = useParams();
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const { downloadDocument } = useDownloadDocument();

  const [searchValue, setSearchValue] = useState('');
  const selectedDocumentsData = useSelector((state) =>
    getDocumentsByIds(state, selectedDocuments),
  );
  const currentProject = useSelector(getCurrentProject);
  const { view: documentsView } = usePreferredSearchParams();

  const dispatch = useDispatch();

  const searchParams = qs.parse(search);
  const isArchived = R.propEq('status', 'archived', searchParams);
  const searchFilter = R.prop('name', searchParams);
  const currentProjectId = R.propOr(null, '_id', currentProject);

  useEffect(() => {
    setSearchValue(searchFilter);
  }, [searchFilter]);

  useEffect(() => {
    setSelectedDocuments([]);
  }, [setSelectedDocuments]);

  const resetDocumentsSelection = () => {
    setSelectedDocumentsCount(0);
    setAreAllDocumentsSelected(false);
    setSelectedDocuments([]);
  };

  // filter out document of with connectedLicense since they don't have a PDF
  const downloadableDocuments = selectedDocumentsData.filter(
    (doc) => !doc.connectedLicense,
  );

  const downloadDocuments = () => {
    if (downloadableDocuments.length > 1) {
      const files = downloadableDocuments.map((doc) => ({
        url: doc.url,
        name: getUniqueDocumentFileName(doc),
      }));
      dispatch(
        downloadInit({
          files,
          zipName: 'Documents',
        }),
      );
    } else {
      downloadableDocuments.forEach(downloadDocument);
    }

    setSelectedDocuments([]);
    trackEvent('User bulk-downloaded documents');
  };

  const reviewDocuments = () => {
    navigate(
      `/${organizationSlugifyName}/parties/${
        partyId ? partyId : EveryPartyId
      }/documents-review?${qs.stringify({
        selected: selectedDocuments,
      })}`,
    );
  };

  const changeView = () => {
    const view =
      documentsView === ListView.Card ? ListView.Table : ListView.Card;
    dispatch(
      setPreferredSearchParams({
        view,
      }),
    );

    navigate(
      `${pathname}?${qs.stringify(R.assoc('view', view, searchParams))}`,
      { replace: true },
    );
  };

  const handleOnSortChange = (option) => updateFilters(option, []);

  return (
    <>
      <Row data-cy="selectAllButtonGroup">
        <ColLeft>
          {selectedDocumentsCount ? (
            <DocumentsFilterButtons
              isActiveDocuments={!isArchived}
              disableDownloadButton={
                areAllDocumentsSelected || !downloadableDocuments.length
              }
              disableReviewButton={areAllDocumentsSelected}
              disableBulkMatchRequirementsButton={areAllDocumentsSelected}
              disableAddDocumentTypesButton={areAllDocumentsSelected}
              disableRemoveDocumentTypesButton={areAllDocumentsSelected}
              areAllSelected={areAllDocumentsSelected}
              selectedEntitiesCount={selectedDocumentsCount}
              onSelectAll={selectAllDocuments}
              onSelectAllVisible={selectAllVisibleDocuments}
              onSelectNone={resetDocumentsSelection}
              totalCount={documentsTotalCount}
              totalVisible={documentsVisibleCount}
              {...{
                setIsVisibleAssignModal,
                setIsVisibleAssociateToProjectModal,
                setIsVisibleBulkMatchRequirementsModal,
                setIsVisibleAddRequirementValueModal,
                setIsVisibleAddDocumentTypesModal,
                setIsVisibleRemoveDocumentTypesModal,
                markSelectedAsReviewed,
                markSelectedAsToReview,
                updateDocumentsArchiveStatus,
                deleteDocuments,
                detachDocumentsFromParty,
                detachDocumentsFromProject,
                downloadDocuments,
                reviewDocuments,
                currentProjectId,
              }}
            />
          ) : (
            <SearchInput
              key={searchValue}
              isLoading={loading}
              defaultValue={searchValue}
              onSearch={updateNameFilter}
            />
          )}
        </ColLeft>
        <ColRight>
          <ViewSwitcher view={documentsView} onChange={changeView} />
          <FilterButtonWithPopover
            filtersCount={filtersCount}
            popoverContent={
              <DocumentsFilterContentContainer
                loadedProjects={loadedProjects}
                updateFilters={updateFilters}
              />
            }
            onResetFilters={resetFilters}
          />
          <SortButtonWithPopover
            sortLabelData={getSortLabelData(sortField, sortDirection)}
            popoverContent={
              <SortOptionsList
                options={DocumentsSortOptions}
                onChange={handleOnSortChange}
              />
            }
          />
        </ColRight>
      </Row>
    </>
  );
};

export default DocumentsFilterContainer;
