import type { EmailTemplateType, PlaceholderGroup } from '../constants';

type Placeholder = {
  name: string;
  description: string;
};

export const getPlaceholdersByEmailTemplateType = (
  placeholders: PlaceholderGroup[],
  emailTemplateType?: EmailTemplateType,
) => {
  if (!emailTemplateType) {
    return [];
  }

  return placeholders?.reduce<Placeholder[]>((res, group) => {
    return [
      ...res,
      ...(group.getIsDisabled(emailTemplateType)
        ? []
        : group.placeholders.map((placeholder) => ({
            name: placeholder.region,
            description: placeholder.name,
          }))),
    ];
  }, []);
};
