import { gql } from '@apollo/client';
import { useMutation } from '@graphql/hooks';
import type { EnablePrimaryRecordsAutomationMutationVariables } from '@graphql/types/graphql';

// TODO: In this mutation in the future will be added the return type for handling bulk operations
const ENABLE_PRIMARY_RECORDS_AUTOMATION_MUTATION = gql`
  mutation EnablePrimaryRecordsAutomation($data: PrimaryRecordsAutomationInput!) {
    enablePrimaryRecordsAutomation(data: $data) {
      operation {
        code
        name
        status
        progress
      }
      errors {
        code
        message
      }
    }
  }
`;

type EnableAutomationArgs = {
  variables: EnablePrimaryRecordsAutomationMutationVariables['data'];
};

export const useEnableAutomationMutation = () => {
  const [enablePrimaryRecordsAutomation, { loading: isEnablingAutomation }] =
    useMutation(ENABLE_PRIMARY_RECORDS_AUTOMATION_MUTATION);

  const enableAutomation = ({ variables }: EnableAutomationArgs) => {
    return enablePrimaryRecordsAutomation({
      variables: { data: variables },
    });
  };

  return {
    enableAutomation,
    isEnablingAutomation,
  };
};
