import { useCallback, useEffect, useState } from 'react';

import { useMutation } from '@graphql/hooks';

import { UPDATE_REQUIREMENT_PUBLIC_NOTES } from '../mutations';

import { NOTE_EVENTS } from './events';
import type { Note } from './types';

export const useDeleteNoteModal = () => {
  const [currentNote, setCurrentNote] = useState<Omit<
    Note,
    'publicNotes'
  > | null>(null);
  const isVisible = !!currentNote;
  const [mutate, { loading }] = useMutation(UPDATE_REQUIREMENT_PUBLIC_NOTES);
  const onCloseModal = useCallback(() => setCurrentNote(null), []);
  const onDeleteNote = useCallback(() => {
    if (!currentNote) return;

    return mutate({
      variables: {
        data: {
          id: currentNote._id,
          projectId: currentNote.selectedProject,
          publicNotes: '',
        },
      },
      onCompleted: onCloseModal,
    });
  }, [currentNote, onCloseModal, mutate]);

  useEffect(() => {
    const listener = (e: CustomEvent<Omit<Note, 'publicNotes'>>) => {
      setCurrentNote(e.detail);
    };

    // @ts-expect-error
    document.addEventListener(NOTE_EVENTS.DELETE_NOTE, listener);

    return () => {
      // @ts-expect-error
      document.removeEventListener(NOTE_EVENTS.DELETE_NOTE, listener);
    };
  }, []);

  return {
    isDeleting: loading,
    isVisible,
    currentNote,
    onCloseModal,
    onDeleteNote,
  };
};

export const dispatchDeleteNoteModalEvent = (
  note: Omit<Note, 'publicNotes'>,
) => {
  document.dispatchEvent(
    new CustomEvent(NOTE_EVENTS.DELETE_NOTE, {
      detail: note,
    }),
  );
};
