import { Collapse } from 'antd';
import styled from 'styled-components';

const CollapsePanel = ({ activeKeys, onChange, children }) => {
  return (
    <CollapsePanel.Collapse
      activeKey={activeKeys}
      onChange={onChange}
      bordered={false}
    >
      {children}
    </CollapsePanel.Collapse>
  );
};

CollapsePanel.Collapse = styled(Collapse)`
  && {
    width: 100%;
    background-color: transparent;
  }

  .ant-collapse-item-active .ant-collapse-header {
    border-bottom: none;
  }

  .ant-collapse {
    background-color: transparent !important;
  }

  .ant-collapse-borderless {
    background-color: transparent !important;
    border: none !important;
  }

  .ant-collapse-item {
    margin-bottom: 20px;
    padding: 0 8px;
    width: 100%;
    border: 1px solid hsla(213deg, 69%, 95%, 1) !important;
    border-radius: 4px;
    background-color: ${(props) => props.theme.colors.white};
    box-shadow: rgba(71, 88, 114, 0.14) 0 2px 2px;

    .ant-collapse-header {
      border-bottom: none !important;
    }
  }
`;

export default CollapsePanel;
