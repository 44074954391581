import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Modal, message } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { deleteContact, updateContact } from '@modules/party-contact/actions';
import { DELETION_TYPES } from '@modules/party-contact/constants';
import {
  resendMessageIssue,
  updatePartyConversation,
} from '@modules/party-conversation/actions';

import PartyContactForm from '../../forms/PartyContactForm';

import RemoveContactButton from './RemoveContactButton';

const CustomFooter = ({ onCancel, onSubmit, onDelete, contact, isUpdated }) => {
  const deletionOptions = [
    {
      key: DELETION_TYPES.fromParty,
      title: 'Delete contact from party',
      description: 'The contact will no longer exist within the party',
      disabled: contact?.isPrimary,
    },
    {
      key: DELETION_TYPES.fromConversation,
      title: 'Remove contact from conversation',
      description: 'The contact will still exist within the party',
    },
  ];

  return (
    <StyledCustomFooterWrapper>
      <RemoveContactButton
        modalTitle={
          <StyledTitleWrapper>
            <StyledWarnIcon />
            <p>How would you like to remove {contact?.email} as a contact?</p>
          </StyledTitleWrapper>
        }
        onDelete={onDelete}
        options={deletionOptions}
      />
      <div>
        <Button type="default" onClick={onCancel}>
          Cancel
        </Button>
        <Button type="primary" onClick={onSubmit}>
          {isUpdated ? 'Update and Resend' : 'Resend'}
        </Button>
      </div>
    </StyledCustomFooterWrapper>
  );
};

const PartyEditContactModal = ({
  visible,
  onClose,
  partyName,
  contact,
  issueId,
  selectedConversation,
}) => {
  const [form, setForm] = useState(null);
  const [isUpdated, setIsUpdated] = useState(false);
  const [avatarUrl, setAvatarUrl] = useState('');

  const dispatch = useDispatch();

  useEffect(() => {
    if (Boolean(contact)) {
      setAvatarUrl(contact?.avatar);
    }
  }, [contact]);

  useEffect(() => {
    if (contact?._id && form && visible) {
      form.setFieldsValue({
        contactPersonName: contact?.contactPersonName ?? '',
        companyName: contact?.companyName ?? '',
        title: contact?.title ?? '',
        isDefaultRequestRecipient:
          contact?.isDefaultRequestRecipient ?? !!contact?.isPrimary,
        email: contact?.email ?? '',
        phone: contact?.phone ?? '',
        fax: contact?.fax ?? '',
      });
    }
  }, [contact, form, visible]);

  const handleDelete = (deleteType) => {
    switch (deleteType) {
      case DELETION_TYPES.fromConversation:
        const updatedParticipants = selectedConversation.participants.filter(
          (contactId) => contactId !== contact?._id,
        );
        dispatch(
          updatePartyConversation({
            conversationId: selectedConversation._id,
            participants: updatedParticipants,
          }),
        );
        break;
      case DELETION_TYPES.fromParty:
        if (!contact?._id) {
          message.warning(
            'The contact you attempted to delete is not associated with this party.',
          );
        } else {
          dispatch(
            deleteContact({
              id: contact?._id,
              conversationId: selectedConversation?._id,
            }),
          );
        }
        break;
      default:
        break;
    }

    handleClose();
  };

  const handleSubmit = async () => {
    try {
      if (form) {
        const values = await form.validateFields();
        const formValues = {
          ...{ id: contact?._id, avatar: avatarUrl },
          ...values,
        };

        if (formValues.email !== contact?.email) {
          dispatch(updateContact(formValues));
        }

        dispatch(resendMessageIssue(issueId, formValues.email));

        handleClose();
      }
    } catch {}
  };

  const handleCancel = () => {
    setIsUpdated(false);
    onClose();
  };

  const handleClose = () => {
    if (form) {
      form.resetFields();
      handleCancel();
    }
  };

  const handleFormChange = () => {
    setIsUpdated(true);
  };

  return (
    <Modal
      title="Edit and resend"
      open={visible}
      onCancel={handleClose}
      width={766}
      footer={
        <CustomFooter
          onCancel={handleCancel}
          onSubmit={handleSubmit}
          onDelete={handleDelete}
          contact={contact}
          isUpdated={isUpdated}
        />
      }
    >
      <PartyContactForm
        initialEmail={contact?.email}
        partyName={partyName}
        setForm={setForm}
        isDefaultRequestRecipient={
          contact?.isDefaultRequestRecipient ?? !!contact?.isPrimary
        }
        avatarUrl={avatarUrl}
        setAvatarUrl={setAvatarUrl}
        onFieldsChange={handleFormChange}
        isOnlyEmailEditable={true}
      />
    </Modal>
  );
};

const StyledCustomFooterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledTitleWrapper = styled.div`
  display: flex;

  p {
    margin-left: 1rem;
  }
`;

const StyledWarnIcon = styled(ExclamationCircleOutlined)`
  color: ${({ theme }) => theme.colors.yellow};
  font-size: 1.4rem;
`;

export default PartyEditContactModal;
