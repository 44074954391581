import { gql } from '@apollo/client';

import { namespace } from '../constants';

export const FETCH_PARTY_REQUIREMENTS_BY_PROJECT = `${namespace}/FETCH_PARTY_REQUIREMENTS_BY_PROJECT`;

const FETCH_PARTY_REQUIREMENTS_BY_PROJECT_QUERY = gql`
  query listProjectPartyData($filter: Any) {
    listProjectPartyData(filter: $filter) {
      _id
      requirements {
        _id
        organization
        party
        partyComplianceProfile
        moduleId
        moduleLabel
        subjectId
        subjectLabel
        attributeId
        attributeLabel
        attributeType
        attributeDescription
        matchingCriteria
        automaticallyDetected
        masterDocumentAttributeId
        notes
        operator
        targetValue
        requirementComplianceStatusValue
        requirementComplianceStatusSource
        matchingDocumentsCount
        createdAt
        updatedAt
      }
    }
  }
`;

export const fetchPartyRequirementsByProject = (partyId, projectId) => ({
  type: FETCH_PARTY_REQUIREMENTS_BY_PROJECT,
  payload: {
    key: 'listProjectPartyData',
    graphql: {
      fetchPolicy: 'no-cache',
      query: FETCH_PARTY_REQUIREMENTS_BY_PROJECT_QUERY,
      variables: { filter: { party: partyId, project: projectId } },
    },
  },
});
