import { FilterOutlined } from '@ant-design/icons';
import { Button, Popover } from 'antd';
import styled from 'styled-components';

const FilterButtonWithPopover = ({
  filtersCount,
  popoverContent,
  onPopoverVisibleChange,
  onResetFilters,
}) => {
  return (
    <Popover
      placement="bottomRight"
      trigger="click"
      onOpenChange={onPopoverVisibleChange}
      title={
        <StyledTitle>
          <div className="title">Filter by</div>
          <span className="resetButton" onClick={() => onResetFilters()}>
            Reset all filters
          </span>
        </StyledTitle>
      }
      content={popoverContent}
    >
      <StyledFilterButton
        data-cy="listPageFilterButton"
        icon={<FilterOutlined />}
      >
        Filter
        {filtersCount > 0 && (
          <StyledFilterCount>{filtersCount}</StyledFilterCount>
        )}
      </StyledFilterButton>
    </Popover>
  );
};

const StyledTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 16px;

  .title {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.85);
    font-style: normal;
  }

  .resetButton {
    color: ${(props) => props.theme.colors.blue};
    font-size: 12px;
    font-weight: normal;
    cursor: pointer;
  }
`;

const StyledFilterButton = styled(Button)`
  margin-left: 9px;
`;

const StyledFilterCount = styled.span`
  margin-left: 6px;
  position: relative;
  width: 17px;
  height: 17px;
  border-radius: 50%;
  background-color: ${(props) => props.theme.colors.blue};
  font-size: 11px;
  font-weight: bold;
  color: ${(props) => props.theme.colors.white};
`;

export default FilterButtonWithPopover;
