import * as R from 'ramda';

import { STATE_KEY } from '../constants';

export const getReportsByType = (state, type) =>
  R.compose(
    R.filter((report) => report.type === type),
    R.values,
    R.path([STATE_KEY, 'data']),
  )(state);

export const getReportById = (state, id) =>
  R.path([STATE_KEY, 'data', id], state);
