import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import { Button, Popover, Tooltip } from 'antd';
import * as R from 'ramda';
import styled from 'styled-components';

const SortButtonWithPopover = ({ sortLabelData, popoverContent }) => {
  return (
    <Popover placement="bottomRight" trigger="click" content={popoverContent}>
      <Tooltip title={R.prop('helpText', sortLabelData)}>
        <SortButtonWithPopover.Button data-cy="listPageSortButton">
          Sort by: {R.prop('label', sortLabelData)}
          {R.prop('iconType', sortLabelData) === 'arrow-down' && (
            <ArrowDownOutlined />
          )}
          {R.prop('iconType', sortLabelData) === 'arrow-up' && (
            <ArrowUpOutlined />
          )}
        </SortButtonWithPopover.Button>
      </Tooltip>
    </Popover>
  );
};

SortButtonWithPopover.Button = styled(Button)`
  margin-left: 9px;
`;

export default SortButtonWithPopover;
