import { Checkbox, Popconfirm } from 'antd';
import * as R from 'ramda';
import styled from 'styled-components';

const ComplianceProfileModuleLabels = ({
  requirements,
  complianceModules,
  formModules,
  currentDocumentChecklists,
  activeKeyTab,
  onSetActiveModules,
  setIsVisibleAddModuleModal,
  setIsVisibleAddChecklistModal,
  setIsActiveDocumentChecklist,
}) => {
  return (
    <StyledContainer>
      <StyledWrapper>
        <p>Compliance Modules</p>
        {complianceModules.map(({ moduleId, moduleLabel, isActive }) => (
          <StyledRow
            data-cy="moduleNameCheckbox"
            key={moduleId}
            isActive={activeKeyTab === moduleId}
          >
            <StyledName isActive={activeKeyTab === moduleId}>
              {requirements.filter(
                (requirement) => requirement.moduleId === moduleId,
              ).length !== 0 ? (
                <Popconfirm
                  placement="right"
                  title={
                    <div style={{ width: '250px' }}>
                      Are you sure you want to remove all the requirements for
                      the module {moduleLabel}?
                    </div>
                  }
                  onConfirm={() => onSetActiveModules(moduleId, !isActive)}
                >
                  <StyledCheckbox checked={isActive}>
                    {moduleLabel}
                  </StyledCheckbox>
                </Popconfirm>
              ) : (
                <StyledCheckbox
                  checked={isActive}
                  onChange={() => onSetActiveModules(moduleId, !isActive)}
                >
                  {moduleLabel}
                </StyledCheckbox>
              )}
            </StyledName>
          </StyledRow>
        ))}

        <StyledCustomButton onClick={() => setIsVisibleAddModuleModal(true)}>
          <Checkbox checked={false} />
          <StyledTitle>Add new module...</StyledTitle>
        </StyledCustomButton>
      </StyledWrapper>

      {Boolean(formModules.length) && (
        <StyledWrapper>
          <p>Form modules</p>
          {formModules.map(({ moduleId, moduleLabel, isActive }) => (
            <StyledRow
              data-cy="moduleNameCheckbox"
              key={moduleId}
              isActive={activeKeyTab === moduleId}
            >
              <StyledName isActive={activeKeyTab === moduleId}>
                {requirements.filter(
                  (requirement) => requirement.moduleId === moduleId,
                ).length !== 0 ? (
                  <Popconfirm
                    placement="right"
                    title={
                      <div style={{ width: '250px' }}>
                        Are you sure you want to remove all the requirements for
                        the module {moduleLabel}?
                      </div>
                    }
                    onConfirm={() => onSetActiveModules(moduleId, !isActive)}
                  >
                    <StyledCheckbox checked={isActive}>
                      {moduleLabel}
                    </StyledCheckbox>
                  </Popconfirm>
                ) : (
                  <StyledCheckbox
                    checked={isActive}
                    onChange={() => onSetActiveModules(moduleId, !isActive)}
                  >
                    {moduleLabel}
                  </StyledCheckbox>
                )}
              </StyledName>
            </StyledRow>
          ))}
        </StyledWrapper>
      )}

      <StyledWrapper>
        <p>Document checklists</p>

        {R.compose(
          R.uniq,
          R.filter(({ isActive }) => isActive),
          R.map(({ isActive, type }) => ({ isActive, type })),
        )(currentDocumentChecklists).map(({ type, isActive }) => (
          <StyledRow key={type._id} isActive={activeKeyTab === type._id}>
            <StyledName isActive={activeKeyTab === type._id}>
              {currentDocumentChecklists.filter(
                (checklist) =>
                  checklist.type._id === type._id &&
                  Boolean(checklist.attributeId) &&
                  checklist.isActive,
              ).length !== 0 ? (
                <Popconfirm
                  placement="right"
                  title={
                    <div style={{ width: '250px' }}>
                      Are you sure you want to remove all attributes of this
                      document type {type.nae}?
                    </div>
                  }
                  onConfirm={() =>
                    setIsActiveDocumentChecklist(type._id, !isActive)
                  }
                >
                  <StyledCheckbox checked={isActive}>
                    <span>{type.name}</span>
                  </StyledCheckbox>
                </Popconfirm>
              ) : (
                <StyledCheckbox
                  checked={isActive}
                  onChange={() =>
                    setIsActiveDocumentChecklist(type._id, !isActive)
                  }
                >
                  <span>{type.name}</span>
                </StyledCheckbox>
              )}
            </StyledName>
          </StyledRow>
        ))}
        <StyledCustomButton
          data-cy="addNewChecklist"
          onClick={() => setIsVisibleAddChecklistModal(true)}
        >
          <Checkbox checked={false} />
          <StyledTitle>Add new checklist...</StyledTitle>
        </StyledCustomButton>
      </StyledWrapper>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  margin-top: 23px;
  width: 245px;
  border-right: 1px solid #f0f1f2;

  p {
    margin-bottom: 10px;
    margin-left: 2px;
    color: ${(props) => props.theme.colors.grayText};
    font-weight: 600;
    font-size: 13px;
    text-transform: uppercase;
  }
`;

const StyledWrapper = styled.div`
  margin-bottom: 45px;
`;

const StyledRow = styled.div`
  && {
    padding: 4px 0;
    border-right: ${(props) => (props.isActive ? '4px solid #1890ff' : 'none')};
  }
`;

const StyledName = styled.div`
  border: 1px solid transparent;
  display: flex;
  align-items: center;
  font-size: 13px;
  color: ${(props) => (props.isActive ? '#1890ff' : 'none')};

  &:hover {
    color: #1890ff;
    cursor: pointer;
  }
`;

const StyledCheckbox = styled(Checkbox)`
  margin-right: 10px !important;
  font-size: 13px;
`;

const StyledCustomButton = styled.div`
  padding: 4px 3px 4px 10px;
  margin: 0 20px 0 -10px;
  border: 1px dashed #cdcdcd;
  border-radius: 4px;
  opacity: 0.6;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
`;

const StyledTitle = styled.span`
  margin-left: 10px;
  font-size: 13px;
`;

export default ComplianceProfileModuleLabels;
