import { Cascader } from 'antd';
import styled from 'styled-components';

export default styled(Cascader)`
  width: 100%;
  margin-right: 10px !important;

  .ant-cascader-picker-label {
    font-size: 13px;
  }
`;
