import { Checkbox, Input, InputNumber } from 'antd';
import styled from 'styled-components';

import { CustomFieldType } from '../constants';

const CustomFieldTypeForm = ({
  customFieldName,
  value,
  type,
  options,
  onChange,
}) => {
  const getFormItem = () => {
    switch (type) {
      case CustomFieldType.Text: {
        return (
          <Input
            defaultValue={value}
            onBlur={(e) => onChange(e.target.value)}
            onPressEnter={(e) => onChange(e.target.value)}
            placeholder={`Search on ${customFieldName}`}
          />
        );
      }
      case CustomFieldType.Number: {
        return (
          <InputNumber
            defaultValue={value ? parseInt(value) : null}
            onBlur={(e) => onChange(e.target.value)}
            onPressEnter={(e) => onChange(e.target.value)}
            placeholder={`Search on ${customFieldName}`}
          />
        );
      }
      case CustomFieldType.Dropdown: {
        return (
          <Checkbox.Group
            value={value}
            onChange={(checkedValues) => onChange(checkedValues)}
            options={options.map((option) => ({
              value: option.key,
              label: option.value,
            }))}
          />
        );
      }
      default: {
        return null;
      }
    }
  };

  return (
    <CustomFieldTypeForm.Wrapper data-cy="customFieldForm">
      {getFormItem()}
    </CustomFieldTypeForm.Wrapper>
  );
};

CustomFieldTypeForm.Wrapper = styled.div`
  max-width: 400px;

  .ant-input-number {
    width: 100%;
  }
`;

export default CustomFieldTypeForm;
