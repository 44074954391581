import { useLayoutEffect, useRef } from 'react';
import styled from 'styled-components';

import MessageContainer from '../../containers/MessageContainer';

type ConversationEventTypes =
  | 'reminder'
  | 'request'
  | 'message'
  | 'expiring'
  | 'new:token:link'
  | 'request:forwarded'
  | 'contact:removed:from:conversation'
  | 'contact:deleted'
  | 'contact:email:updated'
  | 'message:resent';

export type ConversationEvent = {
  _id: string;
  request: string;
  sendgridMessageId: string;
  type: ConversationEventTypes;
  status: string;
  message: string;
  senderContactId: string;
  senderType: string;
  senderUser: {
    _id: string;
    email: string;
    profile: {
      name: string;
      avatar: string;
    };
  };
  recipients: string;
  outputHtml: string;
  sendgridEvents: {
    _id: string;
    status: string;
    date: string;
    reason: string;
  };
  attachments: {
    url: string;
    friendlyName: string;
    s3Key: string;
  };
  readAt: string;
  createdAt: string;
  updatedAt: string;
};

type MessagesGroupProps = {
  conversationRequest: string;
  selectedPartyConversationId: string;
  messageKey: string;
  title: string;
  messagesMap: Record<string, ConversationEvent[]>;
  isScrolling: boolean;
  messagesCount: number;
  selectedConversationData: any;
};

const MessagesGroup = ({
  conversationRequest,
  selectedPartyConversationId,
  messageKey,
  title,
  messagesMap,
  isScrolling,
  messagesCount,
  selectedConversationData,
}: MessagesGroupProps) => {
  const ref = useRef<HTMLDivElement>(null);

  // biome-ignore lint/correctness/useExhaustiveDependencies: More Deps Than Needed
  useLayoutEffect(() => {
    if (ref.current && !isScrolling) {
      ref.current.scrollIntoView(false);
    }
  }, [selectedPartyConversationId, isScrolling, messagesCount]);

  return (
    <div ref={ref}>
      <StyledTitle>{title}</StyledTitle>
      {messagesMap[messageKey].map((partyMessage) => (
        <MessageContainer
          key={partyMessage._id}
          partyMessage={partyMessage}
          conversationRequest={conversationRequest}
          selectedConversationData={selectedConversationData}
        />
      ))}
    </div>
  );
};

type MessagesListProps = {
  conversationRequest: string;
  selectedPartyConversationId: string;
  messagesByDate: Record<string, ConversationEvent[]>;
  isScrolling: boolean;
  messagesCount: number;
  selectedConversationData: any;
};

const MessagesList = ({
  conversationRequest,
  selectedPartyConversationId,
  isScrolling,
  messagesCount,
  messagesByDate,
  selectedConversationData,
}: MessagesListProps) => (
  <StyledMessagesListSection data-cy="messagesList">
    {Object.keys(messagesByDate).map((key) => (
      <MessagesGroup
        key={key}
        selectedPartyConversationId={selectedPartyConversationId}
        conversationRequest={conversationRequest}
        messageKey={key}
        title={key}
        messagesMap={messagesByDate}
        isScrolling={isScrolling}
        messagesCount={messagesCount}
        selectedConversationData={selectedConversationData}
      />
    ))}
  </StyledMessagesListSection>
);

const StyledMessagesListSection = styled.div`
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  position: absolute;
  inset: 0;

  > div:first-child {
    margin-top: auto;
  }
`;

const StyledTitle = styled.div`
  position: sticky;
  top: 0;
  z-index: 3;
  padding-top: 5px;
  padding-bottom: 5px;
  border-bottom: 1px solid #e6eaef;
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  background-color: white;
  font-size: 12px;
`;

export default MessagesList;
