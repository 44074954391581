import { Modal } from 'antd';
import * as R from 'ramda';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { uploadUserAvatar } from '@modules/user/actions';
import { getGraphqlPayload } from '@store/helpers';

import PartyContactForm from '../forms/PartyContactForm';

const PartyCreateContactModal = ({
  visible,
  onCancel,
  onCreateContact,
  onUpdateContact,
  partyId,
  partyName,
  contact,
  defaultRequestRecipients,
}) => {
  const [form, setForm] = useState(null);
  const [avatarUrl, setAvatarUrl] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const contactId = R.propOr(null, '_id', contact);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!R.isEmpty(contact)) {
      setAvatarUrl(R.propOr('', 'avatar', contact));
    }
  }, [contact]);

  useEffect(() => {
    if (contactId && form && visible) {
      form.setFieldsValue({
        contactPersonName: R.propOr('', 'contactPersonName', contact),
        companyName: R.propOr('', 'companyName', contact),
        title: R.propOr('', 'title', contact),
        isDefaultRequestRecipient: R.propOr(
          R.propOr(false, 'isPrimary', contact),
          'isDefaultRequestRecipient',
          contact,
        ),
        email: R.propOr('', 'email', contact),
        phone: R.propOr('', 'phone', contact),
        fax: R.propOr('', 'fax', contact),
      });
    }
  }, [contact, contactId, form, visible]);

  const onClose = () => {
    if (form) {
      form.resetFields();
      onCancel();
    }
  };

  const handleSubmit = async () => {
    if (form) {
      try {
        const values = await form.validateFields();
        if (contactId) {
          onUpdateContact(
            R.mergeRight({ id: contactId, avatar: avatarUrl }, values),
          );
        } else {
          onCreateContact(
            R.mergeRight({ party: partyId, avatar: avatarUrl }, values),
          );
        }
        onClose();
      } catch {}
    }
  };

  const onDrop = useCallback(
    async (images) => {
      if (images.length) {
        setIsLoading(true);
        const res = await dispatch(uploadUserAvatar({ file: images[0] }));
        setIsLoading(false);
        const { url } = getGraphqlPayload(res);
        setAvatarUrl(url);
      }
    },
    [dispatch],
  );

  const isADefaultRequestRecipient = Boolean(
    contact?.isDefaultRequestRecipient,
  );
  const hasOnlyOneDefaultRequestRecipient = Boolean(
    defaultRequestRecipients?.length === 1,
  );

  return (
    <Modal
      title={contactId ? 'Edit contact' : `New contact for ${partyName}`}
      open={visible}
      onCancel={onClose}
      onOk={handleSubmit}
      okText={contactId ? 'Update contact' : 'Create contact'}
      width={766}
    >
      <PartyContactForm
        initialEmail={R.prop('email', contact)}
        setForm={setForm}
        isDefaultContactCheckboxDisabled={
          hasOnlyOneDefaultRequestRecipient && isADefaultRequestRecipient
        }
        isDefaultRequestRecipient={isADefaultRequestRecipient}
        isLoading={isLoading}
        avatarUrl={avatarUrl}
        setAvatarUrl={setAvatarUrl}
        onDrop={onDrop}
      />
    </Modal>
  );
};

export default PartyCreateContactModal;
