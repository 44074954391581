/**
 * @deprecated Use PlusOutlined from antd instead
 * @returns {JSX.Element}
 */

const PlusIcon = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.21053 0H6.78947C6.92982 0 7 0.0444444 7 0.133333V11.8667C7 11.9556 6.92982 12 6.78947 12H5.21053C5.07018 12 5 11.9556 5 11.8667V0.133333C5 0.0444444 5.07018 0 5.21053 0Z"
      fill="#1890FF"
    />
    <path
      d="M0.139535 5H11.8605C11.9535 5 12 5.07018 12 5.21053V6.78947C12 6.92982 11.9535 7 11.8605 7H0.139535C0.0465116 7 0 6.92982 0 6.78947V5.21053C0 5.07018 0.0465116 5 0.139535 5Z"
      fill="#1890FF"
    />
  </svg>
);

export default PlusIcon;
