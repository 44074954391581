import * as R from 'ramda';
import { useState } from 'react';
import { useSelector } from 'react-redux';

import {
  getEditorHTMLContent,
  getEditorPlainText,
  getEmptyEditorState,
  isEditorStateBlank,
} from '@common/components/RichTextEditor/helpers';
import { getOrganizationMembers } from '@modules/organization-member/selectors';
import { getActiveOrganizationData } from '@modules/organization/selectors';
import { getParty } from '@modules/party/selectors';
import { getCurrentProject } from '@modules/project/selectors';
import { getUser } from '@modules/user/selectors';

import EventLogAddComment from '../components/EventLogAddComment';
import EventLogBox from '../components/EventLogBox';
import { EventType } from '../constants';
import usePartyEventLogs from '../hooks/usePartyEventLogs';
import { getEventLogsTotalCount } from '../selectors';

const PartyEventLogContainer = ({ partyId }) => {
  const [commentEditorState, setCommentEditorState] = useState(
    getEmptyEditorState(),
  );

  const {
    partyEventLogs: timeline,
    createEvent,
    isTimelineLoading,
    isTimelineLoadingMore,
    fetchMore,
  } = usePartyEventLogs({ partyId });

  const organization = useSelector(getActiveOrganizationData);
  const organizationMembers = useSelector(getOrganizationMembers);
  const partyData = useSelector((state) => getParty(state, partyId));
  const totalEventsCount = useSelector(getEventLogsTotalCount);
  const {
    _id,
    email,
    profile: { name, avatar },
  } = useSelector(getUser);
  const currentProject = useSelector(getCurrentProject);

  const isEmptyComment = isEditorStateBlank(commentEditorState);

  const members = R.compose(
    R.filter((member) => member.id !== _id && member.name !== '@'),
    R.map((member) => ({
      id: R.path(['user', '_id'], member),
      name: `@${R.pathOr('', ['user', 'profile', 'name'], member).replace(
        / /g,
        '',
      )}`,
    })),
  )(organizationMembers);

  const getUserIdsFromComment = () => {
    const namesFromComment = R.compose(
      R.filter((word) => R.head(word) === '@'),
      R.split(' '),
    )(getEditorPlainText(commentEditorState));

    return R.compose(
      R.map((member) => member.id.toString()),
      R.filter((member) =>
        namesFromComment.some((name) => name === member.name),
      ),
    )(members);
  };

  const onRecordEvent = async () => {
    if (!isEmptyComment) {
      createEvent({
        variables: {
          payload: {
            type: EventType.CommentCreated,
            data: {
              avatarLink: avatar,
              userName: name,
              userEmail: email,
              message: getEditorHTMLContent(commentEditorState),
              previousVersions: [],
            },
            contextEntityType: 'party',
            contextEntityId: partyId,
            partyId: partyId,
            partyName: R.prop('name', partyData),
            projectId: R.prop('_id', currentProject),
            projectName: R.prop('name', currentProject),
            actorType: 'user', // TODO: this should be added by the server...
            actorId: _id, // TODO: this should be added by the server...
            organization: organization.id, // TODO: this should be added by the server...
            usersToSend: getUserIdsFromComment(),
          },
        },
        onCompleted: () => setCommentEditorState(getEmptyEditorState()),
      });
    }
  };

  return (
    <EventLogBox
      timeline={timeline}
      isTimelineLoading={isTimelineLoading}
      isLoadingMore={isTimelineLoadingMore}
      loadMore={() => fetchMore()}
      hasToLoadMore={totalEventsCount > timeline?.length}
    >
      <EventLogAddComment
        members={members}
        avatarLink={avatar}
        userName={name}
        userEmail={email}
        commentEditorState={commentEditorState}
        onCommentEditorChange={setCommentEditorState}
        recordEvent={onRecordEvent}
        isEventsExist={timeline?.length > 0}
        isCTADisabled={isEmptyComment}
      />
    </EventLogBox>
  );
};

export default PartyEventLogContainer;
