import { graphql } from '@graphql/types';

export const WAIVE_REQUIREMENT_MUTATION = graphql(`
  mutation WaiveRequirement($payload: WaiveRequirementInput!) {
    waiveRequirement(data: $payload) {
      _id
      name
      status
      isTrackingCompliance
      hasWaivedRequirements
      hasOverriddenRequirements
      partyComplianceProfile {
        _id
        isProcessing
        party
        metadata
        complianceProfile
        name
        nextExpirationDate
        latestExpirationDate
        complianceStats {
          complianceScore
          compliantRequirementsCount
          nonCompliantRequirementsCount
          waivedRequirementsCount
          overriddenRequirementsCount
          totalRequirementsCount
          totalSubjectsCount
          compliantSubjectsCount
        }
        createdAt
        updatedAt
      }
      requirements {
        _id
        organization
        party
        partyComplianceProfile
        moduleId
        moduleLabel
        subjectId
        subjectLabel
        attributeId
        attributeLabel
        attributeType
        attributeDescription
        matchingCriteria
        automaticallyDetected
        masterDocumentAttributeId
        notes
        publicNotes
        operator
        targetValue
        requirementComplianceStatusValue
        requirementComplianceStatusSource
        matchingDocumentsCount
        resetRequirementOn
        createdAt
        updatedAt
      }
      automaticallyWaivedSubjects {
        subjectId
        status
      }
      automaticallyWaivedRequirements {
        attributeId
        status
      }
      documentChecklists {
        type {
          _id
          name
          pluralName
          isSystemType
        }
        attributeId
        attributeLabel
        complianceProfile
        isActive
        createdAt
        updatedAt
      }
    }
  }
`);
