import qs from 'query-string';
import * as R from 'ramda';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { SortDirectionsMap } from '@common/constants';
import { setPreferredSearchParams } from '@modules/system-settings/actions';

const useFilters = (paramsToOmit) => {
  const { pathname, search } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const searchParams = qs.parse(search);

  const updateFilters = (options, fieldsToOmit = ['report']) => {
    const omittedParams = R.omit(fieldsToOmit, searchParams);
    const newParams = R.mergeRight(omittedParams, options);

    dispatch(
      setPreferredSearchParams(
        R.pick(['sortDirection', 'sortField'], newParams),
      ),
    );

    if (!R.equals(searchParams, newParams)) {
      navigate(
        `${pathname}?${qs.stringify(
          R.filter((param) => Boolean(param), newParams),
        )}`,
      );
    }
  };

  const updateSort = (sort) => {
    updateFilters(
      {
        sortField: R.prop('field', sort),
        sortDirection: SortDirectionsMap[R.prop('order', sort)],
      },
      [],
    );
  };

  const updateNameFilter = (value) =>
    value
      ? updateFilters({ name: value, page: 1 })
      : updateFilters({}, ['name']);

  const resetFilters = (defaultParams = {}) => {
    const persistentParams = R.pick(paramsToOmit, searchParams);
    const newParams = R.mergeRight(persistentParams, defaultParams);

    navigate(`${pathname}?${qs.stringify(newParams)}`);
  };

  return { updateFilters, updateSort, resetFilters, updateNameFilter };
};

export default useFilters;
