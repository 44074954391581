import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import * as R from 'ramda';
import styled from 'styled-components';

const SortOptionsList = ({ options, onChange }) => (
  <div>
    {options.map((option, index) => (
      <Tooltip title={R.prop('helpText', option)} placement="left" key={index}>
        <SortOptionsList.Option
          data-cy="listPageSortOption"
          onClick={() =>
            onChange({
              sortField: R.prop('field', option),
              sortDirection: R.prop('direction', option),
            })
          }
        >
          {R.prop('label', option)}
          {R.prop('iconType', option) === 'arrow-down' && <ArrowDownOutlined />}
          {R.prop('iconType', option) === 'arrow-up' && <ArrowUpOutlined />}
        </SortOptionsList.Option>
      </Tooltip>
    ))}
  </div>
);

SortOptionsList.Option = styled.div`
  cursor: pointer;
  margin: 0 -16px;
  padding: 0 16px;

  &:hover {
    background-color: ${(props) => props.theme.colors.lightBlue};
  }

  .anticon {
    margin-left: 5px;
  }
`;

export default SortOptionsList;
