import * as R from 'ramda';

import type { RootState } from '@common/types';

import { STATE_KEY } from '../constants';

export const getFillableFormsMap = (state: RootState) => state[STATE_KEY].data;

export const getTotalFormsCount = R.pathOr(0, [STATE_KEY, 'totalCount']);

export const getFillableFormById = (state: RootState, id: string) =>
  R.path([STATE_KEY, 'data', id], state);
