import { SettingFilled } from '@ant-design/icons';
import { Select } from 'antd';
import styled from 'styled-components';

import { EMAIL_TEMPLATE_TYPES, type EmailTemplateType } from '../../constants';

export type EmailTemplate = {
  _id: string;
  type: EmailTemplateType;
  name: string;
};

type EmailTemplateSelectProps = {
  templates: EmailTemplate[];
  selectedTemplateId?: string;
  className?: string;
  onTemplateChange: (templateId: string) => void;
};

export const EmailTemplateSelect = ({
  templates,
  selectedTemplateId,
  className,
  onTemplateChange,
}: EmailTemplateSelectProps) => {
  return (
    <Select
      data-cy="emailTemplateSelect"
      showSearch
      filterOption={(input, option) =>
        ((option?.label as string).toLowerCase() ?? '').includes(
          input.toLowerCase(),
        )
      }
      placeholder="Select a template..."
      onChange={onTemplateChange}
      value={selectedTemplateId}
      className={className}
    >
      {templates?.map(({ _id, type, name }) => (
        <Select.Option key={_id} value={_id} label={name}>
          {type !== EMAIL_TEMPLATE_TYPES.Custom && (
            <StyledSystemTemplateIcon data-cy="systemTemplateIcon" />
          )}
          {name}
        </Select.Option>
      ))}
    </Select>
  );
};

const StyledSystemTemplateIcon = styled(SettingFilled)`
  margin-right: 4px;
  color: rgba(0, 0, 0, 0.2);
  cursor: pointer;
`;
