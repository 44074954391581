import * as R from 'ramda';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { fetchDocumentsByRequirement } from '@modules/document/actions';
import { getGraphqlPayload } from '@store/helpers';

import MatchedAttributes from '../components/MatchedAttributes';

const MatchedAttributesContainer = ({ requirementId, partyId, ...props }) => {
  const [matchedDocuments, setMatchedDocuments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (isVisible) {
      (async () => {
        setLoading(true);

        const res = await dispatch(
          fetchDocumentsByRequirement({ id: requirementId, party: partyId }),
        );
        R.compose(setMatchedDocuments, getGraphqlPayload)(res);

        setLoading(false);
      })();
    }
  }, [dispatch, isVisible, partyId, requirementId]);

  return (
    <MatchedAttributes
      {...props}
      loading={loading}
      matchedDocuments={matchedDocuments}
      setIsVisible={setIsVisible}
    />
  );
};

export default MatchedAttributesContainer;
