import { IdcardOutlined, InboxOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import styled from 'styled-components';

import { HideForViewerRole } from '@modules/organization-member/containers/HideForRoles';

import PlusIcon from './Icons/PlusIcon';

const MessagesControlPanel = ({
  isMessages,
  isEmptyConversations,
  setIsMessages,
  addClickHandle,
}) => (
  <MessagesControlPanel.Wrapper>
    <Button.Group>
      <MessagesControlPanel.Button
        data-cy="messagesControlPanelContactButton"
        checked={!isMessages}
        type={!isMessages ? 'primary' : 'default'}
        onClick={() => setIsMessages(false)}
        icon={<IdcardOutlined />}
      >
        Contacts
      </MessagesControlPanel.Button>
      <MessagesControlPanel.Button
        checked={isMessages}
        type={isMessages ? 'primary' : 'default'}
        onClick={() => setIsMessages(true)}
        icon={<InboxOutlined />}
        disabled={isEmptyConversations}
      >
        Messages
      </MessagesControlPanel.Button>
    </Button.Group>
    <HideForViewerRole>
      <Tooltip
        title={isMessages ? 'Start a new conversation' : 'Create new contact'}
      >
        <MessagesControlPanel.AddButton
          shape="circle"
          onClick={() => addClickHandle()}
          data-cy="addMessagesEntityBtn"
        >
          <PlusIcon />
        </MessagesControlPanel.AddButton>
      </Tooltip>
    </HideForViewerRole>
  </MessagesControlPanel.Wrapper>
);

MessagesControlPanel.Wrapper = styled.div`
  height: 70px;
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  border-bottom: 1px solid #e6eaef;
  position: sticky;
  top: 0;
  z-index: 2;

  .ant-btn-group {
    display: flex;
  }
`;

MessagesControlPanel.Button = styled(Button)`
  padding-left: 15px;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
`;

MessagesControlPanel.AddButton = styled(Button)`
  font-size: 15px;
`;

export default MessagesControlPanel;
