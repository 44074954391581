import { Button, Checkbox, Modal, message } from 'antd';
import pluralize from 'pluralize';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { trackEvent } from '@common/utils/track-helpers';
import {
  ComplianceProfilesSelect,
  type ProfileValue,
} from '@modules/compliance-profile/containers/ComplianceProfilesSelect/ComplianceProfilesSelect';
import {
  assignComplianceProfile,
  bulkAssignPartiesComplianceProfile,
} from '@modules/party-compliance-profile/actions';
import { getParty } from '@modules/party/selectors';
import styled from 'styled-components';

type AssignComplianceProfileModalProps = {
  isShow: boolean;
  onClose: () => void;
  party?: string;
  parties?: string[];
  onSuccess: () => void;
  partiesFilters?: Record<string, any>;
  selectedPartiesCount?: number;
  areAllPartiesSelected?: boolean;
  showApplyToAllProjectsCheckbox: boolean;
};

const AssignComplianceProfileModal = ({
  isShow,
  onClose,
  party,
  parties,
  onSuccess,
  partiesFilters,
  selectedPartiesCount,
  areAllPartiesSelected,
  showApplyToAllProjectsCheckbox = true,
}: AssignComplianceProfileModalProps) => {
  const [selectedComplianceProfile, setSelectedComplianceProfile] = useState<
    ProfileValue | undefined
  >();
  const [isApplyToAllProjects, setIsApplyToAllProjects] = useState(false);
  const [isClosed, setIsClosed] = useState(false);
  const [isAssigningComplianceProfile, setIsAssigningComplianceProfile] =
    useState(false);
  const partyData = useSelector((state) => getParty(state, party));

  const dispatch = useDispatch();

  useEffect(() => {
    if (isClosed && !isAssigningComplianceProfile) {
      onCancel();
    }
  }, [isClosed, isAssigningComplianceProfile]);

  const onCancel = () => {
    onClose();
    setSelectedComplianceProfile(undefined);
    setIsClosed(false);
    setIsApplyToAllProjects(false);
  };

  const onOk = async () => {
    setIsAssigningComplianceProfile(true);

    if (party) {
      await dispatch(
        assignComplianceProfile({
          partyId: party,
          complianceProfileId: selectedComplianceProfile?._id,
          applyToAllProjects: isApplyToAllProjects,
        }),
      );

      trackEvent("User updated a party's compliance profile");

      message.success(`Compliance profile successfully assigned to party`);
    } else {
      const filterQuery = !areAllPartiesSelected
        ? {
            _id: parties,
          }
        : partiesFilters;

      await dispatch(
        bulkAssignPartiesComplianceProfile({
          filterQuery,
          complianceProfileId: selectedComplianceProfile?._id,
          applyToAllProjects: isApplyToAllProjects,
        }),
      );

      message.success(
        `${pluralize(
          'party',
          selectedPartiesCount,
          true,
        )}  scheduled to add compliance profile `,
      );
    }
    onSuccess();
    setIsAssigningComplianceProfile(false);
    setIsClosed(true);
    setIsApplyToAllProjects(false);
  };

  return (
    <Modal
      title="Assign compliance profile"
      open={isShow}
      onOk={onOk}
      onCancel={onCancel}
      closable={!isAssigningComplianceProfile}
      footer={[
        <Button
          key="back"
          onClick={onCancel}
          disabled={isAssigningComplianceProfile}
        >
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={isAssigningComplianceProfile}
          onClick={onOk}
          disabled={!selectedComplianceProfile}
        >
          Ok
        </Button>,
      ]}
    >
      <p>
        Select which compliance profile you want to assign{' '}
        {party
          ? `to ${partyData?.name}`
          : `to ${pluralize('party', selectedPartiesCount, true)}`}
      </p>

      <>
        <StyledDropdownWrapper>
          <ComplianceProfilesSelect
            value={selectedComplianceProfile}
            onChange={setSelectedComplianceProfile}
            size="middle"
          />
        </StyledDropdownWrapper>
        {showApplyToAllProjectsCheckbox && (
          <Checkbox
            disabled={!selectedComplianceProfile}
            checked={isApplyToAllProjects}
            onChange={() => setIsApplyToAllProjects(!isApplyToAllProjects)}
          >
            Apply to all projects
          </Checkbox>
        )}
      </>
    </Modal>
  );
};

const StyledDropdownWrapper = styled.div`
  margin-bottom: 16px;
`;

export default AssignComplianceProfileModal;
