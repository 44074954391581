import { PartyFilterFields } from '@modules/party/constants';

export enum AutomationsFilterOperator {
  in = 'in',
  notIn = 'notIn',
}

export type FilterPartyAutomation = {
  operator: AutomationsFilterOperator;
  value: string[];
};

export type FilterPartyAutomationsPayload = FilterPartyAutomation[] | undefined;

export type FilterPartyAutomationsPayloadSerialized =
  | { [PartyFilterFields.Automations]?: string[] }
  | undefined;
