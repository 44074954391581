import { gql } from '@apollo/client';

import { namespace } from '../constants';

export const IGNORE_MESSAGE_ISSUE = `${namespace}/IGNORE_MESSAGE_ISSUE`;

const IGNORE_MESSAGE_ISSUE_MUTATION = gql`
  mutation ignorePartyMessageIssue($issueId: ObjectId!) {
    ignorePartyMessageIssue(issueId: $issueId) {
      _id
    }
  }
`;

export const ignorePartyMessageIssue = (issueId) => ({
  type: IGNORE_MESSAGE_ISSUE,
  payload: {
    graphql: {
      mutation: IGNORE_MESSAGE_ISSUE_MUTATION,
      variables: {
        issueId,
      },
    },
  },
});
