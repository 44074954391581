import { Button, Collapse, Divider, Modal } from 'antd';
import moment from 'moment';
import * as R from 'ramda';
import styled from 'styled-components';

import { NRExplanation, RatingAction, RatingImplication } from '../constants';
import { financialSizeCategoryToText } from '../utils/requirement-helpers';

import RatingInformationNote from './RatingInformationNote';

const AMBestDetails = ({
  carrier,
  isShowDetailsModal,
  setIsShowDetailsModa,
}) => {
  const onCloseModal = () => {
    setIsShowDetailsModa(false);
  };
  const carrierName =
    R.propOr('', 'extractedName', carrier) ||
    R.propOr('n/a', 'canonicalName', carrier);

  return (
    <AMBestDetails.Container>
      <AMBestDetails.StyledModal
        open={isShowDetailsModal}
        onOk={onCloseModal}
        onCancel={onCloseModal}
        title={carrierName}
        width={640}
        footer={[
          <Button key="back" onClick={onCloseModal}>
            Close
          </Button>,
        ]}
      >
        <div>
          <AMBestDetails.ContentHeader>
            <AMBestDetails.HeaderFirstLine>
              Financial Strength Rating
            </AMBestDetails.HeaderFirstLine>
            <AMBestDetails.HeaderSecondLine>
              {carrier.ratingEffectiveDate
                ? `A.M. Best Rating as of ${moment(
                    carrier.ratingEffectiveDate,
                  ).format('MMM MM, YYYY')}`
                : 'A.M. Best Rating'}
            </AMBestDetails.HeaderSecondLine>
          </AMBestDetails.ContentHeader>
          <AMBestDetails.Divider />

          <AMBestDetails.InformationContainer>
            <AMBestDetails.InformationCel>
              <AMBestDetails.InformationLabel>
                Rating
              </AMBestDetails.InformationLabel>
              <AMBestDetails.InformationText>
                {!carrier?.rating
                  ? 'n/a'
                  : `${carrier?.rating}${
                      carrier?.ratingModifier
                        ? ' ' + carrier?.ratingModifier
                        : ''
                    }`}
              </AMBestDetails.InformationText>
            </AMBestDetails.InformationCel>
            {carrier?.rating === 'NR' ? (
              <AMBestDetails.NrExplanation>
                {`${carrierName} ${NRExplanation}`}
              </AMBestDetails.NrExplanation>
            ) : (
              <>
                <AMBestDetails.InformationCel>
                  <AMBestDetails.InformationLabel>
                    Financial Size Category
                  </AMBestDetails.InformationLabel>
                  <AMBestDetails.InformationText>
                    {!carrier?.financialSizeCategory
                      ? 'n/a'
                      : `${
                          carrier?.financialSizeCategory
                        } ${financialSizeCategoryToText(
                          carrier?.financialSizeCategory,
                        )}`}
                  </AMBestDetails.InformationText>
                </AMBestDetails.InformationCel>
                <AMBestDetails.InformationCel>
                  <AMBestDetails.InformationLabel>
                    Implication
                  </AMBestDetails.InformationLabel>
                  <AMBestDetails.InformationText>
                    {R.propOr(
                      'n/a',
                      carrier?.ratingImplication,
                      RatingImplication,
                    )}
                  </AMBestDetails.InformationText>
                </AMBestDetails.InformationCel>
                <AMBestDetails.InformationCel>
                  <AMBestDetails.InformationLabel>
                    Action
                  </AMBestDetails.InformationLabel>
                  <AMBestDetails.InformationText>
                    {R.propOr('n/a', carrier?.ratingAction, RatingAction)}
                  </AMBestDetails.InformationText>
                </AMBestDetails.InformationCel>
                <AMBestDetails.InformationCel>
                  <AMBestDetails.InformationLabel>
                    Effective Date:
                  </AMBestDetails.InformationLabel>
                  <AMBestDetails.InformationText>
                    {carrier?.ratingEffectiveDate
                      ? moment(carrier?.ratingEffectiveDate).format('LL')
                      : 'n/a'}
                  </AMBestDetails.InformationText>
                </AMBestDetails.InformationCel>
              </>
            )}
          </AMBestDetails.InformationContainer>
          <AMBestDetails.InformationNoteContainer>
            <Collapse bordered={false}>
              <Collapse.Panel
                header="Best’s Financial Strength Rating information"
                style={{ bordered: 0 }}
              >
                <RatingInformationNote />
              </Collapse.Panel>
            </Collapse>
          </AMBestDetails.InformationNoteContainer>
        </div>
      </AMBestDetails.StyledModal>
    </AMBestDetails.Container>
  );
};

AMBestDetails.Container = styled.div`
  > .ant-modal-body {
    padding: 0;
  }
`;

AMBestDetails.ContentHeader = styled.div`
  padding: 0 24px;
  display: grid;
`;

AMBestDetails.HeaderFirstLine = styled.span`
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
`;

AMBestDetails.HeaderSecondLine = styled.span`
  font-size: 12px;
  line-height: 20px;
`;

AMBestDetails.Divider = styled(Divider)`
  margin: 13px 0;
`;

AMBestDetails.InformationContainer = styled.div`
  padding: 0 24px;
  display: grid;
  grid-template-columns: 288px 288px;
  grid-gap: 16px;
`;

AMBestDetails.InformationCel = styled.div`
  display: grid;
`;

AMBestDetails.InformationLabel = styled.span`
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
`;

AMBestDetails.NrExplanation = styled.div`
  padding-top: 8px;
  grid-column: 1 / 3;
  max-width: 572px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
`;

AMBestDetails.InformationNoteContainer = styled.div`
  padding-top: 25px;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;

  .ant-collapse-content {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.65);
    padding: 8px;
  }
`;

AMBestDetails.StyledModal = styled(Modal)`
  .ant-modal-body {
    padding: 24px 0 0;
  }

  .ant-collapse-item {
    border: none;
    background-color: #f5f5f5;
  }

  .ant-modal-footer {
    border: none;
    padding: 10px;
  }
`;

AMBestDetails.InformationText = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
`;

export default AMBestDetails;
