import { gql } from '@apollo/client';
import { useMutation } from '@graphql/hooks';
import type { DisablePrimaryRecordsAutomationMutationVariables } from '@graphql/types/graphql';

// TODO: In this mutation in the future will be added the return type for handling bulk operations
const DISABLE_PRIMARY_RECORDS_AUTOMATION_MUTATION = gql`
  mutation DisablePrimaryRecordsAutomation($data: PrimaryRecordsAutomationInput!) {
    disablePrimaryRecordsAutomation(data: $data) {
      operation {
        code
        name
        status
        progress
      }
      errors {
        code
        message
      }
    }
  }
`;

type DisableAutomationArgs = {
  variables: DisablePrimaryRecordsAutomationMutationVariables['data'];
};

export const useDisableAutomationMutation = () => {
  const [disablePrimaryRecordsAutomation, { loading: isDisablingAutomation }] =
    useMutation(DISABLE_PRIMARY_RECORDS_AUTOMATION_MUTATION);

  const disableAutomation = ({ variables }: DisableAutomationArgs) => {
    return disablePrimaryRecordsAutomation({
      variables: { data: variables },
    });
  };

  return {
    disableAutomation,
    isDisablingAutomation,
  };
};
