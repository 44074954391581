import styled from 'styled-components';

const EmptyCarrierInfo = ({ onAddClick }) => {
  return (
    <EmptyCarrierInfo.Container>
      <EmptyCarrierInfo.NoCarrierLabel>
        No carrier information available
      </EmptyCarrierInfo.NoCarrierLabel>
      <EmptyCarrierInfo.AddInDocumentLabel onClick={onAddClick}>
        Add in document
      </EmptyCarrierInfo.AddInDocumentLabel>
    </EmptyCarrierInfo.Container>
  );
};

EmptyCarrierInfo.Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 7px;
  margin-bottom: 5px;
  border: 1px dashed #ddd;
  border-radius: 4px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.65);
`;

EmptyCarrierInfo.NoCarrierLabel = styled.span`
  color: ${(props) => props.theme.colors.lightTextGray};
`;

EmptyCarrierInfo.AddInDocumentLabel = styled.span`
  color: rgba(0, 0, 0, 0.85);
  cursor: pointer;
  text-decoration: underline;
`;

export default EmptyCarrierInfo;
