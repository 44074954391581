import styled from 'styled-components';

import ErrorStatus from '@common/icons/ErrorStatus';
import PulseIcon from '@common/icons/PulseIcon';
import SuccessStatus from '@common/icons/SuccessStatus';
import WarningStatus from '@common/icons/WarningStatus';
import { POLICY_STATUSES } from '@modules/document/constants';

type Statuses =
  | (typeof POLICY_STATUSES)[keyof typeof POLICY_STATUSES]
  | null
  | undefined;
type PulseIconWithStatusProps = {
  status?: Statuses;
};

const IconByStatus = ({ status }: { status: Statuses }) => {
  switch (status) {
    case POLICY_STATUSES.inforce:
    case POLICY_STATUSES.renewed:
      return <SuccessStatus />;

    case POLICY_STATUSES.pendingCancellation:
    case POLICY_STATUSES.nonRenewed:
      return <WarningStatus />;

    case POLICY_STATUSES.expired:
    case POLICY_STATUSES.canceled:
      return <ErrorStatus />;

    default:
      return null;
  }
};

export const PulseIconWithStatus = ({
  status,
  ...rest
}: PulseIconWithStatusProps) => (
  <StyledIconGroup {...rest}>
    <StyledIconBadge>
      <IconByStatus status={status} />
    </StyledIconBadge>
    <PulseIcon
      disabled={!Boolean(status) || status === POLICY_STATUSES.notFound}
    />
  </StyledIconGroup>
);

const StyledIconGroup = styled.div`
  position: relative;
`;

const StyledIconBadge = styled.div`
  :not(:empty) {
    position: absolute;
    width: 10px;
    height: 10px;
    right: -4px;
    top: -2px;
  }
`;
