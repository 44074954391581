import { Button, Timeline } from 'antd';
import { isEmpty } from 'lodash';
import * as R from 'ramda';
import { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';

import { getEventLogItem } from './EventLogTimeline.utils.jsx';

const EventLogTimeline = ({
  isLoadingMore,
  timeline,
  loadMore,
  hasToLoadMore,
  editableComments,
}) => {
  const themeContext = useContext(ThemeContext);

  if (isEmpty(timeline)) {
    return <p>No entries to show for this user</p>;
  }

  const items = R.sortBy(R.prop('createdAt'), timeline)
    .reverse()
    .map((log) => getEventLogItem({ log, themeContext, editableComments }));

  return (
    <>
      <StyledTimeline items={items} />
      {timeline.length > 0 && hasToLoadMore && (
        <StyledLoadMoreButton
          loading={isLoadingMore}
          type="link"
          onClick={() => loadMore()}
        >
          Load more entries...
        </StyledLoadMoreButton>
      )}
    </>
  );
};

const StyledTimeline = styled(Timeline)`
  margin-left: 5px !important;
  font-size: 12px;
  font-weight: normal;

  p.timestamp {
    margin: 3px 0 0;
    font-size: 13px;
  }

  b {
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
  }

  a {
    text-decoration: underline;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const StyledLoadMoreButton = styled(Button)`
  && {
    margin-top: 15px;
    padding: 0;
  }
`;

export default EventLogTimeline;
