import { Spin } from 'antd';
import moment from 'moment';
import * as R from 'ramda';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { useAppDispatch } from '@store/hooks';

import { getPartyConversationMessages } from '../actions';
import MessagesList from '../components/MessagesList';
import { getPartyConversationMessagesCount } from '../selectors';

const MessagesListContainer = ({
  selectedPartyConversationId,
  isScrolling,
  selectedConversationData,
}) => {
  const dispatch = useAppDispatch();
  const [isLoadingMessages, setIsLoadingMessages] = useState(false);
  const messages = selectedConversationData?.messages?.nodes ?? [];
  const messagesWithFormattedDate = R.compose(
    R.map((message) => ({
      ...message,
      createdDate: moment(message.createdAt)
        .startOf('day')
        .format('dddd, MMMM DD, YYYY'),
    })),
  )(messages);
  const messagesByDate = R.compose(
    R.reduce(
      (res, dateKey) =>
        R.assoc(
          dateKey,
          messagesWithFormattedDate.filter(
            (message) => message.createdDate === dateKey,
          ),
          res,
        ),
      {},
    ),
    R.uniq,
    R.sort((a, b) => new Date(a) - new Date(b)),
    R.map((message) => message.createdDate),
  )(messagesWithFormattedDate);
  const hasUnfetchedMessages =
    useSelector((state) =>
      getPartyConversationMessagesCount(state, selectedPartyConversationId),
    ) > messages.length;

  useEffect(() => {
    if (selectedPartyConversationId && hasUnfetchedMessages) {
      const fetchPartyConversationMessages = async () => {
        setIsLoadingMessages(true);
        await dispatch(
          getPartyConversationMessages(selectedPartyConversationId),
        );
        setIsLoadingMessages(false);
      };
      fetchPartyConversationMessages();
    }
  }, [selectedPartyConversationId, hasUnfetchedMessages, dispatch]);

  return (
    <>
      {isLoadingMessages && (
        <StyledSpinOverlay>
          <div>
            <Spin />
          </div>
        </StyledSpinOverlay>
      )}
      <MessagesList
        selectedPartyConversationId={selectedPartyConversationId}
        messagesByDate={messagesByDate}
        isScrolling={isScrolling}
        messagesCount={messages.length}
        conversationRequest={selectedConversationData?.request}
        selectedConversationData={selectedConversationData}
        isLoadingMessages={isLoadingMessages}
      />
    </>
  );
};

const StyledSpinOverlay = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  position: absolute;
  background: rgba(0, 0, 0, 0.1);
`;

export default MessagesListContainer;
