import { gql } from '@apollo/client';

import type { AnyGQLQueryAction } from '@common/types';

import { namespace } from '../constants';

export const FETCH_FILLABLE_FORM_SUGGESTIONS: `${typeof namespace}/FETCH_FILLABLE_FORM_SUGGESTIONS` = `${namespace}/FETCH_FILLABLE_FORM_SUGGESTIONS`;

const FILLABLE_FORM_SUGGESTIONS_FRAGMENT = gql`
  fragment FillabelForm on Form {
    _id
    name
    formFields
  }
`;

const FILLABLE_FORM_SUGGESTIONS_QUERY = gql`
  query listFormsSuggestions(
    $filters: ListFormFilters
    $skip: Int
    $limit: Int
  ) {
    listForms(filters: $filters, skip: $skip, limit: $limit) {
      ...FillabelForm
    }
    countForms(filters: $filters)
  }
  ${FILLABLE_FORM_SUGGESTIONS_FRAGMENT}
`;

export type FetchFillableFormSuggestionsPayload = {
  skip: number;
  limit: number;
  filters: { name: string };
};

export type FetchFillableFormSuggestions = (
  payload: FetchFillableFormSuggestionsPayload,
) => AnyGQLQueryAction<
  typeof FETCH_FILLABLE_FORM_SUGGESTIONS,
  FetchFillableFormSuggestionsPayload
>;

export const fetchFillableFormSuggestions: FetchFillableFormSuggestions = (
  payload,
) => ({
  type: FETCH_FILLABLE_FORM_SUGGESTIONS,
  payload: {
    key: 'listFormsSuggestions',
    graphql: {
      fetchPolicy: 'no-cache',
      query: FILLABLE_FORM_SUGGESTIONS_QUERY,
      variables: payload,
    },
  },
});
