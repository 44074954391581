import { ClockCircleOutlined } from '@ant-design/icons';
import styled from 'styled-components';

import Box from '@common/components/Box';
import BoxTitle from '@common/components/BoxTitle';
import Spinner from '@common/components/Spinner';

import EventLogTimeline from './EventLogTimeline';

const EventLogBox = ({
  isTimelineLoading,
  isLoadingMore,
  timeline,
  loadMore,
  hasToLoadMore,
  children,
}) => (
  <StyledWrapper>
    <StyledItemHeader>
      <BoxTitle icon={<ClockCircleOutlined />} label="Activity timeline" />
    </StyledItemHeader>
    {!isTimelineLoading ? (
      <>
        {children}
        <StyledContent>
          <EventLogTimeline
            timeline={timeline}
            isLoadingMore={isLoadingMore}
            hasToLoadMore={hasToLoadMore}
            loadMore={loadMore}
            editableComments={true}
          />
        </StyledContent>
      </>
    ) : (
      <Spinner />
    )}
  </StyledWrapper>
);

const StyledWrapper = styled(Box)`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;

  .ant-timeline-item-head-custom {
    padding: 0;
  }

  .ant-timeline-item-tail {
    border-left: 3px solid #f3f6f8;
  }

  .ant-timeline-item-content {
    position: relative;
    left: 15px;
    margin: 0 0 0 18px;
    word-break: break-word;
    font-size: 13px;
  }

  .ant-timeline-item {
    position: relative;
    margin: 0;
    padding: 0 0 20px;
    font-size: 14px;
    list-style: none;
  }
`;

const StyledItemHeader = styled.div`
  margin-bottom: 20px;
  display: flex;
  align-items: center;
`;

const StyledContent = styled.div`
  padding-left: 68px;
  padding-right: 16px;
`;

export default EventLogBox;
