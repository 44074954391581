import { useQuery } from '@graphql/hooks';
import { graphql } from '@graphql/types';
import { DocumentFilterFields } from '@modules/document/constants';
import { useMemo } from 'react';

const DOCUMENTS_FILTER_TAGS_QUERY = graphql(`
  query DocumentFilterTagsQuery($subjects: ListSubjectsInput!) {
    listSubjects(input: $subjects) {
      nodes {
        _id
        subjectLabel
      }
    }
}`);

export const useDocumentsFilterTags = ({
  activeFilters,
}: {
  activeFilters: any;
}) => {
  const variables = useMemo(() => {
    let vars: any = null;

    if (activeFilters[DocumentFilterFields.Subject]) {
      const values = activeFilters[DocumentFilterFields.Subject];
      const ids = Array.isArray(values) ? values : [values];
      const subjects = {
        first: ids.length,
        offset: 0,
        filter: {
          and: [
            {
              name: 'subjectId',
              operator: 'in',
              value: ids,
            },
          ],
        },
      };

      vars = { ...vars, subjects };
    }

    return vars;
  }, [activeFilters]);

  const { data, loading } = useQuery(DOCUMENTS_FILTER_TAGS_QUERY, {
    skip: !variables,
    variables,
  });

  return {
    subjects: data?.listSubjects?.nodes || [],
    isFilterTagsDataLoading: loading,
  };
};
