import { gql } from '@apollo/client';

import type { AnyGQLQueryAction, AnyGQLSuccessAction } from '@common/types';
import { ACTION_SUCCESS_SUFFIX } from '@store/constants';

import { namespace } from '../constants';

export const FETCH_TYPEFORM_FORMS =
  `${namespace}/FETCH_TYPEFORM_FORMS` as const;
export const FETCH_TYPEFORM_FORMS_SUCCESS =
  `${namespace}/FETCH_TYPEFORM_${ACTION_SUCCESS_SUFFIX}` as const;

const FETCH_TYPEFORM_FORMS_QUERY = gql`
  query listTypeformForms {
    listTypeformForms {
      id
      title
    }
  }
`;

export type FetchTypeformForms = () => AnyGQLQueryAction<
  typeof FETCH_TYPEFORM_FORMS
>;

export const fetchTypeformForms: FetchTypeformForms = () => ({
  type: FETCH_TYPEFORM_FORMS,
  payload: {
    key: 'listTypeformForms',
    graphql: {
      fetchPolicy: 'no-cache',
      query: FETCH_TYPEFORM_FORMS_QUERY,
    },
  },
});

export type TypeformForm = {
  id: string;
  title: string;
};

export type FetchTypeformFormsSuccessAction = AnyGQLSuccessAction<
  typeof FETCH_TYPEFORM_FORMS_SUCCESS,
  TypeformForm[]
>;
