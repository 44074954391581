import { useQuery } from '@graphql/hooks';
import { graphql } from '@graphql/types';

const PROJECT_CUSTOM_FIELDS = graphql(`
    query ProjectCustomFields($projectId: String!) {
      getProjectById(_id: $projectId) {
            customFields {
                nodes {
                    customField {
                    _id
                    name
                    type
                    color
                    options {
                        key
                        value
                    }
                    }
                    value
                }
            }
        }
    }
    `);

export function useProjectCustomFields(projectId: string) {
  const { loading, data } = useQuery(PROJECT_CUSTOM_FIELDS, {
    variables: {
      projectId,
    },
  });

  return {
    customFields: data?.getProjectById.customFields?.nodes ?? [],
    loading,
  };
}
