import type {
  ApolloError,
  DocumentNode,
  LazyQueryResultTuple,
  OperationVariables,
  QueryHookOptions,
  TypedDocumentNode,
} from '@apollo/client';
import isNil from 'lodash/isNil';
import { useCallback, useMemo, useRef } from 'react';

import type { RootState } from '@common/types';
import { getDataWithoutTypename } from '@graphql/utils';
import { ACTION_ERROR_SUFFIX, ACTION_SUCCESS_SUFFIX } from '@store/constants';
import { useAppDispatch, useAppSelector } from '@store/hooks';

import useLazyQuery, { type UseLazyQueryCustomOptions } from './useLazyQuery';

type UseLazyQueryWithReduxCustomOptions = {
  reduxActionType: string;
  reduxActionKey: string;
};

type TReduxData = {
  [x: string]: any | undefined;
};

const fakeSelector = () => null;

/**
 * Use this hook to start fetching data using Apollo hooks incrementally without being worried about breaking the current behavior.
 * This hook will dispatch SUCCESS or ERROR action for the reduxAction type you pass.
 * It also returns the data saved in Redux as long as a reduxSelector is passed.
 * This allows a progressive migration of all the components which are using and/or modifying the data in redux store.
 * Example:
 *    Comp A and B are using the data in redux, and Comp B might modify that data that is now reflected in Comp A.
 *    By using this hook you can refactor Comp A without breaking it because it will keep reading from redux.
 *    Once Comp B is migrated as well, you can replace this hook in favor of useLazyQuery
 */

function useLazyQueryWithRedux<
  TData = any,
  TVariables extends OperationVariables = OperationVariables,
>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options: QueryHookOptions<TData, TVariables> &
    UseLazyQueryCustomOptions &
    UseLazyQueryWithReduxCustomOptions,
): LazyQueryResultTuple<TData, TVariables>;

function useLazyQueryWithRedux<
  TData = any,
  TVariables extends OperationVariables = OperationVariables,
>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options: QueryHookOptions<TData, TVariables> &
    UseLazyQueryCustomOptions &
    UseLazyQueryWithReduxCustomOptions & {
      reduxSelector: (state: RootState) => any;
    },
): LazyQueryResultTuple<TReduxData, TVariables>;

function useLazyQueryWithRedux<
  TData = any,
  TVariables extends OperationVariables = OperationVariables,
>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options: QueryHookOptions<TData, TVariables> &
    UseLazyQueryCustomOptions &
    UseLazyQueryWithReduxCustomOptions & {
      reduxSelector?: (state: RootState) => any;
    },
) {
  const dispatch = useAppDispatch();
  const { reduxActionType, reduxActionKey, reduxSelector, ...queryOptions } =
    options;

  const reduxData = useAppSelector(reduxSelector || fakeSelector);

  const meta = useMemo(
    () => ({
      previousAction: {
        payload: {
          key: reduxActionKey,
        },
      },
    }),
    [reduxActionKey],
  );

  const getEventsHandler = useCallback(
    (options?: {
      onCompleted?: (data: TData) => void;
      onError?: (data: ApolloError) => void;
    }) => ({
      onCompleted: (data: TData) => {
        dispatch({
          type: `${reduxActionType}${ACTION_SUCCESS_SUFFIX}`,
          payload: { data: getDataWithoutTypename(data) },
          meta,
        });
        options?.onCompleted?.(data);
      },
      onError: (error: ApolloError) => {
        dispatch({
          type: `${reduxActionType}${ACTION_ERROR_SUFFIX}`,
          payload: error,
          meta,
        });
        options?.onError?.(error);
      },
    }),
    [dispatch, meta, reduxActionType],
  );

  /**
   * add events handler to hook options
   */
  const queryWithReduxOptions = {
    ...queryOptions,
    ...getEventsHandler(queryOptions),
  };

  const [queryFn, queryRes] = useLazyQuery(query, queryWithReduxOptions);

  /**
   * add events handler to queryFn
   * because when we pass onCompleted or onError to mutationFn they will override the ones passed to the hook
   */
  const queryOptionsRef = useRef(queryOptions);
  const queryFnWithRedux: typeof queryFn = useCallback(
    (options) =>
      queryFn({
        ...options,
        ...getEventsHandler({
          ...queryOptionsRef.current,
          ...options,
        }),
      }),
    [getEventsHandler, queryFn],
  );

  /**
   * if reduxSelector is passed, we will use the data in redux as the data of the query
   */
  if (reduxSelector) {
    const queryResFromRedux = {
      ...(queryRes || {}),
      data: {
        [reduxActionKey]: !isNil(
          (queryRes?.data as Record<string, TData>)?.[reduxActionKey],
        )
          ? reduxData
          : undefined,
      },
    };

    return [queryFnWithRedux, queryResFromRedux];
  }

  return [queryFnWithRedux, queryRes];
}

export default useLazyQueryWithRedux;
