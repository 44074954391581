import { Menu } from 'antd';
import * as R from 'ramda';
import styled from 'styled-components';

import PartyConversationContainer from '../containers/PartyConversationContainer';
import { getNearestConversations } from '../utils/party-conversation-helpers';

const PartyConversationList = ({
  partyConversations,
  selectedPartyConversationId,
  setSelectedPartyConversationId,
}) => {
  const sortedConversations = getNearestConversations(partyConversations);

  return (
    <PartyConversationList.Wrapper>
      <Menu>
        {sortedConversations.map((partyConversation) => (
          <Menu.Item
            key={R.prop('_id', partyConversation)}
            onClick={() =>
              setSelectedPartyConversationId(R.prop('_id', partyConversation))
            }
          >
            <PartyConversationContainer
              isActive={
                R.prop('_id', partyConversation) === selectedPartyConversationId
              }
              partyConversation={partyConversation}
            />
          </Menu.Item>
        ))}
      </Menu>
    </PartyConversationList.Wrapper>
  );
};

PartyConversationList.Wrapper = styled.div`
  .ant-menu-vertical > .ant-menu-item {
    height: auto;
    line-height: 20px;
    margin: 0;
    padding: 0;
  }

  ul {
    overflow-y: auto;
    border-right: 0;
  }
`;

export default PartyConversationList;
