import moment from 'moment-timezone';
import type { ParsedQuery } from 'query-string';

import {
  DateSuffix,
  FilterInputType,
  KeyType,
  type OnFilter,
  type RangeFilter,
  type WithinDateType,
  type WithinFilter,
} from '@common/components/DateFilterSelector';

// Maintain the UTC date after conversion
export const getSelectFilterUtcDate = (
  filterName: string,
  params: ParsedQuery<string>,
) => {
  const key = params[
    `${filterName}${DateSuffix.FilterType}`
  ] as unknown as KeyType;

  switch (key) {
    case KeyType.ON:
      return {
        key,
        filterType: FilterInputType.SELECT,
        value: moment(
          moment
            .utc(
              parseInt(
                params[`${filterName}${DateSuffix.PickerValue}`] as string,
              ),
            )
            .format('YYYY-MM-DD'),
        ),
      } as OnFilter;
    case KeyType.BETWEEN:
      return {
        key,
        filterType: FilterInputType.RANGE,
        from: moment(
          moment
            .utc(parseInt(params[`${filterName}${DateSuffix.From}`] as string))
            .format('YYYY-MM-DD'),
        ).startOf('D'),
        to: moment(
          moment
            .utc(parseInt(params[`${filterName}${DateSuffix.To}`] as string))
            .format('YYYY-MM-DD'),
        ).endOf('D'),
      } as RangeFilter;
    default:
      return getSelectedFilter(filterName, params);
  }
};

// TODO: validation for parsed values
export const getSelectedFilter = (
  filterName: string,
  params: ParsedQuery<string>,
) => {
  const key = params[
    `${filterName}${DateSuffix.FilterType}`
  ] as unknown as KeyType;

  switch (key) {
    case KeyType.ON:
      return {
        key,
        filterType: FilterInputType.SELECT,
        value: moment(
          parseInt(params[`${filterName}${DateSuffix.PickerValue}`] as string),
        ),
      } as OnFilter;
    case KeyType.BETWEEN:
      return {
        key,
        filterType: FilterInputType.RANGE,
        from: moment(
          parseInt(params[`${filterName}${DateSuffix.From}`] as string),
        ),
        to: moment(parseInt(params[`${filterName}${DateSuffix.To}`] as string)),
      } as RangeFilter;
    case KeyType.LAST:
    case KeyType.NEXT:
    case KeyType.OLDER:
      return {
        key,
        filterType: FilterInputType.PICKER,
        withinDateType: params[
          `${filterName}${DateSuffix.WithinDateType}`
        ] as unknown as WithinDateType,
        withinDateValue: Number(
          params[`${filterName}${DateSuffix.WithinDateCount}`],
        ),
      } as WithinFilter;
    default:
      return;
  }
};
