import styled from 'styled-components';

const DocumentsEmptyState = () => (
  <DocumentsEmptyState.NoData data-cy="documentsListEmptyState">
    <img
      style={{ maxWidth: '192px' }}
      src="/images/empty-documents.png"
      alt="empty documents"
    />
    <DocumentsEmptyState.NoDataTitle>
      No documents to show
    </DocumentsEmptyState.NoDataTitle>
    <DocumentsEmptyState.NoDataMessage>
      After sending a request, you will receive documents from your parties.
      <br />
      You can also upload documents manually.
    </DocumentsEmptyState.NoDataMessage>
  </DocumentsEmptyState.NoData>
);

DocumentsEmptyState.NoData = styled.div`
  margin: auto;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

DocumentsEmptyState.NoDataTitle = styled.h2`
  margin-top: 25px;
  font-size: 14px;
  font-weight: 600;
  color: #7b91a7;
`;

DocumentsEmptyState.NoDataMessage = styled.p`
  margin: 10px 0 20px;
  font-size: 12px;
  color: ${(props) => props.theme.colors.grayText};
  text-align: center;
`;

export default DocumentsEmptyState;
