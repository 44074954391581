import * as R from 'ramda';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import uuidByString from 'uuid-by-string';

import * as ComplianceProfileActions from '../actions';
import DocumentChecklist from '../components/DocumentChecklist';
import { getCurrentDocumentChecklists } from '../selectors';
import { getAttributesByDocumentType } from '../utils/checklist-helpers';

const DocumentChecklistContainer = ({ type, checklistsRefs }) => {
  const [value, setValue] = useState('');
  const [isShowForm, setIsShowForm] = useState(false);
  const currentChecklists = useSelector(getCurrentDocumentChecklists);
  const attributes = getAttributesByDocumentType(currentChecklists, type);

  const dispatch = useDispatch();

  const addDocumentChecklist = () => {
    // eslint-disable-next-line fp/no-let
    let checklistsToUpdate = null;
    const emptyAttributeChecklist = currentChecklists.filter(
      (x) => x.type._id === type._id && !Boolean(x.attributeId),
    );
    const attributeId = uuidByString(value);
    const attributeLabel = value;

    if (emptyAttributeChecklist.length === 1) {
      checklistsToUpdate = currentChecklists.map((x) => {
        if (x === R.head(emptyAttributeChecklist)) {
          return {
            ...x,
            attributeId,
            attributeLabel,
          };
        }

        return x;
      });
    } else {
      checklistsToUpdate = R.append(
        {
          type,
          attributeId,
          attributeLabel,
          isActive: true,
        },
        currentChecklists,
      );
    }

    dispatch(
      ComplianceProfileActions.setCurrentDocumentChecklists(checklistsToUpdate),
    );
    setIsShowForm(false);
    setValue('');
  };

  return (
    <DocumentChecklist
      attributes={attributes}
      type={type}
      value={value}
      setValue={setValue}
      addDocumentChecklist={addDocumentChecklist}
      isShowForm={isShowForm}
      setIsShowForm={setIsShowForm}
      checklistsRefs={checklistsRefs}
    />
  );
};

export default DocumentChecklistContainer;
