import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Spinner from '@common/components/Spinner';
import { fetchEmailTemplate } from '@modules/email-template/actions';
import EmailTemplateEditForm from '@modules/email-template/forms/EmailTemplateEditForm';
import { getEmailTemplate } from '@modules/email-template/selectors';
import { getActiveOrganizationId } from '@modules/organization/selectors';

const CustomizeEmailTemplateContainer = ({ onChange, templateId }) => {
  const dispatch = useDispatch();
  const activeOrganizationId = useSelector(getActiveOrganizationId);
  const template = useSelector((state) => getEmailTemplate(state, templateId));
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const loadEmailTemplate = async () => {
      setIsLoading(true);
      await dispatch(fetchEmailTemplate({ payload: templateId }));
      setIsLoading(false);
    };

    loadEmailTemplate();
  }, [dispatch, templateId]);

  const handleOnFormChange = (_, allValues) => {
    onChange({
      _id: templateId,
      organization: activeOrganizationId,
      type: template?.type,
      ...allValues,
    });
  };

  return isLoading ? (
    <Spinner />
  ) : (
    <EmailTemplateEditForm data={template} onChange={handleOnFormChange} />
  );
};

export default CustomizeEmailTemplateContainer;
