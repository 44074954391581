export const PROJECT_PLACEHOLDER_VALUES = {
  compliantProjects: 'compliantProjects',
} as const;

export const projectPlaceholders = {
  title: 'Project',
  placeholders: [
    {
      name: 'Compliant projects list',
      region: `{{{ ${PROJECT_PLACEHOLDER_VALUES.compliantProjects} }}}`,
    },
  ],
};
