import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { Fragment } from 'react';
import styled from 'styled-components';

import ChecklistAttributeContainer from '../containers/ChecklistAttributeContainer';
import Form from '../forms/ChecklistAttributeForm';

const DocumentChecklist = ({
  attributes,
  type,
  value,
  setValue,
  addDocumentChecklist,
  isShowForm,
  setIsShowForm,
  checklistsRefs,
}) => (
  <DocumentChecklist.Wrapper
    ref={(node) => (checklistsRefs[type._id] = node)}
    id={`drawer_${type._id}`}
  >
    <DocumentChecklist.Title>{type.name}</DocumentChecklist.Title>
    <div>
      {attributes.map((item) => (
        <Fragment key={item.attributeId}>
          <ChecklistAttributeContainer checklist={item} type={type._id} />
        </Fragment>
      ))}
    </div>
    {isShowForm ? (
      <Form
        value={value}
        submitButtonLabel="Add"
        onChangeHandle={(e) => setValue(e.target.value)}
        submitButtonHandle={addDocumentChecklist}
        closeButtonHandle={() => setIsShowForm(false)}
      />
    ) : (
      <DocumentChecklist.AddNewItem>
        <Button
          data-cy={`addChecklistItemButton_${type._id}`}
          type="link"
          icon={<PlusOutlined />}
          onClick={() => setIsShowForm(true)}
        >
          Add new item
        </Button>
      </DocumentChecklist.AddNewItem>
    )}
  </DocumentChecklist.Wrapper>
);

DocumentChecklist.Wrapper = styled.div`
  margin: 30px 0;
  padding: 10px 20px;
  border-radius: 4px;
  border: 1px solid #e8e8e8;
  background-color: #f7f9fc;
`;

DocumentChecklist.Title = styled.div`
  font-size: 16px;
  margin-bottom: 10px;
  color: ${(props) => props.theme.colors.grayishBlue};
`;

DocumentChecklist.AddNewItem = styled.div`
  margin: 5px 0;

  button {
    padding: 0;
  }
`;

export default DocumentChecklist;
