import { gql } from '@apollo/client';

import { namespace } from '../constants';

export const SEND_MESSAGE = `${namespace}/SEND_MESSAGE`;

const SEND_MESSAGE_MUTATION = gql`
  mutation sendMessage($payload: SendMessageInput!) {
    sendMessage(data: $payload)
  }
`;

export const sendMessage = (payload) => ({
  type: SEND_MESSAGE,
  payload: {
    key: 'sendMessage',
    graphql: {
      mutation: SEND_MESSAGE_MUTATION,
      context: { hasUpload: true },
      variables: {
        payload,
      },
    },
  },
});
