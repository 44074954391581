import Icon, {
  MailOutlined,
  MailTwoTone,
  PhoneOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Avatar, Dropdown, Menu, Tooltip } from 'antd';
import * as R from 'ramda';
import styled from 'styled-components';

import Dots from '@common/icons/Dots';
import PrimaryStarIcon from '@common/icons/PrimaryStarIcon';
import { HideForViewerRole } from '@modules/organization-member/containers/HideForRoles';
import useMemberRole from '@modules/organization-member/hooks/useMemberRole';
import { ROLES } from '@modules/organization-member/models/data';

import FaxIcon from './icons/FaxIcon';

const PartyContactCard = ({
  partyContact,
  setContactToEdit,
  deleteContact,
  setContactAsPrimary,
  sendContactMessage,
}) => {
  const memberRole = useMemberRole();

  return (
    <PartyContactCard.Wrapper>
      <PartyContactCard.ContentWrapper>
        <PartyContactCard.IconWrapper>
          {Boolean(R.prop('avatar', partyContact)) ? (
            <Avatar size={34} src={R.prop('avatar', partyContact)} />
          ) : (
            <Avatar size={34} icon={<UserOutlined />} />
          )}
          {R.prop('isPrimary', partyContact) && (
            <PartyContactCard.PrimaryIconWrapper>
              <PrimaryStarIcon />
            </PartyContactCard.PrimaryIconWrapper>
          )}
        </PartyContactCard.IconWrapper>
        <PartyContactCard.DescriptionWrapper>
          {R.prop('contactPersonName', partyContact) ? (
            <PartyContactCard.Name
              data-cy="partyContactCardName"
              $clickable={memberRole !== ROLES.VIEWER}
              onClick={() => {
                if (memberRole === ROLES.VIEWER) {
                  return;
                }

                setContactToEdit(partyContact);
              }}
            >
              {R.prop('contactPersonName', partyContact)}
            </PartyContactCard.Name>
          ) : (
            <PartyContactCard.Unnamed data-cy="partyContactCardName">
              Unnamed Contact
            </PartyContactCard.Unnamed>
          )}
          {R.prop('companyName', partyContact) && (
            <PartyContactCard.CompanyName data-cy="partyContactCardCompanyName">
              {R.prop('companyName', partyContact)}
            </PartyContactCard.CompanyName>
          )}
          {R.prop('title', partyContact) && (
            <PartyContactCard.Title data-cy="partyContactCardTitle">
              {R.prop('title', partyContact)}
            </PartyContactCard.Title>
          )}
          {R.prop('email', partyContact) && (
            <PartyContactCard.Email
              data-cy="partyContactCardEmail"
              onClick={() => {
                if (Boolean(R.prop('email', partyContact))) {
                  sendContactMessage(R.prop('_id', partyContact));
                }
              }}
            >
              {R.prop('isDefaultRequestRecipient', partyContact) ? (
                <Tooltip
                  overlayClassName="partyContactCardTooltip"
                  title="This contact is used for document requests/reminders"
                >
                  <MailTwoTone />
                </Tooltip>
              ) : (
                <MailOutlined />
              )}
              {R.prop('email', partyContact)}
            </PartyContactCard.Email>
          )}
          {R.prop('phone', partyContact) && (
            <PartyContactCard.Phone data-cy="partyContactCardPhone">
              <PhoneOutlined />
              {R.prop('phone', partyContact)}
            </PartyContactCard.Phone>
          )}
          {R.prop('fax', partyContact) && (
            <PartyContactCard.Fax data-cy="partyContactCardFax">
              <Icon component={FaxIcon} />
              {R.prop('fax', partyContact)}
            </PartyContactCard.Fax>
          )}
        </PartyContactCard.DescriptionWrapper>
        <PartyContactCard.ActionWrapper>
          <HideForViewerRole>
            <Dropdown
              trigger={['click']}
              dropdownRender={() => (
                <Menu>
                  <Menu.Item
                    key="contactSendMessage"
                    data-cy="contactSendMessage"
                    disabled={!Boolean(R.prop('email', partyContact))}
                    onClick={() => {
                      if (Boolean(R.prop('email', partyContact))) {
                        sendContactMessage(R.prop('_id', partyContact));
                      }
                    }}
                  >
                    Send message
                  </Menu.Item>
                  {!R.prop('isPrimary', partyContact) && (
                    <Menu.Item
                      key="contactSetAsPrimary"
                      data-cy="contactSetAsPrimary"
                      onClick={() =>
                        setContactAsPrimary(R.prop('_id', partyContact))
                      }
                    >
                      Set as primary contact
                    </Menu.Item>
                  )}
                  <Menu.Item
                    key="contactEdit"
                    data-cy="contactEdit"
                    onClick={() => setContactToEdit(partyContact)}
                  >
                    Edit
                  </Menu.Item>
                  <Menu.Item
                    key="contactDelete"
                    data-cy="contactDelete"
                    disabled={R.prop('isPrimary', partyContact)}
                    onClick={() => {
                      if (!R.prop('isPrimary', partyContact)) {
                        deleteContact(R.prop('_id', partyContact));
                      }
                    }}
                  >
                    Delete
                  </Menu.Item>
                </Menu>
              )}
            >
              <PartyContactCard.MenuIconWrapper data-cy="contactMenu">
                <Dots />
              </PartyContactCard.MenuIconWrapper>
            </Dropdown>
          </HideForViewerRole>
        </PartyContactCard.ActionWrapper>
      </PartyContactCard.ContentWrapper>
    </PartyContactCard.Wrapper>
  );
};

PartyContactCard.Wrapper = styled.div`
  margin-bottom: 15px;
  padding: 15px;
  background: ${(props) => props.theme.colors.white};
  border: 1px solid #dbe4ef;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;

  .ant-avatar.ant-avatar-icon {
    background-color: rgb(105, 123, 141);
  }
`;

PartyContactCard.ContentWrapper = styled.div`
  display: flex;
`;

PartyContactCard.IconWrapper = styled.div`
  position: relative;
`;

PartyContactCard.PrimaryIconWrapper = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;
`;

PartyContactCard.DescriptionWrapper = styled.div`
  width: 75%;
  margin-left: 13px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;

  .anticon {
    margin-right: 10px;
  }
`;

PartyContactCard.Name = styled.div`
  font-size: 16px;
  line-height: 19px;
  color: rgba(0, 0, 0, 0.85);
  transition: color 0.3s;
  cursor: ${(props) => (props.$clickable ? 'pointer' : 'default')};
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    color: rgb(64, 169, 255);
  }
`;

PartyContactCard.Unnamed = styled.div`
  font-size: 16px;
  color: rgba(0, 0, 0, 0.35);
`;

PartyContactCard.Title = styled.div`
  margin-top: 7px;
`;

PartyContactCard.CompanyName = styled.div`
  margin-top: 7px;
  font-size: 14px;
`;

PartyContactCard.Email = styled.div`
  margin-top: 7px;
  text-decoration-line: underline;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
`;

PartyContactCard.Phone = styled.div`
  margin-top: 7px;
`;

PartyContactCard.Fax = styled.div`
  margin-top: 7px;
`;

PartyContactCard.ActionWrapper = styled.div`
  height: 20%;
  margin-left: auto;
  cursor: pointer;
`;

PartyContactCard.MenuIconWrapper = styled.div`
  width: 15px;
  display: flex;
  justify-content: center;
  font-size: 15px;
`;

export default PartyContactCard;
