import { Button } from 'antd';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import {
  getEditorHTMLContent,
  getEditorPlainText,
  getEmptyEditorState,
  isEditorStateBlank,
} from '@common/components/RichTextEditor/helpers';
import { trackEvent } from '@common/utils/track-helpers';
import { HideForViewerRole } from '@modules/organization-member/containers/HideForRoles';
import { sendMessage } from '@modules/party-conversation/actions';
import RichTextArea from '@modules/party-conversation/components/RichTextArea';

const SendMessageForm = ({ selectedPartyConversationId }) => {
  const [attachments, setAttachments] = useState([]);
  const [messageEditorState, setMessageEditorState] = useState(
    getEmptyEditorState(),
  );
  const [isAttaching, setIsAttaching] = useState(false);
  const dispatch = useDispatch();
  const isTextAreaEmpty = isEditorStateBlank(messageEditorState);

  const handleMessageSumbit = async () => {
    if (!isTextAreaEmpty || Boolean(attachments.length)) {
      await dispatch(
        sendMessage({
          conversationId: selectedPartyConversationId,
          message: getEditorPlainText(messageEditorState),
          outputHtml: getEditorHTMLContent(messageEditorState),
          attachments,
        }),
      );

      trackEvent('User sent a message');

      setMessageEditorState(getEmptyEditorState());
      setAttachments([]);
    }
  };

  return (
    <HideForViewerRole>
      <StyledWrapper>
        <StyledTextEditorWrapper>
          <RichTextArea
            isValid
            attachments={attachments}
            isAttaching={isAttaching}
            setAttachments={setAttachments}
            setIsAttaching={setIsAttaching}
            focusDependence={selectedPartyConversationId}
            textAreaEditorState={messageEditorState}
            onChange={setMessageEditorState}
          />
        </StyledTextEditorWrapper>
        <StyledButtonWrapper>
          <Button
            type="primary"
            ghost={isTextAreaEmpty}
            disabled={isTextAreaEmpty}
            onClick={() => {
              if (!isAttaching) {
                handleMessageSumbit();
              }
            }}
          >
            Send
          </Button>
        </StyledButtonWrapper>
      </StyledWrapper>
    </HideForViewerRole>
  );
};

const StyledWrapper = styled.div`
  border-top: 1px solid #e6eaef;
  padding: 10px 15px 5px;
  height: 100%;
  width: 100%;
  display: flex;
`;

const StyledTextEditorWrapper = styled.div`
  width: 90%;
  margin-right: 20px;
`;

const StyledButtonWrapper = styled.div`
  margin-top: auto;
  margin-bottom: 42px;
  display: flex;
`;

export default SendMessageForm;
