import { graphql } from '@graphql/types';

export const PARTY_EVENT_LOGS_QUERY = graphql(`
  query PartyEventLogs($filters: EventLogFilters!) {
    listEventLogs(filters: $filters) {
      data {
        _id
        type
        data
        contextEntityType
        contextEntityId
        actorType
        projectName
        projectId
        partyName
        partyId
        actorId
        createdAt
        updatedAt
        deletedAt
      }
      totalCount
    }
  }
`);
