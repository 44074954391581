import { FeatureFlagsMap } from '../constants';

import { useFeatureFlag } from './useFeatureFlag';

export const usePulseIntegrationFeatureFlag = () => {
  const { PULSE_INTEGRATION } = FeatureFlagsMap;
  const isPulseIntegrationFeatureFlagEnabled =
    useFeatureFlag(PULSE_INTEGRATION);
  return { isPulseIntegrationFeatureFlagEnabled };
};
