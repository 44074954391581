import { Button, Popover, Select } from 'antd';
import * as R from 'ramda';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { hideForViewerRole } from '@modules/organization-member/hoc/hideForRoles';
import * as PartyConversationActions from '@modules/party-conversation/actions';
import { getParty } from '@modules/party/selectors';

const AddParticipantsPopover = ({
  children,
  conversationId,
  currentParticipants,
}) => {
  currentParticipants = R.uniq(currentParticipants);
  const { partyId } = useParams();
  const [participants, setParticipants] = useState(currentParticipants);
  const partyData = useSelector((state) => getParty(state, partyId));
  const partyContacts = R.propOr([], 'partyContacts', partyData);
  const dispatch = useDispatch();

  const updateParticipants = async () => {
    await dispatch(
      PartyConversationActions.updatePartyConversation({
        conversationId,
        participants,
      }),
    );
  };

  const getLabelByPartyContact = (partyContact) =>
    `${R.prop('contactPersonName', partyContact)} <${R.prop(
      'email',
      partyContact,
    )}>`;

  return (
    <Popover
      title="Edit participants"
      placement="bottom"
      trigger="click"
      onOpenChange={(visible) => {
        setParticipants(visible ? currentParticipants : []);
      }}
      content={
        <AddParticipantsPopover.Wrapper>
          <p>
            Newly added participants will receive future messages from this
            conversation.
          </p>
          <AddParticipantsPopover.Select
            data-cy="selectParticipants"
            placeholder="Select party contact..."
            mode="multiple"
            value={participants}
            onChange={(values) => setParticipants(values)}
          >
            {partyContacts
              .filter((contact) => contact.email)
              .map((partyContact) => (
                <Select.Option
                  key={R.prop('_id', partyContact)}
                  value={R.prop('_id', partyContact)}
                >
                  {getLabelByPartyContact(partyContact)}
                </Select.Option>
              ))}
          </AddParticipantsPopover.Select>
          <AddParticipantsPopover.ButtonWrapper>
            <Button
              data-cy="updateParticipantsButton"
              type="primary"
              onClick={() => updateParticipants()}
              disabled={!participants.length}
            >
              Update
            </Button>
          </AddParticipantsPopover.ButtonWrapper>
        </AddParticipantsPopover.Wrapper>
      }
    >
      {children}
    </Popover>
  );
};

AddParticipantsPopover.Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;
`;

AddParticipantsPopover.Select = styled(Select)`
  width: 100%;
`;

AddParticipantsPopover.ButtonWrapper = styled.div`
  margin-top: 15px;
`;

export default hideForViewerRole(AddParticipantsPopover);
