import { gql } from '@apollo/client';

import { namespace } from '../constants';

export const BULK_ADD_PARTIES_TO_PROJECT = `${namespace}/BULK_ADD_PARTIES_TO_PROJECT`;

const BULK_ADD_PARTIES_TO_PROJECT_MUTATION = gql`
  mutation bulkAddPartiesToProject($payload: BulkAddPartiesToProjectInput!) {
    bulkAddPartiesToProject(data: $payload) {
      scheduled
    }
  }
`;

export const bulkAddPartiesToProject = (payload) => ({
  type: BULK_ADD_PARTIES_TO_PROJECT,
  payload: {
    key: 'bulkAddPartiesToProject',
    graphql: {
      mutation: BULK_ADD_PARTIES_TO_PROJECT_MUTATION,
      variables: {
        payload,
      },
    },
  },
});
