import { Avatar, Button } from 'antd';
import { useState } from 'react';
import styled from 'styled-components';

import RichTextEditor from '@common/components/RichTextEditor';
import UserAvatar from '@common/components/UserAvatar';

const EventLogAddComment = ({
  members,
  avatarLink,
  commentEditorState,
  onCommentEditorChange,
  userName,
  userEmail,
  recordEvent,
  isEventsExist,
  isCTADisabled,
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [isHover, setIsHover] = useState(false);

  return (
    <>
      <StyledWrapper>
        <StyledAvatarWrapper>
          {avatarLink ? (
            <Avatar size={40} src={avatarLink} alt="Avatar" />
          ) : (
            <UserAvatar name={userName} email={userEmail} />
          )}
        </StyledAvatarWrapper>
        <div>
          <StyledTextAreaWrapper
            $isFocused={isFocused || isHover}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            onMouseOver={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
          >
            <RichTextEditor
              isValid
              removeBorder
              minHeight={50}
              placeholder="Enter your comment..."
              members={members}
              editorState={commentEditorState}
              onChange={onCommentEditorChange}
            />
          </StyledTextAreaWrapper>
          <StyledButton
            data-cy="addCommentButton"
            type="primary"
            disabled={isCTADisabled}
            ghost={isCTADisabled}
            onClick={() => recordEvent()}
          >
            Comment
          </StyledButton>
        </div>
      </StyledWrapper>
      {isEventsExist && (
        <>
          <StyledBottomBorder />
          <StyledVerticalLine />
        </>
      )}
    </>
  );
};

const StyledWrapper = styled.div`
  position: relative;
  margin-bottom: 20px;
  padding-left: 61px;
`;

const StyledBottomBorder = styled.div`
  margin-left: 61px;
  height: 3px;
  background-color: #f3f6f8;
`;

const StyledVerticalLine = styled.div`
  margin-left: 77px;
  border-left: 3px solid #f3f6f8;
  height: 28px;
`;

const StyledAvatarWrapper = styled.div`
  position: absolute;
  left: 5px;
  top: 6px;
`;

const StyledButton = styled(Button)`
  margin-top: 13px;
`;

const StyledTextAreaWrapper = styled.div`
  position: relative;
  padding: 1px;
  border: 1px solid ${(props) => (props.$isFocused ? '#40a9ff' : '#d9d9d9')};
  border-radius: 5px;

  textarea {
    padding-top: 12px;
    padding-bottom: 12px;
  }

  &&::after,
  &&::before {
    position: absolute;
    right: 100%;
    top: 23px;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    pointer-events: none;
  }

  &&::after {
    border-color: rgba(136, 183, 213, 0);
    border-right-color: #fff;
    border-width: 7px;
    margin-top: -7px;
  }

  &&::before {
    border-color: rgba(194, 225, 245, 0);
    border-right-color: ${(props) => (props.$isFocused ? '#40a9ff' : '#d9d9d9')};
    border-width: 8px;
    margin-top: -8px;

    .test:hover {
      border-right-color: #40a9ff;
    }
  }
`;

export default EventLogAddComment;
