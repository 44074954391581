import { Input, Modal } from 'antd';
import DOMPurify from 'dompurify';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import Spinner from '@common/components/Spinner';
import { getGraphqlPayload } from '@store/helpers';

import { renderEmailTemplate } from '../actions';

const ON_THE_FLY_PLACEHOLDER_LINK_REGEX = /http.*\/email-preview-placeholder/;

const EmailPreviewContainer = ({ template, partyId }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [preview, setPreview] = useState();
  const templateMemoized = useMemo(() => JSON.stringify(template), [template]);
  const emailRef = useRef();

  // biome-ignore lint/correctness/useExhaustiveDependencies: Legacy
  useEffect(() => {
    const load = async () => {
      setIsLoading(true);
      const res = await dispatch(renderEmailTemplate({ template, partyId }));
      const payload = getGraphqlPayload(res);

      payload && setPreview(payload);
      setIsLoading(false);
    };

    load();
  }, [templateMemoized, partyId, dispatch]);

  const onLinkClicked = (ev) => {
    ev.preventDefault();
    const url = ev.target?.getAttribute('href');
    const isPlaceholderLink = ON_THE_FLY_PLACEHOLDER_LINK_REGEX.test(url);

    if (!isPlaceholderLink) {
      window.open(url, '_blank');
    } else {
      Modal.info({
        title: 'Link not available in preview mode',
        content:
          'This link will be generated on the fly when the email has been sent.',
      });
    }
  };

  useEffect(() => {
    if (!emailRef?.current) {
      return;
    }
    const links = emailRef.current.querySelectorAll('[href]');
    links.forEach((link) => link.addEventListener('click', onLinkClicked));

    return () =>
      links.forEach((link) => link.removeEventListener('click', onLinkClicked));
  });

  if (isLoading) {
    return <Spinner />;
  }

  /**
   * @note "sanitize" excludes by default the "head" tag and so part of the template's style generated by MJML is removed.
   * To avoid this behavior the "saniteize" method needs the following options: { WHOLE_DOCUMENT: true, ADD_TAGS: ['head'] }
   *
   * see also an ISSUE in DOMPurify GitHub @link https://github.com/cure53/DOMPurify/issues/501
   */

  return (
    <StyledWrapper>
      <label>Subject</label>
      <Input readOnly value={preview?.subject} />
      <div
        ref={emailRef}
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(preview?.body, {
            WHOLE_DOCUMENT: true,
            ADD_TAGS: ['head'],
          }),
        }}
      />
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  label {
    display: block;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.85);
    padding: 0 0 12px;
  }

  > div {
    margin-top: 16px;
  }
`;

export default EmailPreviewContainer;
