import { Divider, Popover } from 'antd';
import moment from 'moment';
import * as R from 'ramda';
import { useState } from 'react';
import styled from 'styled-components';

import {
  RequirementIconByStatus,
  RequirementStatus,
  ratingColors,
} from '@modules/requirement/constants';

import { getIsNaicAutoGenerated } from '../../document/utils/document-helpers';
import { getRatingLevel } from '../utils/requirement-helpers';

import AMBestDetails from './AMBestDetails';
import DocumentSelector from './DocumentSelector';

const AMBestAlertPopoverContent = () => (
  <AMBestAlertPopoverContent.AMBestAlert>
    {RequirementIconByStatus[RequirementStatus.Invalidated]}
    <p>
      The AM Best requirement is insufficient and is preventing other
      requirements from being compliant
    </p>
  </AMBestAlertPopoverContent.AMBestAlert>
);

const CarriersDetails = ({
  documents,
  carrier,
  onSetSelectedDocument,
  selectedDocumentId,
  policyNumber,
  onSelectedDocumentClick,
  isAMBestAlertVisible,
  CarrierPolicyInfo,
}) => {
  const [isShowDetailsModal, setIsShowDetailsModal] = useState(false);
  const level = getRatingLevel(carrier?.rating);
  const carrierName =
    R.propOr('', 'extractedName', carrier) ||
    R.propOr('n/a', 'canonicalName', carrier);

  return (
    <CarriersDetails.Container>
      <CarriersDetails.DocumentContainer>
        <CarriersDetails.DocumentLabel>
          As found in document:
        </CarriersDetails.DocumentLabel>
        <CarriersDetails.DocumentName>
          <DocumentSelector
            documents={documents}
            onSelectedDocumentClick={onSelectedDocumentClick}
            selectedDocumentId={selectedDocumentId}
            onSetSelectedDocument={onSetSelectedDocument}
          />
        </CarriersDetails.DocumentName>
      </CarriersDetails.DocumentContainer>
      <CarriersDetails.Wrapper>
        <CarriersDetails.CarrierNameWrapper>
          <CarriersDetails.CategoryLabel>Carrier</CarriersDetails.CategoryLabel>
          <CarriersDetails.CarrierName>
            {carrierName}
          </CarriersDetails.CarrierName>
        </CarriersDetails.CarrierNameWrapper>
        <CarriersDetails.NaicWrapper>
          <CarriersDetails.CategoryLabel>NAIC</CarriersDetails.CategoryLabel>
          <CarriersDetails.NumberLabel>
            {getIsNaicAutoGenerated(carrier?.naic)
              ? 'n/a'
              : R.propOr('n/a', 'naic', carrier)}
          </CarriersDetails.NumberLabel>
        </CarriersDetails.NaicWrapper>
        <CarriersDetails.PolycyWrapper>
          <CarriersDetails.CategoryLabel>
            Policy Number
          </CarriersDetails.CategoryLabel>
          <StyledPolicyNumberWithStatus>
            <p>{policyNumber ? policyNumber : 'n/a'}</p>
            <StyledCarrierInfo>{CarrierPolicyInfo}</StyledCarrierInfo>
          </StyledPolicyNumberWithStatus>
        </CarriersDetails.PolycyWrapper>
        <CarriersDetails.RatingWrapper>
          <CarriersDetails.CategoryLabel>
            {`AM Best Rating ${
              carrier?.ratingEffectiveDate
                ? '(' +
                  moment(carrier?.ratingEffectiveDate).format('MMM M, YYYY') +
                  ')'
                : ''
            }`}
          </CarriersDetails.CategoryLabel>
          <CarriersDetails.RatingValueWrapper>
            <CarriersDetails.Rating
              borderColor={ratingColors[level]?.borderColor}
              backgroundColor={ratingColors[level]?.backgroundColor}
              onClick={() => {
                setIsShowDetailsModal(true);
              }}
            >
              <CarriersDetails.RatingText
                textColor={ratingColors[level]?.textColor}
              >
                {`${R.propOr('n/a', 'rating', carrier)}${
                  carrier?.ratingModifier ? ' ' + carrier?.ratingModifier : ''
                }${
                  carrier?.financialSizeCategory
                    ? ' ' + carrier?.financialSizeCategory
                    : ''
                }`}
              </CarriersDetails.RatingText>
            </CarriersDetails.Rating>
            {isAMBestAlertVisible && (
              <Popover
                content={<AMBestAlertPopoverContent />}
                title="AM Best rating is non-compliant"
                trigger="hover"
              >
                <CarriersDetails.RatingAlertIcon>
                  {RequirementIconByStatus[RequirementStatus.Invalidated]}
                </CarriersDetails.RatingAlertIcon>
              </Popover>
            )}
          </CarriersDetails.RatingValueWrapper>
        </CarriersDetails.RatingWrapper>
      </CarriersDetails.Wrapper>
      <Divider />
      <AMBestDetails
        carrier={carrier}
        isShowDetailsModal={isShowDetailsModal}
        setIsShowDetailsModa={setIsShowDetailsModal}
      />
    </CarriersDetails.Container>
  );
};

const StyledCarrierInfo = styled.div`
  width: 16px;
  height: 16px;
`;

CarriersDetails.Container = styled.div`
  .ant-divider {
    margin: 16px 0;
  }
`;

CarriersDetails.Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  column-gap: 7px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  overflow: hidden;
  padding: 7px 24px;
  margin: 0 -24px;
  border: 1px solid #fff;
  border-left: none;
  border-right: none;
  background: #fff;
  transition: all 0.3s;

  &:hover {
    background: #f4f8fd;
    border-color: hsla(213deg, 69%, 95%, 1);
  }
`;
CarriersDetails.CategoryLabel = styled.span`
  font-size: 12px;
  line-height: 20px;
  color: #697b8d;
  white-space: nowrap;
  overflow: hidden;
`;

CarriersDetails.CarrierNameWrapper = styled.span`
  display: grid;
`;

CarriersDetails.CarrierName = styled.span`
  font-weight: 600;
  line-height: 22px;
  color: #262626;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

CarriersDetails.NaicWrapper = styled.span`
  display: grid;
  min-width: 50px;
`;

const StyledPolicyNumberWithStatus = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;

  > p {
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.85);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
    padding: 0;
  }
`;

CarriersDetails.NumberLabel = styled.span`
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.85);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

CarriersDetails.PolycyWrapper = styled.span`
  min-width: 100px;
  max-width: 180px;
  display: grid;
`;

CarriersDetails.RatingWrapper = styled.span`
  display: grid;
`;

CarriersDetails.RatingText = styled.span`
  font-size: 14px;
  line-height: 22px;
  color: ${(props) => props.textColor};
`;

CarriersDetails.RatingValueWrapper = styled.div`
  display: flex;
  align-items: center;
`;

CarriersDetails.RatingAlertIcon = styled.div`
  margin-left: 12px;
`;

CarriersDetails.Rating = styled.div`
  padding: 0 8px;
  border: 1px solid ${(props) => props.borderColor};
  background-color: ${(props) => props.backgroundColor};
  justify-self: start;
  cursor: pointer;
`;

CarriersDetails.DocumentLabel = styled.span`
  line-height: 22px;
  color: #697b8d;
`;

CarriersDetails.DocumentContainer = styled.span`
  display: flex;
  font-size: 12px;
  padding-bottom: 7px;
`;

CarriersDetails.DocumentName = styled.span`
  padding-left: 10px;
`;

AMBestAlertPopoverContent.AMBestAlert = styled.div`
  display: flex;
  align-items: center;
  max-width: 450px;
  background: ${({ theme }) => theme.colors.lightRedTooltipBg};
  padding: 12px 17px;

  p {
    margin: 0;
    padding: 0 0 0 17px;
  }
`;

export default CarriersDetails;
