import { Radio } from 'antd';
import styled from 'styled-components';

import MenuBarIcon from '@common/icons/MenuBarIcon';
import MenuLinesIcon from '@common/icons/MenuLinesIcon';
import { ListView } from '@modules/system-settings/constants';

const ViewSwitcher = ({ view, onChange }) => (
  <StyledRadioGroup
    onChange={onChange}
    value={view}
    optionType="button"
    buttonStyle="solid"
    options={[
      {
        label: (
          <span data-cy="cardViewButton">
            <MenuBarIcon />
          </span>
        ),
        value: ListView.Card,
      },
      {
        label: (
          <span data-cy="listViewButton">
            <MenuLinesIcon />
          </span>
        ),
        value: ListView.Table,
      },
    ]}
  />
);

const StyledRadioGroup = styled(Radio.Group)`
  .ant-radio-button-wrapper span:not(.ant-radio-button) > span {
    line-height: 35px;
  }
`;

export default ViewSwitcher;
