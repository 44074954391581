import { useQuery } from '@graphql/hooks';
import { graphql } from '@graphql/types';

const COMPLIANCE_PROFILE_QUERY = graphql(`
   query SendRequestDrawer_ComplianceProfileQuery($id: ObjectId!) {
    getComplianceProfile(id: $id) {
      _id
      name
      baseComplianceProfile
    }
  }`);

export const useComplianceProfileQuery = ({
  complianceProfileId,
}: {
  complianceProfileId?: string;
}) => {
  const { loading, data } = useQuery(COMPLIANCE_PROFILE_QUERY, {
    skip: !complianceProfileId,
    variables: {
      id: complianceProfileId,
    },
  });

  return {
    isComplianceProfileLoading: loading,
    complianceProfile: data?.getComplianceProfile,
  };
};
