export const PARTY_COMMENT_PLACEHOLDER_VALUES = {
  mentioningUserName: 'mentioningUserName',
  mentionedUserName: 'mentionedUserName',
  message: 'message',
} as const;

export const partyCommentPlaceholders = {
  title: 'Party comment',
  placeholders: [
    {
      name: ' Mentioning user name',
      region: `{{ ${PARTY_COMMENT_PLACEHOLDER_VALUES.mentioningUserName} }}`,
    },
    {
      name: 'Mentioned user name',
      region: `{{ ${PARTY_COMMENT_PLACEHOLDER_VALUES.mentionedUserName} }}`,
    },
    {
      name: 'Message',
      region: `{{{ ${PARTY_COMMENT_PLACEHOLDER_VALUES.message} }}}`,
    },
  ],
};
