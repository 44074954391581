import MessagesControlPanel from '../components/MessagesControlPanel';

const MessagesControlPanelContainer = ({
  isMessages,
  isEmptyConversations,
  setIsMessages,
  setIsVisibleContactModal,
  setIsVisibleConversationModal,
}) => {
  const addClickHandle = () =>
    isMessages
      ? setIsVisibleConversationModal(true)
      : setIsVisibleContactModal(true);

  return (
    <MessagesControlPanel
      isMessages={isMessages}
      isEmptyConversations={isEmptyConversations}
      setIsMessages={setIsMessages}
      addClickHandle={addClickHandle}
    />
  );
};

export default MessagesControlPanelContainer;
