export const getTemplateInviteBrokerMessage = (
  partyName: string,
  userName: string,
) => `
  <p>I received a COI for your client, ${partyName}, and would like to validate the policy. TrustLayer offers a real-time validation platform called Pulse that makes this simple, but it does not appear this policy has been included yet.</p>
  <p>I would encourage you to click <a href="https://trustlayer.io/pages/pulse-invite-broker" target="_blank">here<a> to learn more and get setup so I and other businesses can easily validate policy information, eliminating manual requests.</p>
  <p><br></p>
  <p>Thank you</p>
  <p><br></p>
  <p>- ${userName}</p>
`;
