import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import Spinner from '@common/components/Spinner';
import { getGraphqlPayload } from '@store/helpers';

import { fetchConnectedRequirements } from '../../actions';

import ConnectedRequirementDetails from './ConnectedRequirementDetails';

const ConnectedRequirementsDetails = ({
  partyId,
  projectId,
  subjectId,
  onSelectedDocumentClick,
}) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [connectedRequirementsData, setConnectedRequirementsData] = useState(
    [],
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies: Legacy
  useEffect(() => {
    const getBySubjectId = async (subjectId) => {
      const res = await dispatch(
        fetchConnectedRequirements({
          filter: {
            partyId,
            projectId,
            subjectId,
          },
          options: {
            first: 12,
            offset: 0,
            sort: { createdAt: 1 },
          },
          documentsOptions: {
            sort: { createdAt: -1 },
          },
        }),
      );

      if (res.error) {
        throw Error(res.error);
      }

      const payload = getGraphqlPayload(res);
      return payload.nodes;
    };

    const fetchData = async () => {
      setIsLoading(true);

      const res = await getBySubjectId(subjectId).catch(() => []);
      setConnectedRequirementsData(res);
      setIsLoading(false);
    };

    fetchData();
  }, []);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      {connectedRequirementsData.map((req) => (
        <ConnectedRequirementDetails
          key={req._id}
          documents={req?.documents}
          onSelectedDocumentClick={onSelectedDocumentClick}
        />
      ))}
    </>
  );
};

export default ConnectedRequirementsDetails;
