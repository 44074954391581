import { Alert, Checkbox, Form, Input, Modal } from 'antd';
import * as R from 'ramda';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import Spinner from '@common/components/Spinner';

import { createProject, fetchProjectById } from '../actions';
import { ProjectField } from '../constants';
import { getProjectById } from '../selectors';
import { normalizeProjectToServer } from '../utils/project-helpers';

const DesiredAttributeByField = {
  [ProjectField.Description]: { label: 'Copy description', defaultValue: '' },
  [ProjectField.StartDate]: { label: 'Copy start date', defaultValue: '' },
  [ProjectField.EndDate]: { label: 'Copy end date', defaultValue: '' },
  [ProjectField.CustomFields]: {
    label: 'Copy custom fields',
    defaultValue: [],
  },
  [ProjectField.Parties]: {
    label: 'Copy associated parties',
    defaultValue: [],
  },
  [ProjectField.Attachments]: { label: 'Copy attachments', defaultValue: [] },
};

const ProjectDuplicateModal = ({ projectId, visible, onSuccess, onCancel }) => {
  const [form] = Form.useForm();
  const [fieldsToOmit, setFieldsToOmit] = useState([]);
  const [loading, setLoading] = useState(false);
  const [duplicating, setDuplicating] = useState(false);

  const originProject = useSelector((state) =>
    getProjectById(state, projectId),
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (visible && projectId) {
      (async () => {
        setLoading(true);

        await dispatch(fetchProjectById(projectId));

        setLoading(false);
      })();
    }
  }, [dispatch, projectId, visible]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: Legacy
  useEffect(() => {
    if (!visible) {
      form.resetFields();
      setFieldsToOmit([]);
    }
  }, [visible]);

  const onCopyProject = async () => {
    try {
      const values = await form.validateFields();
      // Get default value from enum and pick them.
      const defaultFields = R.compose(
        R.pick(fieldsToOmit),
        R.map((attribute) => attribute.defaultValue),
      )(DesiredAttributeByField);

      const project = R.compose(
        R.mergeLeft({ ...values, ...defaultFields }),
        normalizeProjectToServer,
      )(originProject);

      setDuplicating(true);

      await dispatch(createProject(project));

      setDuplicating(false);
      onSuccess();
    } catch {}
  };

  return (
    <Modal
      title={`Enter a name for the copy of "${R.prop('name', originProject)}"`}
      open={visible}
      okButtonProps={{ loading: duplicating }}
      onOk={() => onCopyProject()}
      onCancel={onCancel}
    >
      {loading ? (
        <Spinner />
      ) : (
        <div>
          <Form form={form}>
            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: 'Please input name',
                },
              ]}
            >
              <Input placeholder="Enter a name for project..." />
            </Form.Item>
            <h4>Desired attributes</h4>
            {R.compose(
              R.values,
              R.mapObjIndexed(({ label }, key) => {
                const checked = !fieldsToOmit.includes(key);

                return (
                  <ProjectDuplicateModal.CheckboxWrapper key={key}>
                    <Checkbox
                      checked={checked}
                      onClick={() =>
                        setFieldsToOmit(
                          checked
                            ? R.append(key, fieldsToOmit)
                            : R.without([key], fieldsToOmit),
                        )
                      }
                    >
                      {label}
                    </Checkbox>
                  </ProjectDuplicateModal.CheckboxWrapper>
                );
              }),
            )(DesiredAttributeByField)}
          </Form>
          <ProjectDuplicateModal.Alert
            type="info"
            showIcon
            message={
              <span className="alert-title">
                Project specific compliance profiles will not be copied, you can
                edit the compliance profile for each party after the project is
                duplicated
              </span>
            }
          />
        </div>
      )}
    </Modal>
  );
};

ProjectDuplicateModal.CheckboxWrapper = styled.div`
  margin: 5px 0;
`;

ProjectDuplicateModal.Alert = styled(Alert)`
  margin-top: 10px;
  padding: 8px 15px 8px 23px;
  border: none;
  background-color: transparent;
  font-size: 14px;

  .alert-title {
    color: #bfbfbf;
  }

  .ant-alert-icon {
    left: 0;
    font-size: 14px;
    color: #bfbfbf;
  }
`;

export default ProjectDuplicateModal;
