import {
  COMPLIANCE_PROFILE_PLACEHOLDER_VALUES,
  CONVERSATION_PLACEHOLDER_VALUES,
  DOCUMENTS_PLACEHOLDER_VALUES,
  DOCUMENT_REQUEST_PLACEHOLDER_VALUES,
  ORGANIZATION_PLACEHOLDER_VALUES,
  PARTY_COMMENT_PLACEHOLDER_VALUES,
  PARTY_PLACEHOLDER_VALUES,
  PROJECT_PLACEHOLDER_VALUES,
  USER_PLACEHOLDER_VALUES,
  complianceProfilePlaceholders,
  conversationPlaceholders,
  documentRequestPlaceholders,
  documentsPlaceholders,
  organizationPlaceholders,
  partyCommentPlaceholders,
  partyPlaceholders,
  projectPlaceholders,
  userPlaceholders,
} from '@common/constants/placeholders';

export const EMAIL_TEMPLATE_TYPES = {
  OrganizationMemberInvite: 'OrganizationMemberInvite',
  MentionInPartyComments: 'MentionInPartyComments',
  NewPartyComplianceRequest: 'NewPartyComplianceRequest',
  PartyComplianceRequestReminder: 'PartyComplianceRequestReminder',
  ExpiringDocumentUpdateRequest: 'ExpiringDocumentUpdateRequest',
  ContactResponseConversation: 'ContactResponseConversation',
  UploadDocument: 'UploadDocument',
  UploadMultipleDocuments: 'UploadMultipleDocuments',
  PartyCompliant: 'PartyCompliant',
  Custom: 'Custom',
};

export type EmailTemplateType =
  (typeof EMAIL_TEMPLATE_TYPES)[keyof typeof EMAIL_TEMPLATE_TYPES];

export const emailOrganizationPlaceholders = {
  ...organizationPlaceholders,
  getIsDisabled: () => false,
};

export const emailPartyPlaceholders = {
  ...partyPlaceholders,
  getIsDisabled: (type: EmailTemplateType) =>
    type === EMAIL_TEMPLATE_TYPES.OrganizationMemberInvite,
};

export const emailPartyCommentPlaceholders = {
  ...partyCommentPlaceholders,
  getIsDisabled: (type: EmailTemplateType) =>
    type !== EMAIL_TEMPLATE_TYPES.MentionInPartyComments,
};

export const emailProjectPlaceholders = {
  ...projectPlaceholders,
  getIsDisabled: (type: EmailTemplateType) =>
    type !== EMAIL_TEMPLATE_TYPES.PartyCompliant,
};

export const emailConversationPlaceholders = {
  ...conversationPlaceholders,
  getIsDisabled: (type: EmailTemplateType) =>
    type !== EMAIL_TEMPLATE_TYPES.Custom
      ? ![
          EMAIL_TEMPLATE_TYPES.ExpiringDocumentUpdateRequest,
          EMAIL_TEMPLATE_TYPES.ContactResponseConversation,
        ].includes(type)
      : false,
};

export const emailComplianceProfilePlaceholders = {
  ...complianceProfilePlaceholders,
  getIsDisabled: (type: EmailTemplateType) =>
    type === EMAIL_TEMPLATE_TYPES.OrganizationMemberInvite,
};

export const emailDocumentRequestPlaceholders = {
  ...documentRequestPlaceholders,
  getIsDisabled: (type: EmailTemplateType) =>
    type === EMAIL_TEMPLATE_TYPES.OrganizationMemberInvite,
};

export const emailDocumentsPlaceholders = {
  ...documentsPlaceholders,
  getIsDisabled: (type: EmailTemplateType) =>
    type !== EMAIL_TEMPLATE_TYPES.UploadMultipleDocuments,
};

export const emailUserPlaceholders = {
  ...userPlaceholders,
  getIsDisabled: (type: EmailTemplateType) =>
    type !== EMAIL_TEMPLATE_TYPES.Custom
      ? [
          EMAIL_TEMPLATE_TYPES.ExpiringDocumentUpdateRequest,
          EMAIL_TEMPLATE_TYPES.ContactResponseConversation,
          EMAIL_TEMPLATE_TYPES.UploadDocument,
          EMAIL_TEMPLATE_TYPES.UploadMultipleDocuments,
          EMAIL_TEMPLATE_TYPES.PartyCompliant,
        ].includes(type)
      : false,
};

type Placeholder = {
  name: string;
  region: string;
};

export type PlaceholderGroup = {
  title: string;
  placeholders: Placeholder[];
  getIsDisabled: (type: EmailTemplateType) => boolean;
};

export const getPlaceholders = ({
  isComplianceRequirementsAddOnEnabled,
  isContextsAddOnEnabled,
}: {
  isComplianceRequirementsAddOnEnabled: boolean;
  isContextsAddOnEnabled: boolean;
}): PlaceholderGroup[] => {
  const placeholders = [
    emailOrganizationPlaceholders,
    emailPartyPlaceholders,
    emailPartyCommentPlaceholders,
    emailConversationPlaceholders,
    emailDocumentRequestPlaceholders,
    emailDocumentsPlaceholders,
    emailUserPlaceholders,
  ];

  if (isComplianceRequirementsAddOnEnabled) {
    placeholders.push(emailComplianceProfilePlaceholders);
  }

  if (isContextsAddOnEnabled) {
    placeholders.push(emailProjectPlaceholders);
  }

  return placeholders;
};

export const PLACEHOLDER_VALUES = {
  ...ORGANIZATION_PLACEHOLDER_VALUES,
  ...PARTY_PLACEHOLDER_VALUES,
  ...PARTY_COMMENT_PLACEHOLDER_VALUES,
  ...CONVERSATION_PLACEHOLDER_VALUES,
  ...COMPLIANCE_PROFILE_PLACEHOLDER_VALUES,
  ...DOCUMENT_REQUEST_PLACEHOLDER_VALUES,
  ...DOCUMENTS_PLACEHOLDER_VALUES,
  ...USER_PLACEHOLDER_VALUES,
  ...PROJECT_PLACEHOLDER_VALUES,
};
