import { InfoCircleOutlined } from '@ant-design/icons';
import { List, Popover } from 'antd';
import styled from 'styled-components';

const MatchedAttributes = ({
  loading,
  matchingDocumentsCount,
  matchedDocuments,
  redirectToReviewPage,
  setIsVisible,
}) => (
  <span>
    {matchingDocumentsCount > 0 && (
      <Popover
        placement="bottom"
        trigger="click"
        onOpenChange={(isVisible) => setIsVisible(isVisible)}
        content={
          <MatchedAttributes.ListWrapper>
            <List
              loading={loading}
              data-cy="documentsNames"
              size="small"
              pagination={{
                pageSize: 12,
                simple: true,
                hideOnSinglePage: true,
              }}
              header={<span>Data found in the following documents:</span>}
              footer={null}
              dataSource={matchedDocuments || []}
              renderItem={(item) => (
                <List.Item>
                  <ins
                    key={item._id}
                    onClick={() => redirectToReviewPage(item._id)}
                  >
                    {item.friendlyName}
                  </ins>
                </List.Item>
              )}
            />
          </MatchedAttributes.ListWrapper>
        }
      >
        <MatchedAttributes.InfoCircleOutlined
          data-cy="satisfiedAttributeIcon"
          color="#697B8D"
          size="14px"
        />
      </Popover>
    )}
  </span>
);

MatchedAttributes.InfoCircleOutlined = styled(InfoCircleOutlined)`
  && {
    margin-left: 10px;
    position: relative;
    top: 1px;
    color: ${(props) => props.color};
    font-size: ${(props) => props.size};
    cursor: pointer;
  }
`;

MatchedAttributes.ListWrapper = styled.div`
  font-size: 12px;

  .ant-list-split .ant-list-header {
    border-bottom: 0;
    padding: 5px 0;
  }

  .ant-list-sm .ant-list-item {
    padding: 3px 0;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.65);

    ins {
      cursor: pointer;
    }
  }

  .ant-list-split .ant-list-item {
    border-bottom: 0 !important;
  }
`;

export default MatchedAttributes;
