import { Modal } from 'antd';
import * as R from 'ramda';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { getPartyConversations } from '@modules/party-conversation/selectors';
import { fetchPartyWithDetails } from '@modules/party/actions';
import { getParty } from '@modules/party/selectors';
import { getMyId } from '@modules/user/selectors';

import { trackEvent } from '@common/utils/track-helpers';
import * as PartyContactActions from '../actions';
import PartyContactCardList from '../components/PartyContactCardList';
import PartyCreateContactModal from '../components/PartyCreateContactModal';

const PartyContactCardListContainer = ({
  searchValue,
  isVisibleContactModal,
  setSelectedRecipientId,
  setIsVisibleConversationModal,
  setIsVisibleContactModal,
  setIsMessages,
  setSelectedPartyConversationId,
}) => {
  const { partyId } = useParams();

  const [contactToEdit, setContactToEdit] = useState(null);
  const partyData = useSelector((state) => getParty(state, partyId));
  const partyConversations = useSelector((state) =>
    getPartyConversations(state, partyId),
  );
  const userId = useSelector(getMyId);

  const partyContacts = partyData?.partyContacts || [];
  const defaultRequestRecipients = partyData?.defaultRequestRecipients || [];

  const dispatch = useDispatch();

  useEffect(() => {
    setIsVisibleContactModal(Boolean(contactToEdit));
  }, [contactToEdit, setIsVisibleContactModal]);

  const createContact = async (data) => {
    await dispatch(PartyContactActions.createContact(data));
    await dispatch(fetchPartyWithDetails(partyId));
    trackEvent('User added a contact to a party');
  };

  const updateContact = async (data) => {
    await dispatch(PartyContactActions.updateContact(data));
    await dispatch(fetchPartyWithDetails(partyId));
    trackEvent('User updated a contact on a party');
  };

  const setContactAsPrimary = (contactId) => {
    Modal.destroyAll();
    Modal.confirm({
      title: `Are you sure you want to mark this contact as primary?`,
      onOk: () => {
        dispatch(
          PartyContactActions.updateContact({
            id: contactId,
            isPrimary: true,
          }),
        );
      },
    });
  };

  const deleteContact = (contactId) => {
    Modal.destroyAll();
    Modal.confirm({
      title: 'Are you sure you want to delete this contact?',
      okButtonProps: {
        type: 'danger',
      },
      okText: 'Delete contact',
      okType: 'danger',
      onOk: () => {
        dispatch(
          PartyContactActions.deleteContact({
            id: contactId,
          }),
        );
        trackEvent('User removed a contact from a party');
      },
    });
  };

  const transformUsersToIds = (conversation) =>
    R.compose(
      R.map((user) => R.prop('_id', user)),
      R.propOr([], 'usersParticipants'),
    )(conversation);

  const isEqualsConversation = (conversation, contactId) =>
    R.equals([contactId], conversation.participants) &&
    R.equals([userId], conversation.usersParticipants);

  const sendContactMessage = (contactId) => {
    const conversationToSelect = partyConversations
      .map((conversation) => ({
        ...conversation,
        usersParticipants: transformUsersToIds(conversation),
      }))
      .find((conversation) => isEqualsConversation(conversation, contactId));

    if (conversationToSelect) {
      setSelectedPartyConversationId(R.prop('_id', conversationToSelect));
      setIsMessages(true);
    } else {
      setSelectedRecipientId(contactId);
      setIsVisibleConversationModal(true);
    }
  };

  return (
    <>
      <PartyCreateContactModal
        visible={isVisibleContactModal}
        onCancel={() => {
          setIsVisibleContactModal(false);
          setContactToEdit(null);
        }}
        onCreateContact={createContact}
        onUpdateContact={updateContact}
        partyId={partyId}
        partyName={partyData?.name}
        contact={contactToEdit}
        defaultRequestRecipients={defaultRequestRecipients}
      />
      <PartyContactCardList
        partyContacts={partyContacts.filter(
          (contact) =>
            contact.contactPersonName?.indexOf(searchValue) + 1 ||
            contact.title?.indexOf(searchValue) + 1 ||
            contact.email?.indexOf(searchValue) + 1,
        )}
        setContactToEdit={setContactToEdit}
        deleteContact={deleteContact}
        setContactAsPrimary={setContactAsPrimary}
        sendContactMessage={sendContactMessage}
      />
    </>
  );
};

export default PartyContactCardListContainer;
