import { Badge, Tooltip } from 'antd';
import moment from 'moment';
import * as R from 'ramda';
import styled from 'styled-components';

import { PartyConversationType } from '../constants';

import FilePaperIcon from './Icons/FilePaperIcon';
import MessageStatusIcon from './MessageStatusIcon';

const ConversationTypeIconMap = {
  [PartyConversationType.DocumentRequest]: <FilePaperIcon />,
  [PartyConversationType.Conversation]: null,
};

const PartyConversation = ({
  partyConversation,
  participants,
  lastMessageText,
  isReadLastMessage,
  lastMessageStatus,
  lastMessageDate,
  isActive,
}) => {
  const typeIcon = ConversationTypeIconMap[R.prop('type', partyConversation)];

  return (
    <PartyConversation.Wrapper isActive={isActive}>
      {typeIcon && (
        <PartyConversation.IconWrapper>
          {typeIcon}
        </PartyConversation.IconWrapper>
      )}
      <PartyConversation.Details>
        <PartyConversation.Header>
          <PartyConversation.Subject>
            <PartyConversation.SubjectContent>
              {R.prop('subject', partyConversation)}
            </PartyConversation.SubjectContent>
            {!isReadLastMessage && (
              <PartyConversation.Badge status="processing" />
            )}
          </PartyConversation.Subject>
          <PartyConversation.CreatedDate>
            <PartyConversation.IconStatusWrapper>
              <MessageStatusIcon type={lastMessageStatus} />
            </PartyConversation.IconStatusWrapper>
            {lastMessageDate && moment(lastMessageDate).format('ll')}
          </PartyConversation.CreatedDate>
        </PartyConversation.Header>
        <PartyConversation.Content>
          <Tooltip title={participants} placement="bottomLeft">
            <p>{participants}</p>
          </Tooltip>
          <i>{lastMessageText}</i>
        </PartyConversation.Content>
      </PartyConversation.Details>
    </PartyConversation.Wrapper>
  );
};

PartyConversation.Wrapper = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  padding: 10px 10px 10px 42px;
  display: flex;
  border-bottom: 1px solid #e6eaef;
  background-color: ${(props) =>
    props.isActive ? '#f4f8fd' : props.theme.colors.white};

  &&::after {
    position: absolute;
    top: 0;
    right: 0;
    content: ' ';
    height: 100%;
    border-right: ${(props) =>
      props.isActive ? `3px solid ${props.theme.colors.blue}` : 'none'};
  }

  &:hover {
    background-color: #f4f8fd;
  }
`;

PartyConversation.IconWrapper = styled.div`
  position: absolute;
  left: 7.5px;
  top: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 27px;
  height: 27px;
  border-radius: 50%;
  background-color: ${(props) => props.theme.colors.white};
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.1);
`;

PartyConversation.IconStatusWrapper = styled.span`
  margin-right: 5px;
  color: ${(props) => props.theme.colors.blue};

  i {
    margin-right: 0 !important;
  }
`;

PartyConversation.Details = styled.div`
  width: 100%;
`;

PartyConversation.Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

PartyConversation.Subject = styled.div`
  display: flex;
  width: 65%;
  font-weight: 500;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
`;

PartyConversation.SubjectContent = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

PartyConversation.Badge = styled(Badge)`
  margin-left: 5px;
`;

PartyConversation.CreatedDate = styled.div`
  font-weight: normal;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.65);
  margin-left: auto;
`;

PartyConversation.Content = styled.div`
  margin-top: 5px;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: rgba(0, 0, 0, 0.65);

  p {
    margin-bottom: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  i {
    color: rgba(0, 0, 0, 0.35);
  }
`;

export default PartyConversation;
