import { CoffeeOutlined, QuestionOutlined } from '@ant-design/icons';
import { Popover, Progress, Skeleton, Tooltip } from 'antd';
import { forwardRef } from 'react';
import styled from 'styled-components';

import BaseComplianceProfileIcon from '@modules/compliance-profile/components/BaseComplianceProfileIcon';
import PartyComplianceIndicatorPopoverContent from '../PartyComplianceIndicatorPopoverContent';
import { useLazyFetchComplianceProfileQuery } from './hooks';

const PartyComplianceIndicator = ({
  party,
  size = 40,
  strokeWidth = 9,
  fontSize = 10,
  popoverPlacement = 'top',
  hasLinks = false,
  popoverTrigger = 'click',
  fetchRequirements,
}) => {
  const partyComplianceProfile = party?.partyComplianceProfile;
  const isTrackingCompliance = party?.isTrackingCompliance;
  const complianceStats = partyComplianceProfile?.complianceStats || {};

  const {
    fetchComplianceProfile,
    data: complianceProfile,
    loading: loadingComplianceProfile,
  } = useLazyFetchComplianceProfileQuery();

  const {
    complianceScore,
    nonCompliantRequirementsCount,
    compliantSubjectsCount,
    totalSubjectsCount,
    waivedRequirementsCount,
    overriddenRequirementsCount,
  } = complianceStats;

  const isFullyCompliant =
    complianceScore === 100 &&
    waivedRequirementsCount === 0 &&
    overriddenRequirementsCount === 0;

  if (!isTrackingCompliance) {
    return (
      <StyledProgressWithForwardRef
        format={() => (
          <Tooltip title="Compliance tracking disabled">
            <CoffeeOutlined />
          </Tooltip>
        )}
        percent={0}
        size="small"
        type="circle"
        strokeWidth={strokeWidth}
        width={size}
        status="active"
      />
    );
  }

  if (!partyComplianceProfile) {
    return (
      <StyledProgressWithForwardRef
        format={() => (
          <Tooltip title="No compliance profile">
            <QuestionOutlined />
          </Tooltip>
        )}
        percent={0}
        size="small"
        type="circle"
        strokeWidth={strokeWidth}
        width={size}
        status="active"
      />
    );
  }

  return (
    <Popover
      placement={popoverPlacement}
      onOpenChange={(visible) =>
        visible &&
        partyComplianceProfile?.complianceProfile &&
        fetchComplianceProfile(partyComplianceProfile?.complianceProfile)
      }
      trigger={popoverTrigger}
      title={
        <StyledTitle>
          {loadingComplianceProfile ? (
            <Skeleton active paragraph={false} />
          ) : (
            <>
              {complianceProfile?.baseComplianceProfile && (
                <>
                  <BaseComplianceProfileIcon />{' '}
                </>
              )}
              {complianceProfile?.name}
            </>
          )}
        </StyledTitle>
      }
      content={
        <PartyComplianceIndicatorPopoverContent
          hasLinks={hasLinks}
          party={party}
          fetchRequirements={fetchRequirements}
        />
      }
    >
      <StyledProgressWithForwardRef
        format={() => (
          <StyledProgressFormat
            data-cy="partyComplianceIndicatorText"
            fontSize={fontSize}
            $fullycompliant={Number(isFullyCompliant)}
          >
            {compliantSubjectsCount}/{totalSubjectsCount}
          </StyledProgressFormat>
        )}
        percent={(compliantSubjectsCount / totalSubjectsCount) * 100}
        size="small"
        type="circle"
        strokeWidth={strokeWidth}
        width={size}
        $fullycompliant={Number(isFullyCompliant)}
        status={
          complianceScore === 100 || nonCompliantRequirementsCount === 0
            ? 'success'
            : 'active'
        }
      />
    </Popover>
  );
};

const StyledTitle = styled.div`
  font-size: 13px;
  color: rgba(0, 0, 0, 0.85);
`;

// do note use it directly, use the StyledProgressWithForwardRef instead
const StyledProgress = styled(Progress)`
  margin-right: 12px !important;
  background-color: ${(props) =>
    props['$fullycompliant']
      ? props.theme.colors.lightGreenTooltipBg
      : 'transparent'} !important;
  border-radius: 50%;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

const StyledProgressWithForwardRef = forwardRef((props, ref) => {
  return (
    <div ref={ref}>
      <StyledProgress {...props} />
    </div>
  );
});

const StyledProgressFormat = styled.div`
  font-size: ${(props) => props.fontSize}px;
  color: ${(props) =>
    props['$fullycompliant'] ? '#52C41B' : 'rgba(0, 0, 0, 0.65)'};
`;

export default PartyComplianceIndicator;
