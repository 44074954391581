import { gql } from '@apollo/client';

import { namespace } from '../constants';

export const RENDER_EMAIL_TEMPLATE = `${namespace}/RENDER_EMAIL_TEMPLATE`;

const RENDER_EMAIL_TEMPLATE_MUTATION = gql`
  query renderEmailTemplate(
    $partyId: ObjectId!
    $template: CustomTemplateInput!
  ) {
    renderEmailTemplate(partyId: $partyId, template: $template) {
      body
      subject
    }
  }
`;

export const renderEmailTemplate = (payload) => ({
  type: RENDER_EMAIL_TEMPLATE,
  payload: {
    key: 'renderEmailTemplate',
    graphql: {
      fetchPolicy: 'no-cache',
      query: RENDER_EMAIL_TEMPLATE_MUTATION,
      variables: payload,
    },
  },
});
