import { useCallback, useEffect, useState } from 'react';

import { NOTE_EVENTS } from './events';
import type { Note } from './types';

export const useNoteModal = () => {
  const [currentNote, setCurrentNote] = useState<Note | null>(null);
  const isVisible = !!currentNote;
  const onCloseModal = useCallback(() => setCurrentNote(null), []);

  useEffect(() => {
    const listener = (e: CustomEvent<Note>) => {
      setCurrentNote(e.detail);
    };

    // @ts-expect-error
    document.addEventListener(NOTE_EVENTS.EDIT_NOTE, listener);

    return () => {
      // @ts-expect-error
      document.removeEventListener(NOTE_EVENTS.EDIT_NOTE, listener);
    };
  }, []);

  return {
    isVisible,
    currentNote,
    onCloseModal,
  };
};

export const dispatchNoteModalEvent = (note: Note) => {
  document.dispatchEvent(
    new CustomEvent(NOTE_EVENTS.EDIT_NOTE, {
      detail: note,
    }),
  );
};
