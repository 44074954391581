import { useLazyQuery } from '@graphql/hooks';
import { graphql } from '@graphql/types';

export const COMPLIANCE_PROFILE_QUERY = graphql(`
  query PartyComplianceIndicator_ComplianceProfile($id: ObjectId!) {
    getComplianceProfile(id: $id) {
      _id
      name
      baseComplianceProfile
    }
  }
  `);

export const useLazyFetchComplianceProfileQuery = (id: string) => {
  const [fetchComplianceProfile, { data, loading }] = useLazyQuery(
    COMPLIANCE_PROFILE_QUERY,
  );

  return {
    fetchComplianceProfile: (id: string) =>
      fetchComplianceProfile({ variables: { id } }),
    data: data?.getComplianceProfile,
    loading,
  };
};
