import useMemberRole from '../hooks/useMemberRole';
import { ROLE_VIEWER } from '../models/data';

const hideForRoles =
  (roles = []) =>
  (Comp) =>
  (props) => {
    const role = useMemberRole();

    if (roles.includes(role)) {
      return null;
    }

    return <Comp {...props} />;
  };

export const hideForViewerRole = hideForRoles([ROLE_VIEWER]);

export default hideForRoles;
