import { graphql } from '@graphql/types';

export const AUTOCOMPLETE_PARTY_TAGS = graphql(`
  query AutoCompletePartyTags($filters: JSON) {
    listPartyTags(filters: $filters) {
      _id
      name
      color
    }
  }
`);
