import Icon, { FileSearchOutlined } from '@ant-design/icons';
import { Button, Col, Row, Tooltip } from 'antd';
import * as R from 'ramda';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import Box from '@common/components/Box';
import PaperPlane from '@common/icons/PaperPlane';
import { partyHasDocuments } from '@common/utils/document-helpers';
import { HideForViewerRole } from '@modules/organization-member/containers/HideForRoles';
import DocumentUploader from '@modules/request/components/DocumentUploader';
import CollapsePanelContainer from '@modules/requirement/containers/CollapsePanelContainer';

import {
  NoContactInformationLabel,
  isPrimaryContactEmailEmpty,
} from '../constants';

import ComplianceSummary, { BoxType } from './ComplianceSummary';

const TabComplianceContent = ({
  organizationNamespace,
  currentProjectId,
  party,
  partyComplianceProfile,
  requests,
  requirements,
  documents,
  selectedDocuments,
  setCurrentRequirements,
  setCurrentComplianceProfile,
  setIsShowUploadModal,
  setUploadFiles,
  setRejectedFiles,
  setIsReminderSendRequest,
  setIsShowSendRequestModal,
  setConversationModalData,
  isPartyComplianceProfileLoading,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isShowProfileDrawer, setIsShowProfileDrawer] = useState(false);
  const [isVisibleTooltip, setIsVisibleTooltip] = useState(false);
  const primaryContactInvalid = isPrimaryContactEmailEmpty(party);

  const hasDocuments = partyHasDocuments(party);

  return (
    <div>
      <Row gutter={24}>
        <Col span={16}>
          <ComplianceSummary
            organizationNamespace={organizationNamespace}
            party={party}
            partyComplianceProfile={partyComplianceProfile}
            requirements={requirements}
            type={BoxType.Compliance}
            setSelectedComplianceProfile={setCurrentComplianceProfile}
            setCurrentRequirements={setCurrentRequirements}
            isShowProfileDrawer={isShowProfileDrawer}
            setIsShowProfileDrawer={setIsShowProfileDrawer}
            isPartyComplianceProfileLoading={isPartyComplianceProfileLoading}
          />
          <StyledSubjectWrapper>
            <StyledCollapsePanelWrapper>
              <CollapsePanelContainer
                documents={documents}
                organizationNamespace={organizationNamespace}
                party={party}
                projectId={currentProjectId}
                requirements={requirements}
                selectedDocuments={selectedDocuments}
                setConversationModalData={setConversationModalData}
                waivedSubjects={party?.subjects}
              />
            </StyledCollapsePanelWrapper>
          </StyledSubjectWrapper>
        </Col>
        <Col span={8}>
          <StyledColumnWrapper>
            <StyledDocumentsBox>
              <HideForViewerRole>
                <div onClick={() => setIsShowUploadModal(true)}>
                  <DocumentUploader
                    onUpload={(acceptedFiles, rejectedFiles) => {
                      if (!R.isEmpty(requirements)) {
                        setUploadFiles(acceptedFiles);
                        setRejectedFiles(rejectedFiles);
                        setIsShowUploadModal(true);
                      }
                    }}
                    disableClick={true}
                  />
                </div>
              </HideForViewerRole>
              <StyledDocumentBoxButtons>
                <HideForViewerRole>
                  <StyledTooltip
                    placement="top"
                    title={NoContactInformationLabel}
                    open={isVisibleTooltip}
                    onOpenChange={() =>
                      setIsVisibleTooltip(
                        primaryContactInvalid ? !isVisibleTooltip : false,
                      )
                    }
                  >
                    <StyledButton
                      type="default"
                      disabled={
                        !partyComplianceProfile || primaryContactInvalid
                      }
                      onClick={() => {
                        setIsReminderSendRequest(requests?.length > 0);
                        setIsShowSendRequestModal(true);
                      }}
                    >
                      <Icon component={PaperPlane} />
                      {requests?.length > 0
                        ? 'Send reminder'
                        : 'Send document request'}
                    </StyledButton>
                  </StyledTooltip>
                </HideForViewerRole>
                <Button
                  type="default"
                  icon={<FileSearchOutlined />}
                  disabled={!hasDocuments}
                  onClick={() =>
                    navigate(
                      `${organizationNamespace}/parties/${party?._id}/documents-review`,
                      { state: { prevLocation: location.pathname } },
                    )
                  }
                >
                  Review documents
                </Button>
              </StyledDocumentBoxButtons>
            </StyledDocumentsBox>
          </StyledColumnWrapper>
        </Col>
      </Row>
    </div>
  );
};

const StyledCollapsePanelWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const StyledSubjectWrapper = styled.div`
  width: 100%;
  margin: 30px 0;
  display: flex;
`;

const StyledColumnWrapper = styled.div`
  margin-left: auto;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const StyledDocumentsBox = styled(Box)`
  display: flex;
  flex-direction: column;

  label {
    margin-bottom: 15px;
    font-weight: 600;
  }
`;

const StyledDocumentBoxButtons = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;

  button {
    margin-top: 10px;
  }
`;

const StyledTooltip = styled(Tooltip)`
  margin-top: 10px;
`;

const StyledButton = styled(Button)`
  width: 100%;
  margin-top: 0 !important;
`;

export default TabComplianceContent;
