import {
  ArrowLeftOutlined,
  EditOutlined,
  EnvironmentOutlined,
  FileSearchOutlined,
  PoweroffOutlined,
  ReloadOutlined,
  UploadOutlined,
  UserOutlined,
} from '@ant-design/icons';
import {
  Button,
  Col,
  Modal,
  Popover,
  Row,
  Tooltip,
  Typography,
  message as notify,
} from 'antd';
import * as R from 'ramda';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import FlagBadgePopover from '@common/components/Badges/components/FlagBadgePopover';
import { getSeverityLevelByOrder } from '@common/components/Badges/utils/badges-helpers';
import VerticalSeparator from '@common/components/VerticalSeparator';
import PaperPlane from '@common/icons/PaperPlane';
import { partyHasDocuments } from '@common/utils/document-helpers';
import { useMutation } from '@graphql/hooks/';
import useMutationWithRedux from '@graphql/hooks/useMutationWithRedux';
import { getOperationName } from '@graphql/utils';
import { getNearestHighestPriorityDocument } from '@modules/document/utils/document-helpers';
import ConnectivityBadge from '@modules/integration/components/ConnectivityBadge';
import { HideForViewerRole } from '@modules/organization-member/containers/HideForRoles';
import PartyContactCardPopover from '@modules/party-contact/components/PartyContactCardPopover';
import PartyTag from '@modules/party-tag/components/PartyTag.legacy';
import ExpirationDatePopover from '@modules/party-tag/components/PartyTag.legacy/ExpirationDatePopover';
import AutoCompletePartyTag from '@modules/party-tag/containers/AutoCompletePartyTag';
import {
  ADD_TAGGINGS_TO_PARTIES_MUTATION,
  CREATE_PARTY_TAG_MUTATION,
  DELETE_TAGGINGS_FROM_PARTIES_MUTATION,
} from '@modules/party-tag/mutations';
import { getCurrentProject } from '@modules/project/selectors';
import {
  ADD_TAGGINGS_TO_PARTIES,
  DELETE_TAGGINGS_FROM_PARTIES,
} from '@modules/tagging/actions';

import { trackEvent } from '@common/utils/track-helpers';
import {
  NoContactInformationLabel,
  isPrimaryContactEmailEmpty,
} from '../constants';

const { Paragraph } = Typography;

const notEmpty = (item) => item && !R.isEmpty(item);

export const PartyAddress = ({
  rawAddress,
  line1,
  line2,
  city,
  region,
  country,
}) => {
  const firstLine = [line1, line2].filter(notEmpty).join(', ');
  const secondLine = R.join(', ')([city, region, country].filter(notEmpty));

  return !R.isEmpty(firstLine) ||
    !R.isEmpty(secondLine) ||
    !R.isEmpty(rawAddress) ? (
    <div>
      <div>{firstLine}</div>
      <div>{secondLine || rawAddress}</div>
    </div>
  ) : null;
};

const containerConfig = {
  xs: { span: 24 },
};

const confirm = Modal.confirm;

const ModalContent = styled.div`
  li {
    list-style-type: initial;
  }
`;

const PartyHeaderSection = ({
  organizationNamespace,
  party,
  updateParty,
  partyType,
  onSendRequest,
  onSendReminder,
  isSendReminder,
  onDocumentUpload,
}) => {
  const navigate = useNavigate();
  const [createPartyTag] = useMutation(CREATE_PARTY_TAG_MUTATION);
  const [addTaggingsToParties] = useMutationWithRedux(
    ADD_TAGGINGS_TO_PARTIES_MUTATION,
    {
      reduxActionKey: getOperationName(ADD_TAGGINGS_TO_PARTIES_MUTATION),
      reduxActionType: ADD_TAGGINGS_TO_PARTIES,
    },
  );
  const [deleteTaggingsFromParties] = useMutationWithRedux(
    DELETE_TAGGINGS_FROM_PARTIES_MUTATION,
    {
      reduxActionKey: getOperationName(DELETE_TAGGINGS_FROM_PARTIES_MUTATION),
      reduxActionType: DELETE_TAGGINGS_FROM_PARTIES,
    },
  );

  const defaultPartiesUrl = `${organizationNamespace}/parties`;
  const currentProject = useSelector(getCurrentProject);

  const hasAddress =
    Object.values(party.address ?? {}).filter((el) => el).length > 0;

  const hasDocuments = partyHasDocuments(party);
  const hasComplianceProfile = Boolean(R.prop('partyComplianceProfile', party));
  const contactName = R.path(['primaryContact', 'contactPersonName'], party);
  const contactEmail = R.path(['primaryContact', 'email'], party);
  const contactPhone = R.path(['primaryContact', 'phone'], party);
  const hasContactInformation =
    [contactName, contactPhone, contactEmail].filter((item) => item).length > 0;
  const partyDocuments = R.flatten(
    R.append(
      R.propOr([], 'documents', party),
      R.propOr([], 'applicableDocuments', party),
    ),
  );
  const selectedDocument = getNearestHighestPriorityDocument(partyDocuments);
  const primaryContactInvalid = isPrimaryContactEmailEmpty(party);

  const taggings = party.taggings ?? [];
  const assignedTags = taggings.map((x) => x.partyTag);

  const isIncludedInCurrentProject =
    R.isEmpty(currentProject) ||
    R.compose(
      R.includes(party._id),
      R.map((x) => x._id),
      R.propOr([], 'parties'),
    )(currentProject);

  const createNewTag = async ({ name }) => {
    const res = await createPartyTag({ variables: { data: { name } } });
    // it might fails if tag already exist or tag contains special chars (not supported by api for now)
    if (res.errors) {
      return;
    }

    addTagToParty(res.data.createPartyTag);
  };

  const addTagToParty = async (tag) => {
    await addTaggingsToParties({
      variables: {
        data: {
          partyTagsIds: [tag._id],
          partiesIds: [party._id],
        },
      },
    });

    trackEvent('User added a tag to a party');
  };

  const makeInactiveHandle = () =>
    confirm({
      className: 'e2e-makePartyConfirmModal',
      title: `Mark ${party.name} as inactive?`,
      okText: 'Mark as inactive',
      width: 500,
      content: (
        <ModalContent>
          <Paragraph>
            Please confirm that you want to mark this party as inactive.
          </Paragraph>
          <ul>
            <li>It will be moved in the &quot;Inactive Parties&quot; table</li>
            <li>It will not show up in your reports and dashboard</li>
            <li>No automatic requests for expired documents will be sent</li>
            <li>
              All associated documents will be archived (
              {party.totalDocumentsCount})
            </li>
          </ul>
        </ModalContent>
      ),
      onOk() {
        updateParty({
          id: party._id,
          name: party.name,
          type: party.type._id,
          isActive: false,
        }).then(() => notify.success(`Party successfully marked as inactive`));
      },
      onCancel() {},
    });

  const makeActiveHandle = () =>
    confirm({
      className: 'e2e-makePartyConfirmModal',
      title: `Mark ${party.name} as active?`,
      okText: 'Mark as active',
      content: (
        <ModalContent>
          <Paragraph>
            Please confirm that you want to mark this party as active.
          </Paragraph>
          <ul>
            <li>It will be moved in the &quot;Active Parties&quot; table</li>
            <li>It will show up in your reports and dashboard</li>
            <li>Automatic requests for expired documents will be sent</li>
          </ul>
        </ModalContent>
      ),
      onOk() {
        updateParty({
          id: party._id,
          name: party.name,
          type: party.type._id,
          isActive: true,
        }).then(() => notify.success(`Party successfully marked as active`));
      },
      onCancel() {},
    });

  const reviewButton = (
    <Tooltip title="Review documents">
      <Button
        shape="circle"
        icon={<FileSearchOutlined />}
        disabled={!hasDocuments}
      />
    </Tooltip>
  );

  return (
    <StyledWrapper>
      {!isIncludedInCurrentProject && (
        <StyledIncludedInCurrentProject>
          <span data-cy="notIncludedMessage">
            This party is not included in the currently selected project
          </span>
        </StyledIncludedInCurrentProject>
      )}
      {!party.isActive && (
        <StyledInactive data-cy="inactiveHeader">
          <span>This party is inactive</span>
        </StyledInactive>
      )}
      <Row style={{ padding: '0 20px' }}>
        <Col {...containerConfig}>
          <StyledHeadWrapper>
            <StyledArrowWrapper
              onClick={() => {
                const hasPreviousPage = window.history.length > 1;
                if (hasPreviousPage) return navigate(-1);
                navigate(defaultPartiesUrl);
              }}
            >
              <StyledArrowLeftOutlined />
            </StyledArrowWrapper>
            <VerticalSeparator />
            <StyledPartyName>
              <ConnectivityBadge entity={party} showTitle />
              {party.name}
            </StyledPartyName>
            <StyledFlag>
              <FlagBadgePopover
                party={R.prop('_id', party)}
                nearestFlagSeverity={getSeverityLevelByOrder(
                  R.prop('nearestFlagSeverityOrder', party),
                )}
                documentData={{
                  _id: R.propOr('', '_id', selectedDocument),
                  issuedBy: R.propOr({}, 'issuedBy', selectedDocument),
                  documentName: R.propOr(
                    null,
                    'friendlyName',
                    selectedDocument,
                  ),
                }}
                partyFlags={R.propOr([], 'partyFlags', party)}
                sizeIcon="Large"
                defaultVisible
              />
            </StyledFlag>

            <StyledActionsButtons>
              <HideForViewerRole>
                <Link to={`${defaultPartiesUrl}/${party._id}/edit`}>
                  <Tooltip title="Edit">
                    <Button
                      data-cy="editPartyButton"
                      shape="circle"
                      icon={<EditOutlined />}
                    />
                  </Tooltip>
                </Link>
                {party.isActive ? (
                  <Tooltip title="Mark inactive">
                    <Button
                      data-cy="markInactiveButton"
                      shape="circle"
                      icon={<PoweroffOutlined />}
                      onClick={makeInactiveHandle}
                    />
                  </Tooltip>
                ) : (
                  <Tooltip title="Mark active">
                    <Button
                      data-cy="markActiveButton"
                      shape="circle"
                      icon={<ReloadOutlined />}
                      onClick={makeActiveHandle}
                    />
                  </Tooltip>
                )}
                {isSendReminder ? (
                  <Tooltip
                    title={
                      primaryContactInvalid
                        ? NoContactInformationLabel
                        : 'Send reminder'
                    }
                  >
                    <Button
                      data-cy="sendReminderButton"
                      shape="circle"
                      onClick={onSendReminder}
                      disabled={primaryContactInvalid}
                    >
                      <PaperPlane />
                    </Button>
                  </Tooltip>
                ) : (
                  <Tooltip
                    title={
                      primaryContactInvalid
                        ? NoContactInformationLabel
                        : 'Send document request'
                    }
                  >
                    <Button
                      data-cy="sendDocumentRequest"
                      shape="circle"
                      onClick={onSendRequest}
                      disabled={!hasComplianceProfile || primaryContactInvalid}
                    >
                      <PaperPlane />
                    </Button>
                  </Tooltip>
                )}
              </HideForViewerRole>
              <Tooltip title="Upload document">
                <Button shape="circle" onClick={onDocumentUpload}>
                  <UploadOutlined />
                </Button>
              </Tooltip>
              {hasDocuments ? (
                <Link to={`${defaultPartiesUrl}/${party._id}/documents-review`}>
                  {reviewButton}
                </Link>
              ) : (
                reviewButton
              )}
            </StyledActionsButtons>
          </StyledHeadWrapper>
        </Col>
      </Row>
      <Row style={{ padding: '0 40px 0 82px' }}>
        <StyledPartyType>
          <span>{partyType?.name || ''}</span>
          {hasContactInformation && (
            <Popover
              trigger="click"
              placement="bottom"
              title="Primary contact information"
              content={
                <StyledPartyInfoWrapper>
                  <PartyContactCardPopover
                    partyContact={R.prop('primaryContact', party)}
                  />
                </StyledPartyInfoWrapper>
              }
            >
              <UserOutlined data-cy="partyContactIcon" />
            </Popover>
          )}
          {hasAddress && (
            <Popover
              title="Address"
              trigger="click"
              placement="bottom"
              content={<PartyAddress {...party.address} />}
            >
              <EnvironmentOutlined data-cy="partyAddressIcon" />
            </Popover>
          )}
        </StyledPartyType>
        <StyledTags data-cy="partyHeaderSectionTags">
          {assignedTags &&
            assignedTags.map((tag) => {
              const tagging = party.taggings.find(
                (tagging) => tagging.partyTag._id === tag?._id,
              );

              return (
                <ExpirationDatePopover
                  tagging={tagging}
                  key={`key_${tag?._id}`}
                >
                  <PartyTag
                    tag={tag}
                    expiresAt={tagging.expiresAt}
                    onClose={() => {
                      deleteTaggingsFromParties({
                        variables: {
                          data: {
                            partiesIds: [party?._id],
                            partyTags: [tag?._id],
                          },
                        },
                      }).then(() => {
                        trackEvent('User removed a tag from a party');
                      });
                    }}
                  />
                </ExpirationDatePopover>
              );
            })}
          <AutoCompletePartyTag
            onTagSelected={addTagToParty}
            onNewTagSelected={createNewTag}
          />
        </StyledTags>
      </Row>
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  background-color: white;
  padding: 16px 0 36px;
  border-bottom: 1px solid #e8e8e8;
`;

const StyledIncludedInCurrentProject = styled.div`
  margin: -20px 0 10px;
  padding: 5px;
  margin-bottom: 20px;
  text-align: center;
  font-size: 11px;
  font-weight: 500;
  text-transform: uppercase;
  border-bottom: 1px solid #eee;
  background-color: #fffbe3;
`;

const StyledInactive = styled.div`
  margin-top: -20px;
  padding: 10px;
  margin-bottom: 20px;
  text-align: center;
  font-size: 11px;
  font-weight: 500;
  text-transform: uppercase;
  border-bottom: 1px solid #eee;
  background: repeating-linear-gradient(
    45deg,
    #f1f1f1,
    #f1f1f1 10px,
    #fafafa 10px,
    #fafafa 20px
  );
`;

const StyledHeadWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 7px;
`;

const StyledArrowWrapper = styled.div`
  cursor: pointer;
  display: flex;
  text-decoration: none;
  color: ${(props) => props.theme.colors.black};

  &:hover {
    color: ${(props) => props.theme.colors.blue};
  }
`;

const StyledArrowLeftOutlined = styled(ArrowLeftOutlined)`
  font-size: 20px;
`;

const StyledPartyName = styled.h1`
  margin: 0;
  font-size: 23px;
  font-weight: 400;
`;

const StyledFlag = styled.div`
  margin-left: 15px;
`;

const StyledPartyType = styled.p`
  margin: 0 20px 8px 0;
  font-size: 13px;
  color: ${(props) => props.theme.colors.lightBlack};
  opacity: 0.85;

  > * {
    margin-right: 10px;
  }
`;

const StyledTags = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

const StyledActionsButtons = styled.div`
  margin-left: auto;
  display: flex;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & > a,
  & > button,
  & > span {
    margin: 0 5px;
  }
`;

const StyledPartyInfoWrapper = styled.div`
  font-size: 13px;
`;

export default PartyHeaderSection;
