import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getPartyConversationIssues } from '../actions';
import { getPartyConversationTotalIssuesCount } from '../selectors';

type PartyMessageIssue = {
  _id: string;
  email: string;
  messageId: string;
  partyConversationId: string;
  contactId: string;
  sendgridEventId: string;
  reason?: string;
  resendable: boolean;
  ignorable: boolean;
  resolved: boolean;
  organization: string;
};

type PartyMessage = {
  _id: string;
  request: string;
  sendgridMessageId: string;
  type: string;
  status: string;
  message: string;
  senderContactId: string;
  senderType: string;
  senderUser: {
    _id: string;
    email: string;
    profile: {
      name: string;
      avatar: string;
    };
  };
  recipients: string;
  outputHtml: string;
  sendgridEvents: {
    _id: string;
    status: string;
    date: string;
    reason: string;
  };
  attachments: {
    url: string;
    friendlyName: string;
    s3Key: string;
  };
  readAt: string;
  createdAt: string;
  updatedAt: string;
};

type MessagesMap = Record<string, PartyMessage>;

const DEFAULT_FIRST = 10;
const DEFAULT_FILTER_OPTIONS = {
  resolved: false,
};

const useMessagesIssues = (conversationId: string) => {
  const [issues, setIssues] = useState<PartyMessageIssue[]>([]);
  const [messagesMap, updateMessagesMap] = useState<MessagesMap>();
  const [areIssuesLoading, setAreIssuesLoading] = useState(false);
  const issuesCount = useSelector((state) =>
    getPartyConversationTotalIssuesCount(state, conversationId),
  );

  const dispatch = useDispatch();

  // biome-ignore lint/correctness/useExhaustiveDependencies: Legacy
  const loadMessageIssues = useCallback(
    async (filterOptions = DEFAULT_FILTER_OPTIONS) => {
      setAreIssuesLoading(true);
      try {
        const { payload } = await dispatch(
          getPartyConversationIssues({
            conversationId,
            options: {
              offset: 0,
              first: DEFAULT_FIRST,
              filter: filterOptions,
            },
          }),
        );
        // @ts-expect-error - payload doesn't infer the correct type
        if (payload?.data) {
          const {
            issues: issuesAPI,
            messages,
            // @ts-expect-error - payload doesn't infer the correct type
          } = payload.data?.partyConversation;

          setIssues(issuesAPI?.nodes || []);

          if (messages?.nodes) {
            const tempMessagesMap = messages.nodes.reduce(
              (currentMap: MessagesMap, message: PartyMessage) => ({
                ...currentMap,
                [message._id]: message,
              }),
              {},
            );

            updateMessagesMap(tempMessagesMap);
          }
        }
      } catch (err) {
        console.log('[useMessagesIssues] error: ', err);
      }

      setAreIssuesLoading(false);
    },
    [
      dispatch,
      setAreIssuesLoading,
      updateMessagesMap,
      conversationId,
      issuesCount,
    ],
  );

  const loadMoreMessageIssues = async (
    filterOptions = DEFAULT_FILTER_OPTIONS,
  ) => {
    setAreIssuesLoading(true);
    const { payload } = await dispatch(
      getPartyConversationIssues({
        conversationId,
        options: {
          offset: issues.length,
          first: DEFAULT_FIRST,
          filter: filterOptions,
        },
      }),
    );
    // @ts-expect-error - payload doesn't infer the correct type
    if (payload?.data) {
      // @ts-expect-error - payload doesn't infer the correct type
      const { issues: issuesAPI } = payload.data?.partyConversation;

      if (issuesAPI) {
        setIssues([...issues, ...issuesAPI.nodes]);
      }
    }

    setAreIssuesLoading(false);
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: Legacy
  useEffect(() => {
    loadMessageIssues();
  }, [conversationId, loadMessageIssues, issuesCount]);

  return {
    loadMessageIssues,
    loadMoreMessageIssues,
    issues,
    issuesCount,
    areIssuesLoading,
    messagesMap,
  };
};

export default useMessagesIssues;
