import { gql } from '@apollo/client';

import type { POLICY_STATUSES } from '@modules/document/constants';

export type PolicyStatuses =
  (typeof POLICY_STATUSES)[keyof typeof POLICY_STATUSES];
export type CheckPolicySubscriptionResponse = {
  status: PolicyStatuses;
};

export const CHECK_POLICY_SUBSCRIPTION = gql`
  subscription CheckPolicySubscription(
    $policyNumber: String!
    $insurerNAIC: String!
  ) {
    checkPolicy(policyNumber: $policyNumber, insurerNAIC: $insurerNAIC) {
      status
    }
  }
`;
