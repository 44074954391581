import { Dropdown } from 'antd';
import type { ReactElement } from 'react';
import styled from 'styled-components';

import { HideForViewerRole } from '@modules/organization-member/containers/HideForRoles';
import { SUBJECT_STATUS } from '@modules/party/constants';

type ActionMenuProps = {
  onMenuItemClicked: (item: string) => void;
  menuIcon: ReactElement | null;
  subjectStatus?: (typeof SUBJECT_STATUS)[keyof typeof SUBJECT_STATUS];
  isAutomaticOverriddenAcrossProjects: boolean;
  isAutomaticWaivedAcrossProjects: boolean;
};

export const MENU_OPTIONS = {
  WAIVE: 'waive',
  OVERRIDE: 'override',
  EDIT: 'edit',
  REMOVE: 'remove',
  REMOVE_AUTOMATIC_OVERRIDE: 'remove-automatic-override',
  REMOVE_AUTOMATIC_WAIVER: 'remove-automatic-waiver',
};

const getSubjectLabels = (
  status?: (typeof SUBJECT_STATUS)[keyof typeof SUBJECT_STATUS],
) => {
  if (status === SUBJECT_STATUS.WAIVED) {
    return {
      edit: 'Edit waiver',
      remove: 'Remove waiver',
    };
  }

  if (status === SUBJECT_STATUS.OVERRIDDEN) {
    return {
      edit: 'Edit override',
      remove: 'Remove override',
    };
  }
};

export const SubjectActionMenu = ({
  onMenuItemClicked,
  subjectStatus,
  isAutomaticOverriddenAcrossProjects,
  isAutomaticWaivedAcrossProjects,
  menuIcon = null,
}: ActionMenuProps) => {
  const labels = getSubjectLabels(subjectStatus);
  const subjectMenuItems = [
    {
      key: 'waive-subject',
      label: (
        <span
          key="waive-subject"
          data-cy="waiveSubjectBtn"
          onClick={() => onMenuItemClicked(MENU_OPTIONS.WAIVE)}
        >
          Waive subject
        </span>
      ),
    },
    {
      key: 'override-subject',
      label: (
        <span
          key="override-subject"
          data-cy="overrideSubjectBtn"
          onClick={() => onMenuItemClicked(MENU_OPTIONS.OVERRIDE)}
        >
          Override subject
        </span>
      ),
    },
  ];

  const subjectEditMenuItems = [
    {
      key: 'edit-status',
      label: (
        <span
          key="edit-status"
          onClick={() => onMenuItemClicked(MENU_OPTIONS.EDIT)}
        >
          {labels?.edit}
        </span>
      ),
    },

    {
      key: 'remove-status',
      label: (
        <span
          key="remove-status"
          onClick={() => onMenuItemClicked(MENU_OPTIONS.REMOVE)}
        >
          {labels?.remove}
        </span>
      ),
    },
  ];

  const items = !subjectStatus ? subjectMenuItems : subjectEditMenuItems;

  if (isAutomaticOverriddenAcrossProjects) {
    items.push({
      key: 'remove-automatic-override',
      label: (
        <span
          key="remove-automatic-override"
          onClick={() =>
            onMenuItemClicked(MENU_OPTIONS.REMOVE_AUTOMATIC_OVERRIDE)
          }
        >
          Remove automatic overridden placement
        </span>
      ),
    });
  }

  if (isAutomaticWaivedAcrossProjects) {
    items.push({
      key: 'remove-automatic-waiver',
      label: (
        <span
          key="remove-automatic-waiver"
          onClick={() =>
            onMenuItemClicked(MENU_OPTIONS.REMOVE_AUTOMATIC_WAIVER)
          }
        >
          Remove automatic waiver placement
        </span>
      ),
    });
  }

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <HideForViewerRole>
        <Dropdown
          data-cy="dropdownMenu"
          //@ts-expect-error - ant does not support synthetic event attached on the dropdown element
          onClick={(e) => e.stopPropagation()}
          trigger={['click']}
          menu={{
            items,
          }}
        >
          <StyledIconContainer data-cy="moreOptionsBtn">
            {menuIcon}
          </StyledIconContainer>
        </Dropdown>
      </HideForViewerRole>
    </div>
  );
};

const StyledIconContainer = styled.div`
  padding: 5px;
  position: relative;
  font-size: 16px;
  color: ${(props) => props.theme.colors.paleGray};
`;
