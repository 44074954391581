import { AlignLeftOutlined } from '@ant-design/icons';
import { Button, Input, Radio, Tooltip } from 'antd';
import styled from 'styled-components';

import Cascader from '@common/components/Cascader';
import CheckIcon from '@common/icons/CheckIcon';

import { displayRule } from '../utils/rule-helpers';

import CustomRequirement from './CustomRequirement';
import DefinedRequirement from './DefinedRequirement';

const { TextArea } = Input;

const AddRequirement = ({
  description,
  matchingCriteria,
  cascaderOptions,
  isShowForm,
  isCustom,
  isCustomSubject,
  isShowDescription,
  setDescription,
  setMatchingCriteria,
  selectedAttribute,
  selectedOperator,
  onChangeHandle,
  targetValue,
  searchFilter,
  setTargetValue,
  setSelectedAttribute,
  setIsShowDescription,
  setIsShowForm,
  setIsCustom,
  isActiveCreate,
  createRequirement,
  isValidRequirement,
  validateCustomRequirement,
  resetState,
}) =>
  isShowForm ? (
    <AddRequirement.Wrapper>
      <AddRequirement.InputsWrapper>
        {!isCustom && (
          <AddRequirement.CascaderWrapper>
            <Cascader
              data-cy="requirementCascader"
              expandTrigger="hover"
              options={cascaderOptions}
              value={[selectedAttribute.attributeId, selectedOperator]}
              onChange={onChangeHandle}
              displayRender={(labels) => displayRule(labels, targetValue)}
              showSearch={{ filter: searchFilter }}
            />
          </AddRequirement.CascaderWrapper>
        )}
        {isCustom ? (
          <CustomRequirement
            selectedAttribute={selectedAttribute}
            setSelectedAttribute={setSelectedAttribute}
            validateCustomRequirement={validateCustomRequirement}
            targetValue={targetValue}
            selectedOperator={selectedOperator}
            setTargetValue={setTargetValue}
          />
        ) : (
          <DefinedRequirement
            targetValue={targetValue}
            selectedOperator={selectedOperator}
            fieldType={selectedAttribute.attributeType}
            setTargetValue={setTargetValue}
          />
        )}
        <Tooltip title="Provide additional information for this requirement">
          <AddRequirement.FormButton
            data-cy="addDescriptionButton"
            type="default"
            icon={<AlignLeftOutlined />}
            onClick={() => setIsShowDescription(!isShowDescription)}
          />
        </Tooltip>
        <AddRequirement.FormButton
          data-cy="cancelAddRequirementButton"
          onClick={() => {
            setIsShowForm(false);
            resetState();
          }}
        >
          Cancel
        </AddRequirement.FormButton>
        <AddRequirement.FormButton
          data-cy="addRequirementButton"
          type="primary"
          disabled={!isActiveCreate() || !isValidRequirement}
          onClick={() => {
            if (isValidRequirement) {
              createRequirement();
            }
          }}
        >
          Add
        </AddRequirement.FormButton>
      </AddRequirement.InputsWrapper>
      {isShowDescription && (
        <>
          <AddRequirement.DescriptionWrapper>
            <TextArea
              data-cy="descriptionInput"
              rows={2}
              placeholder="Additional information..."
              value={description}
              autoSize={{ minRows: 2, maxRows: 8 }}
              onChange={({ target }) => setDescription(target.value)}
            />
          </AddRequirement.DescriptionWrapper>
          <AddRequirement.MatchingCriteria>
            <span>Matching criteria:</span>
            <Radio.Group
              defaultValue={matchingCriteria || 'matchOnce'}
              onChange={(e) => {
                setMatchingCriteria(e.target.value);
              }}
            >
              <Radio value="matchOnce">Match once</Radio>
              <Radio value="matchRepeatedly">Match repeatedly</Radio>
            </Radio.Group>
          </AddRequirement.MatchingCriteria>
        </>
      )}
    </AddRequirement.Wrapper>
  ) : (
    <div data-cy="addNewRequirementButton">
      <AddRequirement.CustomButton
        onClick={() => {
          setIsShowForm(true);
          setIsCustom(isCustomSubject);
        }}
      >
        <CheckIcon muted />
        <AddRequirement.CustomButtonTitle>
          Add new requirement...
        </AddRequirement.CustomButtonTitle>
      </AddRequirement.CustomButton>
    </div>
  );

AddRequirement.Wrapper = styled.div`
  margin: 5px 0 10px;
  padding: 10px;
  display: flex;
  flex-flow: column wrap;
  border: 1px solid #ffe58e;
  background: #fefbe6;
`;

AddRequirement.InputsWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;

  > * {
    margin: 0 9px 0 0 !important;
  }

  .ant-input-number {
    flex: 1;
  }
`;

AddRequirement.CascaderWrapper = styled.span`
  flex: 3;
  margin: 0;
`;

AddRequirement.Cascader = styled(Cascader)`
  && {
    width: 100%;
  }
`;

AddRequirement.FormButton = styled(Button)`
  margin-left: 10px;

  &:last-child {
    margin-right: 0 !important;
  }
`;

AddRequirement.AddNewRequirement = styled(Button)`
  margin-top: 5px;
  font-size: 13px;
`;

AddRequirement.DescriptionWrapper = styled.div`
  margin: 10px 0 0;
`;

AddRequirement.MatchingCriteria = styled.div`
  margin: 10px 0 0;

  .ant-radio-wrapper,
  > span {
    font-size: 13px;
  }

  > span {
    margin-right: 10px;
  }
`;

AddRequirement.CustomButton = styled.div`
  margin: 5px 0 10px;
  padding: 4px 3px 4px 10px;
  width: 100%;
  border: 1px dashed #cdcdcd;
  border-radius: 4px;
  opacity: 0.6;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
`;

AddRequirement.CustomButtonTitle = styled.span`
  margin-left: 10px;
  font-size: 13px;
`;

export default AddRequirement;
