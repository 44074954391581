const PulseIcon = ({ disabled }: { disabled?: boolean }) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-cy={`pulseIcon-${disabled ? 'disabled' : 'enabled'}`}
  >
    <path
      opacity=".3"
      d="M2.667 1.84A1.84 1.84 0 0 1 4.505 0h9.655C15.177 0 16 .823 16 1.84v9.654a1.839 1.839 0 0 1-1.84 1.84H4.507a1.84 1.84 0 0 1-1.84-1.84V1.84Z"
      fill={disabled ? '#D9D9D9' : '#1C66DC'}
    />
    <path
      d="M.368 4.738a1.84 1.84 0 0 1 1.434-2.17l9.46-1.93a1.84 1.84 0 0 1 2.17 1.435l1.93 9.46a1.839 1.839 0 0 1-1.435 2.17l-9.46 1.93a1.84 1.84 0 0 1-2.17-1.435l-1.93-9.46Z"
      fill={disabled ? '#D9D9D9' : '#1C66DC'}
    />
    <path
      d="m1.877 2.935 9.46-1.93a1.464 1.464 0 0 1 1.728 1.143l1.93 9.46a1.464 1.464 0 0 1-1.143 1.727l-9.46 1.93a1.464 1.464 0 0 1-1.727-1.142l-1.93-9.46a1.464 1.464 0 0 1 1.142-1.728Z"
      stroke="url(#gradient)"
      strokeWidth=".75"
      style={{ mixBlendMode: 'soft-light' }}
      opacity=".75"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.224 4.516a.684.684 0 0 1 .716.36l2.251 4.329.338-1.256c.067-.25.27-.44.523-.493l1.787-.364a.684.684 0 0 1 .273 1.34l-1.372.28-.684 2.545a.684.684 0 0 1-1.267.138L6.683 7.346l-.285 1.799a.684.684 0 0 1-.539.562l-1.787.365a.684.684 0 1 1-.273-1.34l1.322-.27.537-3.378a.684.684 0 0 1 .566-.568Z"
      fill={disabled ? '#8C8C8C' : '#FFFFFF'}
    />
    <defs>
      <linearGradient
        id="gradient"
        x1="6.532"
        y1="1.603"
        x2="9.197"
        y2="14.668"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff" />
        <stop offset="1" stopColor="#fff" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
);

export default PulseIcon;
