import * as R from 'ramda';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useNavigate, useParams } from 'react-router-dom';

import * as PartyConversationActions from '@modules/party-conversation/actions';
import {
  getPartyConversationById,
  getPartyConversationsByParty,
} from '@modules/party-conversation/selectors';
import { getNearestConversations } from '@modules/party-conversation/utils/party-conversation-helpers';
import { PARTY_DETAILS_MESSAGES_ROUTE } from '@modules/router/utils';

import MessagesTab from '../components/MessagesTab';
import { getParty, getPartyContactsByIds } from '../selectors';

const MessagesTabContainer = ({
  setIsVisibleConversationModal,
  setSelectedRecipientId,
}) => {
  const currentURLParams = useParams();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [isMessages, setIsMessages] = useState(false);
  const [isVisibleContactModal, setIsVisibleContactModal] = useState(false);
  const [selectedPartyConversationId, setSelectedPartyConversationId] =
    useState(currentURLParams.conversationId);

  const partyConversationData = useSelector((state) =>
    getPartyConversationById(state, selectedPartyConversationId),
  );
  const partyConversations = useSelector((state) =>
    getPartyConversationsByParty(state, currentURLParams.partyId),
  );

  const partyConversationsCount = partyConversations.length;
  const conversationParticipantsData = useSelector((state) =>
    getPartyContactsByIds(
      state,
      currentURLParams.partyId,
      R.propOr([], 'participants', partyConversationData),
    ),
  );
  const partyData = useSelector((state) =>
    getParty(state, currentURLParams.partyId),
  );

  const dispatch = useDispatch();
  const partyContacts = R.propOr([], 'partyContacts', partyData);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      await dispatch(
        PartyConversationActions.fetchPartyConversations(
          { partyId: currentURLParams.partyId },
          {
            filter: {
              resolved: false,
            },
          },
        ),
      );

      setIsLoading(false);
    };
    fetchData();
  }, [dispatch, currentURLParams.partyId]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: Legacy
  useEffect(() => {
    if (selectedPartyConversationId) {
      const params = {
        ...currentURLParams,
        conversationId: selectedPartyConversationId,
      };

      const pathname = generatePath(PARTY_DETAILS_MESSAGES_ROUTE, params);
      navigate(pathname, { replace: true });
    }
  }, [selectedPartyConversationId, navigate]);

  useEffect(() => {
    if (partyConversationsCount > 0) {
      setIsMessages(true);
    } else {
      setIsMessages(false);
    }
  }, [partyConversationsCount]);

  useEffect(() => {
    const hasConversationsAndNoOnesSelected =
      partyConversationsCount > 0 && !selectedPartyConversationId;

    if (hasConversationsAndNoOnesSelected) {
      const nearestConversation = R.compose(
        R.head,
        getNearestConversations,
      )(partyConversations);
      setSelectedPartyConversationId(R.prop('_id', nearestConversation));
    }
  }, [
    partyConversationsCount,
    selectedPartyConversationId,
    partyConversations,
  ]);

  return (
    <MessagesTab
      isLoading={isLoading}
      isVisibleContactModal={isVisibleContactModal}
      setIsVisibleContactModal={setIsVisibleContactModal}
      setIsVisibleConversationModal={setIsVisibleConversationModal}
      selectedPartyConversationId={selectedPartyConversationId}
      setSelectedPartyConversationId={setSelectedPartyConversationId}
      setSelectedRecipientId={setSelectedRecipientId}
      searchValue={searchValue}
      setSearchValue={setSearchValue}
      isMessages={isMessages}
      setIsMessages={setIsMessages}
      isEmptyConversations={!Boolean(partyConversationsCount)}
      isEmptyContacts={
        !Boolean(partyContacts.length) ||
        !partyContacts.some((partyContact) => R.prop('email', partyContact))
      }
      conversationParticipantsData={conversationParticipantsData}
      selectedConversationData={partyConversationData}
    />
  );
};

export default MessagesTabContainer;
