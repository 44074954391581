import { EditOutlined, PushpinOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import DOMPurify from 'dompurify';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import Box from '@common/components/Box';
import BoxTitle from '@common/components/BoxTitle';
import RichTextEditor from '@common/components/RichTextEditor';
import {
  getEditorHTMLContent,
  getEmptyEditorState,
  getUpdatedEditorState,
  isEditorStateBlank,
} from '@common/components/RichTextEditor/helpers';
import { HideForViewerRole } from '@modules/organization-member/containers/HideForRoles';

const AdditionalNoteBox = ({ party, updateAdditionalNotes }) => {
  const [isEdit, setIsEdit] = useState(false);
  const [noteEditorState, setNoteEditorState] = useState(getEmptyEditorState());

  useEffect(() => {
    setNoteEditorState(getUpdatedEditorState(party?.additionalNotes ?? ''));
  }, [party?.additionalNotes]);

  const onSaveNote = () => {
    updateAdditionalNotes({
      partyId: party._id,
      additionalNotes: getEditorHTMLContent(noteEditorState),
    });
    setIsEdit(false);
  };

  return (
    <StyledWrapper>
      <BoxTitle icon={<PushpinOutlined />} label="Additional notes">
        {!isEdit && (
          <HideForViewerRole>
            <StyledButton
              size="small"
              icon={<EditOutlined />}
              onClick={() => setIsEdit(true)}
            >
              Edit
            </StyledButton>
          </HideForViewerRole>
        )}
        {isEdit && (
          <StyledButton size="small" onClick={onSaveNote}>
            Save
          </StyledButton>
        )}
      </BoxTitle>
      <StyledContent>
        {isEdit ? (
          <RichTextEditor
            isValid
            minHeight={50}
            placeholder="Enter additional notes..."
            editorState={noteEditorState}
            onChange={setNoteEditorState}
          />
        ) : (
          !isEditorStateBlank(noteEditorState) && (
            <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(
                  getEditorHTMLContent(noteEditorState),
                ),
              }}
            />
          )
        )}
      </StyledContent>
    </StyledWrapper>
  );
};

const StyledWrapper = styled(Box)`
  margin-bottom: 30px;

  p {
    margin-bottom: 0;
  }

  br {
    margin-bottom: 1em;
  }

  ul,
  ol,
  dl {
    padding-left: 20px;
    margin: 10px 0;
  }

  h1,
  h2,
  h3 {
    margin: 10px 0;
  }

  h1 {
    font-size: 18px;
  }

  h2 {
    font-size: 16px;
  }

  h3 {
    font-size: 14px;
  }
`;

const StyledContent = styled.div`
  padding-left: 26px;

  &:empty {
    margin-top: -12px;
  }
`;

const StyledButton = styled(Button)`
  margin-left: auto;
  font-size: 12px !important;
`;

export default AdditionalNoteBox;
