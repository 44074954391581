import { REVIEW_STATUS } from '@modules/document/constants';
import { Checkbox } from 'antd';

type ReviewStatusFilterProps = {
  onChange: (value: string[]) => void;
  value: string[];
};

export const ReviewStatusFilter = ({
  onChange,
  value: rawValue = [],
}: ReviewStatusFilterProps) => {
  const value = Array.isArray(rawValue)
    ? rawValue.flatMap((item) => (Array.isArray(item) ? item : [item]))
    : [rawValue];

  return (
    <Checkbox.Group
      value={value}
      onChange={(values) => {
        // typecast to string[] because can't Checkbox do not infer the type of the value
        onChange(values as string[]);
      }}
    >
      <div>
        <Checkbox value={REVIEW_STATUS.Reviewed}>Reviewed</Checkbox>
      </div>
      <div>
        <Checkbox value={REVIEW_STATUS.ToReview}>To review</Checkbox>
      </div>
    </Checkbox.Group>
  );
};
