import type { Tag } from '.';

export const getPartyTagsWithNewTag = (partyTags: Tag[], newTag?: Tag) => {
  const isExistingTag = partyTags?.some(
    (partyTag) => partyTag.name === newTag?.name,
  );
  if (!newTag || isExistingTag) return partyTags;
  return [newTag, ...partyTags];
};

export const getExistingPartyTagByName = (
  tagName: string,
  partyTags: Tag[],
) => {
  return partyTags?.find(({ name }) => {
    return tagName?.toLowerCase() === name.toLowerCase();
  });
};
