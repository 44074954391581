import { Divider } from 'antd';
import { useState } from 'react';
import styled from 'styled-components';

import { ConnectedLicenseType } from '@modules/document/constants';

import DocumentSelector from '../DocumentSelector';

import NPNConnectedLicenseSummary from './NPNConnectedLicenseSummary';

const ConnectedRequirementDetails = ({
  documents = [],
  onSelectedDocumentClick,
}) => {
  const [selectedDocument, setSelectedDocument] = useState(documents[0]);

  const renderLicense = (document) => {
    const { connectedLicense } = document;

    if (connectedLicense?.type === ConnectedLicenseType.NPN) {
      return <NPNConnectedLicenseSummary license={connectedLicense} />;
    }

    return null;
  };

  if (documents.length === 0) {
    return null;
  }

  return (
    <StyledWrapper>
      <StyledDocumentSelectorWrapper>
        <StyledDocumentSelectorLabel>
          As found in document:
        </StyledDocumentSelectorLabel>
        <DocumentSelector
          documents={documents}
          selectedDocumentId={selectedDocument?._id}
          onSetSelectedDocument={(documentId) => {
            setSelectedDocument(
              documents.find(({ _id }) => _id === documentId),
            );
          }}
          onSelectedDocumentClick={onSelectedDocumentClick}
        />
      </StyledDocumentSelectorWrapper>
      {selectedDocument && renderLicense(selectedDocument)}
      <StyledDivider />
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  padding: 8px;
`;

const StyledDivider = styled(Divider)`
  margin: 16px 0 0;
`;

const StyledDocumentSelectorWrapper = styled.div`
  display: flex;
  margin-bottom: 8px;
`;

const StyledDocumentSelectorLabel = styled.span`
  margin-right: 4px;
  font-size: 12px;
  color: ${(props) => props.theme.colors.lightTextGray};
  line-height: 22px;
`;

export default ConnectedRequirementDetails;
