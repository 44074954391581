import { gql } from '@apollo/client';

import { namespace } from '../constants';

export const BULK_UPDATE_PROJECTS_ACTIVE_STATUS = `${namespace}/BULK_UPDATE_PROJECTS_ACTIVE_STATUS`;

const BULK_UPDATE_PROJECTS_ACTIVE_STATUS_MUTATION = gql`
  mutation bulkUpdateProjectsActiveStatus(
    $payload: BulkUpdateProjectsActiveStatusInput
  ) {
    bulkUpdateProjectsActiveStatus(data: $payload) {
      scheduled
    }
  }
`;

export const bulkUpdateProjectsActiveStatus = (payload) => ({
  type: BULK_UPDATE_PROJECTS_ACTIVE_STATUS,
  payload: {
    key: 'bulkUpdateProjectsActiveStatus',
    graphql: {
      mutation: BULK_UPDATE_PROJECTS_ACTIVE_STATUS_MUTATION,
      variables: {
        payload,
      },
    },
  },
});
