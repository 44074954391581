import { Modal, Switch } from 'antd';
import styled from 'styled-components';

import { DisableForViewerRole } from '@modules/organization-member/containers/DisableForRoles';
import { useAppDispatch } from '@store/hooks';

import { toggleComplianceTracking } from '../actions';

type ComplianceTrackingSwitchProps = {
  partyId?: string;
  isTrackingCompliance: boolean;
};

export const ComplianceTrackingSwitch = ({
  partyId,
  isTrackingCompliance,
}: ComplianceTrackingSwitchProps) => {
  const { confirm } = Modal;
  const dispatch = useAppDispatch();

  const onToggleChangeConfirm = (value: boolean) => {
    dispatch(
      toggleComplianceTracking({
        id: partyId,
        isTrackingCompliance: value,
      }),
    );
  };

  return (
    <DisableForViewerRole>
      <StyledWrapper>
        <Switch
          data-cy={`partyDetailsComplianceTrackingButton_${isTrackingCompliance.toString()}`}
          checked={isTrackingCompliance}
          size="small"
          onChange={(checked) => {
            if (checked === false) {
              confirm({
                title:
                  'Are you sure you want to disable compliance tracking for this party?',
                content:
                  'All existing requirements will be removed and the party will not be associated with any compliance profile.',
                okText: 'Disable compliance tracking',
                onOk() {
                  onToggleChangeConfirm(checked);
                },
              });
            } else {
              onToggleChangeConfirm(checked);
            }
          }}
        />
        <StyledLabel>Compliance tracking</StyledLabel>
      </StyledWrapper>
    </DisableForViewerRole>
  );
};

const StyledWrapper = styled.div`
  padding: 0 10px;
  margin-top: 15px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
`;

const StyledLabel = styled.span`
  margin-left: 10px;
  color: ${(props) => props.theme.colors.grayishBlue};
  font-size: 14px;
`;
