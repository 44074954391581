import { Table } from 'antd';
import React from 'react';
import styled from 'styled-components';

const StyledTable = styled(Table)`
  .ant-table {
    thead {
      font-size: 13px;

      > tr > th,
      > tr > td {
        padding: 10px;
        white-space: nowrap !important;

        &.ant-table-column-sort {
          background-color: ${(props) => props.theme.colors.lightBlue};
        }

        &.ant-table-column-has-actions.ant-table-column-has-sorters:hover {
          background-color: ${(props) => props.theme.colors.lightBlue};
        }

        .ant-table-header-column .ant-table-column-sorters:hover::before {
          background: transparent;
        }
      }
    }

    .ant-table-tbody {
      td.ant-table-row-expand-icon-cell > button {
        margin-left: 20px;
      }

      td {
        transition: 0s;

        &.ant-table-column-sort {
          background: transparent;
        }
      }

      .ant-table-row {
        td {
          padding: 10px;
          vertical-align: middle !important;
        }

        &.ant-table-row-hover,
        &:hover {
          td {
            background-color: ${(props) => props.theme.colors.lightBlue} !important;
          }
        }

        &.ant-table-row-selected {
          td {
            background: #f9fbfe;
          }
        }
      }
    }

    .ant-table-placeholder {
      border-bottom: none;
    }

    .ant-table-title {
      padding: 0 20px 15px;
    }

    .ant-table-row > td p {
      margin: 0;
    }
  }

  .ant-table-pagination.ant-pagination {
    margin: 15px;
    display: flex;
    align-items: center;
  }
`;

const CustomTable = React.forwardRef<
  React.ElementRef<typeof StyledTable>,
  React.ComponentPropsWithoutRef<typeof StyledTable>
>(({ loading, ...props }, ref) => (
  // @ts-expect-error - Augmenting with the loading prop for Cypress testing
  <StyledTable
    ref={ref}
    {...{
      ...props,
      loading:
        loading && typeof loading === 'object'
          ? {
              ...loading,
              'data-cy': 'loadingSpinner',
            }
          : loading,
    }}
  />
)) as unknown as typeof StyledTable;

CustomTable.displayName = 'Table';

export default CustomTable;
