import { useNewBackgroundTableColorsFeatureFlag } from '@modules/feature-flags/hooks';
import { Row as AntRow, Col } from 'antd';
import styled from 'styled-components';

export const ColLeft = styled(Col)`
  margin: 0 20px 0 0;
  display: flex;
  align-items: center;
`;

export const ColRight = styled(Col)`
  display: flex;

  > button,
  > div {
    margin: 10px 0 10px 10px;

    &:first-child {
      margin-left: 0;
    }
  }
`;

export const Row = ({ children, ...props }) => {
  const { isNewBackgroundTableColorsFeatureFlagEnabled } =
    useNewBackgroundTableColorsFeatureFlag();
  return (
    <StyledAntRow
      $hasNewBgColor={isNewBackgroundTableColorsFeatureFlagEnabled}
      {...props}
    >
      {children}
    </StyledAntRow>
  );
};

const StyledAntRow = styled(AntRow)`
  padding: 8px 0;
  display: flex;
  position: sticky;
  top: 0;
  margin: 0 -2px;
  background-color: #fff;
  ${({ theme, $hasNewBgColor }) =>
    $hasNewBgColor
      ? `
        background-color: ${theme.colors.white};
      `
      : `
        border-bottom: 1px solid rgba(196, 214, 236, 0.5);
        background-color: rgb(244, 248, 253);
      `}
  transition: width 2s;
  z-index: 1;
  justify-content: space-between;
`;
