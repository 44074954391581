import { CaretDownOutlined, SearchOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import moment from 'moment';
import * as R from 'ramda';
import React from 'react';
import styled from 'styled-components';

import useMenu from '@common/hooks/useMenu';

const DocumentSelector = ({
  documents = [],
  selectedDocumentId,
  onSetSelectedDocument,
  onSelectedDocumentClick,
}) => {
  const [hoveredElementId, setHoveredElementId] =
    React.useState(selectedDocumentId);
  const [searchText, setSearchText] = React.useState('');
  const dropdownAnchor = React.useRef(null);
  const anchorCoordinate = dropdownAnchor.current
    ? dropdownAnchor.current.getBoundingClientRect()
    : {};

  const filesToDisplay = React.useMemo(() => {
    return documents.reduce((acc, doc, i) => {
      acc.push(
        R.compose(
          R.assoc('initialIndex', i),
          R.pick(['friendlyName', 'createdAt', '_id']),
        )(doc),
      );
      return acc;
    }, []);
  }, [documents]);

  const filteredFiles = React.useMemo(() => {
    const searchParams = new RegExp(searchText, 'i');
    return filesToDisplay?.filter((file) =>
      file.friendlyName?.match(searchParams),
    );
  }, [filesToDisplay, searchText]);

  const onSetSearchText = React.useCallback((e) => {
    setSearchText(e.target.value);
  }, []);

  const dropdownRef = React.useRef(null);
  const [isMenuOpen, onMenuHover, onMenuLeave, onTargetClick, closeMenu] =
    useMenu(dropdownRef);

  // biome-ignore lint/correctness/useExhaustiveDependencies: More Deps Than Needed
  const onSelectFile = React.useCallback(
    (index) => {
      onSetSelectedDocument(documents[index]?._id);
      closeMenu();
      setSearchText('');
    },
    [documents, onSetSelectedDocument, closeMenu, setSearchText],
  );
  const selectedDocument = documents.find(
    (doc) => doc?._id === selectedDocumentId,
  );
  return (
    <div>
      <DocumentSelector.DocumentNameWrapper>
        <DocumentSelector.DocumentName
          onClick={() => {
            onSelectedDocumentClick(selectedDocumentId);
          }}
        >
          {R.pathOr('', ['friendlyName'], selectedDocument)}
        </DocumentSelector.DocumentName>
        <DocumentSelector.DropDownIcon
          onClick={onTargetClick}
          ref={dropdownAnchor}
        />
        <DocumentSelector.DropDownContainer>
          {isMenuOpen ? (
            <DocumentSelector.DropDownWrapper
              anchor={anchorCoordinate}
              ref={dropdownRef}
              onMouseLeave={onMenuLeave}
              onMouseEnter={onMenuHover}
            >
              <DocumentSelector.DropDownLabel>
                Change document
              </DocumentSelector.DropDownLabel>
              <DocumentSelector.InputContainer>
                <DocumentSelector.SearchIcon />
                <DocumentSelector.SearchInput
                  placeholder="Search for a document..."
                  value={searchText}
                  onChange={onSetSearchText}
                />
              </DocumentSelector.InputContainer>
              <DocumentSelector.FileList>
                {filteredFiles.map((file, i) => (
                  <DocumentSelector.FileOption
                    key={file?.friendlyName + i}
                    onClick={() => {
                      onSelectFile(file?.initialIndex);
                      setHoveredElementId(file?._id);
                    }}
                    isHovered={file?._id === hoveredElementId}
                  >
                    <DocumentSelector.FileName>
                      {file?.friendlyName}
                    </DocumentSelector.FileName>
                    <DocumentSelector.CreationDate>
                      {`Uploaded ${
                        file?.createdAt
                          ? moment(file?.createdAt).format('LL')
                          : ''
                      }`}
                    </DocumentSelector.CreationDate>
                  </DocumentSelector.FileOption>
                ))}
              </DocumentSelector.FileList>
            </DocumentSelector.DropDownWrapper>
          ) : null}
        </DocumentSelector.DropDownContainer>
      </DocumentSelector.DocumentNameWrapper>
    </div>
  );
};

DocumentSelector.DocumentNameWrapper = styled.span`
  display: flex;
  font-size: 12px;
`;

DocumentSelector.DocumentName = styled.span`
  line-height: 22px;
  text-decoration: underline;
  padding-right: 4px;
  max-width: 20vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
`;

DocumentSelector.SearchInput = styled(Input)`
  border: 1px solid #d9d9d9;
  box-sizing: border-box;
  border-radius: 2px;
  padding-left: 27px;
  width: 100%;
`;

DocumentSelector.SearchIcon = styled(SearchOutlined)`
  position: relative;
  z-index: 10;

  > svg {
    position: absolute;
    left: 10px;
    top: 9px;
  }
`;

DocumentSelector.DropDownContainer = styled.div`
  position: relative;
`;

DocumentSelector.DropDownWrapper = styled.div`
  background: white;
  width: 290px;
  padding: 10px 0;
  position: absolute;
  top: 25px;
  left: -136px;
  z-index: 1;
  box-shadow: ${(props) => props.theme.shadows.raitingShadow};
  border-radius: 2px;
`;

DocumentSelector.DropDownLabel = styled.span`
  padding-left: 12px;
  font-weight: 600;
  font-size: 14px;
  color: #262626;
`;

DocumentSelector.FileList = styled.div`
  max-height: 210px;
  overflow: scroll;
  padding-top: 5px;
`;

DocumentSelector.FileOption = styled.div`
  padding: 5px 12px;
  display: grid;
  cursor: pointer;
  color: ${(props) => props.theme.mineShaft};
  background-color: ${(props) => (props.isHovered ? '#F4F8FD' : 'none')};

  > span:first-child {
    font-weight: ${(props) => (props.isHovered ? 600 : 400)};
  }
`;

DocumentSelector.DropDownIcon = styled(CaretDownOutlined)`
  display: grid;
  align-self: center;
  cursor: pointer;
`;

DocumentSelector.FileName = styled.span`
  font-size: 14px;
  line-height: 22px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

DocumentSelector.CreationDate = styled.span`
  font-size: 12px;
  line-height: 20px;
`;

DocumentSelector.InputContainer = styled.div`
  padding: 10px 12px 4px;
  width: 100%;
  display: flex;
`;

export default DocumentSelector;
