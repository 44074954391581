import { gql } from '@apollo/client';

import { namespace } from '../constants';

export const FETCH_DOCUMENT_FORM = `${namespace}/FETCH_DOCUMENT_FORM`;

const FETCH_DOCUMENT_FORM_QUERY = gql`
  query getDocumentForm($data: GetDocumentFormInput!) {
    getDocumentForm(data: $data) {
      _id
      url
      friendlyName
      fillableForm {
        _id
        annotations
        formFields
        formFieldValues
        attachments
        format
        pdfId
        status
      }
    }
  }
`;

export const fetchDocumentForm = (data) => ({
  type: FETCH_DOCUMENT_FORM,
  payload: {
    key: 'getDocumentForm',
    graphql: {
      mutation: FETCH_DOCUMENT_FORM_QUERY,
      variables: { data },
    },
  },
});
