const ErrorStatus = () => (
  <svg
    viewBox="0 0 12 12"
    display="block"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-cy="errorStatusCircleIcon"
  >
    <rect width="12" height="12" rx="6" fill="#F5222D" />
    <path
      d="M9.15 3.941a.626.626 0 0 0-.885-.884L6.208 5.115 4.15 3.06a.626.626 0 0 0-.885.884L5.324 6 3.267 8.059a.626.626 0 0 0 .885.884l2.056-2.058 2.06 2.056a.626.626 0 0 0 .884-.884L7.093 6 9.15 3.941Z"
      fill="#fff"
    />
  </svg>
);
export default ErrorStatus;
