import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Badge, Popover, Tag } from 'antd';
import moment from 'moment';
import styled from 'styled-components';

import { USA_STATES } from '@common/constants';
import { ConnectedLicenseStatus } from '@modules/document/constants';

import ErrorBadge from './ErrorBadge';
import PendingBadge from './PendingBadge';

const NPNConnectedLicenseSummary = ({ license = {} }) => {
  const { status, name, number, customData, initialData } = license;
  const { licenses } = customData;

  return (
    <StyledWrapper>
      <Number number={number} initialData={initialData} />
      {status === ConnectedLicenseStatus.ACTIVE && (
        <LicensesStates licenses={licenses} />
      )}
      {status !== ConnectedLicenseStatus.ACTIVE && (
        <>
          <Name name={name} initialData={initialData} />
          <Status status={status} />
        </>
      )}
    </StyledWrapper>
  );
};

const Number = ({ number, initialData }) => {
  if (number) {
    return (
      <StyledField>
        <StyledLabel>NPN</StyledLabel>
        <span>{number}</span>
      </StyledField>
    );
  }

  if (initialData?.producerNumber) {
    return (
      <StyledField>
        <StyledLabel>NPN</StyledLabel>
        <span>{initialData?.producerNumber}</span>
      </StyledField>
    );
  }

  return (
    <>
      <StyledField>
        <StyledLabel>State Lic. #</StyledLabel>
        <span>{initialData?.licenseNumber}</span>
      </StyledField>
      <StyledField>
        <StyledLabel>State</StyledLabel>
        <span>{initialData?.licenseState}</span>
      </StyledField>
    </>
  );
};

const Status = ({ status }) => (
  <StyledField>
    <StyledLabel>Status</StyledLabel>
    {status === ConnectedLicenseStatus.PENDING && <PendingBadge />}
    {(status === ConnectedLicenseStatus.INACTIVE ||
      status === ConnectedLicenseStatus.INVALID) && <ErrorBadge />}
  </StyledField>
);

const Name = ({ name, initialData }) => (
  <StyledField>
    <StyledLabel>Name</StyledLabel>
    <span>{name || `${initialData?.firstName} ${initialData?.lastName}`}</span>
  </StyledField>
);

const LicensesStates = ({ licenses = [] }) => (
  <StyledField>
    <StyledLabel>Licensed states</StyledLabel>
    <StyledTagsContainer>
      {licenses?.map(
        ({ stateCode, licenseStatus, licenseExpirationDate, loa }) => (
          <Popover
            content={() => (
              <StateLicensePopoverContent
                stateCode={stateCode}
                licenseStatus={licenseStatus}
                licenseExpirationDate={licenseExpirationDate}
                loa={loa}
              />
            )}
            title="License information"
            trigger="hover"
            key={stateCode}
          >
            {licenseStatus === 'Y' ? (
              <Tag icon={<CheckCircleOutlined />} color="success">
                {stateCode}
              </Tag>
            ) : (
              <Tag icon={<CloseCircleOutlined />} color="error">
                {stateCode}
              </Tag>
            )}
          </Popover>
        ),
      )}
    </StyledTagsContainer>
  </StyledField>
);

const getLoaText = (loa) => {
  if (!loa?.length) {
    return '-';
  }

  const more = loa?.slice(1)?.length;
  const firstLoaName = loa[0].loaName;

  return `${firstLoaName}${more ? `, +${more} more` : ''}`;
};

const StateLicensePopoverContent = ({
  stateCode,
  licenseStatus,
  licenseExpirationDate,
  loa,
}) => {
  return (
    <StyledPopoverContentWrapper>
      <StyledField>
        <StyledLabel>Lines of Authority</StyledLabel>
        <span>{getLoaText(loa)}</span>
      </StyledField>
      <StyledField>
        <StyledLabel>State</StyledLabel>
        <span>{USA_STATES[stateCode]}</span>
      </StyledField>
      <StyledField>
        <StyledLabel>Expiration</StyledLabel>
        <span>
          {licenseExpirationDate
            ? moment(licenseExpirationDate).format('ll')
            : '-'}
        </span>
      </StyledField>
      <StyledField>
        <StyledLabel>Status</StyledLabel>
        <span>
          {licenseStatus === 'Y' ? (
            <Badge color="green" text="Active" />
          ) : (
            <Badge color="red" text="Inactive" />
          )}
        </span>
      </StyledField>
    </StyledPopoverContentWrapper>
  );
};

const StyledPopoverContentWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 16px 40px;
  grid-template-areas: '. .' '. .';
  max-width: 420px;
`;

const StyledWrapper = styled.div`
  display: flex;
  gap: 8px 10%;
`;

const StyledField = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledTagsContainer = styled.div`
  margin-top: 8px;
  display: flex;
  row-gap: 8px;
  flex-flow: wrap;
`;

const StyledLabel = styled.span`
  font-size: 12px;
  color: ${(props) => props.theme.colors.lightTextGray};
`;

export default NPNConnectedLicenseSummary;
