import { useTrustLayerV2FeatureFlag } from '@modules/feature-flags/hooks';
import PartyDetailsPage from '@modules/party/pages/PartyDetailsPage';
import { PRIMARY_RECORDS_TABS } from '@modules/primary-records';
import { Navigate, useParams } from 'react-router-dom';
import { legacyPartyDetailsTabToPrimaryRecordDetailsTabMap } from '../constants';

export const PrimaryRecordPageGuard = () => {
  const { isTlV2FeatureFlagEnabled } = useTrustLayerV2FeatureFlag();
  const { partyId, tab, organizationSlugifyName, conversationId } = useParams();

  if (!isTlV2FeatureFlagEnabled) {
    return <PartyDetailsPage />;
  }

  const BASE_PATH = `/${organizationSlugifyName}/p/parties/${partyId}`;

  return conversationId ? (
    <Navigate
      to={`${BASE_PATH}/${PRIMARY_RECORDS_TABS.Messages.key}/${conversationId}`}
    />
  ) : (
    <Navigate
      to={`${BASE_PATH}/${tab ? legacyPartyDetailsTabToPrimaryRecordDetailsTabMap[tab] : PRIMARY_RECORDS_TABS.Requests.key}`}
    />
  );
};
