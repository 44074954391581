import { FolderOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import qs from 'query-string';
import * as R from 'ramda';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import ListPageHeader from '@common/components/ListPageHeader';
import { HideForViewerRole } from '@modules/organization-member/containers/HideForRoles';
import { ReportType } from '@modules/report/constants';
import { getReportById } from '@modules/report/selectors';

const ProjectsHeaderContainer = ({
  totalCount,
  onClickToAction,
  onSuccess,
}) => {
  const { partyId } = useParams();
  const navigate = useNavigate();
  const { search, pathname } = useLocation();

  const searchParams = qs.parse(search);

  const toggleProjectsStatus = () => {
    const newParams =
      searchParams?.status === 'inactive'
        ? R.dissoc('status', searchParams)
        : R.assoc('status', 'inactive', searchParams);
    onSuccess();
    navigate(`${pathname}?${qs.stringify(R.dissoc('page', newParams))}`, {
      relative: true,
    });
  };

  const report = useSelector((state) =>
    getReportById(state, searchParams?.report),
  );

  const toggleStatusLabel = `show ${
    searchParams?.status === 'inactive' ? 'active' : 'inactive'
  }`;

  return (
    <>
      <ListPageHeader
        report={report}
        controlButtons={
          <HideForViewerRole>
            <Button
              type="primary"
              icon={<FolderOutlined />}
              onClick={onClickToAction}
            >
              {partyId ? 'Add to project' : 'New project'}
            </Button>
          </HideForViewerRole>
        }
        fallbackEntitiesName="projects"
        statusTitleLabel={
          searchParams?.status === 'inactive' ? 'Inactive' : 'Active'
        }
        toggleStatusButtonLabel={toggleStatusLabel}
        onToggleStatusClick={toggleProjectsStatus}
        total={totalCount}
        reportsType={ReportType.Projects}
      />
    </>
  );
};

export default ProjectsHeaderContainer;
