import { Drawer } from 'antd';
import styled from 'styled-components';

const ComplianceProfileViewDrawer = ({
  showConfirmClose,
  isShow,
  title,
  children,
}) => (
  <StyledDrawer
    rootClassName="cy-complianceProfileDrawer"
    title={title}
    placement="right"
    closable={false}
    onClose={showConfirmClose}
    open={isShow}
    contentWrapperStyle={{ width: '80%', minWidth: '1000px' }}
  >
    {children}
  </StyledDrawer>
);

const StyledDrawer = styled(Drawer)`
  .ant-anchor-link-active {
    .ant-anchor-link-title {
      color: inherit;
    }
  }

  .ant-drawer-wrapper-body {
    overflow: hidden !important;
  }

  .ant-drawer-body {
    padding-top: 0;
    display: flex;
  }

  .ant-drawer-header {
    padding-bottom: 0;
    border-bottom: none !important;
  }

  .ant-drawer-header-title {
    position: relative;
  }

  .ant-drawer-title {
    width: 100%;
  }
`;

export default ComplianceProfileViewDrawer;
