import { ClockCircleFilled, SyncOutlined } from '@ant-design/icons';
import { Tag, Tooltip } from 'antd';
import moment from 'moment';
import styled from 'styled-components';

const Badge = styled(Tag)`
  font-size: 10px;
  line-height: 16px !important;
  border-radius: 99em;
  color: #fff;
  border-color: transparent;
  background-color: #333;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.04);
  font-weight: 500;
`;

const WaivedBadge = styled(Badge)`
  background-color: #8099bc;
`;

const OverriddenBadge = styled(Badge)`
  background-color: #f90;
`;

const WaitingCompletionBadge = styled(Badge)`
  background-color: ${({ theme }) => theme.colors.lightGray};
  color: ${({ theme }) => theme.colors.darkGrayText};
`;

export const Waived = ({
  until,
  isAutomaticallyWaivedAcrossProjects,
}: {
  until?: string;
  isAutomaticallyWaivedAcrossProjects?: boolean;
}) =>
  until ? (
    <Tooltip title={`until ${moment(until).format('ll')}`}>
      <WaivedBadge>
        <ClockCircleFilled /> Waived
        {isAutomaticallyWaivedAcrossProjects && <SyncOutlined />}
      </WaivedBadge>
    </Tooltip>
  ) : (
    <WaivedBadge>
      Waived {isAutomaticallyWaivedAcrossProjects && <SyncOutlined />}
    </WaivedBadge>
  );

export const Overridden = ({
  until,
  isAutomaticallyWaivedAcrossProjects,
}: {
  until?: string;
  isAutomaticallyWaivedAcrossProjects?: boolean;
}) =>
  until ? (
    <Tooltip title={`until ${moment(until).format('ll')}`}>
      <OverriddenBadge>
        <ClockCircleFilled /> Overridden
        {isAutomaticallyWaivedAcrossProjects && <SyncOutlined />}
      </OverriddenBadge>
    </Tooltip>
  ) : (
    <OverriddenBadge>
      Overridden {isAutomaticallyWaivedAcrossProjects && <SyncOutlined />}
    </OverriddenBadge>
  );

export const WaitingCompletion = () => (
  <WaitingCompletionBadge>Waiting for completion</WaitingCompletionBadge>
);
