import * as R from 'ramda';

import { DEFAULT_PROJECT_FIELDS } from '../constants';

export const normalizeProjectToServer = (project) =>
  R.compose(
    (currentProject) => ({
      ...currentProject,
      partiesData: project.parties.map((party) => ({
        partyId: party._id,
        complianceProfileId: R.path(
          ['partyComplianceProfile', 'complianceProfile'],
          party,
        ),
      })),
    }),
    R.omit(['parties']),
    R.pick(DEFAULT_PROJECT_FIELDS),
  )(project);
