import { Modal } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as ComplianceProfileActions from '../actions';
import ChecklistAttribute from '../components/ChecklistAttribute';
import { getCurrentDocumentChecklists } from '../selectors';
import { getAttributesByDocumentType } from '../utils/checklist-helpers';

const ChecklistAttributeContainer = ({ checklist, type }) => {
  const [value, setValue] = useState('');
  const [isShowForm, setIsShowForm] = useState(false);
  const currentChecklists = useSelector(getCurrentDocumentChecklists);

  const dispatch = useDispatch();

  useEffect(() => {
    if (value) {
      setIsShowForm(true);
    }
  }, [value]);

  const updateChecklistAttribute = () => {
    const checklistToUpdate = {
      ...checklist,
      attributeLabel: value,
    };

    dispatch(
      ComplianceProfileActions.setCurrentDocumentChecklists(
        currentChecklists.map((x) => {
          if (x.attributeId === checklist.attributeId) {
            return checklistToUpdate;
          }

          return x;
        }),
      ),
    );
    setIsShowForm(false);
    setValue('');
  };

  const deleteChecklistAttribute = () => {
    Modal.confirm({
      title: 'Are you sure you want to delete this checklist item?',
      okText: 'Delete item',
      okType: 'danger',
      onOk: () => {
        const attributesByType = getAttributesByDocumentType(
          currentChecklists,
          type,
        );

        if (attributesByType.length === 0) {
          dispatch(
            ComplianceProfileActions.setCurrentDocumentChecklists(
              currentChecklists.map((x) => {
                if (x.attributeId === checklist.attributeId) {
                  return {
                    ...x,
                    attributeId: '',
                    attributeLabel: '',
                  };
                }

                return x;
              }),
            ),
          );
        } else {
          dispatch(
            ComplianceProfileActions.setCurrentDocumentChecklists(
              currentChecklists.filter(
                (x) => x.attributeId !== checklist.attributeId,
              ),
            ),
          );
        }
      },
      onCancel: () => {},
      zIndex: 1200,
    });
  };

  return (
    <ChecklistAttribute
      checklist={checklist}
      isShowForm={isShowForm}
      setIsShowForm={setIsShowForm}
      value={value}
      setValue={setValue}
      updateChecklistAttribute={updateChecklistAttribute}
      deleteChecklistAttribute={deleteChecklistAttribute}
    />
  );
};

export default ChecklistAttributeContainer;
