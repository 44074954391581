import { LoadingOutlined } from '@ant-design/icons';
import { Button, Popover } from 'antd';
import noop from 'lodash/noop';
import moment from 'moment';
import { useMemo, useState } from 'react';
import styled from 'styled-components';

import PulseIconWithStatus from '@common/components/PulseIconWithStatus';
import ErrorStatus from '@common/icons/ErrorStatus';
import SuccessStatus from '@common/icons/SuccessStatus';
import WarningStatus from '@common/icons/WarningStatus';
import { useSubscription } from '@graphql/hooks';
import { POLICY_STATUSES } from '@modules/document/constants';
import { CHECK_POLICY_SUBSCRIPTION } from '@modules/document/subscriptions';

type PulsePolicyInfoProps = {
  insurerNAIC: string;
  policyNumber: string;
  showInviteBrokerButton?: boolean;
  onInviteBroker?: () => void;
};

type PopoverContentByStatusProps = {
  status: (typeof POLICY_STATUSES)[keyof typeof POLICY_STATUSES];
  responseData: string | null;
  showInviteBrokerButton: boolean;
  onInviteBroker?: () => void;
};

const PopoverContentByStatus = ({
  status,
  responseData,
  showInviteBrokerButton,
  onInviteBroker = noop,
}: PopoverContentByStatusProps) => {
  switch (status) {
    case POLICY_STATUSES.inforce:
      return (
        <StyledPopoverContentWrapper>
          <div className="basicInfo-icon">
            <SuccessStatus />
          </div>
          <div className="basicInfo-content">
            <strong>Policy found</strong>
            <p>
              Status: <span className="success">ACTIVE (IN FORCE)</span>
            </p>
            <p className="date">Lookup perfomed on {responseData}</p>
            <p>
              <a
                href="https://trustlayer.io/pulse-policy-status-learn-more"
                target="blank"
                rel="noopener norefer"
              >
                Learn more
              </a>
            </p>
          </div>
        </StyledPopoverContentWrapper>
      );

    case POLICY_STATUSES.renewed:
      return (
        <StyledPopoverContentWrapper>
          <div className="basicInfo-icon">
            <SuccessStatus />
          </div>
          <div className="basicInfo-content">
            <strong>Policy found</strong>
            <p>
              Status: <span className="success">ACTIVE (RENEWED)</span>
            </p>
            <p className="date">Lookup perfomed on {responseData}</p>
            <p>
              <a
                href="https://trustlayer.io/pulse-policy-status-learn-more"
                target="blank"
                rel="noopener norefer"
              >
                Learn more
              </a>
            </p>
          </div>
        </StyledPopoverContentWrapper>
      );
    case POLICY_STATUSES.expired:
    case POLICY_STATUSES.canceled:
      return (
        <StyledPopoverContentWrapper>
          <div className="basicInfo-icon">
            <ErrorStatus />
          </div>
          <div className="basicInfo-content">
            <strong>Policy found</strong>
            <p>
              Status: <span className="error">CANCELLED</span>
            </p>
            <p className="date">Lookup perfomed on {responseData}</p>
            <p>
              <a
                href="https://trustlayer.io/pulse-policy-status-learn-more"
                target="blank"
                rel="noopener norefer"
              >
                Learn more
              </a>
            </p>
          </div>
        </StyledPopoverContentWrapper>
      );

    case POLICY_STATUSES.nonRenewed:
    case POLICY_STATUSES.pendingCancellation:
      return (
        <StyledPopoverContentWrapper>
          <div className="basicInfo-icon">
            <WarningStatus />
          </div>
          <div className="basicInfo-content">
            <strong>Policy found</strong>
            <p>
              Status: <span className="warn">PENDING CANCELLATION</span>
            </p>
            <p className="date">Lookup perfomed on {responseData}</p>
            <p>
              <a
                href="https://trustlayer.io/pulse-policy-status-learn-more"
                target="blank"
                rel="noopener norefer"
              >
                Learn more
              </a>
            </p>
          </div>
        </StyledPopoverContentWrapper>
      );

    case POLICY_STATUSES.error:
    case POLICY_STATUSES.notFound:
      return (
        <StyledPopoverContentWrapper>
          <div className="basicInfo-icon">
            <PulseIconWithStatus />
          </div>
          <div className="basicInfo-content">
            <strong>Policy not found</strong>
            <p className="date">Lookup perfomed on {responseData}</p>
            <p>
              TrustLayer could not find this policy with any of our existing
              data partners.
            </p>
            <p>
              <a
                href="https://trustlayer.io/pulse-policy-status-learn-more"
                target="blank"
                rel="noopener norefer"
              >
                Learn more
              </a>
              {showInviteBrokerButton && (
                <InviteBrokerButton type="link" onClick={onInviteBroker}>
                  Invite Broker
                </InviteBrokerButton>
              )}
            </p>
          </div>
        </StyledPopoverContentWrapper>
      );

    default:
      return (
        <StyledPopoverContentWrapper>
          <div className="basicInfo-icon">
            <LoadingOutlined width="100%" />
          </div>
          <div className="basicInfo-content">
            <strong>Checking for policy...</strong>
            <p>
              TrustLayer Pulse is searching for this policy across its data
              partners.
            </p>
            <p>
              <a
                href="https://trustlayer.io/pulse-policy-status-learn-more"
                target="blank"
                rel="noopener norefer"
              >
                Learn more
              </a>
            </p>
          </div>
        </StyledPopoverContentWrapper>
      );
  }
};

export const PulsePolicyInfo = ({
  insurerNAIC,
  policyNumber,
  showInviteBrokerButton = false,
  onInviteBroker = noop,
}: PulsePolicyInfoProps) => {
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);
  const { data, error } = useSubscription(CHECK_POLICY_SUBSCRIPTION, {
    variables: { insurerNAIC, policyNumber },
  });

  const forceErrorStatus = error && POLICY_STATUSES.error;

  const responseData = useMemo(() => {
    return moment(
      (data?.checkPolicy?.verifiedAt || new Date()).toISOString(),
    ).format(`MMM DD, YYYY`);
  }, [data]);

  function handleOnInviteBroker() {
    setIsPopoverVisible(false);
    onInviteBroker();
  }

  return (
    <Popover
      open={isPopoverVisible}
      onOpenChange={(visible) => setIsPopoverVisible(visible)}
      content={
        <PopoverContentByStatus
          status={data?.checkPolicy?.status || forceErrorStatus}
          showInviteBrokerButton={showInviteBrokerButton}
          responseData={responseData}
          onInviteBroker={handleOnInviteBroker}
        />
      }
    >
      <PulseIconWithStatus status={data?.checkPolicy?.status} />
    </Popover>
  );
};

const InviteBrokerButton = styled(Button)`
  font-size: 12px;
  padding-left: 10px;
  height: initial;
  border: none;
`;

const StyledPopoverContentWrapper = styled.div`
  display: flex;
  max-width: 330px;

  .basicInfo-icon {
    width: 24px;
    font-size: 24px;
    color: ${({ theme }) => theme.colors.blue};
    margin-right: 8px;
    flex-shrink: 0;
  }

  .basicInfo-content {
    strong {
      font-weight: 600;
      font-size: 14px;
      margin-bottom: 5px;
    }

    .date {
      color: ${({ theme }) => theme.colors.grayText};
    }

    p {
      margin-bottom: 4px;
      font-size: 12px;
      color: ${({ theme }) => theme.colors.mineShaft};

      .error {
        color: ${({ theme }) => theme.colors.red};
      }

      .warn {
        color: ${({ theme }) => theme.colors.yellow};
      }

      .success {
        color: ${({ theme }) => theme.colors.green};
      }
    }
  }
`;
