import * as R from 'ramda';

import { STATE_KEY } from '../constants';

export const getProjectCustomFields = R.compose(
  R.values,
  R.pathOr({}, [STATE_KEY, 'projectCustomFields']),
);

export const getPartyCustomFields = R.compose(
  R.values,
  R.pathOr({}, [STATE_KEY, 'partyCustomFields']),
);

export const getProjectCustomFieldsTotalCount = R.pathOr(0, [
  STATE_KEY,
  'projectCustomFieldsTotalCount',
]);

export const getPartyCustomFieldsTotalCount = R.pathOr(0, [
  STATE_KEY,
  'partyCustomFieldsTotalCount',
]);
