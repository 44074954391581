import { UserOutlined } from '@ant-design/icons';
import { Button, Form, Input } from 'antd';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

const SignAgreement = ({
  onSignerChange,
  onFormVisibilityChange,
  verifySigner,
  signer,
}) => {
  const isSignerNameMissing = verifySigner && !signer?.name;
  const [isFormVisible, setIsFormVisible] = useState(isSignerNameMissing);

  const handleOnFormSubmit = (data) => {
    onSignerChange(data);
    setIsFormVisible(false);
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: Legacy
  useEffect(() => {
    onFormVisibilityChange(isFormVisible);
  }, [isFormVisible]);

  return (
    <>
      I agree to be legally bound by this document and the{' '}
      <a
        className="link"
        href="//trustlayer.io/terms-of-use/"
        rel="noopener noreferrer"
        target="_blank"
      >
        TrustLayer Terms and Conditions
      </a>
      . Click on ‘I Agree’ to finalize the signatures you have added onto this
      document and to confirm that you have read{' '}
      <a
        className="link"
        href="//trustlayer.io/electronic-disclosure/"
        rel="noopener noreferrer"
        target="_blank"
      >
        this electronic consumer disclosure
      </a>
      .
      {verifySigner && (
        <SignAgreement.VerifySigner>
          <em>Verify your information</em>
          {!isFormVisible && (
            <SignAgreement.VerifySignerBox>
              <UserOutlined />
              <strong>{signer?.name}</strong>
              <span>{signer?.email}</span>
              <Button type="link" onClick={() => setIsFormVisible(true)}>
                {signer?.name ? 'Change' : 'Add'} name
              </Button>
            </SignAgreement.VerifySignerBox>
          )}
          {isFormVisible && (
            <SignAgreement.VerifySignerForm>
              <Form
                name="signer"
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 20 }}
                initialValues={signer}
                onFinish={handleOnFormSubmit}
              >
                <Form.Item
                  label="Name"
                  name="name"
                  rules={[
                    { required: true, message: 'Please input your name' },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item label="Email" name="email">
                  <Input disabled />
                </Form.Item>
                <div className="buttonsWrapper">
                  {!isSignerNameMissing && (
                    <Button
                      type="link"
                      className="cancel"
                      onClick={() => setIsFormVisible(false)}
                    >
                      Cancel
                    </Button>
                  )}
                  <Form.Item>
                    <Button type="link" htmlType="submit">
                      Update
                    </Button>
                  </Form.Item>
                </div>
              </Form>
            </SignAgreement.VerifySignerForm>
          )}
        </SignAgreement.VerifySigner>
      )}
    </>
  );
};

SignAgreement.VerifySigner = styled.div`
  margin-top: 16px;

  > em {
    display: block;
    font-style: normal;
    color: ${(props) => props.theme.colors.lightTextGray};
    font-size: 12px;
    margin-bottom: 4px;
  }
`;

SignAgreement.VerifySignerBox = styled.section`
  background: #fafafa;
  border: 1px solid #f0f0f0;
  padding: 16px 0;
  border-radius: 2px;
  display: grid;
  grid-template: '. avatar name button' '. avatar email button' / 0 32px 1fr 122px;
  column-gap: 12px;
  place-items: center start;

  > .anticon-user {
    grid-area: avatar;
    background: #6c7a8b;
    color: white;
    padding: 9px;
    border-radius: 100%;
  }

  > strong {
    grid-area: name;
    display: block;
    font-weight: 400;
  }

  > span {
    grid-area: email;
    display: block;
    font-size: 12px;
    color: ${(props) => props.theme.colors.lightTextGray};
  }

  > button {
    grid-area: button;
    font-size: 12px;
  }
`;

SignAgreement.VerifySignerForm = styled.section`
  padding: 16px 12px 4px;
  background: #f6fafe;
  border: 1px solid #bfe2ff;
  border-radius: 2px;

  .ant-row {
    margin-bottom: 16px;
  }

  .buttonsWrapper {
    display: flex;
    justify-content: flex-end;
  }

  button.cancel {
    color: ${(props) => props.theme.colors.black};
  }
`;

export default SignAgreement;
