import { graphql } from '@graphql/types';

export const UPDATE_PARTY_CUSTOM_FIELD_MUTATION = graphql(`
  mutation updatePartyDataCustomField($data: UpdatePartyDataCustomFieldInput!) {
    updatePartyDataCustomField(data: $data) {
      customFields {
        nodes {
          _id
          name
          value
          description
          type
          options {
            _id
            key
            value
          }
        }
        totalCount
      }
    }
  }
`);
