import { gql } from '@apollo/client';

import type { AnyGQLQueryAction, AnyGQLSuccessAction } from '@common/types';
import { ACTION_SUCCESS_SUFFIX } from '@store/constants';

import { namespace } from '../constants';

export const FETCH_FILLABLE_FORM_REQUIREMENTS =
  `${namespace}/FETCH_FILLABLE_FORM_REQUIREMENTS` as const;
export const FETCH_FILLABLE_FORM_REQUIREMENTS_SUCCESS =
  `${namespace}/FETCH_FILLABLE_FORM_REQUIREMENTS${ACTION_SUCCESS_SUFFIX}` as const;

const FETCH_FILLABLE_FORM_REQUIREMENTS_QUERY = gql`
  query listFillableFormRequirements(
    $data: ListFillableFormRequirementsInput!
  ) {
    listFillableFormRequirements(data: $data) {
      _id
      requirementComplianceStatusValue
      fillableForm {
        _id
        name
        additionalInfo
      }
      documents {
        _id
        url
        fillableForm {
          _id
          status
        }
      }
    }
  }
`;

export type FetchFillableFormRequirementsPayload = {
  partyId: string;
  projectId?: string | null;
  filler?: string;
};

export type FetchFillableFormRequirements = (
  payload: FetchFillableFormRequirementsPayload,
) => AnyGQLQueryAction<
  typeof FETCH_FILLABLE_FORM_REQUIREMENTS,
  { data: FetchFillableFormRequirementsPayload }
>;

export const fetchFillableFormRequirements: FetchFillableFormRequirements = (
  data,
) => ({
  type: FETCH_FILLABLE_FORM_REQUIREMENTS,
  payload: {
    key: 'listFillableFormRequirements',
    graphql: {
      fetchPolicy: 'no-cache',
      query: FETCH_FILLABLE_FORM_REQUIREMENTS_QUERY,
      variables: { data },
    },
  },
});

export type FillableFormRequirement = {
  _id: string;
  fillableForm: {
    _id: string;
    name: string;
    additionalInfo: string;
  };
  requirementComplianceStatusValue: string;
  documents: [
    {
      _id: string;
      url: string;
      fillableForm: {
        _id: string;
        status: string;
      };
    },
  ];
};

export type FetchFillableFormRequirementsSucceessAction = AnyGQLSuccessAction<
  typeof FETCH_FILLABLE_FORM_REQUIREMENTS_SUCCESS,
  FillableFormRequirement[]
>;
