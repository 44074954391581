import styled from 'styled-components';

const ProjectsEmptyState = () => (
  <ProjectsEmptyState.NoData>
    <ProjectsEmptyState.NoDataTitle>
      No projects to show
    </ProjectsEmptyState.NoDataTitle>
  </ProjectsEmptyState.NoData>
);

ProjectsEmptyState.NoData = styled.div`
  margin: auto;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

ProjectsEmptyState.NoDataTitle = styled.h2`
  margin-top: 25px;
  font-size: 14px;
  font-weight: 600;
  color: #7b91a7;
`;

ProjectsEmptyState.NoDataMessage = styled.p`
  margin: 10px 0 20px;
  font-size: 12px;
  color: ${(props) => props.theme.colors.grayText};
  text-align: center;
`;

export default ProjectsEmptyState;
