import { useDispatch } from 'react-redux';

import { updateSubjectStatus } from '@modules/party/actions';

type setSubjectStatusPayload = {
  partyId: string;
  subjectId: string;
  status: 'waived' | 'overridden';
  notes?: string;
  projectId?: string;
  resetRequirementOn?: string;
};

export const useUpdateSubjectMutation = () => {
  const dispatch = useDispatch();

  const removeSubjectStatus = ({
    partyId,
    subjectId,
    projectId,
  }: {
    partyId: string;
    subjectId: string;
    projectId?: string;
  }) =>
    dispatch(
      updateSubjectStatus({
        partyId,
        subjectId,
        projectId,
        // BE needs this hardcoded value in order to remove the waiver or override. The new status will be compliant or non compliant base don how it was before
        status: 'non_compliant',
      }),
    );

  const setSubjectStatus = (payload: setSubjectStatusPayload) => {
    dispatch(updateSubjectStatus(payload));
  };

  return {
    setSubjectStatus,
    removeSubjectStatus,
  };
};
