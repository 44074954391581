import type { ReactNode } from 'react';
import { useIsComplianceRequirementsAddOnEnabled } from '../hooks';

type ComplianceRequirementsAddOnFeatureProps = {
  children: ReactNode;
};

export const ComplianceRequirementsAddOnFeature = ({
  children,
}: ComplianceRequirementsAddOnFeatureProps) => {
  const { isComplianceRequirementsAddOnEnabled } =
    useIsComplianceRequirementsAddOnEnabled();

  if (!isComplianceRequirementsAddOnEnabled) return null;

  return <>{children}</>;
};
