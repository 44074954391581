import { InfoCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import styled from 'styled-components';

const AdCircleInfo = ({
  tooltipText,
  marginLeft = '7px',
  fontSize = '14px',
}) => (
  <Tooltip title={tooltipText}>
    <AdCircleInfo.IconContainer marginLeft={marginLeft} fontSize={fontSize}>
      <InfoCircleOutlined />
    </AdCircleInfo.IconContainer>
  </Tooltip>
);

AdCircleInfo.IconContainer = styled.span`
  margin-left: ${(props) => props.marginLeft};
  font-size: ${(props) => props.fontSize};
`;

export default AdCircleInfo;
