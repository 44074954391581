const SuccessStatus = () => (
  <svg
    viewBox="0 0 12 12"
    fill="none"
    display="block"
    xmlns="http://www.w3.org/2000/svg"
    data-cy="successStatusCircleIcon"
  >
    <rect width="12" height="12" rx="6" fill="#52C41A" />
    <g>
      <path
        d="M10.191 3.059a.626.626 0 0 1 0 .884l-5 5a.626.626 0 0 1-.884 0l-2.5-2.5a.626.626 0 0 1 .884-.884L4.75 7.615 9.309 3.06a.626.626 0 0 1 .884 0h-.002Z"
        fill="#fff"
      />
    </g>
  </svg>
);
export default SuccessStatus;
